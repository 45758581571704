'use strict';

angular.module('app')
    .service('scheduleService',
        function ($http, Request, utils) {
            var scheduleService = {};

            scheduleService.getList = function (page, page_size) {
                var obj = {};

                if (page) {
                    obj.page = page;
                }
                
                if (page_size) {
                    obj.page_size = page_size;
                }

                return Request.get('booking/' + utils.getCurrentShopSlug() + '/package-categories/',obj).then(function (res) {
                    _.each(res.data.results, function(val) {
                        val.startDate = moment(val.startDate).utc().format('YYYY-MM-DD');
                        val.endDate = moment(val.endDate).utc().format('YYYY-MM-DD');
                    });
                    
                    return {
                        allList: res.data,
                        data: res.data.results
                    };
                });
            };

            scheduleService.addTrip = function (trip) {
                var data = _.clone(trip);

                data.endDate = moment(data.startDate).add(data.duration,'days').format('YYYY-MM-DDThh:mm');
                data.startDate =  moment(data.startDate).format('YYYY-MM-DDThh:mm');

                if (!data.title) {
                    data.title = '';
                }

                return Request.post('booking/' + utils.getCurrentShopSlug() + '/package-categories/', data).then(function (res) {
                    return res.data;
                });
            };

            scheduleService.saveTrip = function (trip) {
                if (!trip.id || !trip.startDate || !trip.itinerary) {
                    return 0;
                }

                var _trip = _.clone(trip);

                _trip.endDate =  moment(_trip.endDate).format('YYYY-MM-DDThh:mm');
                _trip.startDate =  moment(_trip.startDate).format('YYYY-MM-DDThh:mm');

                
                return Request.patch('booking/' + utils.getCurrentShopSlug() + '/package-categories/' + _trip.id + '/', _trip).then(function (res) {
                    return res.data;
                });
            };

            scheduleService.deleteTrip = function (trip) {
                return Request.del('booking/' + utils.getCurrentShopSlug() + '/package-categories/' + trip.id + '/').then(function (res) {
                    return res.data;
                });
            };

            return scheduleService;
        });
