'use strict';

angular
  .module('app')
  .controller('TripPackageCtrl', function (
    $scope,
    info,
    utils,
    $rootScope,
    $location,
    groupTrips,
    $routeParams,
    gettextCatalog,
    SeoService
  ) {
    var vm = this,
      range = _.map(_.range(1, 101), function (v) { return { title: v, value: v}; }),
      availableSpotsRange = _.clone(range);

    availableSpotsRange.unshift({value: 0, title: gettextCatalog.getString('Sold out') });
    availableSpotsRange.unshift({ title: gettextCatalog.getString('Unlimited'), value: null });
    range.unshift({ title: gettextCatalog.getString('Unlimited'), value: null  });

    vm.savePackage = savePackage;
    vm.pricingPage = true;
    vm.tripId = $routeParams.id;
    vm.packageId = $routeParams.package;
    vm.currencyList = info.shop.CURRENCY_CHOICES;
    vm.saveCurrency = saveCurrency;
    $scope.trip = {
      tripId: $routeParams.id,
    };
    vm.range = range;
    vm.availableSpotsRange = availableSpotsRange;
    vm.getOperatorRooms = getOperatorRooms;
    vm.needTranslate = ['title'];

    activate();

    function activate() {
      if ($routeParams.id) {
        vm.loading = true;

        groupTrips.getTrip($routeParams.id).then(function (tripData) {
          if (utils.isArrayContainElementsFromOtherArray(tripData.autoTranslatedFields, vm.needTranslate)) {
            $rootScope.showNotify(gettextCatalog.getString('The text fields on this page have been auto-translated using Google Translate. You may edit them manually if you wish.'));
          }
          if (!tripData.availability) {
            tripData.availability = {};
          }
          vm.trip = tripData;
          vm.loading = false;
          if ($routeParams.package) getPackage();
        });
      }
    }

    function getPackage() {
      vm.loading = true;
      groupTrips.getTripPackage(vm.tripId, vm.packageId).then(function (data) {
        vm.add = _.clone(data);
        SeoService.generateMetaTags(vm.add.title + gettextCatalog.getString(' - Group Trips - PADI Travel for Business'));
        vm.loading = false;
      });
    }

    function savePackage(form, data) {
      form.itemsLeft.$setValidity('maxValue', true);
      if (!data.availability) {
        data.availability = {};
      }

      if (!_.isNull(data.availability.itemsTotal) && data.availability.itemsLeft > data.availability.itemsTotal) {
        form.itemsLeft.$setValidity('maxValue', false);
        $rootScope.showNotify(gettextCatalog.getString('Available spots can`t be more than total spots'));
      }
      if (form.$valid) {
        vm.loading = true;
        var packageData = _.clone(data);

        if (vm.packageId) {
          packageData.shopId = utils.getCurrentShopId();
          groupTrips.updateTripPackage(vm.tripId, packageData).then(function (data) {
            vm.loading = false;
            $rootScope.showNotify(gettextCatalog.getString('The package was successfully updated'));
            $location.path(utils.createURL('group_trips_pricing', {id: vm.tripId }));
          });
        } else {
          groupTrips.addTripPackage(vm.tripId, packageData).then(function (data) {
            vm.loading = false;
            $rootScope.showNotify(gettextCatalog.getString('The package was successfully added'));
            $location.path(utils.createURL('group_trips_pricing', {id: vm.tripId }));
          });
        }
      } else {
        console.log('error', form, data);
      }
    }

    function getOperatorRooms() {
      groupTrips.getOperatorRooms(vm.tripId).then(function (result) {
        vm.rooms = result;
        vm.rooms.push({id: null, title: gettextCatalog.getString('No cabin selected'), shopTitle: ''});
      });
    }

    function saveCurrency() {
      vm.loading = true;
      groupTrips.updateTrip({id: vm.trip.id, currency: vm.trip.currency}).then(function () {
        vm.loading = false;
        $rootScope.showNotify(gettextCatalog.getString('Trip currency was successfully updated'));
      }).catch(function (err) {
        console.log(err);
        vm.loading = false;
        $rootScope.showNotify(gettextCatalog.getString('Trip currency was successfully updated'));
      });
    }
  });
