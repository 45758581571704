'use strict';

angular.module('app')
    .controller('TripStartCtrl', function ($q, $timeout, info, $uibModalInstance, utils, itineraryService, itinerary) {

        var vm = this;

        vm.close = function() {
          $uibModalInstance.dismiss('cancel');
        };
        vm.ITINERARY_TIME_APPROXIMATION = info.shop.ITINERARY_TIME_APPROXIMATION;
        vm.save = save;
        vm.getAirport = itineraryService.getAirport;
        vm.itinerary = itinerary;
        vm.setId = setId;

        vm.itinerary.isOtherCheckin = itinerary.checkInOther ? true : false;
        vm.itinerary.isOtherTime = itinerary.timeDepartureOther ? true : false;
        vm.itinerary.isAirportOther = itinerary.airportPickupOther ? true : false;
        vm.itinerary.isHotelPickupOther = itinerary.hotelPickupOther ? true : false;


        if (vm.itinerary.timeDeparture) {
            vm.itinerary.timeDeparture = vm.itinerary.timeDeparture.slice(0,-3);
        }
        if (vm.itinerary.timeDepartureTo) {
            vm.itinerary.timeDepartureTo = vm.itinerary.timeDepartureTo.slice(0,-3);
        }
        if (vm.itinerary.checkInTo) {
            vm.itinerary.checkInTo = vm.itinerary.checkInTo.slice(0,-3);
        }
        if (vm.itinerary.checkInFrom) {
            vm.itinerary.checkInFrom = vm.itinerary.checkInFrom.slice(0,-3);
        }
        if (vm.itinerary.airportPickupTo) {
            vm.itinerary.airportPickupTo = vm.itinerary.airportPickupTo.slice(0,-3);
        }
        if (vm.itinerary.airportPickupFrom) {
            vm.itinerary.airportPickupFrom = vm.itinerary.airportPickupFrom.slice(0,-3);
        }
        if (vm.itinerary.hotelPickupFrom) {
            vm.itinerary.hotelPickupFrom = vm.itinerary.hotelPickupFrom.slice(0,-3);
        }
        if (vm.itinerary.hotelPickupTo) {
            vm.itinerary.hotelPickupTo = vm.itinerary.hotelPickupTo.slice(0,-3);
        }

        itineraryService.getHarbours().then(function (data) {
            vm.harbours = data;
            vm.loading = false;
        });

        function save(form) {
          form.$setSubmitted();

          if (form.$valid) {
            vm.loading = true;

            itineraryService.saveTripStart(vm.itinerary).then(function() {
                $uibModalInstance.close('saved');
            });
          }
        }

        function setId($item, field) {
            vm.itinerary[field] = $item.id;
        }

    });
