'use strict';

angular
  .module('app')
  .controller('AdminCtrl', function (
    $location,
    $rootScope,
    $routeParams,
    $scope,
    AuthExtranet,
    adminService,
    shopService,
    info,
    GTM,
    utils,
    groupTrips,
    gettextCatalog,
    walletService,
    $timeout
  ) {
    GTM.event('business_listing_admin_start');
    // GTM.variables({'business_listing_admin': "Start"});

    var vm = this;
    vm.countryList = info.countryList;
    vm.businessTypeList = info.shop.BUSINESS_TYPE;
    vm.loading = true;
    vm.deleteMilestone = deleteMilestone;
    vm.addLocalContact = addLocalContact;
    vm.deleteContact = deleteContact;
    vm.fileUpload = fileUpload;
    vm.setLinkToFile = setLinkToFile;
    vm.info = info;
    vm.getMinMilestoneValue = getMinMilestoneValue;
    vm.governmentChoices = info.shop.GOVERNMENT_ID_TYPE;
    vm.milestoneTypes = info.shop.MILESTONE_AMOUNT_TYPE;
    vm.validateMilestones = validateMilestones;
    vm.addMilestone = addMilestone;
    vm.handleAutoGenChange = handleAutoGenChange;
    vm.setPaymentCollected = setPaymentCollected;
    vm.gtTags = [];
    vm.HWLoading = false;

    var loadingCallbacks = {
      start: function () {
        $timeout(() => {
          vm.HWLoading = true;
        });
      },
      finish: function () {
        $timeout(() => {
          vm.HWLoading = false;
        });
      },
    };

    $rootScope.showListing = true;

    // $scope.$on('$locationChangeStart', function (event) {
    //   if (!formSubmited) {
    //     if ($scope.extranetForm.$dirty && $scope.extranetForm.$invalid) {
    //       event.preventDefault();
    //       utils.warningPopup().then(function () {
    //         $scope.extranetForm.$setPristine();
    //         $location.path($rootScope.currentUrl);
    //       }).catch(function () {
    //         $rootScope.currentUrl = '/admin/';
    //       });
    //     }

    //     if ($scope.extranetForm.$dirty && $scope.extranetForm.$valid) {
    //       if (vm.payPal && +vm.form.cancellationPolicy !== 10) {
    //         vm.form.isPaypal = true;
    //       } else {
    //         vm.form.isPaypal = false;
    //       }
    //       adminService.setInfo(vm.form).then(function () {
    //         $rootScope.checkedList.admin = true;
    //       });
    //     }
    //   }
    // });

    if ($routeParams.shop) {
      const shopId = +$routeParams.shop;
      if (shopId !== utils.getCurrentShopId()) {
        shopService.getShopData(shopId).then((shop) => {
          AuthExtranet.setChoosenShop(shop, true);
          $location.search({});
        });
      } else {
        $location.search({});
      }
    }

    getMilestones();

    adminService.getInfo().then(function (data) {
      vm.form = data;

      if (vm.form.bookingContactEmail.length > 0) {
        vm.form.bookingContactEmailJoined = vm.form.bookingContactEmail.join(
          ', '
        );
      }

      if (!vm.form.localEmergencyContacts.length) {
        vm.form.localEmergencyContacts = [{ phone: null, firstName: null }];
      }

      if (vm.form.grsDocument) {
        vm.form.fileName = vm.form.grsDocument.replace(/^.*[\\\/]/, '');
      }

      if (data.isPaypal && data.paypalAccountEmail) {
        vm.payPal = true;
      } else if (!data.isPaypal && data.bankCountry) {
        vm.internationalBank = true;
      }

      if (
        +$rootScope.selectedShop.resortKind === 10 ||
        +$rootScope.selectedShop.kind === 0
      ) {
        groupTrips.getTags().then(function (tags) {
          vm.gtTags = tags.map(function (tag) {
            return tag.title;
          });
        });
      }

      // setting shop currency to 'amount' choice;
      _.each(vm.milestoneTypes, function (type) {
        if (type[0] === 20) type[1] = data.shop.currency;
      });

      if (vm.form.hyperwalletUsers && vm.form.hyperwalletUsers.length) {
        let instanceTravel = new walletService.Wallet(),
          instanceAdventure = new walletService.Wallet();
        vm.form.hyperwalletUsers.forEach((item) => {
          if (item.verificationStatus === 'REQUIRED') {
            vm[`displayHWForm${item.instance}`] = true;
            if (item.instance === 10) {
              instanceTravel.loadHyperWallet(
                item.token,
                loadingCallbacks,
                'VerificationUI'
              );
            } else {
              instanceAdventure.loadHyperWallet(
                item.token,
                loadingCallbacks,
                'VerificationUI20'
              );
            }
          }
        });
      }

      $timeout(() => {
        vm.loading = false;
      }, 1000);
    });

    vm.saveMilestones = function (form) {
      // delete customer milestones to avoid validation errors
      if (vm.milestones.paymentInformation.isAutoCustomerMilestones) {
        vm.milestones.customerMilestones = [];
      }
      form.customerMilestonesForm.$submitted = true;
      form.supplierMilestonesForm.$submitted = true;

      if (
        form.customerMilestonesForm.$valid &&
        form.supplierMilestonesForm.$valid
      ) {
        vm.loading = true;
        vm.milestones.customCancellationText = vm.form.customCancellationText;
        vm.milestones.isCustomCancellation = vm.form.isCustomCancellation;
        adminService
          .saveMilestones(vm.milestones)
          .then(function () {
            // $location.path('/agreement/');
            $location.path(utils.createURL('payment_details'));
            $rootScope.checkedList.admin = true;

            $rootScope.showNotify(
              gettextCatalog.getString('Information was successfully saved')
            );
            vm.loading = false;
          })
          .catch(function (err) {
            vm.loading = false;
            _.each(Object.keys(err.data), function (key) {
              $rootScope.showNotify(err.data[key]);
              return;
            });
          });
      }
    };

    vm.saveInfo = function (form) {
      // prrevent some hack-saving from non-staff;
      if (
        !$rootScope.user.isStaff &&
        vm.form.isLocked &&
        $rootScope.checkedList.agreement
      ) {
        return;
      }

      // // check milestones;
      if (
        +$rootScope.selectedShop.resortKind === 20 &&
        $rootScope.user.isStaff &&
        !vm.form.basedOn
      ) {
        // except dive centers;
        if (
          !vm.milestones.supplierCancellationMilestones.length ||
          !vm.milestones.customerCancellationMilestones.length
        ) {
          $rootScope.showNotify(
            gettextCatalog.getString(
              'Please add CANCELLATION POLICY milestones'
            )
          );
          return;
        }
      }

      form.$setSubmitted();
      if (form.$valid) {
        vm.loading = true;

        if (vm.form.businessType && +vm.form.businessType === 10) {
          // delete unnecessary fields
          delete vm.form.legalEntityAddress;
          delete vm.form.legalEntityName;
          delete vm.form.legalEntityCountry;
          delete vm.form.legalEntityCity;
          delete vm.form.legalEntityState;
          delete vm.form.legalEntityZipCode;
        }

        if (_.get(vm.form, 'bookingContactEmailJoined.length') > 0) {
          vm.form.bookingContactEmail = vm.form.bookingContactEmailJoined.split(
            ','
          );
        }

        if (vm.payPal) {
          vm.form.isPaypal = true;
        } else {
          vm.form.isPaypal = false;
          vm.form.paypalAccountEmail = null;
        }

        adminService
          .setInfo(vm.form)
          .then(function () {
            GTM.variables({
              business_listing_admin: gettextCatalog.getString('Complete'),
            });
            GTM.event('business_listing_admin_complete');

            adminService
              .saveContacts(vm.form.localEmergencyContacts)
              .then(function () {
                const shopKind = +$rootScope.selectedShop.kind;
                if (
                  shopKind !== 0 &&
                  $rootScope.user.isStaff &&
                  !vm.form.basedOn
                ) {
                  vm.saveMilestones(form);
                  // adminService.saveMilestones(vm.milestones).then(function () {
                  //   $location.path('/agreement/');
                  //   $rootScope.checkedList.admin = true;

                  //   $rootScope.showNotify('Information was successfully saved');
                  //   vm.loading = false;
                  // }).catch(function (err) {
                  //   vm.loading = false;
                  //   _.each(Object.keys(err.data), function (key) {
                  //     $rootScope.showNotify(err.data[key]);
                  //     return;
                  //   });
                  // });
                } else {
                  $rootScope.checkedList.admin = true;

                  $rootScope.showNotify(
                    gettextCatalog.getString(
                      'Information was successfully saved'
                    )
                  );
                  vm.loading = false;
                  const isSignedAgreement =
                      $rootScope.choosenShop.isVacationShop &&
                      !$rootScope.checkedList.vacationAgreement,
                    // eslint-disable-next-line no-nested-ternary
                    route = isSignedAgreement
                      ? shopKind === 0
                        ? 'payment_details_adventures'
                        : 'payment_details'
                      : 'vacations_agreement';
                  $location.path(utils.createURL(route));
                }
              })
              .catch(function () {
                vm.loading = false;
              });
          })
          .catch(function (error) {
            vm.loading = false;
            _.each(error.data, function (val, key) {
              $scope.extranetForm[key].$invalid = true;
            });
          });
      }
    };

    function getMilestones() {
      adminService.getMilestones().then(function (data) {
        vm.milestones = data;

        // if (vm.milestones.paymentInformation.isAutoCustomerMilestones) {
        //   vm.milestones.customerMilestones = [];
        // }
      });
    }

    function addLocalContact() {
      return vm.form.localEmergencyContacts.push({});
    }

    function deleteContact($index, contact) {
      if (contact.id) {
        adminService.deleteContact(contact.id).then(function () {
          vm.form.localEmergencyContacts.splice($index, 1);
        });
      } else {
        vm.form.localEmergencyContacts.splice($index, 1);
      }
    }

    function fileUpload(file) {
      vm.form.fileName = file[0].name;
    }

    function setLinkToFile() {
      if (_.isString(vm.form.grsDocument)) {
        return vm.form.grsDocument;
      }

      return null;
    }

    function getMinMilestoneValue(index, milestonesArray, amountType) {
      if (amountType !== 10) return '';
      if (index !== 0) {
        return milestonesArray[index - 1].amount || 0;
      }
      return 0;
    }

    function addMilestone(type) {
      var curArray = [],
        otherArray = [],
        otherArrayType = '';
      if (type === 'customer') {
        curArray = vm.milestones.customerMilestones;
        otherArray = vm.milestones.supplierMilestones;
        otherArrayType = 'supplier';
      } else if (type === 'supplier') {
        curArray = vm.milestones.supplierMilestones;
        otherArray = vm.milestones.customerMilestones;
        otherArrayType = 'customer';
      } else {
        return;
      }

      var prevMilestone = curArray[curArray.length - 1];
      curArray.push({ amount: 100, amountType: 10 });

      if (+prevMilestone.amountType === 10 && prevMilestone.amount >= 100) {
        // last milestone should be 100% so prev can't be 100;
        prevMilestone.amount = 99;
      }

      if (otherArray.length === 1) {
        // to prevent errors, add milestone to other array if there's only one milestone;
        addMilestone(otherArrayType);
      }
    }

    function deleteMilestone($index, milestones, type) {
      milestones.splice($index, 1);
      if (type === 'payment') {
        checkLastMilestone();
      }
    }

    function checkLastMilestone() {
      if (
        vm.milestones.customerMilestones.length === 1 ||
        vm.milestones.supplierMilestones.length === 1
      ) {
        vm.milestones.customerMilestones.splice(1);
        vm.milestones.supplierMilestones.splice(1);
      }

      var lastSupplierMilestone = _.last(vm.milestones.supplierMilestones),
        lastCustomerMilestone = _.last(vm.milestones.customerMilestones);

      if (lastCustomerMilestone) {
        lastCustomerMilestone.amount = 100;
        lastCustomerMilestone.amountType = 10;
      }

      lastSupplierMilestone.amount = 100;
      lastSupplierMilestone.amountType = 10;
    }

    function validateMilestones(milestonesArray, form) {
      _.each(milestonesArray, function (milestone, index) {
        var prevMilestone = milestonesArray[index - 1],
          isLast = index === milestonesArray.length - 1;
        // isFirst = (index === 0);

        // if (isFirst && !vm.milestones.paymentInformation.isAutoCustomerMilestones) {
        //   vm.milestones.customerMilestones[0] = milestone;
        //   vm.milestones.supplierMilestones[0] = milestone;
        // }

        if (isLast) {
          return;
        }

        if (+milestone.amountType === 10) {
          if (milestone.amount >= 100) {
            // if this milestone amount more than 100 and it is not the last one
            milestone.error = gettextCatalog.getString(
              'This is not the last milestone, so the percentage of the total amount paid by this milestone must be less than 100%'
            );
            form['milestones' + index].$setValidity('wrong-value', false);
          } else if (
            prevMilestone &&
            +prevMilestone.amountType === 10 &&
            milestone.amount <= prevMilestone.amount
          ) {
            // if current and previous milestone is in % and current one is bigger than prev
            milestone.error = gettextCatalog.getString(
              'The percentage of the total amount paid for this booking must be higher than the total amount paid in the previous milestone'
            );
            form['milestones' + index].$setValidity('wrong-value', false);
          } else {
            milestone.error = '';
            form['milestones' + index].$setValidity('wrong-value', true);
          }
        } else {
          milestone.error = '';
          form['milestones' + index].$setValidity('wrong-value', true);
        }
      });
    }

    function setPaymentCollected(key, val) {
      _.last(vm.milestones[key]).isPaymentCollectedBySupplier = val;
    }

    function handleAutoGenChange() {
      if (vm.milestones.paymentInformation.isAutoCustomerMilestones) {
        vm.milestones.customerMilestones = [];
      }
      // else {
      //   vm.milestones.customerMilestones = _.clone(vm.milestones.supplierMilestones);
      // }
    }
  });
