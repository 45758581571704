'use strict';

angular.module('app').directive('hoverInfo', function ($window) {
  return {
    restrict: 'A',
    scope: {
      hoverText: '@',
    },
    link: function(scope, elem, attr) {
    	elem.on('mouseover', function() {
            $(elem).find('.tooltip').addClass('active')
        });

        elem.on('mouseout', function() {
            $(elem).find('.tooltip').removeClass('active')
        });
    }
  }
})
