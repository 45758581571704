/**
 * Created by artem on 02.03.17.
 */
'use strict';

angular.module('app')
    .directive('tooltipSurcharges', function ($timeout) {
        return {
            restrict: 'A',
            link: function(scope, elem, attr) {
                $timeout(function(){
                     var tooltip = $('#' + attr.targetId);

                    elem.on('mouseover', function() {
                        tooltip.addClass('active');

                        if (window.location.pathname.search('/checkout/') === 0) {
                            var _this = $(this).position();
                            tooltip.css({
                                left: _this.left - (tooltip.innerWidth() / 4)
                            });
                        }
                    });

                    elem.on('mouseout', function() {
                        tooltip.removeClass('active');
                    });
                });
            }
        };
    });