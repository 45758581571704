'use strict';

angular.module('app').directive('activityPhotoPopup', function ($uibModal) {
  return {
    restrict: 'A',
    scope: {
      callback: '&',
      isSinglePhoto: '=',
      isGroupTrip: '=',
      operator: '=',
      maxNumberOfPictures: '=',
    },
    link: function (scope, elem) {
      elem.on('touchstart, click', function () {
        var modalInstance = $uibModal.open({
          templateUrl: '../templates/_activity-photo-popup.html',
          controller: function ($q, $uibModalInstance, photoService, groupTrips) {
            var vm = this;

            vm.images = [];
            vm.selectedImages = [];
            vm.loading = true;
            vm.queryArray = [];

            if (scope.isGroupTrip) {
              vm.queryArray = [
                groupTrips.getBackground(scope.operator),
                groupTrips.getPhotos(scope.operator),
                groupTrips.getSpecies(scope.operator)
              ];
            } else {
              vm.queryArray = [
                photoService.getBackground(scope.operator),
                photoService.getPhotos(scope.operator),
                photoService.getSpecies(scope.operator)
              ];
            }

            vm.selectImage = function (img) {
              img.isActive = !img.isActive;

              if (img.isActive) {
                if (scope.isSinglePhoto && scope.callback) {
                  vm.close();
                  return scope.callback({ img: img });
                }
              }
              if (!img.isActive) {
                vm.selectedImages = _.reject(vm.selectedImages, { id: img.id });
              } else if (vm.selectedImages.length < scope.maxNumberOfPictures) {
                vm.selectedImages.push(img);
              } else {
                img.isActive = false;
              }
            };

            vm.close = function () {
              $uibModalInstance.close(1);
            };

            vm.saveImages = function () {
              vm.close();
              return scope.callback({ img: vm.selectedImages });
            };

            $q.all(vm.queryArray).then(function (data) {
              vm.images.push(data[0]);
              data[1].forEach(function (img) {
                img.category = 'photos';
                vm.images.push(img);
              });
              data[2].forEach(function (img) {
                img.category = 'species';
                vm.images.push(img);
              });
            });
          },
          controllerAs: 'ctrl',
          windowClass: 'uib-itinerary-popup',
          scope: scope,
        });

        return modalInstance.result;
      });
    },
  };
});
