'use strict';

angular.module('app')
  .service('mainInfo', function ($http, $location, Request) {
    return {
      getTopDestinations: function() {
        return Request.get('footer/top/').then(function(res){
          return res.data;
        });
      },
      getPhone: function() {
      	return Request.get('footer/contact/').then(function(res){
          return res.data;
        });
      }
    }


  });
