'use strict';

angular
  .module('app')
  .controller('TripAddEditCtrl', function (
    $timeout,
    info,
    localStorageService,
    $rootScope,
    $location,
    groupTrips,
    $routeParams,
    SeoService,
    gettextCatalog,
    utils
  ) {
    var vm = this;
    vm.tripEditPage = true;
    vm.add = {
      trips: [],
    };
    vm.customOperatorsToDelete = [];
    vm.cancellationPolicyTypes = info.activities.CANCELLATION_POLICY;
    vm.languagesSpoken = info.activities.LANGUAGES;
    vm.countryList = info.countryList;
    vm.areasList = [];
    vm.tags = [];
    vm.saveTrip = saveTrip;
    vm.operatorType = [
      {value: 10, title: gettextCatalog.getString('Liveaboard')},
      {value: 20, title: gettextCatalog.getString('Dive resort')}
    ];
    vm.selectOperator = selectOperator;
    vm.deleteOperator = deleteOperator;
    vm.selectedOperators = [];
    vm.getOperatorItineraries = getOperatorItineraries;
    vm.getAreas = getAreas;
    vm.selectItinerary = selectItinerary;
    vm.operatorHighlight = ($location.hash() === 'operators');
    vm.showOperatorSearch = false;
    var customOperatorsList = localStorageService.get('customOperator');
    vm.minDate = new Date();
    vm.needTranslate = ['cancellation_policy',
      'description',
      'good_to_know',
      'logistics',
      'payment_policy',
      'title' ];

    activate();

    function activate() {
      vm.tripId = $routeParams.id;
      if (vm.tripId) {
        vm.loading = true;
        vm.minDate = null;
        groupTrips.getTrip(vm.tripId).then(function (data) {
          vm.add = _.clone(data);
          if (utils.isArrayContainElementsFromOtherArray(vm.add.autoTranslatedFields, vm.needTranslate)) {
            $rootScope.showNotify(gettextCatalog.getString('The text fields on this page have been auto-translated using Google Translate. You may edit them manually if you wish.'));
          }
          SeoService.generateMetaTags(vm.add.title + gettextCatalog.getString(' - Group Trips - PADI Travel for Business'));

          if (vm.add.operatorType.length) {
            // ugly solution since we hardcoded operator types;
            _.each(vm.add.operatorType, function (type) {
              if (type === 10) {
                vm.operatorType[0].isChecked = true;
              } else if ( type === 20) {
                vm.operatorType[1].isChecked = true;
              }
            });
          }

          if (vm.add.country) {
            getAreas(vm.add.country);
          }

          vm.selectedOperators = vm.add.operatorsDisplay;
          _.each(vm.selectedOperators, function (operator) {
            if (operator.kind === 10) {
              getOperatorItineraries(operator);
            }
            if (operator.trips) {
              createItineraryTitles(operator.trips);
            }
          });

          vm.loading = false;
        });
      }

      if (customOperatorsList) {
        vm.customOperators = _.where(customOperatorsList, { tripId: vm.tripId || 'new' });
      }

      groupTrips.getTags().then(function (tags) {
        vm.tags = tags.map(function (tag) {
          return tag.title;
        });
      });
    }

    vm.operatorAutoSuggest = function (value) {
      if (value.length < 3) {
        return false;
      }
      return groupTrips.getOperators(value);
    };

    function saveTrip(form, data) {
      if (form.$valid) {
        form.$submitted = true;
        vm.loading = true;
        var tripData = _.clone(data),
          listingTypeTabData = null;
        tripData.operators = [];
        tripData.trips = [];

        // ugly solution since we hardcoded operator types;
        tripData.operatorType = [];
        _.each(vm.operatorType, function (type) {
          if (type.isChecked) tripData.operatorType.push(type.value);
        });

        if (tripData.operatorType.length) {
          vm.operatorTypeError = false;
        } else {
          vm.operatorTypeError = true;
          vm.loading = false;
          return;
        }

        if (vm.customOperators) {
          if (!tripData.customOperators) {
            tripData.customOperators = [];
          }
          _.each(vm.customOperators, function (operator) {
            if (!operator.isDeleted) {
              tripData.customOperators.push(operator.id);
            }
          });
        }
        customOperatorsList = localStorageService.set('customOperator', _.reject(customOperatorsList, { tripId: vm.tripId || 'new' }));

        if (tripData.languagesSpoken) {
          // BE asked to send lang ids in 'parameters'
          tripData.parameters = tripData.languagesSpoken.map(function (lang) {
            return lang.id;
          });
        }

        if (vm.selectedOperators.length) {
          tripData.operators = vm.selectedOperators.map(function (operator) {
            if (operator.trips && operator.trips[0]) {
              tripData.trips.push(operator.trips[0].id);
            }
            return operator.id;
          });
        }

        if (vm.operatorHighlight) vm.operatorHighlight = false;

        if ($routeParams.id) {
          if (vm.customOperatorsToDelete.length) {
            _.each(vm.customOperatorsToDelete, function (id) {
              groupTrips.deleteOperator($routeParams.id, id);
            });
          }
          groupTrips.updateTrip(tripData).then(function () {
            vm.loading = false;
            $rootScope.showNotify(gettextCatalog.getString('The trip was successfully changed'));
            activate();
          }).catch(function () {
            vm.loading = false;
          });
        } else {
          // data from previous tab;
          listingTypeTabData = localStorage.getItem('listingType');
          if (listingTypeTabData) {
            _.extend(tripData, JSON.parse(listingTypeTabData));
            // clear storage data;
            localStorage.removeItem('listingType');
          }

          groupTrips.addTrip(tripData).then(function (result) {
            if (result.data && result.data.id) {
              $rootScope.showNotify(gettextCatalog.getString('The trip was successfully created'));
              $location.path(utils.createURL('group_trips_pricing', {id: result.data.id }));
            }
          }).catch(function () {
            vm.loading = false;
          });
        }
      } else {
        console.log(form, data);
      }
    }

    function selectOperator(operator) {
      vm.selectedOperators.push(operator);
      if (operator.kind === 10) {
        getOperatorItineraries(operator);
        vm.operatorType[0].isChecked = true;
      } else if (operator.kind === 20) {
        vm.operatorType[1].isChecked = true;
      }

      if (!vm.add.country) {
        vm.add.country = operator.countryId;
        getAreas(vm.add.country);
        vm.add.area = operator.areaId;
      }
      vm.showOperatorSearch = false;
      vm.operatorSearch = '';
    }

    function deleteOperator(operator, index, customOperators) {
      if (operator.trips && operator.trips[0]) {
        var tripIndex = vm.add.trips.indexOf(operator.trips[0].id);
        if (tripIndex !== -1) vm.add.trips.splice(tripIndex, 1);
      }
      if (customOperators) {
        vm.add.customOperators = _.without(vm.add.customOperators, operator.id );
        vm.add.customOperatorsDisplay.splice(index, 1);
        vm.customOperatorsToDelete.push(operator.id);
      } else {
        vm.selectedOperators.splice(index, 1);
      }
    }

    function getOperatorItineraries(operator) {
      groupTrips.getOperatorItineraries(operator.id).then(function (result) {
        if (result) {
          operator.itinerariesList = result;
          createItineraryTitles(operator.itinerariesList);
        }
      });
    }

    function getAreas(locationId) {
      groupTrips.getAreas(locationId).then(function (result) {
        if (result) {
          vm.areasList = result;
        }
      });
    }

    function selectItinerary(itinerary) {
      // vm.add.trips.push(itinerary.id);
      if (itinerary) {
        vm.add.dateStart = itinerary.dateStart;
        vm.add.dateEnd = itinerary.dateEnd;
      }
    }

    function createItineraryTitles(itineraryList) {
      _.each(itineraryList, function (itinerary) {
        itinerary.formattedTitle = moment(itinerary.dateStart).format('MMM Do') + ' - ' + moment(itinerary.dateEnd).format('MMM Do, YYYY');
      });
    }
  });
