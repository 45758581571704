'use strict';

angular
  .module('app')
  .controller('TripPricingCtrl', function (
    $scope,
    info,
    $rootScope,
    groupTrips,
    $routeParams,
    SeoService,
    $location,
    gettextCatalog,
    utils
  ) {
    var vm = this;
    vm.saveTrip = saveTrip;
    vm.pricingPage = true;
    vm.currencyList = info.shop.CURRENCY_CHOICES;
    vm.creditCardFees = info.fees.CREDIT_CARD_FEE_PAID_BY_CHOICES;
    vm.deletePackage = deletePackage;
    vm.activatePackage = activatePackage;
    vm.saveCurrency = saveCurrency;

    activate();

    function activate() {
      vm.loading = true;
      groupTrips.getTrip($routeParams.id).then(function (data) {
        vm.tripData = _.clone(data);
        vm.tripId = vm.tripData.id;
        SeoService.generateMetaTags(vm.tripData.title + gettextCatalog.getString(' - Group Trips - PADI Travel for Business'));

        groupTrips.getTripTaxes().then(function (result) {
          vm.taxesList = result.data;
          vm.loading = false;
        }).catch(function () {
          vm.loading = false;
        });
      });

      groupTrips.getTripPackages($routeParams.id).then( function (res) {
        vm.packagesList = res.data;
      });
    }

    function saveTrip(form, data) {
      if (form.$valid) {
        vm.loading = true;
        var tripData = _.clone(data);

        groupTrips.updateTrip(tripData).then(function () {
          vm.loading = false;
          $rootScope.showNotify(gettextCatalog.getString('The trip was successfully updated'));
          $location.path(utils.createURL('group_trips_inclussions', {id: vm.tripId }));
        });
      } else {
        console.log('error', form, data);
      }
    }

    function deletePackage(packageId) {
      groupTrips.deleteTripPackage(vm.tripId, packageId).then( function (res) {
        $rootScope.showNotify('Package deleted');
        activate();
      });
    }

    function activatePackage(pckg) {
      vm.loading = true;
      var updateData = {
        id: pckg.id,
        isActive: pckg.isActive,
      };
      groupTrips.updateTripPackage(vm.tripId, updateData).then(function (data) {
        vm.loading = false;
      });
    }

    function saveCurrency() {
      vm.loading = true;
      groupTrips.updateTrip({id: vm.tripId, currency: vm.tripData.currency}).then(function () {
        vm.loading = false;
        $rootScope.showNotify(gettextCatalog.getString('Trip currency was successfully updated'));
      }).catch(function (err) {
        console.log(err);
        vm.loading = false;
        $rootScope.showNotify(gettextCatalog.getString('Trip currency was successfully updated'));
      });
    }
  });
