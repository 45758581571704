'use strict';

angular.module('app')
  .service('diveResortService', function ($q, Request, diviacLocalStorage, gettextCatalog, $rootScope, info, utils) {
    var diveResort = {};

    diveResort.getInfo = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/dive-resort/').then(function (res) {
        return res.data;
      });
    };

    diveResort.setInfo = function (data, disableMessage) {
      var slug = utils.getCurrentShopSlug();

      return Request.patch('shop/' + slug + '/parameters/', {parameters: data.parameters})
        .then(function () {
          return Request.patch('shop/' + slug + '/dive-resort/', data)
            .then(function (res) {
              if (!disableMessage) {
                $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved'));
              }

              return res.data;
            }).catch(function (res) {
              return res.data;
            });
        }).catch(function (res) {
          return res.data;
        });
    };

    return diveResort;
  });
