'use strict';

angular
  .module('app')
  .factory('authHttpResponseInterceptor', function ($q, $rootScope) {
    function handlerError(error) {
      _.each(error, function (errorMessage, key) {
        if (
          key === 'nonFieldErrors' ||
          key === 'isDraft' ||
          key === '__all__'
        ) {
          $rootScope.showNotify(errorMessage);
        } else if (_.isPlainObject(errorMessage)) {
          handlerError(errorMessage);
        } else if (key) {
          $rootScope.showNotify(
            key.replace(/([a-z])([A-Z])/g, '$1 $2') + ' - ' + errorMessage
          );
        }
      });
    }

    function getCookie(name) {
      var matches = document.cookie.match(
        new RegExp(
          '(?:^|; )' +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
            '=([^;]*)'
        )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function deleteCookie(name) {
      document.cookie =
        name +
        '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' +
        '.' +
        window.location.hostname.split('.').splice(1).join('.');
    }
    var aud = ['https://business.padi.com', 'https://travel.padi.com'].indexOf(window.location.origin) === -1 ? '7u0q6o7jbqck26k2adbdu6qbjk' : '503u87mvc25i53veank5m32e6i';

    return {
      request: function (config) {
        var sub = getCookie(
            'CognitoIdentityServiceProvider.' + aud + '.LastAuthUser'
          ),
          idToken = getCookie(
            'CognitoIdentityServiceProvider.' + aud + '.' + sub + '.idToken'
          );
        if (idToken && config.url.indexOf('/api/v2/') !== -1) {
          config.headers.Authorization = 'Bearer ' + idToken;
        } else {
          config.withCredentials = false;
        }

        return config;
      },
      response: function (response) {
        if (response.status === 401) {
          console.debug('Response  401');
        }

        return response || $q.when(response);
      },
      responseError: function (rejection) {
        if (rejection.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('login_date');

          var sub = getCookie(
            'CognitoIdentityServiceProvider.' + aud + '.LastAuthUser'
          );

          deleteCookie(
            'CognitoIdentityServiceProvider' + aud + '.' + sub + '.idToken'
          );
          deleteCookie(
            'CognitoIdentityServiceProvider' + aud + '.' + sub + '.refreshToken'
          );
          // we don't need to refresh pages on travel
          if (window.location.origin.includes('business.padi')) {
            window.location.reload();
          }
          $rootScope.user = null;
        }

        if (rejection.status === 400) {
          handlerError(rejection.data);
        }

        if (rejection.status === 500) {
          $rootScope.showNotify('Something went wrong!');
        }

        if (rejection.status === 502) {
          $rootScope.showNotify(
            'Server is not available. Please try one more time!'
          );
        }

        return $q.reject(rejection);
      },
    };
  });
