'use strict';

angular
  .module('app')
  .directive('copyAffiliateLink', function (
    utils,
    $rootScope,
    gettextCatalog,
    Request
  ) {
    return function (scope, elem) {
      var longUrl = utils.generateAffiliateUrl();

      function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.opacity = 0;
        textArea.style.position = 'absolute';
        textArea.style.top = '-999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy'),
            msg = successful ? 'successful' : 'unsuccessful';
          console.info('Fallback: Copying text command was ' + msg);
          $rootScope.showNotify('Link was copied to your clipboard');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
      }

      function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(
          function () {
            console.info('Async: Copying to clipboard was successful!');
            $rootScope.showNotify(
              gettextCatalog.getString('Link was copied to your clipboard')
            );
          },
          function (err) {
            console.error('Async: Could not copy text: ', err);
          }
        );
      }

      elem.on('click', function () {
        if (utils.isStaff()) {
          copyTextToClipboard(longUrl);
        } else {
          Request.post('url-shortner/', {
            longUrl: longUrl,
          }).then(function (res) {
            copyTextToClipboard(res.data.shortUrl);
          });
        }
      });
    };
  });
