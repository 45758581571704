'use strict';

angular
  .module('app')
  .service('adminService', function (
    $q,
    Request,
    $rootScope,
    $filter,
    Upload,
    fileReader,
    utils
  ) {
    var admin = {};

    admin.getInfo = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/admin/').then(
        function (res) {
          if (!res.data.basedOn) {
            res.data.basedOn = false;
          }
          return res.data;
        }
      );
    };

    admin.setInfo = function (data) {
      if (data.basedOn === false) {
        data.basedOn = null;
      }

      // for (var key in data) {
      //   if (key) {
      //     obj[utils.camelToUnderscore(key)] =
      //       typeof data[key] !== 'undefined' ? data[key] : null;
      //   }
      // }

      // if (obj.shop && obj.shop.groupTripTags) {
      //   obj['shop.group_trip_tags'] = JSON.stringify(obj.shop.groupTripTags);
      // }

      // if (!obj.gis_document) {
      //   obj.gis_document = '';
      // } else if (_.isString(obj.gis_document) && obj.gis_document.length > 0) {
      //   /* eslint-disable no-undefined */
      //   obj.gis_document = undefined;
      // }

      if (!data.isCustomCancellation) {
        data.isCustomCancellation = false;
      }

      return Request.put(`shop/${utils.getCurrentShopSlug()}/admin/`, data);
      // return Request.patch('shop/' + utils.getCurrentShopSlug() + '/admin/', data).then(function(res) {
      //     return res.data;
      // });
    };

    admin.getAgreements = function (isVacation) {
      const url = `shop/${utils.getCurrentShopSlug()}/agreement/${
        isVacation ? 'vacation/' : ''
      }`;
      return Request.get(url).then(function (res) {
        return res.data;
      });
    };

    admin.getAgreementFile = function () {
      return Request.get(
        'shop/' + utils.getCurrentShopSlug() + '/agreement-file/'
      ).then(function (res) {
        return res.data;
      });
    };

    admin.setAgreements = function (isVacation, data) {
      const url = `shop/${utils.getCurrentShopSlug()}/agreement/${
        isVacation ? 'vacation/' : ''
      }`;
      return Request.patch(url, data).then(function (res) {
        return res.data;
      });
    };

    // admin.saveMilestones = function (paymentMilestones,cancellationMilestones) {
    //    var slug = utils.getCurrentShopSlug(),
    //        payMilstones = _.clone(paymentMilestones),
    //        cancelMilestones = _.clone(cancellationMilestones),
    //        promise = [];
    //
    //    _.each(payMilstones, function(v,k){
    //        if (k === 'payment0') {
    //            promise.push(Request.put('shop/' + slug + '/payment/', {shop: slug, paymentTerms: v.amount }));
    //        } else {
    //            promise.push(Request[v.id ? 'put' : 'post']('shop/' + slug + '/milestones/payment/' + (v.id ? v.id +'/' : ''), {
    //                shop: slug,
    //                amount: v.amount,
    //                days: v.days
    //            }));
    //        }
    //    });
    //
    //    _.each(cancelMilestones, function(v,k) {
    //         promise.push(Request[v.id ? 'put' : 'post']('shop/' + slug + '/milestones/cancellation/' + (v.id ? v.id +'/' : ''), {
    //                shop: slug,
    //                amount: v.amount,
    //                days: v.days
    //         }));
    //    });
    //
    //    return $q.all(promise);
    // };

    // admin.deleteMilestone = function (id, type) {
    //   return Request.del('shop/' + utils.getCurrentShopSlug() + '/milestones/' + type + '/' + id + '/');
    // };

    admin.getCancellation = function () {
      return Request.get(
        'shop/' + utils.getCurrentShopSlug() + '/milestones/cancellation/'
      ).then(function (res) {
        var data = [];

        _.each(res.data, function (val) {
          data.push(val);
        });

        return data;
      });
    };

    admin.getMilestones = function () {
      return Request.get(
        'shop/' + utils.getCurrentShopSlug() + '/milestones/'
      ).then(function (res) {
        var amountVal = null,
          amountType = null;
        if (!isNaN(res.data.paymentInformation.paymentTerms)) {
          amountVal = res.data.paymentInformation.paymentTerms;
          amountType = res.data.paymentInformation.paymentTermsType;
        }
        res.data.customerMilestones.unshift({
          amount: _.get(res.data, 'paymentInformation.paymentTermsCustomer'),
          amountType: _.get(
            res.data,
            'paymentInformation.paymentTermsTypeCustomer'
          ),
        });

        res.data.supplierMilestones.unshift({
          amount: amountVal,
          amountType: amountType,
        });

        return res.data;
      });
    };

    admin.saveMilestones = function (data) {
      var milestones = _.cloneDeep(data),
        customerCancellationLength =
          milestones.customerCancellationMilestones.length,
        supplierCancellationLength =
          milestones.supplierCancellationMilestones.length;

      if (customerCancellationLength > 1) {
        milestones.customerCancellationMilestones[
          customerCancellationLength - 1
        ].days =
          milestones.customerCancellationMilestones[
            customerCancellationLength - 2
          ].days;
      }

      if (supplierCancellationLength > 1) {
        milestones.supplierCancellationMilestones[
          supplierCancellationLength - 1
        ].days =
          milestones.supplierCancellationMilestones[
            supplierCancellationLength - 2
          ].days;
      }

      if (milestones.supplierMilestones[0]) {
        milestones.paymentInformation.paymentTerms =
          milestones.supplierMilestones[0].amount;
        milestones.paymentInformation.paymentTermsType =
          milestones.supplierMilestones[0].amountType;
      }

      if (milestones.customerMilestones[0]) {
        milestones.paymentInformation.paymentTermsCustomer =
          milestones.customerMilestones[0].amount;
        milestones.paymentInformation.paymentTermsTypeCustomer =
          milestones.customerMilestones[0].amountType;
      }

      milestones.customerMilestones.shift();
      milestones.supplierMilestones.shift();

      return Request.put(
        'shop/' + utils.getCurrentShopSlug() + '/milestones/',
        milestones
      );
      // .then(function (res) {
      //   var amountVal = null;
      //   if (!isNaN(res.data.paymentInformation.paymentTerms)) {
      //     amountVal = res.data.paymentInformation.paymentTerms;
      //   }
      //   res.data.customerMilestones.unshift({
      //     amount: amountVal,
      //   });

      //   res.data.supplierMilestones.unshift({
      //     amount: amountVal,
      //   });

      //   return res.data;
      // });
    };

    admin.getPaymentMilestones = function () {
      var slug = utils.getCurrentShopSlug();

      return $q
        .all([
          Request.get('shop/' + slug + '/payment/'),
          Request.get('shop/' + slug + '/milestones/payment/'),
        ])
        .then(function (res) {
          var data = [
            {
              amount: res[0].data.paymentTerms,
            },
          ];

          _.each(res[1].data, function (val) {
            data.push(val);
          });

          return data;
        });
    };

    admin.saveContacts = function (data) {
      var requests = [];

      _.each(data, function (val) {
        if (val.id) {
          requests.push(
            Request.patch(
              'shop/' +
                utils.getCurrentShopSlug() +
                '/admin/local-contacts/' +
                val.id +
                '/',
              val
            )
          );
        } else if (val.phone || val.fullName) {
          requests.push(
            Request.post(
              'shop/' + utils.getCurrentShopSlug() + '/admin/local-contacts/',
              val
            )
          );
        }
      });

      if (requests.length) {
        return $q.all(requests);
      }
      var q = $q.defer();

      q.resolve();

      return q.promise;
    };

    admin.deleteContact = function (id) {
      return Request.del(
        'shop/' +
          utils.getCurrentShopSlug() +
          '/admin/local-contacts/' +
          id +
          '/'
      ).then(function (res) {
        return res.data;
      });
    };

    return admin;
  });
