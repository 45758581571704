'use strict';

angular.module('app')
  .controller('AvailabilityPricingCtrl', function (utils, availabilityService, $location) {
    var vm = this;

    vm.getList = getList;
    vm.setMaxDate = setMaxDate;
    vm.saveOrdering = saveOrdering;
    vm.allowDrag = false;

    vm.today = moment();

    vm.loader = true;
    vm.filter = {
      dateFrom: moment().format('YYYY-MM-DD')
    }

    getList();

    function getList() {

      var passedDates = $location.search();

      if(passedDates.dateFrom && passedDates.dateTo) {
        // dates passed on redirect
        vm.filter.dateFrom = passedDates.dateFrom;
        vm.filter.dateTo = passedDates.dateTo;
        $location.search('');
      } else {
        // date not set 
        if (vm.today > moment(vm.filter.dateFrom)) {
          vm.filter.dateFrom = moment().format('YYYY-MM-DD');
        }
  
        if (!vm.filter.dateTo) {
          vm.filter.dateTo = moment(vm.filter.dateFrom).add(1, 'month').format('YYYY-MM-DD');
        }
      } 

      availabilityService.getAvailability(vm.filter).then(function (data) {
        vm.rooms = data;
        vm.loader = false;

        vm.monthes = [];
        vm.dateRange = utils.enumerateDaysBetweenDates(vm.filter.dateFrom, vm.filter.dateTo);

        var monthes = _.groupBy(vm.dateRange, function (result) {
          return moment(result).startOf('month').format('YYYY-MM-DD');
        });

        _.each(monthes, function (val, key) {
          _.each(val, function (v, k) {
            vm.monthes.push(k === 0 ? moment(key).locale('en').format('MMM YYYY') : '');
          });
        });
      });
    }

    function setMaxDate() {
      return moment(vm.filter.dateFrom).add(1, 'year').format('YYYY-MM-DD');
    }

    function saveOrdering() {
      vm.loader = true;
      _.each(vm.rooms, function (v, key) {
        v.order = key
      });

      return availabilityService.updateRoomOrder(vm.rooms).then(function() {
        vm.allowDrag = false;
        vm.loader = false;
      });
    }

  });
