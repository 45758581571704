'use strict';

angular.module('app').directive('photoLoader', function ($timeout) {
	return {
		restrict: 'A',
		scope: {
			photoLoader: '='
		},
		link: function(scope, el, attrs, uiSelect) {
		  scope.$watch('photoLoader', function() {
		  	if (scope.photoLoader) {
		  		var percent = 100 - scope.photoLoader;
		  		$(el).css({'width': percent + 'px'});
		  	}
		  });
		}
	  };
});