'use strict';

angular.module('app').directive('notify', function($rootScope, $timeout) {
    return {
        restrict: 'AE',
        link: function(scope, element) {

            $rootScope.showNotify = function (message, timeout) {
                var notifyBlock = $(element),
                    delay = timeout || 5000;

                $timeout(function() {
                    notifyBlock.html(message).removeClass('slideOutUp hidden').addClass('slideInDown');
                },400);

                $timeout(function() {
                    notifyBlock.stop().removeClass('slideInDown').addClass('slideOutUp').delay(500).queue(function(){
                        $(this).addClass('hidden');
                    });
                }, delay);
            };
        }
    };
});
