'use strict';

angular.module('app').directive('starRating', ['$compile', function($compile) {

        var link = function(scope, element, attrs, controller) {
            scope.$watch('ratingValue', function () {
                var n = Math.round(parseFloat(scope.ratingValue)),
                    template = '<div class="stars"><ul>';

                for(var i = 1; i <= 5; i++) {
                    if(i <= n){
                        template += '<li><span class="star-active"></span></li>';
                    } else {
                        template += '<li><span class="star"></span></li>';
                    }
                }

                if (scope.reviewsNumber) {
                    template += '<span>(' +  scope.reviewsNumber + ')</span>';
                } else {
                    template += '</ul></div>';
                }

                var contentTr = $compile(template)(scope);
                
                element.empty().append(contentTr);
            });
        };

        return {
            restrict: 'AE',
            replace: true,
            scope: {
                ratingValue: '=',
                starClass: '@',
                reviewsNumber: '='
            },
            link: link
        };
    }
]);
