'use strict';

angular.module('app')
  .controller('LogistcsCtrl', function ($routeParams, SeoService, product, shopService, $rootScope, gettextCatalog, $location, utils, $scope) {
    var vm = this;
    vm.productId = $routeParams.id;
    $rootScope.showActivity = true;

    vm.redirectNewExtranet = product.redirectNewExtranet;
    vm.loading = true;
    vm.logisticsPage = true;
    vm.pointEditing = false;
    vm.getCoords = getCoords;
    vm.addPoint = addPoint;
    vm.editPoint = editPoint;
    vm.savePoint = savePoint;
    vm.deletePoint = deletePoint;
    vm.updateLogistics = updateLogistics;
    vm.checkDropOff = checkDropOff;
    vm.needTranslate = ['description'];
    vm.pickUpMap = {
      marker: {
        latitude: 48,
        longitude: 8,
      },
      options: {
        icon: '/images/marker.png',
        draggable: true,
      },
      events: {
        dragend: centerChanged,
      },
      center: {
        latitude: 48,
        longitude: 8,
      },
      mapStyle: { 'mapTypeControl': false, 'streetViewControl': false, 'fullscreenControl': false },
      bounds: {},
    };

    vm.dropOffMap = {
      marker: {
        latitude: 49,
        longitude: 9,
      },
      options: {
        icon: '/images/marker.png',
        draggable: true,
      },
      events: {
        dragend: centerChanged,
      },
      center: {
        latitude: 49,
        longitude: 9,
      },
      mapStyle: { 'mapTypeControl': false, 'streetViewControl': false, 'fullscreenControl': false },
      bounds: {},
    };

    product.getProduct($routeParams.id).then(function (data) {
      vm.finishedTabs = data.finishedTabs;

      if (utils.isProDiverShop()) {
        if (!data.meetingPointType) {
          data.meetingPointType = {};
        }
        data.meetingPointType.value = 20;
      }

      vm.productData = _.clone(data);
      if (utils.isArrayContainElementsFromOtherArray(vm.productData.autoTranslatedFields, vm.needTranslate)) {
        $rootScope.showNotify(gettextCatalog.getString('The text fields on this page have been auto-translated using Google Translate. You may edit them manually if you wish.'));
      }

      if (+vm.productData.productType.value === 50) {
        vm.productData.logisticsType = {
          value: 10,
        };
      }

      // temporary fix;
      if (!vm.productData.dropOffPoint) {
        vm.productData.dropOffPoint = {
          address: '',
          point: {
            latitude: 0,
            longitude: 0,
          },
        };
      } else {
        vm.dropOffMap.marker.latitude = vm.productData.dropOffPoint.point.latitude;
        vm.dropOffMap.marker.longitude = vm.productData.dropOffPoint.point.longitude;
        vm.dropOffMap.center.latitude = vm.productData.dropOffPoint.point.latitude;
        vm.dropOffMap.center.longitude = vm.productData.dropOffPoint.point.longitude;
      }

      if (!vm.productData.meetingPoint) {
        vm.productData.meetingPoint = {
          address: '',
          point: {
            latitude: 0,
            longitude: 0,
          },
        };
      } else {
        vm.pickUpMap.marker.latitude = vm.productData.meetingPoint.point.latitude;
        vm.pickUpMap.marker.longitude = vm.productData.meetingPoint.point.longitude;
        vm.pickUpMap.center.latitude = vm.productData.meetingPoint.point.latitude;
        vm.pickUpMap.center.longitude = vm.productData.meetingPoint.point.longitude;
      }

      SeoService.generateMetaTags(vm.productData.title + gettextCatalog.getString('Logistics - PADI Travel for Business'));
      product.getDCAddress().then(function (response) {
        vm.DCAddress = response.shopAddress;
        vm.loading = false;
      });

      // vm.productData.logisticsType = {};
      // vm.productData.meetingPointType = {};
    });

    function centerChanged(marker) {
      var center = marker.getPosition();

      if (marker.key === 2 || marker.key === 1) {
        // pick up point or meeting point
        // set center
        vm.pickUpMap.center = {
          latitude: center.lat(),
          longitude: center.lng(),
        };

        if (vm.pointEditing) {
          // pick up point point editing
          vm.pointToEdit.point.latitude = vm.pickUpMap.center.latitude;
          vm.pointToEdit.point.longitude = vm.pickUpMap.center.longitude;
          getAddress(vm.pickUpMap.center.latitude + ',' + vm.pickUpMap.center.longitude).then(function (result) {
            if (result[0].geometry.viewport) {
              setZoom(result[0].geometry.viewport, vm.pickUpMap);
            }
            vm.pointToEdit.address = result[0].formatted_address;
          });
        } else {
          // meeting point editing
          vm.productData.meetingPoint.point.latitude = vm.pickUpMap.center.latitude;
          vm.productData.meetingPoint.point.longitude = vm.pickUpMap.center.longitude;
          getAddress(vm.pickUpMap.center.latitude + ',' + vm.pickUpMap.center.longitude).then(function (result) {
            if (result[0].geometry.viewport) {
              setZoom(result[0].geometry.viewport, vm.pickUpMap);
            }
            vm.productData.meetingPoint.address = result[0].formatted_address;
          });
        }
      } else if (marker.key === 3) {
        // drop off point
        // set center
        vm.dropOffMap.center = {
          latitude: center.lat(),
          longitude: center.lng(),
        };
        // set dropoff point
        vm.productData.dropOffPoint.point.latitude = vm.dropOffMap.center.latitude;
        vm.productData.dropOffPoint.point.longitude = vm.dropOffMap.center.longitude;
        getAddress(vm.dropOffMap.center.latitude + ',' + vm.dropOffMap.center.longitude).then(function (result) {
          if (result[0].geometry.viewport) {
            setZoom(result[0].geometry.viewport, vm.dropOffMap);
          }
          vm.productData.dropOffPoint.address = result[0].formatted_address;
        });
      }
    }

    function getAddress(latlng) {
      return shopService.googleReverseGeoCode(latlng).then(function (data) {
        if (data[0] && data[0].formatted_address) {
          return data;
        }
        return 'no address';
      });
    }

    function getCoords(targetPoint, currentMap) {
      $scope.extranetForm.$submitted = false;
      if (targetPoint) {
        shopService.googleGeoCode(targetPoint.address).then(function (data) {
          if (!_.isEmpty(data) && data[0].geometry) {
            currentMap.center = {
              latitude: data[0].geometry.location.lat,
              longitude: data[0].geometry.location.lng,
            };

            targetPoint.point.latitude = vm.pickUpMap.center.latitude;
            targetPoint.point.longitude = vm.pickUpMap.center.longitude;

            if (data[0].geometry.viewport) {
              setZoom(data[0].geometry.viewport, currentMap);
            }

            currentMap.marker = _.clone(currentMap.center);
          }
        });
      }
    }

    function setZoom(viewport, mapModel) {
      if (viewport) {
        mapModel.bounds = {
          northeast: {
            latitude: viewport.northeast.lat,
            longitude: viewport.northeast.lng,
          },
          southwest: {
            latitude: viewport.southwest.lat,
            longitude: viewport.southwest.lng,
          },
        };
      }
    }

    function addPoint() {
      $scope.extranetForm.$submitted = false;
      vm.pointEditing = true;
      vm.pointToEdit = {
        address: '',
        point: {
          latitude: 0,
          longitude: 0,
        },
      };
    }

    function editPoint(index) {
      vm.pointEditing = true;
      vm.pointToEdit = _.clone(vm.productData.pickupPointsList[index]);
      vm.pointToEdit.index = index;
      getCoords(vm.pointToEdit, vm.pickUpMap);
    }

    function deletePoint(index) {
      vm.productData.pickupPointsList.splice(index, 1);
    }

    function savePoint() {
      if (vm.pointToEdit && vm.pointToEdit.address) {
        if (vm.pointToEdit.index || vm.pointToEdit.index === 0) {
          var editingIndex = vm.pointToEdit.index;
          vm.productData.pickupPointsList[editingIndex] = vm.pointToEdit;
        } else {
          vm.productData.pickupPointsList.push(vm.pointToEdit);
        }
        vm.pointEditingError = false;
        vm.pointEditing = false;
        vm.pointToEdit = {};
      } else {
        vm.pointEditingError = true;
      }
    }

    function updateLogistics(form, newProduct) {
      form.$submitted = true;
      if (+_.get(newProduct, 'logisticsType.value') === 20 &&
        (+_.get(newProduct, 'pickupPointType.value') === 30) &&
        (!_.get(newProduct, 'pickupPointsList.length'))) {
        // If user selected "Customer can choose a place..." + "You have set up pick-up points" ==> at least one pickup address must be created;
        return;
      }
      if (form.$valid) {
        vm.loading = true;
        // actually update
        product.addNew(newProduct, $routeParams.id).then(function (result) {
          vm.loading = false;
          $rootScope.showNotify(gettextCatalog.getString('The product was successfully changed'));
          if (result.data.diveProductId) $location.path(utils.createURL('product_photos', { id: result.data.diveProductId }));
        }, function (error) {
          vm.loading = false;
        });
      }
    }

    function checkDropOff() {
      if (+vm.productData.logisticsType.value === 10 && +vm.productData.meetingPointType.value === 10) {
        vm.productData.dropOffType.value = 10;
      } else {
        vm.productData.dropOffType.value = 20;
      }
    }
  });
