'use strict';

angular.module('app').directive('dpExtranet', function ($timeout, $filter, $rootScope) {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      startDate: '=',
      allDates: '=',
      maxDate: '=',
      notPrefill: '=',
      minDate: '=',
      minDateEqual: '=',
      dpCallback: '&',
    },
    link: function (scope, elem, attr, ngModelCtrl) {
      var localeLanguage = window.navigator.userLanguage || window.navigator.language,
        element = $(elem);

      try {
        $.datepicker.regional[localeLanguage].dateFormat;
        moment.locale(localeLanguage);
      } catch (e) {
        // if no language in $.datepicker.regional we set default 'en'
        localeLanguage = moment.locale('en');
      }

      var minDate = null;

      if (scope.minDate) {
        minDate = moment(scope.minDate).utc().toDate();
      }

      var options = {
        numberOfMonths: 2,
        firstDay: 1,
        minDate: minDate,
        maxDate: scope.maxDate ? new Date(scope.maxDate) : (scope.allDates ? new Date() : null),
        dateFormat: $.datepicker.regional[localeLanguage].dateFormat,
        onSelect: function () {
          scope.$apply(function () {
            var date = $(elem).datepicker('getDate');

            ngModelCtrl.$setViewValue(moment(date).format('YYYY-MM-DD'));

            if (scope.dpCallback) {
              scope.dpCallback();
            }
          });

          $timeout(function () {
            if (!element.datepicker('getDate')) {
              return true;
            }


            if (element.hasClass('js-from')) {
              element.parent().next().find('.js-to').focus();
            } else if (element.hasClass('js-to')) {
              var nextRow = element.parents().eq(1).next();
              if (nextRow.hasClass('dp-wrapper')) {
                nextRow.find('.js-from').focus();
              } else {
                var seasonRow = element.parents().eq(3).next();

                if (seasonRow.hasClass('season')) {
                  seasonRow.find('.title-input').focus();
                }
              }
            }
          }, 400);
        },
      };

      scope.$watch('minDate', function (newVal, oldVal) {
        if (newVal && oldVal != newVal && newVal.length == 10) {
          // var tomorrow = new Date((moment(newVal).toDate()).setDate((new Date(newVal)).getDate() + 1));
          var tomorrow = moment(newVal).add(1, 'days').toDate();

          $(elem).datepicker('option', 'minDate', scope.minDateEqual ? moment(newVal).toDate() : tomorrow);

          // $timeout(function () {
          //   var newDate = $(elem).datepicker('getDate');
          //   if (newDate) {
          //     ngModelCtrl.$setViewValue(moment(newDate).format('YYYY-MM-DD'));
          //   }
          // }, 50);
        }
      });

      scope.$watch('maxDate', function (newVal, oldVal) {
        if (newVal && oldVal != newVal && newVal.length == 10) {
          $(elem).datepicker('option', 'maxDate', moment(scope.maxDate).toDate());
        }
      });

      scope.$watch(function () {
        return ngModelCtrl.$modelValue;
      }, function (newVal, oldVal) {
        if (newVal && (newVal.length === 10 || newVal.length === 20)) {
          var date = moment(newVal).toDate();
          if (date != 'Invalid Date') {
            $timeout(function () {
              $(elem).datepicker('setDate', date);
              ngModelCtrl.$setViewValue(moment(date).format('YYYY-MM-DD'));
            }, 100);
          } else if (newVal !== oldVal) {
            $timeout(function () {
              ngModelCtrl.$setViewValue('');
            }, 50);
          }
        }
      });

      scope.$watch('startDate', function (val) {
        var date = new Date();

        if (scope.startDate) {
          date = moment(scope.startDate).toDate();
        }

        $timeout(function () {
          element.datepicker(options).on('keydown', function (e) {
            // on enter click
            if (e.which === 13 || e.which === 9) {
              $('.ui-datepicker-current-day').click();
            }
          }).on('blur', function (e) {
            if (!$.datepicker._datepickerShowing) {
              $('.ui-datepicker-current-day').click();
            }
          });

          if (!scope.notPrefill && val) {
            $(elem).datepicker('setDate', date);

            ngModelCtrl.$setViewValue(moment(date).format('YYYY-MM-DD'));
          }
        });
      });
      $.datepicker.setDefaults($.datepicker.regional.en);
    },
  };
});
