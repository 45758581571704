'use strict';

angular.module('app').directive('mobileSearch', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope, elem) {
      $(elem).on('click', function () {
        $('.header-search')
          .addClass('search-focused')
          .find('input')
          .focus();
      });

      $('.header-search input').on('focus click', function () {
        $('.header-search').addClass('search-focused');
      }).on('blur', function () {
        $timeout(function () {
          $('.header-search').removeClass('search-focused');
        }, 300);
      });
    },
  };
});
