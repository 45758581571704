'use strict';

angular
  .module('app')
  .controller('TripCompleteCtrl', function (
    $scope,
    $rootScope,
    $location,
    groupTrips,
    $routeParams,
    SeoService,
    gettextCatalog,
    utils
  ) {
    var vm = this;
    // vm.productDescriptionPage = true;
    vm.tripId = $routeParams.id;

    vm.sendForReview = sendForReview;

    SeoService.generateMetaTags(gettextCatalog.getString('Trip Complete - PADI Travel for Business'));

    vm.loading = true;

    activate();

    function activate() {
      vm.loading = true;
      groupTrips.getTrip(vm.tripId).then(function (data) {
        vm.tripData = _.clone(data);
        vm.loading = false;
      });
    }

    function sendForReview() {
      vm.loading = true;

      groupTrips.updateTrip({id: vm.tripId, status: 20}).then(function () {
        vm.loading = false;
        $rootScope.showNotify(gettextCatalog.getString('Your trip was sent for review'));
        $location.path(utils.createURL('group_trips'));
      });
    }
  });
