'use strict';

angular.module('app').directive('difficultyLevel', ['$compile', 'gettextCatalog', function ($compile, gettextCatalog) {
  var link = function (scope, element, attrs, controller) {
    scope.$watch('ratingValue', function () {
      $(element).find('.diff-level').remove();

      var n = Math.round(parseFloat(scope.ratingValue)),
        template = '<div class="diff-level">',
        level;

      for (var i = 1; i <= 5; i++) {
        if (i <= n) {
          template += '<span class="star-icon-active font-icons"></span>';
        }
      }


      if (n > 0 && n < 3) {
        level = gettextCatalog.getString('Low');
      } else if (n == 3) {
        level = gettextCatalog.getString('Medium');
      } else if (n == 4) {
        level = gettextCatalog.getString('High');
      } else {
        level = gettextCatalog.getString('Extreme');
      }

      template += '<p>' + level + '</p></div>';

      var contentTr = $compile(template)(scope);
      element.append(contentTr);
    });
  };

  return {
    restrict: 'AE',
    replace: true,
    scope: {
      ratingValue: '=',
    },
    link: link,
  };
}
]);
