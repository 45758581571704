'use strict';

angular
  .module('app')
  .controller('AgreementCtrl', function (
    $rootScope,
    $routeParams,
    $location,
    AuthExtranet,
    gettextCatalog,
    adminService,
    shopService,
    GTM,
    info,
    utils,
    diviacLocalStorage,
    localStorageService,
    selectedLanguage,
    isVacation
  ) {
    const vm = this;
    let signedDateField = 'dateSigned',
      isVacationAgreementAcceptedField = 'isAgreementAccepted';
    if (isVacation) {
      signedDateField = 'vacationDateSigned';
      isVacationAgreementAcceptedField = 'isVacationAgreementAccepted';
    }

    if ($routeParams.shop) {
      const shopId = +$routeParams.shop;
      if (shopId !== utils.getCurrentShopId()) {
        shopService.getShopData(shopId).then(shop => {
          AuthExtranet.setChoosenShop(shop, true);
          $location.search({});
        });
      } else {
        $location.search({});
      }
    }

    GTM.event('business_listing_agreement_start');

    vm.signDate = new Date();
    vm.loading = true;
    vm.notReady = true;

    function addLanguageSegment() {
      let availableLanguages = ['de', 'es', 'it', 'fr'];
      if (availableLanguages.indexOf(selectedLanguage) !== -1) {
        return selectedLanguage + '/';
      }
      return '';
    }

    vm.termsUrl = `https://affiliates.padi.com/${addLanguageSegment()}adventures/terms-04-2020/`;

    const kind = parseInt($rootScope.selectedShop.kind, 10),
      resortKind = parseInt($rootScope.selectedShop.resortKind, 10);

    vm.TCLink = gettextCatalog.getString(
      'https://affiliates.padi.com/adventures/terms-04-2020/'
    );

    // adminService.getAgreementFile().then(function (file) {
    //   vm.file = file;
    // });

    adminService.getAgreements(isVacation).then(function (data) {
      vm.form = data;

      vm.form.fullAddress = '';

      if (data[signedDateField]) {
        vm.signDate = data[signedDateField];
        vm.form.fullLegalRights = true;
      }
      if (data.businessType === 10) {
        vm.form.fullAddress = data.address;

        if (data.address2) {
          vm.form.fullAddress += ', ' + data.address2;
        }
        if (data.city) {
          vm.form.fullAddress += ', ' + data.city;
        }
        if (data.zipCode) {
          vm.form.fullAddress += ', ' + data.zipCode;
        }
        if (data.state) {
          vm.form.fullAddress += ', ' + data.state;
        }
        if (data.country) {
          let country = _.find(info.countryList, { id: data.country });
          vm.form.fullAddress += ', ' + country.title;
        }
      } else {
        vm.form.fullAddress = data.legalEntityAddress;

        if (data.legalEntityAddress_2) {
          vm.form.fullAddress += ', ' + data.legalEntityAddress_2;
        }
        if (data.legalEntityCity) {
          vm.form.fullAddress += ', ' + data.legalEntityCity;
        }
        if (data.legalEntityZipCode) {
          vm.form.fullAddress += ', ' + data.legalEntityZipCode;
        }
        if (data.legalEntityState) {
          vm.form.fullAddress += ', ' + data.legalEntityState;
        }
        if (data.legalEntityCountry) {
          let country = _.find(info.countryList, {
            id: data.legalEntityCountry,
          });
          vm.form.fullAddress += ', ' + country.title;
        }
      }

      vm.loading = false;
    });

    vm.getLocalizedSignDate = function () {
      return moment(vm.signDate)
        .locale(selectedLanguage)
        .format('MMMM DD, YYYY');
    };

    // vm.uploadFiles = function (file) {
    //   var header = {
    //       'Content-Type': 'application/json',
    //     },
    //     token = localStorage.getItem('token');

    //   if (token) {
    //     header.Authorization = 'Bearer ' + token;
    //   }

    //   return Upload.upload({
    //     url: utils.apiURL + 'shop/' + utils.getCurrentShopSlug() + '/agreement-file/',
    //     headers: header,
    //     arrayKey: '',
    //     method: 'PUT',
    //     data: {
    //       agreement_file: file || null,
    //     },
    //   }).then(function (res) {
    //     vm.file = res.data;
    //   }, function (err) {
    //     throw err;
    //   });
    // };

    vm.save = function (form) {
      form.$submitted = true;
      if (form.$invalid) {
        $rootScope.showNotify(
          gettextCatalog.getString(
            'You should accept our terms and conditions'
          ),
          3000
        );
        return true;
      }
      vm.loading = true;
      vm.form.isBookableOnPadi = true;
      vm.form.isVacationAgreement = isVacation;
      return adminService
        .setAgreements(isVacation, vm.form)
        .then(function (data) {
          GTM.event([
            'business_listing_agreement_complete',
            'business_listing_overall_complete',
          ]);
          GTM.event({
            business_listing_agreement: gettextCatalog.getString('Complete'),
          });

          $rootScope.checkedList[
            isVacation ? 'vacationAgreement' : 'agreement'
          ] = true;

          // $location.path('/home/');

          $rootScope.showListing = false;
          $rootScope.showManagement = true;

          if (kind === 20) {
            _.forEach($rootScope.user.managedShops, function (val) {
              if (val.shop.slug === $rootScope.selectedShop.slug) {
                val.shop.slug = data.shopSlug;
              }
            });

            $rootScope.choosenShop.slug = data.shopSlug;
            diviacLocalStorage.set('user', $rootScope.user);
          }
          if($rootScope.choosenShop) {
            $rootScope.choosenShop.showAgreementPopup = false;
          }
          diviacLocalStorage.set('choosenShop', $rootScope.choosenShop);
          localStorageService.set('choosenShop', $rootScope.choosenShop);

          if (isReadyToPublish()) {
            if (resortKind !== 20 && kind !== 10) {
              // for adventures
              shopService.setState({ isOnAdventures: true }).then(function () {
                $rootScope.isOnAdventures = true;
              });
            } else {
              shopService.setState({ isDraft: false }).then(function () {
                $rootScope.isDraft = false;
                GTM.variables({ business_listing_status: 'Published' });
                GTM.event('business_listing_publish');
              });
            }
          }

          vm.form = data;

          if (data[signedDateField]) {
            vm.signDate = data[signedDateField];
            vm.form.dateSigned = data[signedDateField];
            vm.form.fullLegalRights = true;
          }

          vm.isAgreementAccepted = data[isVacationAgreementAcceptedField];

          $rootScope.showNotify(
            gettextCatalog.getString('Agreement was successfully Accepted'),
            5000
          );
          vm.loading = false;
        });
    };

    $rootScope.$watch('checkedList', function (newVal) {
      if (newVal && newVal.admin) {
        vm.notReady = false;
        // _.each($rootScope.checkedList, function (val, key) {
        //   if (val == false && key != 'agreement') {
        //     vm.notReady = true;
        //   }
        // });
      }
    });

    function isReadyToPublish() {
      const { vacationAgreement, agreement } = $rootScope.checkedList,
        selectedShop = $rootScope.selectedShop;
      if (kind === 0) {
        if (selectedShop.isVacationShop) {
          return vacationAgreement && agreement;
        }
        return agreement;
      } else if (kind === 10) {
        return vacationAgreement;
      } else if (kind === 20) {
        if (+selectedShop.resortKind === 10) {
          return vacationAgreement && agreement;
        }

        return vacationAgreement;
      }

      return false;
    }
  });
