'use strict';

angular.module('app')
  .controller('PromotionsCtrl', function ($q, $rootScope, $routeParams, $location, Request, shopService, gettextCatalog, itineraryService, scheduleService, promotionsService, info, utils, Upload, $timeout) {
    var vm = this;

    vm.promotionChoises = info.promotions.PROMOTION_KIND;
    vm.promotionSelectionKind = info.promotions.PROMOTION_SELECTION_KIND;
    vm.errorFormat = [];
    vm.errorPhoto = [];
    vm.promo = {};
    vm.tagTransform = function (item, b) {
      return {
        title: item,
      };
    };
    vm.tags = [];
    vm.checkboxValid = checkboxValid;
    vm.fileUpload = fileUpload;
    vm.savePromo = savePromo;
    vm.updateTags = updateTags;
    vm.selectAll = selectAll;
    vm.onChange = function (s) {
      if (s && s.length >= 2) {
        vm.tags = [];

        return Request.get('booking/tags/', { search: s } ).then(function (res) {
          if (!_.find(res.data, function (item) {
            return item.title.toLowerCase() === s.toLowerCase();
          })) {
            vm.tags = [{ id: s, title: s, isNew: true }];
          }

          _.each(res.data, function (item) {
            vm.tags.push({
              id: item.id,
              title: item.title,
            });
          });
        });
      }
    };

    shopService.getShopCurrency().then(function (data) {
      vm.currency = data.currency;
    });

    var requests = [
      itineraryService.getList(),
      scheduleService.getList(false, 1000)
    ];

    if ($routeParams.id) {
      requests.push(promotionsService.getItem($routeParams.id));
    }

    vm.loading = true;

    $q.all(requests).then(function (val) {
      vm.itineraries = val[0];
      vm.trips = val[1].data;

      if (val[2]) {
        vm.promo = val[2];

        vm.promo.trips = _.reduce(vm.promo.trips, function (obj, param) {
          obj[param] = param;
          return obj;
        }, {});

        vm.promo.itineraries = _.reduce(vm.promo.itineraries, function (obj, param) {
          obj[param] = param;
          return obj;
        }, {});
      }

      vm.loading = false;
    });

    function fileUpload(file) {
      if (!file || (file && !file.length)) {
        return 0;
      }
      vm.errorFormat = [];
      vm.errorPhoto = [];

      var fileToUpload = file[0];

      if (!utils.acceptedFileFormat(fileToUpload.type)) {
        return vm.errorFormat.push(fileToUpload.name);
      }

      Upload.imageDimensions(fileToUpload).then(function (d) {
        if (d.width < 600 || d.height < 400) {
          return vm.errorPhoto.push(file.name);
        }
        vm.promo.picture = fileToUpload;
      });
    }

    function savePromo() {
      vm.loading = true;
      _.each(vm.promo.tags, function (tag) {
        if (tag.isNew) {
          tag.id = undefined;
        }
      });

      promotionsService.savePromo(vm.promo, vm.trips).then(function () {
        vm.loading = false;

        $location.path(utils.createURL('promotions'));
        if ($routeParams.id) {
          $rootScope.showNotify(gettextCatalog.getString('Your promotion was sucessfully edited'));
        } else {
          $rootScope.showNotify(gettextCatalog.getString('Your promotion was sucessfully saved'));
        }
      }).catch(function (error) {
        vm.loading = false;
      });
    }

    function checkboxValid(field) {
      if (_.isEmpty(vm.promo[field])) {
        return true;
      }

      return _.filter(_.map(vm.promo[field]), function (val) {
        return val;
      });
    }

    function updateTags(item, type) {
      console.log(item, type);
    }

    function selectAll(type) {
      var sourceArray = [],
        resultArray = [],
        selector = false;
      if (type === 'itineraries') {
        sourceArray = vm.itineraries;
        resultArray = vm.promo.itineraries;
        selector = vm.selectAllItineraries;
      } else if (type === 'trips') {
        sourceArray = vm.trips;
        resultArray = vm.promo.trips;
        selector = vm.selectAllTrips;
      }
      _.each(sourceArray, function (item) {
        if (selector === true) {
          resultArray[item.id] = item.id;
        } else {
          resultArray[item.id] = false;
        }
      });
    }
  });
