'use strict';

angular
  .module('app')
  .directive('diveGuideForm', function (
    utils,
    itineraryService,
    diveGuideService
  ) {
    var controller = function ($scope) {
      var vm = this;
      vm.getAirport = itineraryService.getAirport;
      vm.showStatusSelector = false;
      vm.searchMarineLife = diveGuideService.searchMarineLife;
      vm.loading = true;
      vm.dcComplete = false;
      vm.utils = utils;
      vm.monthes = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
      ];
      vm.saveMarineLife = () => {
        if (!vm.newMarineLife) {
          return false;
        }
        const object = {
          ...vm.newMarineLife,
          ...Object.fromEntries(vm.monthes.map((month) => [month, 10])),
        };
        if (!_.isArray($scope.marineLife)) {
          $scope.marineLife = [];
        }
        $scope.marineLife.push(object);
        vm.addMarineLife = false;
        vm.newMarineLife = false;
        vm.fishName = '';
        return true;
      };

      vm.deleteMarineLife = (index) => {
        $scope.marineLife.splice(index, 1);
      };

      vm.onMarineLifeSelect = (item) => {
        vm.newMarineLife = {
          ...item,
          critterId: item.id,
          critter: {
            title: item.title,
            id: item.id,
          },
        };
        return true;
      };

      vm.updateMarineLife = (index, month) => {
        if ($scope.disabled) {
          return false;
        }
        const value = $scope.marineLife[index][month];
        $scope.marineLife[index][month] = value === 30 ? 10 : value + 10;

        return true;
      };

      $scope.$watch(
        'marineLife',
        function (newValue, oldValue) {
          if (newValue && newValue !== oldValue) {
            vm.marineLife = $scope.marineLife;
          }
        },
        true
      );
    };

    return {
      restrict: 'EA',
      templateUrl: '../templates/_dive-guide-form.html',
      controller: controller,
      controllerAs: 'ctrl',
      replace: true,
      require: '^extranetForm',
      scope: {
        extranetForm: '=',
        formValues: '=',
        disabled: '=',
        marineLife: '=',
        hideTitle: '=',
      },
    };
  });

('use strict');

angular
  .module('app')
  .directive('diveGuideFormDetailed', function (
    utils,
    info,
    itineraryService,
    diveGuideService
  ) {
    const formatInfo = (data) =>
        data.map((item) => ({
          title: item[1],
          id: item[0],
        })),
      controller = function ($scope) {
        var vm = this;
        vm.airportList = [];
        vm.getAirport = itineraryService.getAirport;
        vm.getAirportList = (text, selectedAirports) => {
          if (text.length < 3) {
            return false;
          }
          return itineraryService.getAirport(text).then((data) => {
            vm.airportList = data.filter((airport) => {
              return !_.find(selectedAirports, { id: airport.id });
            });
          });
        };
        vm.getGoogleLocations = (text) =>
          diveGuideService.getGoogleLocations(null, text);
        vm.CURRENCY_CHOICES = info.shop.CURRENCY_CHOICES;
        vm.PRESSURE_CHOICES = info.common.PRESSURE_CHOICES;
        vm.LOCAL_UTC_OFFSET = formatInfo(info.places.LOCAL_UTC_OFFSET);
        vm.languagesSpoken = formatInfo(info.places.LOCAL_LANGUAGES);
        vm.LOCAL_PLUG_TYPE = formatInfo(info.places.LOCAL_PLUG_TYPE);
        vm.FIRST_STAGE_STANDARTS = info.places.FIRST_STAGE_STANDARTS;
        vm.diveGuideTrainingCourses = info.diveGuideTrainingCourses;
        vm.showStatusSelector = false;
        vm.loading = true;
        vm.dcComplete = false;
        vm.utils = utils;
        vm.toggleValues = (value) => {
          // I know that I can write !$scope.formValues.trainings[value] - but it's not working :-D
          $scope.formValues.trainings[value] = $scope.formValues.trainings[
            value
          ]
            ? true
            : false;
        };
      };

    return {
      restrict: 'EA',
      templateUrl: '../templates/_dive-guide-form-detailed.html',
      controller: controller,
      controllerAs: 'ctrl',
      replace: true,
      require: '^extranetForm',
      scope: {
        extranetForm: '=',
        formValues: '=',
        disabled: '=',
      },
    };
  });
