'use strict';

angular
  .module('app')
  .controller('ActivityCompleteCtrl', function ($routeParams, product) {
    var vm = this;
    // vm.productDescriptionPage = true;
    vm.productId = $routeParams.id;
    vm.redirectNewExtranet = product.redirectNewExtranet;

    product.getProduct($routeParams.id).then(function (data) {
      vm.finishedTabs = data.finishedTabs;
      vm.productData = data;
    });
  });
