'use strict';

angular.module('app').directive('toggleSidebar', function() {
  return function(scope, elem, attr) {
    var toggled = false,
        leftNav = $('.left-navigation'),
        mainWrapper = $('.main-wrapper');

    $(elem).on('click', function(e){
        leftNav.toggleClass('collapsed');
        mainWrapper.toggleClass('expanded');

        mainWrapper.trigger('classChange');
    });
  };
})
