'use strict';

angular.module('app').directive('onlyUrl', function () {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, element, attr, ctrl) {
      function inputValue(val) {
        if (val) {
          var url = /^((http:|https:)?(\/\/)?(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|^www\.[^\s]+\.[^\s]{2,})$/;
          if (url.test(val)) {
            ctrl.$setViewValue(val);
            ctrl.$render();
          } else {
            return undefined;
          }

          return val;
        }
        return null;
      }
      ctrl.$parsers.push(inputValue);

      ctrl.$viewChangeListeners.push(function () {
        ctrl.$setValidity(attr.name, true);
      });
    },
  };
});
