'use strict';

angular.module('app').directive('formPopup', function ($compile, $rootScope, $location, $timeout, $http) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      $(element).on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        function openFancybox(url) {
          $http.get(url).then(function (response) {
            if (response.status == 200) {
              var template = $compile(angular.element(response.data))(scope);

              $.fancybox({
                content: template,
                type: 'html',
                padding: attrs.padding ? (attrs.padding).split(',') : 40,
                wrapCSS: (attrs.formClass) ? 'form-popup ' + attrs.formClass : 'form-popup',
                maxWidth: attrs.maxWidth,
                beforeShow: function () {
                  if (attrs.beforeShow) {
                    if (attrs.useScope) {
                      $rootScope[attrs.beforeShow]();
                    } else {
                      scope[attrs.beforeShow]();
                    }
                  }
                },

                afterClose: function () {
                  scope.step = 1;

                  if (attrs.onClose) {
                    if (attrs.useScope) {
                      $rootScope[attrs.onClose]();
                    } else {
                      scope[attrs.onClose]();
                    }
                  }
                },
                afterShow: function () {
                  if (attrs.callback) {
                    scope[attrs.callback]();
                  }
                  if (attrs.scrollTo) {
                    $(element).scrollTo(attrs.scrollTo);
                  }
                  if ($location.search().rating) {
                    var number = parseInt($location.search().rating);

                    $('.big-stars .stars').attr('value', number);

                    angular.element($('.big-stars li')[number - 1]).triggerHandler('click');

                    for (var i = 1; i <= 5; i++) {
                      if (i <= number) {
                        $($('.big-stars li span')[i - 1]).addClass('star-active').removeClass('star');
                      }
                    }
                  }
                },
                helpers: {
                  overlay: {
                    locked: attrs.fixedScreen ? true : false,
                  },
                },
              });

              $timeout(function () {
                $.fancybox.update();
              }, 300);
            }
          });
        }

        openFancybox(attrs.includeHtml);
      });
      $(document).on('click', '.popup-close', function () {
        parent.$.fancybox.close(true);
      });
    },
  };
});
