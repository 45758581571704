'use strict';

angular
  .module('app')
  .service('diveCenterService', function (
    Request,
    $rootScope,
    info,
    gettextCatalog,
    utils,
    $uibModal
  ) {
    var diveCenter = {};

    diveCenter.getInfo = function () {
      return Request.get(
        'shop/' + utils.getCurrentShopSlug() + '/dive-center/'
      ).then(function (res) {
        return res.data;
      });
    };

    diveCenter.getParameters = function () {
      return Request.get(
        'shop/' + utils.getCurrentShopSlug() + '/parameters/'
      ).then(function (res) {
        return res.data;
      });
    };

    diveCenter.setParameters = function (data) {
      var slug = utils.getCurrentShopSlug();
      return Request.patch('shop/' + slug + '/parameters/', {
        parameters: data,
      }).then(function (res) {
        return res.data;
      });
    };

    diveCenter.setInfo = function (data) {
      var slug = utils.getCurrentShopSlug();

      return Request.patch('shop/' + slug + '/parameters/', {
        parameters: data.parameters,
      }).then(function () {
        _.each(data, function (val, key) {
          if (val === '') {
            data[key] = null;
          }
        });

        return Request.patch('shop/' + slug + '/dive-center/', data).then(
          function (res) {
            $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved'));
            return res.data;
          }
        );
      });
    };

    diveCenter.getAmenities = function (group) {
      if (!info.parameters) {
        return false;
      }

      var data = _.map(info.parameters[utils.shopKind(group)]);

      _.each(data, function (val, key) {
        if (!val.parameters.length) {
          delete data[key];
        }

        if (val.parameters.length <= 3) {
          val.hideButton = true;
        }
      });

      if ($rootScope.selectedShop.kind == 20 && group === 'Dive center') {
        var DRparams = _.map(info.parameters[utils.shopKind('Dive resort')]);

        _.each(data, function (dc) {
          _.each(DRparams, function (dr) {
            if (dc && dr) {
              dc.parameters = _(dc.parameters)
                .each(function (params, key) {
                  _.each(dr.parameters, function (par) {
                    if (params && par && params.id === par.id) {
                      delete dc.parameters[key];
                    }
                  });
                })
                .compact()
                .value();
            }
          });
        });
      }

      return data;
    };

    diveCenter.showStatusPopup = () => {
      if (utils.getCookie(`dc_popup_${utils.getCurrentShopSlug()}`)) {
        return false;
      }

      return $uibModal.open({
        animation: true,
        templateUrl: '../templates/_possible-to-change-status.html',
        controller: function ($injector, $uibModalInstance) {
          utils.setCookie(`dc_popup_${utils.getCurrentShopSlug()}`, true, {
            path: '/',
            domain: '.' + window.location.hostname.split('.').splice(1).join('.'),
            expires: 3600 * 24 * 1, /* 1 day */
          });

          const _this = this;
          _this.close = function () {
            $uibModalInstance.dismiss('close');
          };

          _this.save = function () {
            const shopService = $injector.get('shopService');
            $rootScope.isOnAdventures = true;
            _this.loading = true;
            shopService.setStateWithConfirm('adventures').then((isSaved) => {
              if (isSaved !== 'cancel') {
                $uibModalInstance.dismiss('close');
              }
              _this.loading = false;
            });
          };
        },
        controllerAs: 'ctrl',
      });
    }

    return diveCenter;
  });
