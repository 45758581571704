'use strict';

angular.module('app')
  .service('availabilityService', function ($q, Request, diviacLocalStorage, $rootScope, info, utils, Upload) {
    var availabilityService = {};

    availabilityService.getList = function (page) {
      let obj = {};

      if (page) {
        obj.page = page;
      }
      return Request.get('booking/' +  utils.getCurrentShopSlug() + '/package-categories/availability/', obj).then(function (res) {
        return generateMonthGroup(res.data);
      });
    };

    availabilityService.getRoomList = function (page) {
      let obj = {};

      if (page) {
        obj.page = page;
      }
      return Request.get('booking/' +  utils.getCurrentShopSlug() + '/package-categories/availability/cabins/', obj).then(function (res) {
        return generateMonthGroup(res.data);
      });
    };

    availabilityService.saveRoom = function (trip) {
      return Request.patch('booking/' +  utils.getCurrentShopSlug() + '/package-categories/availability/cabins/' + trip.id + '/', trip).then(function (res) {
        return generateMonthGroup(res.data);
      });
    };


    availabilityService.saveItem = function (availability) {
      $rootScope.checkedList.availability = true;

      return Request.patch('booking/' +  utils.getCurrentShopSlug() + '/package-categories/availability/' + availability.id + '/', availability).then(function (res) {
        return res.data;
      });
    };

    availabilityService.getInfo = function () {
      return Request.get('shop/' +  utils.getCurrentShopSlug() + '/availability-basic/').then(function (res) {
        return res.data;
      });
    };


    availabilityService.setInfo = function (data) {
      return Request.patch('shop/' +  utils.getCurrentShopSlug() + '/availability-basic/', data).then(function (res) {
        return res.data;
      });
    };


    availabilityService.getAvailability = function (data) {
      return Request.get('booking/' +  utils.getCurrentShopSlug() + '/rooms/availability/', data).then(function (res) {
        return res.data;
      });
    };

    availabilityService.changeAvailability = function (data, roomId, requestType) {
      if (!requestType) {
        requestType = 'patch';
      }
      return Request[requestType]('booking/' +  utils.getCurrentShopSlug() + '/rooms/availability/' + roomId + '/' + (data.id  || 'add') + '/', data).then(function (res) {
        return res.data;
      });
    };

    availabilityService.updateRoom = function (roomId, data, range) {
      return Request.patch('booking/' +  utils.getCurrentShopSlug() + '/rooms/availability/' + roomId + '/bulk-update/', data, range).then(function (res) {
        return res.data;
      });
    };

    availabilityService.updateRoomOrder = function (data) {
      return Request.put('booking/' +  utils.getCurrentShopSlug() + '/rooms/availability/ordering/', data).then(function (res) {
        return res.data;
      });
    };

    availabilityService.updateNumberOfRooms = function (roomId, numberOfRooms) {
      return Request.patch(`booking/rooms/${roomId}`, {
        numberOfRooms,
      }).then(function (res) {
        return res.data;
      });
    };


    function generateMonthGroup(res) {
      var groupedTrips = {};


      _.each(res.results, function (data) {
        var month = moment(data.startDate).month(),
          year = moment(data.startDate).year();

        if (!groupedTrips[year + '_' + month]) {
          groupedTrips[year + '_' + month] = [];
        }

        groupedTrips[year + '_' + month].push(data);
      });

      return {
        groupedTrips: groupedTrips,
        list: res.results,
        allList: res,
      };
    }

    return availabilityService;
  });
