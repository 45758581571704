'use strict';

angular.module('app')
  .controller('RateCtrl', function ($uibModal, shopService, rateService) {
    var vm = this;

    vm.loading = true;
    
    vm.deleteRate = deleteRate;

    shopService.getShopCurrency().then(function(data) {
        vm.currency = data.currency;
    })
    rateService.getList().then(function(data) {
        vm.rates = data;
        _.each(vm.rates, function(rate) {
            parent = _.find(vm.rates, { 'id': rate.basedOn })
            rate.basedOn = parent ? parent.title : null;
        })

        vm.loading = false;
    })

    function deleteRate (id) {
        var modal = $uibModal.open({
            animation: true,
            controller: 'ModalClaimCtrl',
            controllerAs: 'claimModal',
            windowClass: 'contact-us',
            templateUrl: '../templates/_form-change-status.html',
        })
    
        modal.result.then(function() {
          vm.loading = true;

          rateService.deleteRate(id).then(function() {
            vm.rates = _.reject(vm.rates, { id: id});
            vm.loading = false;
          });
        }, function() {
          vm.loading = false;
        });
    }
    
});
