'use strict';

angular.module('app')
    .controller('LogisticsItineraryCtrl', function ($q, $uibModalInstance, utils, itineraryService, itinerary) {

        var vm = this;

        vm.addNew = addNew;
        vm.save = save;
        vm.removeItem = removeItem;
        vm.utils = utils;
        vm.kindOther = utils.getOptionsByText("Other","ITINERARY_DESCRIPTION_KIND");


        vm.close = function() {
          $uibModalInstance.dismiss('cancel');
        };

        vm.loading = true;


        vm.logisticData = itinerary;

        itineraryService.getLogistics(itinerary.id).then(function(data) {
          vm.info = _.groupBy(data, 'section');

          _.each(vm.info, function (val, key) {
            if (!_.find(val, {kind: vm.kindOther})) {
              val.push({kind: vm.kindOther, section: +key})
            }
          });

          vm.loading = false;
        });


        function addNew(section) {
          return vm.info[section].push({kind: vm.kindOther, section: +section});
        }

        function removeItem(item) {
          item.removed = true;
        }

        function save(form) {
          form.$setSubmitted();

          if (form.$valid) {
            vm.loading = true;

            var requests = [];

            _.each(vm.info, function (val) {
              _.each(val, function(v) {
                if (v.removed && v.id) {
                  requests.push(itineraryService.removeLogisticsItem(itinerary.id,v));
                } else if (v.id) {
                  requests.push(itineraryService.updateLogisticsItem(itinerary.id,v));
                } else if (!v.removed && !v.id && v.contents){
                  requests.push(itineraryService.addLogisticsItem(itinerary.id,v));
                }
              });
            });

            requests.push(itineraryService.setLogisticsSwitch(vm.logisticData))

            $q.all(requests).then(function() {
                $uibModalInstance.close('saved');
            })
            .finally(function() {
              vm.loading = false;
            });
          }
        }

    });
