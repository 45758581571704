'use strict';

angular
  .module('app')
  .controller('ProductDescription', function (
    $location,
    $rootScope,
    product,
    $routeParams,
    SeoService,
    utils,
    gettextCatalog
  ) {
    var vm = this;

    $rootScope.showActivity = true;
    vm.loading = true;
    vm.productId = $routeParams.id;
    vm.productDescriptionPage = true;
    vm.updateDescription = updateDescription;
    vm.tags = [];
    vm.needTranslate = [
      'title',
      'other_requirements',
      'short_description',
      'what_to_expect',
      'highlights',
    ];
    vm.highlightChanged = highlightChanged;
    vm.isHighlightsRequired = true;
    vm.redirectNewExtranet = product.redirectNewExtranet;

    product.getProduct($routeParams.id).then(function (data) {
      vm.finishedTabs = data.finishedTabs;
      vm.productData = _.clone(data);

      if (
        utils.isArrayContainElementsFromOtherArray(
          vm.productData.autoTranslatedFields,
          vm.needTranslate
        )
      ) {
        $rootScope.showNotify(
          gettextCatalog.getString(
            'The text fields on this page have been auto-translated using Google Translate. You may edit them manually if you wish.'
          )
        );
      }

      if (!vm.productData.tags.length) {
        vm.productData.tags = [];
      }

      if (vm.productData.productType.value === 50) {
        vm.isHighlightsRequired = false;
      }

      if (!vm.productData.highlights.length) {
        vm.productData.highlights = [];
      }

      // create 5 highlights;
      for (var i = 0; i < 5; i++) {
        if (!vm.productData.highlights[i]) {
          vm.productData.highlights.push('');
        }
      }

      SeoService.generateMetaTags(
        vm.productData.title +
        gettextCatalog.getString(' - Products - PADI Travel for Business')
      );

      product.getTags().then(function (tags) {
        vm.tags = tags;

        vm.loading = false;
      });

      vm.highlightChanged();
    });

    function updateDescription(form, newProduct) {
      if (form.$valid) {
        vm.loading = true;
        newProduct.highlights = newProduct.highlights.filter(Boolean);
        // actually update
        product.addNew(newProduct, $routeParams.id).then(
          function (result) {
            vm.loading = false;
            $rootScope.showNotify(
              gettextCatalog.getString('The product was successfully changed')
            );
            if (result.data.diveProductId) {
              $location.path(
                utils.createURL('product_logistics', {
                  id: result.data.diveProductId,
                })
              );
            }
          },
          function () {
            vm.loading = false;
          }
        );
      }
    }

    function highlightChanged() {
      vm.isHighlightsRequired = true;
      _.each(vm.productData.highlights, (hl) => {
        if (hl) {
          vm.isHighlightsRequired = false;
          return;
        }
      });
    }
  });
