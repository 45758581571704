'use strict'

angular.module('app').filter('limitFromTo', function() {
    return function(input, offset, limit) {
        if(!(input instanceof Array) && !(input instanceof String) && !(typeof input === "string")) return input;

        limit = parseInt(limit,10);

        if (input instanceof String || (typeof input === "string")) {
            if (limit) {
                return limit >= 0 ? input.slice(offset, limit) : input.slice(limit, input.length);
            } else {
                return "";
            }
        }

        var out = [],
            i, n;

        if (limit > input.length)
            limit = input.length;
        else if (limit < -input.length)
            limit = -input.length;

        if (limit > 0) {
            i = offset;
            n = limit;
        } else {
            i = input.length + limit;
            n = input.length;
        }

        for (; i<n; i++) {
            out.push(input[i]);
        }

        return out;
    };
});