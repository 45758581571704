'use strict';

angular
  .module('app')
  .directive('productStatusDirective', function (
    product,
    $rootScope,
    $uibModal,
    info,
    utils,
    gettextCatalog
  ) {
    var controller = function ($scope) {
      var vm = this;
      vm.showStatusSelector = false;
      vm.loading = true;
      vm.dcComplete = false;
      vm.changeStatus = changeStatus;
      vm.statusList = info.booking.DIVE_PRODUCT_STATUS;
      vm.redirectNewExtranet = product.redirectNewExtranet;

      function activate() {
        vm.templateUrl = getTemplateUrl(vm.productType);
        product
          .getStatus(vm.productId)
          .then(function (res) {
            vm.status = res.status;
            vm.showStatusSelector = true;
            vm.loading = false;
          })
          .catch(function (err) {
            console.error(err);
            vm.showStatusSelector = false;
            vm.loading = false;
          });
      }

      function checkDCTabs() {
        var tabs = $rootScope.checkedList;
        if (tabs) {
          return (
            tabs.admin &&
            tabs.agreement &&
            tabs.basics &&
            tabs.photos &&
            tabs.diveSites
          );
        }
        return false;
      }

      function getTemplateUrl(type) {
        return type === 50
          ? '../templates/_conservation-preview.html?v=2'
          : '../templates/_activity-preview.html?v=2';
      }

      function changeStatus(oldVal) {
        vm.dcComplete = checkDCTabs();

        function onSuccess() {
          $rootScope.showNotify(
            gettextCatalog.getString('Product status was successfully updated')
          );
          vm.loading = false;
        }

        function onError() {
          vm.status = oldVal;
          // $rootScope.showNotify('Something went wrong');
          vm.loading = false;
        }

        vm.loading = true;

        if ([20, 30].includes(vm.status) && +oldVal === 10) {
          // submitting for review or publishing;
          if (
            (vm.productComplete && vm.dcComplete) ||
            (utils.isProDiverShop() && vm.productComplete)
          ) {
            product
              .setStatus(vm.productId, vm.status)
              .then(onSuccess)
              .catch(onError);
            vm.redirectNewExtranet();
          } else {
            utils
              .warningPopup(
                '../templates/_activity-mandatory-tabs.html',
                $scope
              )
              .then(function () {
                return;
              })
              .catch(function () {
                return false;
              });
            onError();
          }
        } else if (vm.status === 40) {
          $uibModal.open({
            animation: true,
            templateUrl: '../templates/_reject-activity.html',
            controller: function ($uibModalInstance) {
              this.reject = (form) => {
                form.$setSubmitted(true);
                if (form.$invalid) {
                  return false;
                }
                this.loading = true;
                return product
                  .setStatus(vm.productId, vm.status, this.rejectReason)
                  .then(() => {
                    onSuccess();
                    this.close();
                  })
                  .catch(onError);
              };
              this.close = () => {
                vm.loading = false;
                $uibModalInstance.close();
              };
            },
            controllerAs: '$modal',
          });
        } else {
          product
            .setStatus(vm.productId, vm.status)
            .then(onSuccess)
            .catch(onError);
        }
      }

      if (vm.productId) {
        activate();
        checkDCTabs();
      } else {
        return;
      }
    };

    return {
      restrict: 'A',
      templateUrl: '../templates/_product-status.html',
      controller: controller,
      controllerAs: 'ctrl',
      bindToController: true,
      scope: {
        productId: '=',
        productComplete: '=',
        productType: '=',
      },
    };
  });
