'use strict'

angular.module('app').directive('validateVideo', function() {
  var VALIDATE_VIDEO = /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/;

  return {
    require: 'ngModel',
    restrict: '',
    link: function(scope, elm, attrs, ctrl) {
      if (ctrl) {
        ctrl.$validators.video = function(modelValue) {
          return ctrl.$isEmpty(modelValue) || VALIDATE_VIDEO.test(modelValue);
        };
      }
    }
  };
});