'use strict';

angular.module('app')
  .controller('RoomAddEdit', function ($scope, $routeParams, $location, $timeout, $uibModal, $rootScope, gettextCatalog, info, product, shopService, Upload, roomService, utils, recalcService) {
    var vm = this,
      formSubmited = false,
      prevPrices;

    vm.fileUpload = fileUpload;
    vm.saveRoom = saveRoom;
    vm.deletePhoto = deletePhoto;
    vm.dragEnd = dragEnd;
    vm.toggleParameters = toggleParameters;
    // vm.getMaxDiscount = getMaxDiscount;
    // vm.getPricePerGuest = getPricePerGuest;
    vm.changeOccupancy = changeOccupancy;
    vm.nextPricing = roomService.nextPricing;

    vm.isCopy = window.location.href.search('copy') !== -1;

    activate();

    // $scope.$on('$locationChangeStart', function (event, toState, oldState) {
    //   if (!formSubmited) {
    //     if ($scope.extranetForm.$dirty && $scope.extranetForm.$invalid) {
    //       event.preventDefault();
    //       utils.warningPopup().then(function () {
    //         $scope.extranetForm.$setPristine();
    //         $location.path($rootScope.currentUrl);
    //       }).catch(function () {
    //         $rootScope.currentUrl = '/rooms/';
    //       });
    //     }

    //     if ($scope.extranetForm.$dirty && $scope.extranetForm.$valid) {
    //       if (checkTotalNumbers()) {
    //         event.preventDefault();
    //         utils.warningPopup('../templates/_number-of-rooms-popup.html', $scope).then(function () {
    //           vm.showWarning = false;
    //           saveRoomOnLeave($routeParams.id);

    //           $location.path($rootScope.currentUrl);

    //         }).catch(function () {
    //           vm.loading = false;
    //           $rootScope.currentUrl = '/rooms/';
    //         });

    //         return true;
    //       }
    //       saveRoomOnLeave($routeParams.id);
    //     }
    //   }
    // });

    function saveRoomOnLeave(oldRoom) {
      vm.add.pricesWrite = _.clone(vm.add.pricesRead);
      roomService.saveRoom(vm.add, $routeParams.id).then(function () {
        formSubmited = true;

        $rootScope.checkedList.rooms = vm.params.totalNumberOfRooms ? true : false;

        if (oldRoom) {
          $rootScope.showNotify(gettextCatalog.getString('Room was successfully changed'));
        } else {
          $rootScope.showNotify(gettextCatalog.getString('Room was successfully added'));
        }
      });
    }

    function activate() {
      vm.loading = true;
      vm.roomType = info.roomTypes;
      vm.bedType = info.shop.BED_TYPE;
      vm.imagesThumb = [];
      vm.errorPhoto = [];
      vm.errorFormat = [];
      vm.disableLoading = [];
      vm.roomAmenities = info.roomAmenities;
      vm.selectedParams = {};
      vm.roomsNumber = 0;
      vm.showWarning = true;


      vm.add = {
        roomType: '',
        standardName: '',
        numberOfRooms: '',
        title: '',
        description: '',
        size: null,
        bedType: '',
        standardOccupancy: '',
        maxOccupancy: '',
        roomPrice: '',
        imagesRead: [],
        photos: [],
        writeAmenities: [],
        price: '',
        prices: [],
        whatsIncluded: null,
        whatsNotIncluded: null,
        lowerRate: 'no',
        isDiscountPercent: true,
      };

      shopService.getShopCurrency().then(function (data) {
        vm.currency = data.currency;

        vm.discountVariants = [
          { title: '%', type: true },
          { title: vm.currency || 'USD', type: false }
        ];
      });

      roomService.getInfo().then(function (data) {
        vm.params = data;
        vm.seasons = [];

        if (data.seasons.length) {
          vm.seasons = _.reject(data.seasons, { 'title': 'Closed' });
          vm.selectedPriceTab = vm.seasons[0].pk;


          if (vm.seasons.length > 4) {
            vm.from = 0;
            vm.limit = 3;
          }
        }

        if ($routeParams.id) {
          roomService.getRoom($routeParams.id).then(function (data) {
            vm.add = data;
            vm.add.writeAmenities = [];
            vm.add.lowerRate = data.discountAmount ? 'yes' : 'no';

            changeOccupancy();
            prevPrices = _.cloneDeep(vm.add.prices);
            // getMaxDiscount(true);
            if (data.pricesRead) {
              vm.selectedPriceTab = data.pricesRead[0].id;


              if (vm.add.pricesRead.length > 4) {
                vm.from = 0;
                vm.limit = 3;
              }
            }
            _.each(data.readAmenities, function (val) {
              vm.selectedParams['selected_' + val.id] = true;

              vm.add.writeAmenities.push(val.id);
            });
            checkPrices();
            vm.loading = false;

            if (vm.isCopy) {
              vm.add.title = '';
              delete vm.add.id;
              vm.add.imagesRead = [];
              vm.add.photos = [];
            }
            // changeOccupancy();
          });
        } else {
          changeOccupancy();
          vm.loading = false;
        }
      });

      roomService.getList().then(function (data) {
        _.each(data, function (val) {
          if (!$routeParams.id || $routeParams.id && val.roomId != $routeParams.id) {
            vm.roomsNumber += val.roomNumber;
          }
        });
      });

      recalcService.getRecalcUpdates();
    }

    function checkPrices() {
      vm.priceWarning = false;
      _.each(vm.add.prices, function (price) {
        if (!price.priceNet) vm.priceWarning = true;
      });
    }


    function saveRoom(form) {
      if (vm.add.standardOccupancy > vm.add.maxOccupancy) {
        return true;
      }

      if (form.$valid) {
        vm.loading = true;
        // get latest recalc status
        recalcService.getRecalcUpdates().then(function () {
          vm.add.pricesWrite = _.clone(vm.add.prices);

          var noPrices = _.find(vm.add.prices, function (val) {
            return !val.priceNet;
          });

          if (noPrices) {
            var modalInstance = $uibModal.open({
              animation: false,
              templateUrl: '../templates/_room-save-warning.html',
              controller: 'ModalClaimCtrl',
              controllerAs: 'claimModal',
              windowClass: 'contact-us',
            });

            return modalInstance.result.then(function (res) {
              updateRoomInfo(form);

              return res;
            }).catch(function () {
              vm.loading = false;
              return;
            });
          }

          updateRoomInfo(form);
        });
      }
    }

    function updateRoomInfo(form) {
      _.each(vm.add.prices, function (price) {
        if (!price.priceNet) {
          price.priceNet = null;
        }
        if (_.size(price.seasonPrices) > vm.add.maxOccupancy) {
          _.each(price.seasonPrices, function (val, key) {
            if (+key > vm.add.maxOccupancy) {
              delete price.seasonPrices[key];
            }
          });
        }
      });
      if (checkTotalNumbers()) {
        utils.warningPopup('../templates/_number-of-rooms-popup.html', $scope).then(function () {
          vm.showWarning = false;
          saveRoom(form);
        }).catch(function () {
          vm.loading = false;
        });

        return true;
      }

      if (vm.isCopy) {
        delete $routeParams.id;
        _.each(vm.add.prices, function (price) {
          delete price.pk;
        });
      }

      var pricesChanged = !(_.isEqual(prevPrices, vm.add.prices));
      // forbid seasons saving
      if ($rootScope.recalcStatus === 2 && pricesChanged) {
        $rootScope.showNotify(gettextCatalog.getString('Editing prices and room occupancy is disabled during price recalculations'), 6000);
        vm.loading = false;
        return false;
      }

      roomService.saveRoom(vm.add, $routeParams.id).then(function () {
        formSubmited = true;

        $location.path(utils.createURL('rooms'));

        vm.loading = false;

        $rootScope.checkedList.rooms = true;

        if (pricesChanged) {
          recalcService.setStatus(1); // needs recalc
        }

        if ($routeParams.id) {
          $rootScope.showNotify(gettextCatalog.getString('The product was successfully changed'));
        } else {
          $rootScope.showNotify(gettextCatalog.getString('The product was successfully added'));
        }
      }, function (error) {
        vm.loading = false;
        if (error.data.numberOfRooms) {
          form.numberOfRooms.$invalid = true;
        }
      });
    }

    function fileUpload(files, maxLength) {
      if (files && (vm.add.imagesRead.length <= maxLength)) {
        vm.errorPhoto = [];
        vm.errorFormat = [];

        _.each(files, function (file) {
          var acceptedFormat = _.find(['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'], function (v) {
            return v == file.type;
          });

          if (!acceptedFormat) {
            return vm.errorFormat.push(file.name);
          }

          var randomId = parseInt(Math.random() * 1000000);

          Upload.imageDimensions(file).then(function (d) {
            if (d.width < 600 || d.height < 400) {
              vm.errorPhoto.push(file.name);
            } else if (vm.add.imagesRead.length + 1 <= 6) {
              vm.disableLoading.push(randomId);

              vm.add.imagesRead.push({
                id: randomId,
                absoluteImage: '/images/loading-photo.jpg',
                type: 'loader',
              });

              product.uploadPhotos(file).then(function (data) {
                vm.add.imagesRead = _.reject(vm.add.imagesRead, function (val) {
                  return val.id == randomId;
                });

                vm.add.imagesRead.push(data);

                if (!_.isArray(vm.add.photos)) {
                  vm.add.photos = [];
                }
                vm.add.photos.push(data.id);

                vm.disableLoading = _.without(vm.disableLoading, randomId);
                vm.add.isOwnPhoto = false;
              }, function (error) {
                vm.add.imagesRead = _.reject(vm.add.imagesRead, function (val) {
                  return val.id == randomId;
                });

                vm.disableLoading = _.without(vm.disableLoading, randomId);
              }, function (progress) {
                _.each(vm.add.imagesRead, function (val) {
                  if (val.id == randomId) {
                    val.progress = progress;

                    if (progress == 100) {
                      val.processing = true;
                    }
                  }
                });
              });
            }
          }, function (err) {
            console.log(err);
          });
        });
      }
    }

    function toggleParameters(id) {
      var element = _.filter(vm.add.writeAmenities, function (elem) {
        return elem === id;
      });

      if (element.length) {
        vm.add.writeAmenities = _.reject(vm.add.writeAmenities, function (elem) {
          return elem === id;
        });
      } else {
        vm.add.writeAmenities.push(id);
      }
    }

    // function getMaxDiscount (update) {
    //    if (update) {
    //        var data = _.clone(vm.add.discountAmount);
    //
    //        vm.add.discountAmount = undefined;
    //
    //        $timeout(function() {
    //            vm.add.discountAmount = data;
    //        });
    //
    //        $scope.extranetForm.discount.$dirty = true;
    //    }
    //
    //    if (vm.add.isDiscountPercent) {
    //        vm.max = 100;
    //    } else {
    //        vm.max = _.clone(vm.add.roomPrice);
    //    }
    // }
    //
    // function getPricePerGuest () {
    //    if (!_.isNumber(vm.add.discountAmount)) {
    //        return '-';
    //    }
    //
    //    if (vm.add.isDiscountPercent) {
    //        var value = vm.add.roomPrice - (vm.add.roomPrice * (vm.add.discountAmount / 100));
    //        if (value < 0) {
    //            return '-';
    //        }
    //        return Math.round(value * 100) / 100;
    //    } else {
    //        var data = vm.add.roomPrice - vm.add.discountAmount;
    //
    //        return data < 0 ? '-' : data;
    //    }
    // }

    function deletePhoto(id) {
      return roomService.deletePhoto(id).then(function () {
        vm.add.imagesRead = _.reject(vm.add.imagesRead, { id: id });
        vm.add.photos = _.without(vm.add.photos, id);
      });
    }

    function dragEnd() {
      _.each(vm.add.imagesRead, function (val, index) {
        val.position = index;
      });

      product.setPhoto(vm.add.imagesRead);
    }

    function changeOccupancy(occupancy) {
      if (vm.add.maxOccupancy) {
        if (occupancy) {
          vm.add.prices = [];
        }

        if (vm.seasons.length) {
          _.each(vm.seasons, function (val) {
            for (var i = 1; i <= vm.add.maxOccupancy; i++) {
              if (!_.find(vm.add.prices, {
                season: val.pk,
                numberOfInstances: i,
              })) {
                vm.add.prices.push({
                  priceNet: null,
                  season: val.pk,
                  numberOfInstances: i,
                });
              }
            }
          });
        } else if (!vm.add.prices.length) {
          for (var i = 1; i <= vm.add.maxOccupancy; i++) {
            vm.add.prices.push({
              priceNet: null,
              seasonDateRange: null,
              season: null,
              numberOfInstances: i,
            });
          }
        }
      }
    }

    function checkTotalNumbers() {
      $scope.rooms = +vm.roomsNumber + parseInt(vm.add.numberOfRooms);
      $scope.total = +vm.params.totalNumberOfRooms;

      return $scope.rooms > $scope.total && vm.showWarning === true;
    }
  });
