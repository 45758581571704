'use strict';

angular
  .module('app')
  .controller('TripPhotosCtrl', function (
    $scope,
    utils,
    $rootScope,
    $location,
    groupTrips,
    $routeParams,
    SeoService,
    Upload,
    gettextCatalog,
    $q
  ) {
    var vm = this;
    vm.productPhotosPage = true;
    vm.tripId = $routeParams.id;

    vm.selectPhoto = selectPhoto;
    vm.fileUpload = fileUpload;
    vm.savePhotos = savePhotos;
    vm.removeItem  = removeItem;

    SeoService.generateMetaTags(gettextCatalog.getString('Trip Photos - PADI Travel for Business'));

    vm.photos = [];
    vm.mainPhotos = [];
    vm.loading = true;

    activate();

    function getPhotos() {
      vm.loading = true;
      groupTrips.getTripPhotos(vm.tripId).then(function (photos) {
        vm.mainPhotos = _.filter(photos, { isMain: true });
        vm.photos = _.filter(photos, { isMain: false });
        vm.loading = false;
      }).catch(function () {
        vm.loading = false;
      });
    }

    function activate() {
      vm.loading = true;
      groupTrips.getTrip(vm.tripId).then(function (data) {
        vm.tripData = _.clone(data);
        vm.loading = false;
        getPhotos();
      });
    }

    function selectPhoto(image, isMain) {
      vm.loading = true;
      // var requests = [];
      // if (_.isArray(images)) {
      //   _.each(images, function (img) {
      //     requests.push(groupTrips.copyPhotos(vm.tripId, img, isMain));
      //   });
      // } else {
      //   requests = [groupTrips.copyPhotos(vm.tripId, images, isMain)];
      // }

      if (isMain && vm.mainPhotos.length === 1) {
        // main photo replace;
        utils.warningPopup('../templates/_replace-photo.html').then(function () {
          // if yes delete prev photo
          groupTrips.deletePhoto(vm.mainPhotos[0].id, vm.tripId).then(function () {
            addPhotoToTrip();
          });
        }).catch(function () {
          vm.loading = false;
          return false;
        });
      } else {
        // regular flow;
        addPhotoToTrip();
      }

      function addPhotoToTrip() {
        groupTrips.copyPhotos(vm.tripId, image, isMain).then(function () {
          getPhotos();
        }).catch(function () {
          vm.loading = false;
        });
      }
    }

    function fileUpload(files, maxLength, isMain) {
      var imagesRead = isMain ? vm.mainPhotos : vm.photos;

      function uploadImage(file) {
        var uploadData = {
          picture: file,
          is_main: file.isMain,
        };
        groupTrips.uploadPhotos(uploadData, vm.tripId).then(function () {
          getPhotos();

          vm.loading = false;
        }, function () {
          vm.loading = false;
        });
      }

      if (files) {
        vm.loading = true;
        vm.errorPhoto = [];
        vm.errorFormat = [];

        _.each(files, function (file) {
          file.isMain = isMain;
          if (!utils.acceptedFileFormat(file.type)) {
            vm.loading = false;
            return $rootScope.showNotify(gettextCatalog.getString('unaccepted file format:') + file.type);
          }
          return Upload.imageDimensions(file).then(function (d) {
            if (d.width < 600 || d.height < 400) {
              vm.loading = false;
              return $rootScope.showNotify(file.name + gettextCatalog.getString(':image must me at least 600px wide and 400px tall'));
            } else if (imagesRead.length < maxLength) {
              uploadImage(file);
            } else if (imagesRead.length === 1 && +maxLength === 1) {
              // replace photo;
              utils.warningPopup('../templates/_replace-photo.html').then(function () {
                // if yes delete prev photo
                groupTrips.deletePhoto(vm.mainPhotos[0].id, vm.tripId).then(function () {
                  uploadImage(file);
                });
              }).catch(function () {
                vm.loading = false;
                return false;
              });
            } else if (imagesRead.length >= maxLength) {
              $rootScope.showNotify(gettextCatalog.getString('You already uploaded maximum number of images.'));
              vm.loading = false;
            }
          }, function () {
            vm.loading = false;
          });
        });
      }
    }

    function savePhotos(redirect) {
      $scope.extranetForm.$setSubmitted();

      if ($scope.extranetForm.$valid) {
        vm.loading = true;

        groupTrips.updateTrip(vm.tripData, vm.tripId).then(function () {
          vm.loading = false;
          $rootScope.showNotify(gettextCatalog.getString('The product was successfully changed'));
          activate();
        });

        if (redirect) {
          $location.path(utils.createURL('group_trips_complete', {id: vm.tripId }));
        } else {
          getPhotos();
        }
      } else {
        $scope.extranetForm.$submitted = false;
      }
    }

    function removeItem(imageId) {
      groupTrips.deletePhoto(imageId, vm.tripId).then(function () {
        getPhotos();
      });
    }

    $scope.$on('$locationChangeStart', function (event) {
      if (($scope.extranetForm.$invalid && $scope.extranetForm.$dirty) || (!$scope.extranetForm.$submitted && $scope.extranetForm.$dirty )) {
        event.preventDefault();
        utils.warningPopup('../templates/_copyright-popup.html').then(function () {
          return;
        }).catch(function () {
          return false;
        });
      }
    });
  });
