'use strict';

angular.module('app')
  .service('inclusionsService',
    function (utils, Request, $q, info, $uibModal, gettextCatalog, roomService) {
      var inclusions = {};

      Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
        return this;
      };

      inclusions.getIsPackageDynamic = function () {
        return roomService.getInfo().then(function (res) {
          return res.resortInfo.isDynamicPackages;
        });
      };

      inclusions.getList = function () {
        return $q.all(
          [Request.get('shop/' + utils.getCurrentShopSlug() + '/liveaboard-extras/general/'),
            Request.get('shop/' + utils.getCurrentShopSlug() + '/liveaboard-extras/'),
            Request.get('shop/' + utils.getCurrentShopSlug() + '/inclusions/rental/')
          ]
        ).then(function (res) {
          var list = [],
            allInclusions = res[1].data,
            general = res[0].data,
            rentalEquipment = res[2].data;

          _.each(_.sortBy(info.inclusionGroups, 'order'), function (group) {
            var data = [],
              lastFullBoardSubItem = {};

            _.each(allInclusions, function (inclusion) {
              if (inclusion.belongsTo === group.id) {
                data.push(inclusion);
              }
            });

            if (data.length) {
              _.each(data, function (inclusion, index) {
                // exeption for rental equipment not in "full set";
                if (inclusion.includedIn && !inclusions.isBelongsTo(inclusion.belongsTo, gettextCatalog.getString('Rental equipment'))) {
                  inclusion.isSubItem = true;

                  // finding last full board sub-item index;
                  if (inclusions.isBelongsTo(inclusion.belongsTo, gettextCatalog.getString('Information'))) {
                    if (!data[index + 1] || !data[index + 1].includedIn) {
                      lastFullBoardSubItem.index = index + 1;
                      lastFullBoardSubItem.parentId = inclusion.includedIn;
                    }
                  }
                }
              });

              if (lastFullBoardSubItem.index) {
                data.insert(lastFullBoardSubItem.index, {
                  title: null,
                  belongsTo: group.id,
                  kind: 'new',
                  addField: true,
                  isSubItem: true,
                  includedIn: lastFullBoardSubItem.parentId,
                });
              }

              var clonedData = _.cloneDeep(data),
                scubaSetPosition = _.findIndex(clonedData, { title: gettextCatalog.getString('Full scuba set') });

              if (scubaSetPosition !== -1) {
                _.each(rentalEquipment, function (rentalItem) {
                  scubaSetPosition++;

                  rentalItem.isSubItem = true;
                  rentalItem.checkboxField = true;
                  clonedData.insert(scubaSetPosition, rentalItem);
                });
              }

              clonedData.push({
                title: null,
                belongsTo: group.id,
                kind: 'new',
                addField: true,
              });

              list.push({
                title: group.title,
                data: clonedData,
              });
            }
          });
          return {
            general: general,
            list: list,
          };
        });
      };

      inclusions.getListDC = function () {
        return $q.all([Request.get('shop/' + utils.getCurrentShopSlug() + '/dive-center-extras/'),
          Request.get('shop/' + utils.getCurrentShopSlug() + '/inclusions/rental/')
        ]).then(function (res) {
          var list = [],
            allInclusions = res[0].data,
            rentalEquipment = res[1].data;

          _.each(_.sortBy(info.inclusionGroups, 'order'), function (val) {
            var data = [];
            _.each(allInclusions, function (inclusion) {
              if (inclusion.belongsTo === val.id) {
                data.push(inclusion);
              }
            });

            if (data.length) {
              var fullBoard = _.remove(data, { title: gettextCatalog.getString('Full board, including') }),
                firstOfFullBoardGroup = _.findIndex(data, { secondLevelKind: 10 }),
                arrayShift = 0;

              if (firstOfFullBoardGroup >= 0 && fullBoard.length) {
                data.insert(firstOfFullBoardGroup, fullBoard[0]);
              }

              var clonedData = _.cloneDeep(data);

              _.each(data, function (val, index) {
                if (val.isSecondLevel && val.secondLevelKind !== 10) {
                  if ((data[index - 1] && (!data[index - 1].secondLevelKind || data[index - 1].secondLevelKind !== val.secondLevelKind)) || !data[index - 1]) {
                    var title = utils.getOptions(val.secondLevelKind, 'LIVEABOARD_EXTRA_SECOND_LEVEL');

                    clonedData.insert(index + arrayShift, {
                      title: title,
                      status: general.rentalEquipmentExtras,
                      belongsTo: val.belongsTo,
                      subkind: 'general',
                    });

                    arrayShift++;
                  }
                }
              });

              var scubaSetPosition = _.findIndex(clonedData, { title: gettextCatalog.getString('Full scuba set') });

              if (scubaSetPosition !== -1) {
                _.each(rentalEquipment, function (val) {
                  scubaSetPosition++;

                  val.isSecondLevel = true;
                  val.checkboxField = true;
                  clonedData.insert(scubaSetPosition, val);
                });
              }

              clonedData.push({
                title: null,
                belongsTo: val.id,
                kind: 'new',
                addField: true,
              });

              list.push({
                title: val.title,
                data: clonedData,
              });
            }
          });
          return {
            rentalEquipment: rentalEquipment,
            list: list,
          };
          // var allInclusions = res[0].data,
          // // firstGroup = _.filter(allInclusions, { section: 10 }),
          // firstGroup = _.filter(allInclusions, {
          //     section: 10
          // }),
          // secondGroup = _.filter(allInclusions, {
          //     section: 40
          // }),
          // rentalEquipment = res[1].data,
          // scubaSetPosition = 1;

          // firstGroup.push({
          //     title: null,
          //     section: 10,
          //     kind: "new",
          //     addField: true
          // });
          // secondGroup.push({
          //     title: null,
          //     section: 40,
          //     kind: "new",
          //     addField: true
          // });

          // _.each(rentalEquipment, function(val) {
          //         val.isSecondLevel = true;
          //         val.checkboxField = true;
          //         firstGroup.insert(scubaSetPosition,val);

          //         scubaSetPosition++
          //     });
          // return {
          //     rentalEquipment: rentalEquipment,
          //     list: [
          //         {
          //             title: 'Rental equipment',
          //             data: firstGroup
          //         },
          //         {
          //             title: 'Extras & Surcharge',
          //             data: secondGroup
          //         }
          //     ]
          // }
        });
      };

      inclusions.getListResort = function () {
        return $q.all([
          Request.get('shop/' + utils.getCurrentShopSlug() + '/resort-extras/'),
          /* Request.get('shop/' + utils.getCurrentShopSlug() + '/inclusions/rental/')*/
        ]).then(function (res) {
          var list = [],
            allInclusions = res[0].data;

          _.each(_.sortBy(info.inclusionGroups, 'order'), function (val) {
            var data = [];
            _.each(allInclusions, function (inclusion) {
              if (inclusion.belongsTo === val.id) {
                data.push(inclusion);
              }
            });

            if (data.length) {
              var fullBoard = _.remove(data, { title: gettextCatalog.getString('Full board, including') }),
                firstOfFullBoardGroup = _.findIndex(data, { secondLevelKind: 10 }),
                arrayShift = 0;

              if (firstOfFullBoardGroup >= 0 && fullBoard.length) {
                data.insert(firstOfFullBoardGroup, fullBoard[0]);
              }

              var clonedData = _.cloneDeep(data);

              _.each(data, function (val, index) {
                if (val.isSecondLevel && val.secondLevelKind !== 10) {
                  if ((data[index - 1] && (!data[index - 1].secondLevelKind || data[index - 1].secondLevelKind !== val.secondLevelKind)) || !data[index - 1]) {
                    var title = utils.getOptions(val.secondLevelKind, 'LIVEABOARD_EXTRA_SECOND_LEVEL');

                    clonedData.insert(index + arrayShift, {
                      title: title,
                      status: general.rentalEquipmentExtras,
                      belongsTo: val.belongsTo,
                      subkind: 'general',
                    });

                    arrayShift++;
                  }
                }
              });

              clonedData.push({
                title: null,
                belongsTo: val.id,
                kind: 'new',
                addField: true,
              });

              list.push({
                title: val.title,
                data: clonedData,
              });
            }
          });

          return { list: list };
          // var allInclusions = res[0].data,
          //     // firstGroup = _.filter(allInclusions, { section: 10 }),
          //     secondGroup = _.filter(allInclusions, {
          //         section: 20
          //     }),
          //     thirdGroup = _.filter(allInclusions, {
          //         section: 30
          //     });
          // //     rentalEquipment = res[1].data,
          // //     scubaSetPosition = 1;


          // // _.each(rentalEquipment, function(val) {
          // //     val.isSecondLevel = true;
          // //     val.checkboxField = true;
          // //     firstGroup.insert(scubaSetPosition,val);

          // //     scubaSetPosition++
          // // });

          // // firstGroup.push({title: null, section: 10,kind: "new", addField: true});

          // // secondGroup
          // //     .insert(3,{title: 'Rental equipment', status: general.rentalEquipmentExtras, kind:'rentalEquipmentExtras',subkind: 'general'})
          // //     .insert(20,{title: 'Courses', status: general.coursesExtras,  kind:'coursesExtras',subkind: 'general'})
          // //     .insert(33,{title: 'Private transfers (to/from)', status: general.transferExtras,  kind:'transferExtras',subkind: 'general'})

          // secondGroup.push({
          //     title: null,
          //     section: 20,
          //     kind: "new",
          //     addField: true
          // });
          // thirdGroup.push({
          //     title: null,
          //     section: 30,
          //     kind: "new",
          //     addField: true
          // });
          // return {
          //     // rentalEquipment: rentalEquipment,
          //     list: [
          //         // {title: 'Rental equipment',data: firstGroup},
          //         {
          //             title: 'Transfers',
          //             data: secondGroup
          //         },
          //         {
          //             title: 'Extras & Surcharges',
          //             data: thirdGroup
          //         }
          //     ]
          // }
        });
      };

      inclusions.getRatesList = function (data) {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/resort-extras/' + data.pk + '/rates/').then(function (res) {
          return res.data;
        });
      };

      inclusions.updateGeneral = function (data) {
        return Request.put('shop/' + utils.getCurrentShopSlug() + '/liveaboard-extras/general/', data);
      };

      inclusions.addNew = function (item) {
        return Request.post('shop/' + utils.getCurrentShopSlug() + '/liveaboard-extras/', item).then(function (res) {
          return res.data;
        });
      };

      inclusions.addNewResortItem = function (item) {
        return Request.post('shop/' + utils.getCurrentShopSlug() + '/resort-extras/', item).then(function (res) {
          return res.data;
        });
      };

      inclusions.addNewDCItem = function (item) {
        return Request.post('shop/' + utils.getCurrentShopSlug() + '/dive-center-extras/', item).then(function (res) {
          return res.data;
        });
      };

      inclusions.update = function (data) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/liveaboard-extras/' + data.pk + '/', data).then(function (res) {
          return res.data;
        });
      };

      inclusions.updateResort = function (data) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/resort-extras/' + data.pk + '/', data).then(function (res) {
          return res.data;
        });
      };

      inclusions.del = function (data) {
        return Request.del('shop/' + utils.getCurrentShopSlug() + '/liveaboard-extras/' + data.pk + '/', data).then(function (res) {
          return res.data;
        });
      };

      inclusions.delResort = function (data) {
        return Request.del('shop/' + utils.getCurrentShopSlug() + '/resort-extras/' + data.pk + '/', data).then(function (res) {
          return res.data;
        });
      };
      inclusions.delDC = function (data) {
        return Request.del('shop/' + utils.getCurrentShopSlug() + '/resort-extras/' + data.pk + '/', data).then(function (res) {
          return res.data;
        });
      };

      inclusions.getGoodToKnow = function () {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/liveaboard/').then(function (res) {
          return res.data;
        });
      };

      inclusions.setGoodToKnow = function (data) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/liveaboard/', data).then(function (res) {
          return res.data;
        });
      };

      inclusions.itinerariesList = function (data) {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/liveaboard-extras/' + data.pk + '/itineraries/').then(function (res) {
          return res.data;
        });
      };


      inclusions.updateItinerary = function (data) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/liveaboard-extras/' + data.pk + '/itineraries/' + data.id + '/', data).then(function (res) {
          return res.data;
        });
      };

      inclusions.updateRate = function (data) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/resort-extras/' + data.pk + '/rates/' + data.id + '/', data).then(function (res) {
          return res.data;
        });
      };

      inclusions.updateRentalEquipment = function (data) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/inclusions/rental/' + data.pk + '/', data).then(function (res) {
          return res.data;
        });
      };

      inclusions.preview = function (url) {
        var link = url || 'shop/' + utils.getCurrentShopSlug() + '/liveaboard-extras/preview/';

        return Request.get(link, !url ? {
          page_size: 3,
        } : null, url).then(function (res) {
          return res.data;
        });
      };

      inclusions.paybleOptions = [{
        title: gettextCatalog.getString('Onboard'),
        val: false,
      },
      {
        title: gettextCatalog.getString('In Advance'),
        val: true,
      }
      ];

      inclusions.paybleOptionsResort = [{
        title: gettextCatalog.getString('On premise'),
        val: false,
      },
      {
        title: gettextCatalog.getString('In Advance'),
        val: true,
      }
      ];

      inclusions.validForOptionsResort = [{
        title: gettextCatalog.getString('All rates'),
        val: true,
      },
      {
        title: gettextCatalog.getString('Specific rates'),
        val: false,
      }
      ];

      inclusions.validForOptions = [{
        title: gettextCatalog.getString('All trips'),
        val: true,
      },
      {
        title: gettextCatalog.getString('Selected itineraries'),
        val: false,
      }
      ];

      inclusions.setValidFromTo = function (item, updateFunction, callback) {
        var modal = $uibModal.open({
          animation: true,
          templateUrl: '../templates/_valid-from-to.html',
          openedClass: 'itinerary-modal modal-open',
          resolve: {
            item: _.clone(item),
          },
          controller: function ($scope, item, $uibModalInstance) {
            var _this = this;

            _this.item = item;

            _this.setMinDate = function (date) {
              if (date) {
                return moment(date).add('days', -1).format('YYYY-MM-DD');
              }
            };

            _this.close = function () {
              $uibModalInstance.dismiss('close');
            };

            _this.save = function () {
              _this.loading = true;

              updateFunction(_this.item).then(function () {
                _this.loading = false;

                $uibModalInstance.close('saved');

                if (callback && _.isFunction(callback)) {
                  callback();
                }
              });
            };
          },
          controllerAs: '$modal',
        });
      };

      inclusions.isBelongsTo = function (belongsTo, groupIdentifier) {
        var item = _.find(info.inclusionGroups, { id: +belongsTo });

        return item && item.unique_identifier === groupIdentifier ? item : false;
      };

      return inclusions;
    });
