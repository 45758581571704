'use strict';

angular
  .module('app')
  .controller('TripListingCtrl', function (
    $scope,
    $rootScope,
    $location,
    groupTrips,
    $routeParams,
    SeoService,
    diviacLocalStorage,
    info,
    gettextCatalog,
    utils
  ) {
    var vm = this;
    vm.add = {
      flowType: 10,
    };
    vm.tripDescriptionPage = true;
    vm.tripId = $routeParams.id;
    vm.affiliateProfileKind = _.get($rootScope, 'selectedShop.affiliateProfileKind');
    vm.saveDescription = saveDescription;
    vm.listingTypes = _.filter(info.marketplace.LISTING_TYPE_CHOICES, function (item) {
      if (vm.affiliateProfileKind) {
        // tour operator
        if (vm.affiliateProfileKind === 70) {
          return item[0] === 30;
        }
        return item[0] !== 30;
      }
      return item;
    });
    vm.flowTypes = info.marketplace.GROUP_TRIP_FLOW_TYPE_CHOICES;

    SeoService.generateMetaTags(gettextCatalog.getString('Trip Description - PADI Travel for Business'));

    vm.loading = true;

    activate();

    function activate() {
      if (vm.tripId) {
        vm.loading = true;
        groupTrips.getTrip(vm.tripId).then(function (data) {
          vm.add = _.clone(data);
          vm.loading = false;
        });
      } else {
        vm.loading = false;
      }
    }

    function saveDescription() {
      $scope.extranetForm.$setSubmitted();

      if ($scope.extranetForm.$valid) {
        vm.loading = true;

        if (vm.tripId) {
          groupTrips.updateTrip(vm.add, vm.tripId).then(function () {
            vm.loading = false;
            $rootScope.showNotify(gettextCatalog.getString('The product was successfully changed'));
            $location.path(utils.createURL('group_trips_basics', {id: vm.tripId }));
          }).catch(function () {
            vm.loading = false;
          });
        } else {
          localStorage.setItem('listingType', JSON.stringify(vm.add));
          $location.path(utils.createURL('group_trips_add'));
        }
      }

      return false;
    }
  });
