'use strict';

angular.module('app').directive('tripadvisor', function () {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, element, attr, ctrl) {
          ctrl.$parsers.unshift(function(val) {
            if(val && val.length) {
              if ((val.indexOf('www.tripadvisor.') != -1 && /d\d+/.test(val)) || parseInt(val)) {
                ctrl.$setValidity('tripadvisor', true);
                return val;
              } else {
                ctrl.$setValidity('tripadvisor', false);
                return undefined;
              }
            } else {
              ctrl.$setValidity('tripadvisor', true);
              return '';
            }
          });

          //For model -> DOM validation
        ctrl.$formatters.unshift(function(val) {
          if(val && val.length) {
              if ((val.indexOf('www.tripadvisor.') != -1 && /d\d+/.test(val)) || parseInt(val)) {
                ctrl.$setValidity('tripadvisor', true);
                return val;
              } else {
                ctrl.$setValidity('tripadvisor', false);
                return undefined;
              }
            } else {
              ctrl.$setValidity('tripadvisor', true);
              return '';
            }
        });
      }
    };
});
