'use strict';

angular.module('app')
  .controller('CabinsListCtrl', function ($scope, $rootScope, gettextCatalog,  $uibModal, shopService, $location, roomService, cabinService, info, utils) {
    var vm = this,
      formSubmited = false;

    vm.changeCurrency = changeCurrency;
    vm.saveCabin = saveCabin;
    vm.deleteRoom = deleteRoom;
    vm.deletePackage = deletePackage;
    vm.updateActive = updateActive;
    vm.move = move;
    vm.showWarning = true;

    init();

    // $scope.$on('$locationChangeStart', function (event) {
    //   if (!formSubmited) {
    //     if ($scope.extranetForm.$dirty && $scope.extranetForm.$invalid) {
    //       event.preventDefault();
    //       utils.warningPopup().then(function () {
    //         $scope.extranetForm.$setPristine();
    //         $location.path($rootScope.currentUrl);
    //       }).catch(function () {
    //         $rootScope.currentUrl = '/cabins/';
    //       });
    //     }

    //     if ($scope.extranetForm.$dirty && $scope.extranetForm.$valid) {
    //       roomService.setInfo(vm.params).then(function () {
    //         var product = _.find(vm.roomsList, {isActive: true});

    //         $rootScope.checkedList.rooms = product && vm.params.totalNumberOfRooms ? true : false;

    //         $rootScope.showNotify('Information was successfully saved', 2000);
    //       });
    //     }
    //   }
    // });

    function init() {
      vm.currencyList = info.shop.CURRENCY_CHOICES;
      vm.loader = true;
      vm.loaderDP = true;
      vm.roomsNumber = 0;

      shopService.getShopCurrency().then(function (data) {
        vm.currency = data.currency;
      });

      roomService.getSleepList().then(function (data) {
        vm.sleepList = data;
        vm.loaderDP = false;
      });

      roomService.getInfo().then(function (data) {
        getRoomData(data);
      });

      roomService.getList().then(function (data) {
        vm.roomsList = data;

        _.each(data, function (val) {
          vm.roomsNumber += val.roomNumber;
        });
        vm.loader = false;
      });
    }

    function deleteRoom(prod) {
      utils.confirmationPopup().then(function () {
        cabinService.deleteCabin(prod.roomId).then(function () {
          vm.roomsList = _.reject(vm.roomsList, function (val) {
            return val.roomId === prod.roomId;
          });
          vm.roomsNumber = 0;

          _.each(vm.roomsList, function (val) {
            vm.roomsNumber += val.roomNumber;
          });

          var active = _.find(vm.roomsList, {isActive: true});

          $rootScope.checkedList.rooms = active && vm.params.totalNumberOfRooms ? true : false;

          $rootScope.showNotify(gettextCatalog.getString('The selected product was successfully deleted'));
        }, function (err) {
          if (err.data.nonFieldErrors) {
            $rootScope.showNotify(err.data.nonFieldErrors);
          }
        });
      });
    }

    function deletePackage(pack) {
      roomService.deletePackage(pack.id).then(function () {
        vm.sleepList = _.reject(vm.sleepList, function (val) {
          return val.id === pack.id;
        });


        $rootScope.showNotify(gettextCatalog.getString('The selected sleep & dive package was successfully deleted'));
      }, function (err) {
        if (err.data.nonFieldErrors) {
          $rootScope.showNotify(err.data.nonFieldErrors);
        }
      });
    }

    function saveCabin(form, noRedirect) {
      form.$submitted = true;

      if (form.$valid) {
        vm.loading = true;

        if (checkTotalNumbers() && !noRedirect) {
          utils.warningPopup('../templates/_number-of-rooms-popup.html', $scope).then(function () {
            vm.showWarning = false;
            saveCabin(form, noRedirect);
          }).catch(function () {
            vm.loading = false;
          });

          return true;
        }

        roomService.setInfo(vm.params).then(function (data) {
          formSubmited = true;


          vm.totalRooms = _.clone(+vm.params.totalNumberOfRooms);

          $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved'), 5000);

          var active = _.find(vm.roomsList, {isActive: true});

          $rootScope.checkedList.rooms = active && vm.params.totalNumberOfRooms ? true : false;

          getRoomData(data);

          if (!noRedirect) {
            $location.path(utils.createURL('itinerary'));
          } else {
            roomService.getSleepList().then(function (data) {
              vm.sleepList = data;
            });
            roomService.getList().then(function (data) {
              vm.roomsList = data;
              vm.loading = false;

              _.each(data, function (val) {
                vm.roomsNumber += val.roomNumber;
              });
            });
          }
        }).catch(function (error) {
          if (error.data.totalNumberOfRooms) {
            form.totalNumberOfRooms.$invalid = true;
          }

          vm.loading = false;
        });
      }
    }

    function changeCurrency() {
      vm.loading = true;
      shopService.setShopCurrency({currency: vm.currency}).then(function () {
        $rootScope.showNotify(gettextCatalog.getString('Currency was successfully saved'));

        vm.loading = false;
      });
    }

    function updateActive(prod, type) {
      vm.loading = true;

      if (!type) {
        type = 'saveCabin';
      }

      cabinService[type]({isActive: prod.isActive}, prod.roomId || prod.id).catch(function () {
        prod.isActive = !prod.isActive;
        vm.loading = false;
      }).then(function (res) {
        prod.isActive = res.data.isActive;
        vm.loading = false;

        var active = _.find(vm.roomsList, {isActive: true});

        $rootScope.checkedList.rooms = active && vm.params.totalNumberOfRooms ? true : false;
      });
    }

    function move(roomId) {
      let url = utils.createURL('cabins_edit', {id: roomId});
      $location.path(url);
    }


    function checkTotalNumbers() {
      $scope.rooms = +vm.roomsNumber;
      $scope.total = vm.params ? +vm.params.totalNumberOfRooms : 0;

      return $scope.rooms != $scope.total && vm.showWarning === true;
    }

    function getRoomData(data) {
      vm.params = data;

      vm.totalRooms = _.clone(+vm.params.totalNumberOfRooms);
    }
  });
