'use strict';

angular.module('app')
    .controller('ManageRoomsCtrl', function ($rootScope, $q, $location, roomService, availabilityService) {
        var vm = this;

        vm.loader = true;
        vm.triggerAllRooms = triggerAllRooms;
        vm.save = save;
        vm.now = new Date();

        roomService.getList().then(function (data) {
            vm.roomsList = data;
            vm.loader = false;
        });


        function triggerAllRooms(value) {
            _.each(vm.roomsList, function(val) {
                val.checked = value;
            });
        }

        function save(shouldRedirect) {
            var list = _.filter(vm.roomsList, {'checked': true}),
                requests = [];
                vm.roomError = false;

            if (!list.length) {
                vm.roomError = true;
                return;
            }

            vm.loader = true;

            _.each(list, function(val) {
                val.isBookable = vm.actionToPerform;
                
                requests.push(availabilityService.updateRoom(val.roomId, [val], vm.filter))
            });
            

            $q.all(requests).then(function () {
                vm.loader = false;


                vm.changed = {
                    date_from_after: vm.filter.date_from_after,
                    date_from_before: vm.filter.date_from_before,
                    actionToPerform: vm.actionToPerform ? 'opened' : 'closed',
                    rooms: list
                }

                if (shouldRedirect) {
                    $location.path('/availability-and-pricing/');
                    $location.search({'dateFrom' : vm.filter.date_from_after, 'dateTo': vm.filter.date_from_before});
                }
            });
        }


        
    });