'use strict';

angular.module('app').directive('headerOperatorSelect', function () {
  return {
    restrict: 'A',
    link: function (scope, elem) {
      var options = $(elem).find('.pt-selector .option');

      $(options).on('click', function () {
        if (!$(this).hasClass('active')) {
          options.toggleClass('active');
          $(elem).children('.country-list-wrapper').toggleClass('active');
        }
      });
    },
  };
});
