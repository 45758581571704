'use strict';

angular.module('app')
    .controller('BaseItineraryCtrl', function ($q, info, $uibModalInstance, itineraryService, itinerary, utils) {

        var vm = this;

        vm.itinerary = {
          tags: []
        }

        vm.close = function() {
          $uibModalInstance.dismiss('cancel');
        };

        vm.tags = [];
        vm.tagTransform = utils.tagTransform;
        vm.onChange = function(s) {
          return utils.searchTag(s, function(tag) {
            vm.tags = tag
          })
        }
        
        vm.save = save;
        vm.certificationChoices = info.shop.ITINERARY_CERTIFICATION_CHOICES
        vm.themes  = [];
        vm.sponsors = [];
        vm.loading = true;

        $q.all([itineraryService.getThemes(),itineraryService.getSponsors()])
        .then(function(data) {
          vm.themes  = data[0];
          vm.sponsors  = data[1];
          vm.itinerary = itinerary;
          vm.loading = false;
          console.log(itinerary)
        });

        function save() {
          vm.loading = true;
          itineraryService.saveBase(vm.itinerary).then(function() {
              $uibModalInstance.close('saved');
          });
        }
    });
