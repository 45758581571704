'use strict';

angular.module('app')
    .controller('RouteItineraryCtrl', function ($q, $timeout, info, $uibModalInstance, itineraryService, itinerary) {

        var vm = this;

        vm.close = function() {
          $uibModalInstance.dismiss('cancel');
        };
        vm.save = save;
        if (itinerary.length) {
          vm.daysNumber = new Array(+itinerary.length + 1);
        }
        vm.updateHighlights = updateHighlights;
        vm.routes  = [];
        vm.marineLife = [];
        vm.loading = true;

        $q.all([itineraryService.getRoutes(),itineraryService.getMarineLife()])
        .then(function(data) {
          vm.routes  = data[0];
          vm.marineLife  = data[1];

          vm.itinerary = itinerary;
          vm.loading = false;

          $timeout(function(){
            vm.ready = true;
          }, 500);
        });

        function save() {
          var daysLength = vm.itinerary.days.length;
          vm.loading = true;

          _.each(vm.itinerary.days, function (val) {
              //Hack for Andrew, because he can't do it on his side :D
              if (!val.description) {
                  val.description = '';
              }
          });

          itineraryService.saveRoute(vm.itinerary).then(function() {
              $uibModalInstance.close('saved');
          });
        }

        function updateHighlights (item, isRemoved) {
          if (item.description) {
              vm.itinerary.highlightsDescription = vm.itinerary.highlightsDescription.replace('<p>' + item.description + '</p>','');

              if (!isRemoved) {
                $timeout(function() {
                  vm.itinerary.highlightsDescription += '<p>' + item.description + '</p>';
                });
              }
          }

          if (item.marineLife.length) {
            var marines = [];
            _.each(item.marineLife, function(val) {
              if (_.find(vm.marineLife,{pk:val})) {
                marines.push(val);
              }
            });

            if (isRemoved) {
              _.each(marines, function(val){
                vm.itinerary.marineLife = _.without(vm.itinerary.marineLife, val);
              });
            } else {
              vm.itinerary.marineLife = _.union(vm.itinerary.marineLife,marines);
            }
          }
        }
    });
