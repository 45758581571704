'use strict';

angular.module('app').directive('popup', function($timeout, $compile, $http, $rootScope, $location) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      $(element).on('click', function() {
        var options;
        
        if (attrs.elementId) {
          options = {
            loop: false,
            type: 'image',
            prevEffect: 'none',
            nextEffect: 'none',
            padding: ['20', '50', '80', '50'],
            wrapCSS: 'photo-gallery',
            fitToView: window.innerWidth > 767,
            width: '100%',
            hideOnOverlayClick: false,
            beforeShow: function() {
              swipeEvent();
              $(".fancybox-image").on("click", function() {
                jQuery.fancybox.next();
              });
            },
            afterClose: function() {
              swipeEvent(true);
            },
            helpers: {
              title: {
                type: 'inside'
              },
              thumbs: {
                width: 60,
                height: 60,
                position: 'bottom'
              },
              overlay: {
                locked: true,
                closeClick: false,
                css: {
                  background: 'rgba(0,0,0,0.9)'
                }
              }
            }
          };

          $('.' + attrs.openClass).fancybox(options);

          var elem = $('.' + attrs.openClass + '[data-id=' + attrs.elementId + ']');

          elem.trigger('click');
        } else {
          var thumbs = $(document).find('.' + attrs.openClass);

          options = {
            loop: false,
            type: 'image',
            prevEffect: 'none',
            nextEffect: 'none',
            padding: ['20', '50', '80', '50'],
            wrapCSS: 'photo-gallery',
            hideOnOverlayClick: false,
            fitToView: window.innerWidth > 767,
            width: '100%',
            beforeShow: function() {
              swipeEvent();
              $(".fancybox-image").on("click", function() {
                jQuery.fancybox.next();
              });
              $('.fancybox-inner').css('cursor', 'pointer');
            },
            afterClose: function() {
              swipeEvent(true);

              if (attrs.afterClose) {
                $http.get('templates/_itinerary_popup.html').then(function(response) {
                  if (response.status == 200) {
                    var template = $compile(angular.element(response.data))(scope);
                    jQuery.fancybox({
                      content: template,
                      type: 'html',
                      padding: ['80', '0', '40', '80'],
                      wrapCSS: 'form-popup itinerary-popup',
                      maxWidth: '1150px',
                      helpers: {
                        overlay: {
                          locked: true
                        }
                      },
                      beforeShow: function() {
                        if ($rootScope.changeURL) {
                          $rootScope.changeURL();
                        }
                      },
                      afterClose: function() {
                        if ($rootScope.returnPrevURL) {
                          $rootScope.returnPrevURL();
                        }
                      },
                    });
                  }
                });
              }
            },
            helpers: {
              title: {
                type: 'inside'
              },
              thumbs: {
                width: 60,
                height: 60,
                position: 'bottom'
              },
              overlay: {
                locked: true,
                closeClick: false,
                css: {
                  background: 'rgba(0,0,0,0.9)'
                }
              }
            }
          };

          $timeout(function() {
            jQuery.fancybox(thumbs, options);
          }, 300);
        }
      });

      var xDown = null;
      var yDown = null;

      function handleTouchStart(evt) {
        xDown = evt.touches[0].clientX;
        yDown = evt.touches[0].clientY;
      };

      function handleTouchMove(evt) {
        if (!xDown || !yDown) {
          return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) { /*most significant*/
          if (xDiff > 0) {
            /* left swipe */
            jQuery.fancybox.next();
          } else {
            jQuery.fancybox.prev();
            /* right swipe */
          }
        } else {
          if (yDiff > 0) {
            /* up swipe */
          } else {
            /* down swipe */
          }
        }
        /* reset values */
        xDown = null;
        yDown = null;
      }

      function swipeEvent(disable) {
        if (disable) {
          document.removeEventListener('touchstart', handleTouchStart, false);
          document.removeEventListener('touchmove', handleTouchMove, false);
          return;
        }

        document.addEventListener('touchstart', handleTouchStart, false);
        document.addEventListener('touchmove', handleTouchMove, false);

      }
    }
  };
});
