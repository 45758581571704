'use strict';

angular.module('app').directive('stickyTop', function () {
  return {
    scope: {
      trigger: '@',
      offset: '@',
      clickable: '=',
    },
    restrict: '',
    link: function (scope, elem) {
      var $elem = $(elem),
        position = 0,
        debounceTimer;
      if (scope.trigger) {
        position = $(scope.trigger).offset().top + $(scope.trigger).outerHeight();
      }

      if (scope.offset) {
        position += scope.offset;
      }

      if (scope.clickable) {
        $elem.on('click', function () {
          if (scope.trigger) {
            $('html, body').animate({scrollTop: $(scope.trigger).offset().top}, 500);
          } else {
            $('html, body').animate({scrollTop: 0}, 500);
          }
        });
      }

      var throttledScroll = _.throttle(function () {
        if ($(window).scrollTop() > position) {
          $elem.addClass('active');
        } else if ($elem.hasClass('active')) {
          $elem.animate({
            marginTop: -$elem.innerHeight(),
          }, 300, function () {
            $elem.removeClass('active').css('marginTop', '');
          });
        }
      }, 300);
      $(window).on('scroll', throttledScroll);
    },
  };
});
