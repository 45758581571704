'use strict';

angular.module('app')
  .service('diviacLocalStorage', function () {
    return {
      set: function (key, data) {
        return localStorage.setItem(key, JSON.stringify(data));
      },
      get: function (key) {
        var element = localStorage.getItem(key);

        try {
          return JSON.parse(element);
        } catch (e) {
          return localStorage.removeItem(key);
        }
      },
      remove: function (key) {
        localStorage.removeItem(key);

        return true
      }
    }
  });
