'use strict';

angular.module('app').directive('resetValidityOnChange', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModelCtrl) {
            ngModelCtrl.$viewChangeListeners.push(function () {
                ngModelCtrl.$setValidity(attrs['resetValidityOnChange'], true);
            });
        }
    };
})