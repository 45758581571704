'use strict';

angular.module('app')
  .controller('PackageInclusionsCtrl', function (info, $routeParams, packageService, inclusionsService, config) {
    var vm = this;

    vm.updateRow = _.debounce(updateRow, 300);
    vm.getPayableOptions = getPayableOptions;
    vm.addNewInclusion = addNewInclusion;
    vm.setValidFromTo = setValidFromTo;
    vm.packageId = $routeParams.id;
    vm.updateTitle = updateTitle;
    vm.deleteItem = deleteItem;
    vm.updateRentalEquipment = updateRentalEquipment;

    vm.pricePerOptions = info.shop.RESORT_EXTRA_PAYED_PER;
    vm.pricePerTransfers = _.filter(info.shop.RESORT_EXTRA_PAYED_PER, function (val) {
      return val[0] > 50 && val[0] < 60;
    });
    vm.validForOptions = inclusionsService.validForOptionsResort;
    vm.paybleOptions = inclusionsService.paybleOptionsResort;
    vm.featuredOrderChoices = _.range(11).map(function (x, i) {
      if (i === 0 ) x = 'No';
      return [i, x];
    });

    getResortItems();

    function getPayableOptions(belongsTo) {
      // if transfer
      if (inclusionsService.isBelongsTo(belongsTo, 'Transfers')) {
        return vm.pricePerTransfers;
      }

      return vm.pricePerOptions;
    }

    function setValidFromTo(item) {
      item.packageId = $routeParams.id;
      inclusionsService.setValidFromTo(item, packageService.updateInclusion, getResortItems);
    }

    function showTableBody(title) {
      _.each(vm.inclusions, function (item) {
        if (item.title === title) {
          item.isActive = true;
        }
      });
    }

    function addNewInclusion(incl, title) {
      incl.submitted = true;

      if (incl.title) {
        vm.loading = true;
        incl.kind = 360;

        packageService.addNewInclusion($routeParams.id, incl).then(getResortItems).then(function () {
          showTableBody(title);
        });
      }
    }

    function updateRow(form, data) {
      if (data.addField) {
        return true;
      }

      if (data.status == 0 || data.isMandatory || data.payedInAdvance) {
        data.isBookableInAdvance = true;
      }

      if (form.$valid) {
        if (data.subkind) {
          var general = _.filter(vm.inclusions[1].data, {subkind: 'general'}),
            obj = {};

          _.each(general, function (val) {
            obj[val.kind] = val.status;
          });

          data = obj;
          data.kind = 'general';
        }

        if (data.payedInAdvance === false && data.extraValue && data.extraValue.trim()) {
          data.price = !isNaN(+data.extraValue) ? data.extraValue : null;
        }

        if (data.payedInAdvance) {
          // remove extra value (refers only to payable on premise)
          data.extraValue = null;
        }

        data.packageId = $routeParams.id;

        packageService.updateInclusion(data);
      }
    }

    function getResortItems() {
      vm.loading = true;
      return packageService.getInclusionsList($routeParams.id).then(function (result) {
        vm.inclusions = result.list;
        // vm.rentalEquipment = result.rentalEquipment;
        vm.loading = false;
      });
    }

    function updateTitle(data) {
      if (data.title) {
        vm.loading = true;
        inclusionsService.updateResort(data).then(function () {
          data.edit = false;
          vm.loading = false;
        });
      }
    }

    function updateRentalEquipment(incl) {
      incl.packageId = $routeParams.id;
      packageService.updateRentalEquipment(incl);
    }

    function deleteItem(item, title) {
      vm.loading = true;
      inclusionsService.delResort(item).then(getResortItems).then(function () {
        showTableBody(title);
      });
    }
  });
