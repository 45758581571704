'use strict';

angular.module('app').directive('openSelect', function ($timeout) {
	return {
		require: 'uiSelect',
		restrict: 'A',
		link: function($scope, el, attrs, uiSelect) {
		  var closing = false;

		  $(uiSelect.focusser[0]).on('focus', function() {
			if(!closing) {
			  $('.selectize-input').click();
			}
		  });

		  // Because ui-select immediately focuses the focusser after closing
		  // we need to not re-activate after closing
		  $scope.$on('uis:close', function() {
			closing = true;
			$timeout(function() { // I'm so sorry
			  closing = false;
			},150);
		  });
		}
	  };
});