'use strict';

angular.module('app')
    .controller('LogisticsPreviewCtrl', function (itineraries, utils) {

        var vm = this;

        _.each(itineraries, function (val) {
          utils.bindLogistic(val.logistics);
        });

        vm.list = itineraries;
    });
