'use strict';

angular.module('app').directive('createGraphs', function ($window) {
  return {
    restrict: 'A',
    scope: {
      columnTitle: '@', // We can't write xTitle, sad - but it's true (not working with x only)
      lineTitle: '@', 
      columnData: '=',
      lineData: '=',
      rightSufix: '=',
      leftSufix: '=',
      backgroundColor: '@',
      columnColor: '@',
      lineColor: '@',
      hideAxis: '@',
      oneAxis: '@'
    },
    link: function(scope, elem, attr) {
    	scope.$watch('columnData + lineData',function(newVal) {
    		var options = {
		        chart: {
		            zoomType: 'xy',
		            backgroundColor: scope.backgroundColor ? scope.backgroundColor : '#FFFFFF',
				    polar: true,
				    type: 'line'
		        },
		        credits: {
					    enabled: false
					  },
		        title: {
		            text: null
		        },
		        xAxis: [{
		            categories: moment.monthsShort(),
		            crosshair: true
		        }],
		        yAxis: [{
		            labels: {
		                format: '{value}' + scope.leftSufix,
		                style: {
		                    color: scope.lineColor || '#ff5335'
		                }
		            },
		            title: {
		                text: null,
		                style: {
		                    color: scope.lineColor || '#ff5335'
		                }
		            }
		        }],
		        tooltip: {
		            shared: true
		        },
		        legend: {
		        	align: 'left',
		            backgroundColor: scope.backgroundColor ? scope.backgroundColor : '#FFFFFF',
		        },
		        series: [{
		            name:  scope.lineTitle,
		            type: 'spline',
	            	color: scope.lineColor || '#ff5335',
		            data: scope.lineData,
		            tooltip: {
		                valueSuffix: scope.lineSufix
		            },
		            events: {
						legendItemClick: function () {
				    		return false; // <== returning false will cancel the default action
						}
					}
		        }]
	    	};

    		if (scope.oneAxis) {
				options = {
			        chart: {
			            zoomType: 'xy',
			            backgroundColor: scope.backgroundColor ? scope.backgroundColor : '#FFFFFF',
					    polar: true,
					    type: 'line'
			        },
			        title: {
			            text: null
			        },
			        xAxis: [{
			            categories: moment.monthsShort(),
			            crosshair: true
			        }],
			        credits: {
						    enabled: false
						  },
			        yAxis: {
			            labels: {
			                format: '{value}' + scope.leftSufix,
			                style: {
			                    color: scope.lineColor || '#ff5335'
			                }
			            },
			            title: {
			                text: null,
			                style: {
			                    color: scope.lineColor || '#ff5335'
			                }
			            }
			        },
			        tooltip: {
			            shared: true
			        },
			        legend: {
			        	align: 'left',
			            backgroundColor: scope.backgroundColor ? scope.backgroundColor : '#FFFFFF',
			        },
			        series: []
		    	};

		    	if (scope.lineData) {
		    		options.series.push({
			            name:  scope.lineTitle,
			            type: 'spline',
		            	color: scope.lineColor || '#ff5335',
			            data: scope.lineData,
			            tooltip: {
			                valueSuffix: scope.lineSufix
			            },
			            events: {
							legendItemClick: function () {
					    		return false; // <== returning false will cancel the default action
							}
						}
			        });
		    	}
    		}

    		if (scope.columnData) {
    			if (!scope.oneAxis) {
	    			options.yAxis.push({
			            title: {
			                text: null,
			                style: {
			                    color: scope.columnColor || '#53cae2'
			                }
			            },
			            labels: {
			                format: '{value}' + scope.rightSufix,
			                style: {
			                    color: scope.columnColor || '#53cae2'
			                },
			                enabled: !scope.hideAxis
			            },
			            opposite: true
			        });
			        options.series.unshift({
			            name: scope.columnTitle,
			            type: 'column',
	    		
			            yAxis: scope.oneAxis ? null : 1,
			            color: scope.columnColor || '#53cae2',
			            data: scope.columnData,
			            tooltip: {
			                valueSuffix: scope.columnSufix
			            },
			            events: {
							legendItemClick: function () {
					    		return false; // <== returning false will cancel the default action
							}
						}
			        });
    			} else {
    				options.series.unshift({
			            name: scope.columnTitle,
			            type: 'column',
			            color: scope.columnColor || '#53cae2',
			            data: scope.columnData,
			            tooltip: {
			                valueSuffix: scope.columnSufix
			            },
			            events: {
							legendItemClick: function () {
					    		return false; // <== returning false will cancel the default action
							}
						}
			        });
    			}
    			
    		}

    		if (jQuery.fn.highcharts) {
    			$(elem).highcharts(options);
			} else {
    			var interval = setInterval(function () {
					if (jQuery.fn.highcharts) {
						$(elem).highcharts(options);
						clearInterval(interval);
					}
                },50);
			}
    	});
    }
  };
});
