'use strict';

angular.module('app').directive('adminScroll', function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        $(element).on('click', function() {
          $timeout(function() {
            $('.main-wrapper').animate({
              scrollTop: $(attr.scrollTo ? attr.scrollTo :'.payout-info').offset().top + -$('.content').offset().top
            }, 500);
          }, 300);
        });
      }
    };
});
