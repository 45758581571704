'use strict';

angular.module('app').directive('dayOfWeeksSelector', ['info', 'gettextCatalog', function (info, gettextCatalog) {
  var link = function (scope, attrs, element, ngModel) {
    scope.daysOfWeek = [
      ['1', gettextCatalog.getString('Monday')],
      ['2', gettextCatalog.getString('Tuesday')],
      ['3', gettextCatalog.getString('Wednesday')],
      ['4', gettextCatalog.getString('Thursday')],
      ['5', gettextCatalog.getString('Friday')],
      ['6', gettextCatalog.getString('Saturday')],
      ['7', gettextCatalog.getString('Sunday')]
    ];

    if (scope.startFromZero) {
      scope.daysOfWeek = [
        ['0', gettextCatalog.getString('Monday')],
        ['1', gettextCatalog.getString('Tuesday')],
        ['2', gettextCatalog.getString('Wednesday')],
        ['3', gettextCatalog.getString('Thursday')],
        ['4', gettextCatalog.getString('Friday')],
        ['5', gettextCatalog.getString('Saturday')],
        ['6', gettextCatalog.getString('Sunday')]
      ];
    }

    scope.triggerDays = function (day) {
      var selectedDates = ngModel.$viewValue || [];
      if (scope.findDate(day)) {
        selectedDates = _.without(ngModel.$viewValue, day);
      } else {
        selectedDates.push(day);
      }

      selectedDates.sort();

      ngModel.$setViewValue(selectedDates);
      if (typeof scope.callback === 'function') {
        scope.callback();
      }
    };

    scope.findDate = function (day) {
      if (!ngModel.$viewValue) { return false; }
      return ngModel.$viewValue.indexOf(day) > -1;
    };
  };


  return {
    restrict: 'A',
    require: 'ngModel',
    template: '<div class="day-of-week-selector"><div class="day-of-week-selector__day" ng-class="{ \'active\': findDate(day[0]) }" ng-repeat="day in daysOfWeek"  ng-click="triggerDays(day[0])">{{ day[1] | limitTo:3 }}</div></div>',
    scope: {
      callback: '&',
      ngModel: '=',
      startFromZero: '=',
    },
    link: link,
  };
}
]);
