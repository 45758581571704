'use strict';

angular
  .module('app')
  .service('product', function (
    shopService,
    AuthExtranet,
    $window,
    $rootScope,
    $location,
    $uibModal,
    Request,
    Upload,
    utils,
    GTM
  ) {
    var product = {},
      activityMandatoryFields = {
        basicTabComplete: [
          'title',
          'duration.duration',
          'duration.durationType.value',
          'staffLanguages.length',
          'groupSize.maxSize',
          'groupSize.ratio',
          'requirements.minAge',
          'requirements.minCertification.value',
          'requirements.minDivesCount.value',
        ],
        descriptionTabComplete: [
          'shortDescription',
          'highlights.length',
          'whatToExpect',
        ],
        pricingTabComplete: [
          'padiPrice',
          'localPrice',
          'cancellationPolicy.value',
        ],
        logisticsTabComplete: ['logisticsType.value'],
        inclusionsTabComplete: ['inclusionsSaved'],
      };

    product.redirectNewExtranet = function () {
      $window.location.href = utils.generateNewExtranetUrl(
        'activities',
        utils.getCurrentShopId()
      );
    };

    product.getInfo = function () {
      return Request.get('info/').then(function (res) {
        return res.data;
      });
    };

    product.updateProduct = function (id, data) {
      return Request.patch('booking/products/' + id + '/', data).then(function (
        res
      ) {
        return res.data;
      });
    };

    product.getProduct = function (id) {
      return Request.get(`booking/activities/${id}/`).then(({ data }) => {
        data.finishedTabs = product.checkActivityTabs(data);
        if (data.shopId !== utils.getCurrentShopId()) {
          return shopService.getShopData(data.shopId).then((shop) => {
            AuthExtranet.setChoosenShop(shop, true);
            window.location.reload();
          });
        }
        data.diveSiteType.value = 20;
        return data;
      });
    };

    product.savePrices = function (data) {
      return Request.put(
        'booking/' + utils.getCurrentShopSlug() + '/base-products/',
        data
      ).then(function (res) {
        res.data.prices = _.filter(res.data.prices, function (val) {
          return val.numberOfInstances <= 21;
        });

        return res.data;
      });
    };

    product.addBaseProduct = function (data) {
      var baseProduct = _.find(_.map(_.clone(data)), function (val) {
          return val.standardName;
        }),
        slug = utils.getCurrentShopSlug();

      baseProduct.shop = slug;

      return Request.put('booking/base-products/add/', baseProduct).then(
        function (res) {
          return res.data;
        }
      );
    };

    product.getBaseProducts = function () {
      return Request.get(
        'booking/' + utils.getCurrentShopSlug() + '/base-products/'
      ).then(function (res) {
        res.data.prices = _.filter(res.data.prices, function (val) {
          return val.numberOfInstances <= 22;
        });

        return res.data;
      });
    };

    product.getProducts = function () {
      return Request.get(
        'booking/' + utils.getCurrentShopSlug() + '/products/'
      ).then(function (res) {
        var data = res.data;

        _.each(data, function (val) {
          val.title = val.title.toLowerCase();
          if (val.isBase === true) {
            val.kindSort = 1;
          } else if (val.kind === 30) {
            val.kindSort = 2;
          } else if (val.kind === 40) {
            val.kindSort = 3;
          } else if (val.kind === 50) {
            val.kindSort = 4;
          } else {
            val.kindSort = 5;
          }

          if (val.shownOn) {
            val.shownOnVacation = val.shownOn.indexOf(10) === -1 ? false : true;
            val.shownOnAdventures =
              val.shownOn.indexOf(20) === -1 ? false : true;
            val.shownOnLocator = val.shownOn.indexOf(30) === -1 ? false : true;
          }
        });
        return data;
      });
    };

    product.delProduct = function (id) {
      return Request.del('booking/products/' + id + '/').then(function (data) {
        return data;
      });
    };

    product.getNames = function (kind) {
      return Request.get(
        kind
          ? 'booking/standard-names/?kind=' + kind
          : 'booking/standard-names/'
      ).then(function (res) {
        return res.data;
      });
    };

    product.getCourses = function () {
      return Request.get('booking/dive-course-info/').then(function (res) {
        return res.data;
      });
    };

    product.getCurrencies = function () {
      return Request.get('booking/currency/').then(function (res) {
        return res.data;
      });
    };

    product.getMandatoryFields = function (data) {
      if (
        data.kind &&
        data.title &&
        _.isNumber(data.price) &&
        data.price > 0 &&
        data.description &&
        data.standardName
      ) {
        return false;
      }

      var notFinished = [];

      if (!data.kind) {
        notFinished.push('Product type');
      }
      if (!data.title) {
        notFinished.push('Product title');
      }
      if (!_.isNumber(data.price) || +data.price === 0) {
        notFinished.push('Product price');
      }
      if (!data.description) {
        notFinished.push('Product description');
      }
      if (!data.standardName) {
        notFinished.push('Standard product name');
      }
      if (_.isEmpty(data.photos)) {
        notFinished.push('Product image');
      }

      return notFinished;
    };

    product.prefill = function (data, prefilledData, form) {
      var fields = [
        'duration',
        'requirements',
        'numberOfDives',
        'otherRequirements',
      ];

      if (prefilledData.activity) {
        _.each(fields, function (val) {
          if (!data[val] || (form[val] && form[val].$pristine)) {
            if (val === 'otherRequirements') {
              data.requirements.other =
                prefilledData.activity.otherRequirements;
            } else if (val !== 'duration') {
              data[val] = prefilledData.activity[val];
            } else if (
              val === 'duration' &&
              _.get(prefilledData.activity, 'duration.duration') &&
              _.get(prefilledData.activity, 'duration.durationType.value')
            ) {
              data.duration = prefilledData.activity.duration;
            }
          }
        });
      }
      if (!data.title || (form.title && form.title.$pristine)) {
        if (
          prefilledData.title !== 'Other' &&
          _.get(data, 'productType.value') !== 50
        ) {
          data.title = prefilledData.title;
        }
      }

      return data;
    };

    product.checkStatus = function (productList, activeProducts) {
      if (productList.length && activeProducts) {
        $rootScope.checkedList.products = true;
        // $location.path('/admin/');

        GTM.event('business_listing_product_complete');
      } else if (!productList.length) {
        // $location.path('/admin/');
      } else if (productList.length && !activeProducts) {
        product.showWarning();
      }
    };

    product.showWarning = function () {
      var modalInstance = $uibModal.open({
        animation: false,
        templateUrl: '../templates/_product-warning-popup.html',
        controller: 'ModalClaimCtrl',
        controllerAs: 'claimModal',
        windowClass: 'contact-us',
      });

      return modalInstance.result.then(
        function () {
          $location.path(utils.createURL('admin'));
        },
        function (err) {
          return err;
        }
      );
    };

    product.addNew = function (item) {
      var header = {
          'Content-Type': 'application/json',
        },
        token = localStorage.getItem('token');

      if (token) {
        header.Authorization = 'Bearer ' + token;
      }

      var data = _.clone(item);
      data.shopId = utils.getCurrentShopId();

      // if (data.agency) {
      //   data.parameters = [data.agency];
      // }

      if (data.standardName === 'other') {
        data.standardName = null;
      }

      return Request.post('booking/activities/', data);
    };

    product.deletePhoto = function (id) {
      return Request.del('booking/products/photo/' + id + '/').then(function (
        data
      ) {
        return data;
      });
    };

    product.setPhoto = function (data) {
      return Request.put('booking/products/photos/ordering/', data).then(
        function (res) {
          return res;
        }
      );
    };

    product.uploadPhotos = function (photo) {
      var header = {
          'Content-Type': 'application/json',
        },
        token = localStorage.getItem('token');

      if (token) {
        header.Authorization = 'Bearer ' + token;
      }

      return Upload.upload({
        url: utils.apiURL + 'booking/products/photo/',
        headers: header,
        arrayKey: '',
        method: 'POST',
        data: {
          picture: photo,
        },
      }).then(
        function (res) {
          // SUCCESS EVENT
          return res.data;
        },
        function (err) {
          // ERROR EVENT
          console.warn('ERROR: ', err);
          throw err;
        },
        function (evt) {
          // PROGRESS EVENT
          var progressPercentage = parseInt(
            (100.0 * evt.loaded) / evt.total,
            10
          );
          return progressPercentage;
        }
      );
    };

    product.getDiveSites = function () {
      return Request.get(
        'shop/' + utils.getCurrentShopSlug() + '/dive-sites-to-go/'
      ).then(function (res) {
        return res.data;
      });
    };

    product.getActivityPhotos = function (productId) {
      return Request.get(
        'shops/' +
          utils.getCurrentShopSlug() +
          '/products/' +
          productId +
          '/media/'
      ).then(function (res) {
        return res.data;
      });
    };

    product.saveActivityPhotos = function (productId, photos) {
      return Request.put(
        'shops/' +
          utils.getCurrentShopSlug() +
          '/products/' +
          productId +
          '/media/',
        photos
      ).then(function (res) {
        return res.data;
      });
    };

    product.copyPhotos = function (image) {
      return Request.patch(
        'shops/' +
          utils.getCurrentShopSlug() +
          '/media/' +
          (image.category || 'backgrounds') +
          '/' +
          image.id +
          '/products/media/copy/',
        { id: image.id }
      ).then(function (res) {
        return res.data;
      });
    };

    product.saveSeasons = function (seasons) {
      return Request.put(
        'shop/' + utils.getCurrentShopSlug() + '/dive-seasons/',
        { diveSeasons: seasons }
      ).then(function () {
        $rootScope.showNotify('Seasons was successfully saved');
      });
    };

    product.getSeasons = function () {
      return Request.get(
        'shop/' + utils.getCurrentShopSlug() + '/dive-seasons/'
      ).then(function (res) {
        return res.data;
      });
    };

    product.getDCAddress = function () {
      return Request.get(
        'shop/' + utils.getCurrentShopId() + '/shop-address/'
      ).then(function (res) {
        return res.data;
      });
    };

    product.getTags = function () {
      return Request.get('booking/activities/tags/').then(function (res) {
        return res.data;
      });
    };

    product.getInclusions = function (productId) {
      return Request.get(
        'booking/activities/' + productId + '/extra-options/'
      ).then(function (res) {
        // console.log(res.data);
        return res.data;
      });
    };

    product.updateInclusion = function (data, productId) {
      return Request.post(
        'booking/activities/' + productId + '/extra-option/',
        data
      ).then(function (res) {
        return res.data;
      });
    };

    product.deleteInclusion = function (id) {
      return Request.del('booking/extra-options/' + id).then(function (res) {
        return res.data;
      });
    };

    product.getStatus = function (id) {
      return Request.get('booking/activities/' + id + '/status/').then(
        function (res) {
          return res.data;
        }
      );
    };

    product.setStatus = function (id, status, rejectReasonText) {
      return Request.post('booking/activities/' + id + '/status/', {
        status,
        rejectReasonText,
      }).then(function (res) {
        return res.data;
      });
    };

    product.checkAvailabilityStatus = function (id) {
      return Request.get(
        'booking/activities/' + id + '/changed-availabilities/'
      ).then(function (res) {
        return res.data;
      });
    };

    product.preview = function (id) {
      return Request.get('booking/activities/' + id + '/preview/').then(
        function (res) {
          return res.data;
        }
      );
    };

    product.checkActivityTabs = function (activity) {
      var result = {};
      if (activity.productType.value === 20) {
        // other validation for snorkeling trips;
        _.remove(activityMandatoryFields.basicTabComplete, (n) => {
          return (
            n === 'requirements.minCertification.value' ||
            n === 'requirements.minDivesCount.value'
          );
        });
      }
      if (activity.productType.value !== 30 && !activity.isGuided) {
        // if not guided remove ratio from mandatory fields
        _.remove(activityMandatoryFields.basicTabComplete, (n) => {
          return n === 'groupSize.ratio';
        });
      }
      if (activity.productType.value === 40) {
        // other validation for snorkeling trips;
        _.remove(
          activityMandatoryFields.basicTabComplete,
          (n) => n === 'groupSize.maxSize'
        );
      }

      if (activity.productType.value === 50) {
        // other validation for conservation products;
        var nonRequiredConservation = [
            'groupSize.maxSize',
            'groupSize.ratio',
            'staffLanguages.length',
            'requirements.minAge',
            'requirements.minCertification.value',
            'requirements.minDivesCount.value',
            'highlights.length',
            'whatToExpect',
            'localPrice',
            'logisticsType',
            'inclusionsSaved',
          ],
          additionalFields = {
            pricingTabComplete: ['timezoneId'],
            logisticsTabComplete: ['meetingPointType'],
          };

        _.each(activityMandatoryFields, function (arr, tabName) {
          if (additionalFields[tabName]) {
            arr.push.apply(arr, additionalFields[tabName]);
          }
          _.remove(activityMandatoryFields[tabName], (n) =>
            nonRequiredConservation.includes(n)
          );
        });
      }

      _.each(activityMandatoryFields, function (tab, tabName) {
        result[tabName] = true;
        _.each(tab, function (fieldName) {
          if (!_.get(activity, fieldName)) {
            result[tabName] = false;
          }
        });
      });

      if (
        (activity.scheduleType.value === 10 && !activity.startTime) ||
        (activity.scheduleType.value === 20 && !activity.weekdays.length) ||
        (activity.scheduleType.value === 30 && !activity.dateRanges.length) ||
        (activity.leadTimeType.value === 10 &&
          !_.get(activity, 'durationBefore.duration')) ||
        (activity.leadTimeType.value === 20 && !activity.timeBefore)
      ) {
        result.pricingTabComplete = false;
      }

      if (activity.isCoverMediaExisted) {
        // recieving this from BE to avoid additional api requests;
        result.picturesTabComplete = true;
      }

      result.allTabsComplete = true;
      _.each(result, function (tabName) {
        if (!tabName) {
          result.allTabsComplete = false;
        }
      });
      return result;
    };

    return product;
  });
