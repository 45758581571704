'use strict';

angular.module('app').directive('shareAffiliate', function (utils, Request) {
  return {
    restrict: 'AE',
    link: function (_, element) {
      var shareAffiliateLink = document.querySelector('[share-affiliate-link]');

      return shareAffiliateLink.addEventListener('click', function () {
        var longUrl = utils.generateAffiliateUrl();

        element.html(
          "<div class='share-block placeholder-animation'>" +
            "<span class='placeholder-animation'></span>" +
            "<span class='placeholder-animation'></span>" +
            "<span class='placeholder-animation'></span>" +
            "<span class='placeholder-animation'></span></div>"
        );

        if (utils.isStaff()) {
          element.html(
            '<div class="share-block">' +
              '<a target="_blank" href="https://www.facebook.com/sharer.php?u=' +
              longUrl +
              '"><i class="font-icons fb-icon"></i>Facebook</a>' +
              '<a target="_blank" href="https://twitter.com/intent/tweet?url=' +
              longUrl +
              '"><i class="font-icons twitter-icon"></i>Twitter</a>' +
              '<a target="_blank" href="https://plus.google.com/share?url=' +
              longUrl +
              '"><i class="font-icons icon-google-plus"></i>Google Plus</a>' +
              '<a target="_blank" href="https://www.pinterest.com/pin/create/button/?url=' +
              longUrl +
              '&media=https%3A//s.diviac.com/travel_dist/images/padi_travel_social_default.jpg&description="><i class="font-icons icon-pinterest"></i>Pinterest</a>' +
              '<a target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url=' +
              longUrl +
              'title=PADI%20Travel&summary=PADI%20Travel&source="><i class="font-icons icon-linkedin"></i>Linkedin</a>' +
              '</div>'
          );
        } else {
          Request.post('url-shortner/', {
            longUrl: longUrl,
          })
            .then(function (res) {
              return encodeURIComponent(res.data.shortUrl);
            })
            .then(function (affiliateLink) {
              element.html(
                '<div class="share-block">' +
                  '<a target="_blank" href="https://www.facebook.com/sharer.php?u=' +
                  affiliateLink +
                  '"><i class="font-icons fb-icon"></i>Facebook</a>' +
                  '<a target="_blank" href="https://twitter.com/intent/tweet?url=' +
                  affiliateLink +
                  '"><i class="font-icons twitter-icon"></i>Twitter</a>' +
                  '<a target="_blank" href="https://plus.google.com/share?url=' +
                  affiliateLink +
                  '"><i class="font-icons icon-google-plus"></i>Google Plus</a>' +
                  '<a target="_blank" href="https://www.pinterest.com/pin/create/button/?url=' +
                  affiliateLink +
                  '&media=https%3A//s.diviac.com/travel_dist/images/padi_travel_social_default.jpg&description="><i class="font-icons icon-pinterest"></i>Pinterest</a>' +
                  '<a target="_blank" href="https://www.linkedin.com/shareArticle?mini=true&url=' +
                  affiliateLink +
                  'title=PADI%20Travel&summary=PADI%20Travel&source="><i class="font-icons icon-linkedin"></i>Linkedin</a>' +
                  '</div>'
              );
            });
        }
      });
    },
  };
});
