'use strict';

angular.module('app').directive('focusOn', function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        $(element).on('click', function() {
          var el = $(attr.focusOn);

          $('html,body').animate({
            scrollTop: el.offset().top - 100
          }, 250, function() {
            el.focus();
          });
        });
      }
    };
});


angular.module('app').directive('focusOnWithoutScroll', function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        $(element).on('click', function() {
          var elem =  $(attr.focusOnWithoutScroll);
          elem.selectionStart = elem.selectionEnd = 10000;
          elem.focus();
        });
      }
    };
});



angular.module('app').directive('focusOnSelect', function ($timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attr, ctrl) {
        scope.$watch(function(){
          return ctrl.$viewValue;
        }, function (newVal) {
          if (newVal) {
            $timeout(function() {
              $(attr.focusOnSelect).find('input').click();
            });
          }
        });
      }
    };
});
