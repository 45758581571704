'use strict';

angular.module('app').directive('dragPin', ['$compile', function ($compile) {
  var link = function (scope, element) {
    var dragObject = {},
      elem = angular.element(element),
      newElement = '<img src="../images/marker.png" style="position: absolute;z-index:9999" id="dragged"/>',
      draggedElem,
      hovered;

    elem.on('mousedown', function (e) {
      if (e.which !== 1) { // right click
        return;
      }
      e.preventDefault();

      dragObject.elem = element;

      dragObject.downX = e.pageX;
      dragObject.downY = e.pageY;

      $(document.body).prepend(newElement);

      draggedElem = $('#dragged');
      draggedElem.css(getCenterPosition(e));
      elem.css({ visibility: 'hidden' });
    });

    angular.element(document).on('mousemove', function (e) {
      if (!dragObject.elem && !draggedElem) {
        return;
      }
      draggedElem.css(getCenterPosition(e));
    }).on('mouseup', function (e) {
      $(draggedElem).remove();

      var dropZone = document.elementFromPoint(e.clientX, e.clientY);

      if (dragObject.elem && scope.callback && angular.element(dropZone).hasClass('leaflet-tile')) {
        scope.$eval(scope.callback);
      }

      dragObject.elem = null;

      elem.css({ visibility: 'visible' });
    });

    function getCenterPosition(e) {
      return {
        left: e.pageX - elem.width() / 2 + 'px',
        top: e.pageY - elem.height() + 'px',
      };
    }
  };

  return {
    restrict: 'A',
    scope: {
      callback: '&',
    },
    link: link,
  };
}
]);
