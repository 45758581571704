'use strict';

angular.module('app')
    .controller('ApplyStartEndCtrl', function (itineraryService, itineraries, selectedItems, $uibModalInstance) {

        var vm = this;
        vm.close = function() {
          $uibModalInstance.dismiss('cancel');
        };
        vm.save = save;

        vm.list = [];
        vm.mainItinerary;


        _.each(itineraries, function(val) {
          if (selectedItems.indexOf(val.id) !== -1) {
            vm.list.push({id: val.id,title: val.title});
          }
        });



        function save() {
          vm.loading = true;

          var obj = {};

          obj.master = vm.mainItinerary;
          obj.itinerary = _.without(selectedItems, vm.mainItinerary)

          itineraryService.massFill(obj).then(function() {
              $uibModalInstance.close('saved');
          });
        }
    });
