'use strict';

angular.module('app')
    .service('localStorageCheckService', function (localStorageService) {
        var localStorageCheckService = {};

        // check if localstorase is supported
        // if not replace localstorate to cookies
        localStorageCheckService.check = function() {
            if(!localStorageService.isSupported) {
                window.localStorage = localStorageService;
                window.localStorage.setItem = localStorageService.set;
                window.localStorage.getItem = localStorageService.get;
            }
        };

        return localStorageCheckService;
    });
