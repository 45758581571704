'use strict';

angular.module('app')
    .service('roomService', function ($q, Request, diviacLocalStorage, $rootScope, info, utils, Upload) {
        var room = {};

        room.getInfo = function () {
            return Request.get('shop/'+  utils.getCurrentShopSlug() + '/rooms-basic/').then(function (res) {
                return res.data;
            });
        };

        room.setInfo = function (data) {
			if (!data.haveSeason) {
				data.seasons = [];
			}

            return Request.put('shop/'+  utils.getCurrentShopSlug() + '/rooms-basic/',data).then(function (res) {
                return res.data;
            });
        };

        room.getList = function () {
            return Request.get('booking/'+  utils.getCurrentShopSlug() + '/rooms/').then(function (res) {
                return res.data;
            });
        };

		room.getSleepList = function() {
            return Request.get('booking/'+  utils.getCurrentShopSlug() + '/sleep-and-dives/').then(function (res) {
                return res.data;
            });
		};

		room.getSleep = function (id) {
			return Request.get('booking/sleep-and-dives/' + id + '/').then(function(res){
				return res.data;
			});
		};

		room.saveSleep = function (data, productId) {
			data.shop = utils.getCurrentShopSlug();
			if (productId) {
				return Request.patch('booking/sleep-and-dives/' + productId + '/', data);
			}

			return Request.post('booking/sleep-and-dives/add/', data);
		};

		room.getRoom = function (id) {
			return Request.get('booking/rooms/' + id + '/').then(function(res){
				return res.data;
			});
		};

		room.deleteRoom = function (id) {
			return Request.del('booking/rooms/' + id + '/').then(function(res){
				return res.data;
			});
		};

		room.saveRoom = function (data, productId) {
			data.shop = utils.getCurrentShopSlug();
			if (productId) {

				if (data.lowerRate === 'no') {
					data.discountAmount = null;
				}

				return Request.patch('booking/rooms/' + productId + '/', data);
			}

			return Request.post('booking/rooms/add/', data);
		};

		room.deletePhoto = function (id) {
			return Request.del('booking/products/photo/' + id + '/').then(function(data){
				return data;
			});
		};

		room.nextPricing = function (plus, vm) {
            if (plus) {
                vm.from += vm.from === 0 ? 2 : 1;
            } else {
                vm.from -= vm.from === 2 ? 2 : 1;
            }

            vm.limit = vm.from > 0 ? 2 : 3;
        };


        room.uploadPhotos = function (photo) {
			var header = {
						"Content-Type": "application/json"
					},
					token = localStorage.getItem('token');

			if (token) {
				header["Authorization"] = "Bearer " + token;
			}

			return Upload.upload({
				url: utils.apiURL + 'booking/products/photo/',
				headers: header,
				arrayKey: '',
				method: 'POST',
				data: {
					picture: photo
				}
			}).then(function(res) {
			    //SUCCESS EVENT
		        return res.data;
		    }, function(err) {
		        //ERROR EVENT
		        console.warn('ERROR: ',err);
		        throw err;
		    }, function (evt) {
		          // PROGRESS EVENT
		          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
		          return progressPercentage;
		    });
		};

		room.deletePackage = function (id) {
			return Request.del('booking/sleep-and-dives/' + id + '/').then(function(res){
				return res.data;
			});
		};

		room.copyPackage = function (id) {
			return Request.post('booking/sleep-and-dives/' + id + '/copy/').then(function(res){
				return res.data;
			});
		};
		

		room.updatePackage = function (data,id) {
			return Request.patch('booking/sleep-and-dives/' + id + '/', data);
		}

        return room;
    });
