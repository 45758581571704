'use strict';

angular.module('app').directive('ratingDisplay', ['$compile', function($compile) {

        var link = function(scope, element, attrs, controller) {
            scope.$watch('ratingValue', function () {
                var n = Math.round(parseFloat(scope.ratingValue)),
                    template = '<div class="font-stars"><ul>',
                    string = '';

                

                if (scope.onlyString){
                    for(var i = 1; i <= n; i++) {
                        string += '&#9733;';
                    }
                    element.empty().html(string);
                }else{
                    for(var i = 1; i <= 5; i++) {
                        if(i <= n){
                            template += '<i class="active">&#9733;</i>';
                        } else {
                            template += '<i>&#9733;</i>';
                        }
                    }
                    var contentTr = $compile(template)(scope);

                    element.empty().append(contentTr);
                }
            });
        };

        return {
            restrict: 'AE',
            replace: true,
            scope: {
                ratingValue: '=',
                starClass: '@',
                reviewsNumber: '=',
                onlyString: '=',
            },
            link: link
        };
    }
]);
