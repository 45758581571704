'use strict';

angular.module('app')
  .service('shopService', function ($q, $http, Request, $rootScope, gettextCatalog, diviacLocalStorage, localStorageService, $uibModal, GTM, utils, config) {
    var shop = {};

    shop.getInfo = function () {
      const shopSlug = utils.getCurrentShopSlug();
      if (utils.isProDiver() || !shopSlug) {
        const q = $q.defer();
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const latitude = position.coords.latitude,
              longitude = position.coords.longitude;
            q.resolve({
              latitude,
              longitude,
            });
          },
          function () {
            q.resolve({});
          },
          {
            enableHighAccuracy: false,
            timeout: 5000,
            maximumAge: 0,
          },
        );
        return q.promise;
      }
      return Request.get(`shop/${shopSlug}/basics/`).then(function (res) {
        return res.data;
      });
    };

    shop.getShopCurrency = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/currency/').then(function (res) {
        return res.data;
      });
    };

    shop.setShopCurrency = function (data) {
      return Request.patch('shop/' + utils.getCurrentShopSlug() + '/currency/', data).then(function (res) {
        return res.data;
      });
    };

    shop.setInfo = function (data) {
      return Request.patch('shop/' + utils.getCurrentShopSlug() + '/basics/', data).then(function (res) {
        $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved'));

        _.forEach($rootScope.user.managedShops, function (val) {
          if (val.shop.id == res.data.id) {
            val.shop.title = res.data.title;

            if (res.data.resortTitleRead && $rootScope.choosenShop.kind == 20) {
              val.shop.resortTitle = res.data.resortTitleRead;
            }
          }
        });

        $rootScope.choosenShop.title = res.data.title;

        diviacLocalStorage.set('user', $rootScope.user);
        diviacLocalStorage.set('choosenShop', $rootScope.choosenShop);
        localStorageService.set('choosenShop', $rootScope.choosenShop);

        return res.data;
      });
    };

    shop.getNewChecklistStatus = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/status-check/').then(function (res) {
        $rootScope.pagesStatus = res.data;
        return res.data;
      });
    };

    shop.getChecklistStatus = function () {
      const { kind, resortKind } = $rootScope.selectedShop;
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/checklist-status/').then(function (res) {
        if (+kind === 0) {
          delete res.data.rooms;
          delete res.data.diveResort;
          delete res.data.resortPhotos;
          delete res.data.itineraries;
          delete res.data.liveaboardPhotos;
          delete res.data.schedule;
          delete res.data.pricing;
          delete res.data.availability;
          delete res.data.hasGroupTrips;
        } else if (+kind === 10) {
          delete res.data.diveResort;
          delete res.data.resortPhotos;
          delete res.data.diveCenter;
          delete res.data.diveSites;
          delete res.data.products;
          delete res.data.photos;
          delete res.data.hasGroupTrips;
        } else {
          delete res.data.itineraries;
          delete res.data.liveaboardPhotos;
          delete res.data.schedule;
          delete res.data.pricing;
          delete res.data.availability;

          if (+resortKind === 20) {
            delete res.data.diveCenter;
            delete res.data.diveSites;
            delete res.data.photos;
            delete res.data.products;
          }
        }

        return res.data;
      });
    };

    shop.updateShopList = function (reload) {
      var user = diviacLocalStorage.get('user'),
        selectedShop = diviacLocalStorage.get('choosenShop'),
        alreadyInList = false;

      _.each(user.managedShops, function (val) {
        val.shop.affiliateProfileKind = val.affiliateProfileKind;
        if (val.shop.id == selectedShop.id) {
          alreadyInList = true;
        }
      });

      if (!alreadyInList) {
        if (!_.isArray(user.managedShops)) {
          user.managedShops = [];
        }

        user.managedShops.push({ shop: selectedShop });

        $rootScope.user = user;

        diviacLocalStorage.set('user', user);
      }

      if (reload) {
        // window.location.href = '/home/';
        window.location.href = utils.createURL('home_home');
      }
    };

    shop.getState = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/draft/').then(function (res) {
        return res.data;
      });
    };

    shop.setState = function (data) {
      if (_.isNull(data.isOnAdventures) || data.isOnAdventures === undefined) {
        data.isOnAdventures = $rootScope.isOnAdventures || false;
      }
      if (_.isNull(data.isDraft) || data.isDraft === undefined) {
        data.isDraft = $rootScope.isDraft;
      }
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/draft/', data).then(function (res) {
        return res.data;
      });
    };

    shop.setStateWithConfirm = function (type) {
      var modalInstance = $uibModal.open({
          animation: false,
          templateUrl: '../templates/_form-change-status.html',
          controller: 'ModalClaimCtrl',
          controllerAs: 'claimModal',
          windowClass: 'contact-us',
        }),
        prevStatus = {};

      switch (type) {
      case 'travel':
        prevStatus.isAvailableShop = !$rootScope.isAvailableShop;
        prevStatus.isAdventureAvailableShop = $rootScope.isAdventureAvailableShop;
        break;
      case 'adventures':
        prevStatus.isAdventureAvailableShop = !$rootScope.isAdventureAvailableShop;
        prevStatus.isAvailableShop = $rootScope.isAvailableShop;
        break;
      default:
        return false;
      }

      return modalInstance.result.then(function () {
        // confirm - yes
        return shop.setState({ isDraft: !$rootScope.isAvailableShop, isOnAdventures: $rootScope.isAdventureAvailableShop }).then(function (result) {
          $rootScope.isAvailableShop = result.isAvailableShop;
          $rootScope.isAdventureAvailableShop = result.isAdventureAvailableShop;

          if (type === 'travel') {
            GTM.variables({ business_listing_status: !result.isAvailableShop ? 'Unpublished' : 'Published' });
            if (!result.isAvailableShop) {
              GTM.event('business_listing_unpublish');
            } else {
              GTM.event('business_listing_publish');
            }
          }
        }).catch(function (error) {
          $rootScope.isAvailableShop = prevStatus.isAvailableShop;
          $rootScope.isAdventureAvailableShop = prevStatus.isAdventureAvailableShop;
        });
      }, function (err) {
        // confirm - no
        $rootScope.isAvailableShop = prevStatus.isAvailableShop;
        $rootScope.isAdventureAvailableShop = prevStatus.isAdventureAvailableShop;
        return err;
      });
    };

    shop.googleGeoCode = function (slug) {
      return $http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + slug + '&sensor=false&language=en-US' + '&key=' + config.googleAPIKey, { withCredentials: false }).then(function (data) {
        return data.data.results;
      });
    };

    shop.googleReverseGeoCode = function (latlang) {
      return $http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latlang + '&sensor=false&language=en-US' + '&key=' + config.googleAPIKey, { withCredentials: false }).then(function (data) {
        return data.data.results;
      });
    };

    shop.getSeoInfo = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/seo/').then(function (res) {
        var data = res.data,
          seoInfo = {};

        data.businessType = utils.getType(data.businessType);


        _.each(data, function (val, key) {
          seoInfo[key.replace(/([A-Z])/g, '_$1').toLowerCase()] = val;
        });
        $rootScope.shopUrl = data.businessUrl;
        $rootScope.dcShopUrl = data.businessDcUrl;
        $rootScope.shopLocation = data.businessLocation || data.businessArea || data.businessCountry;

        GTM.variables(seoInfo);

        return res.data;
      });
    };

    shop.getLAGroups = function () {
      return Request.get('shop/autosuggest/group/').then(function (res) {
        return res.data;
      });
    };

    shop.getBoatSpec = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/boat-specification/').then(function (res) {
        return res.data;
      });
    };

    shop.getBoatParameters = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/parameters/').then(function (res) {
        return res.data;
      });
    };

    shop.setBoatSpec = function (data) {
      return Request.patch('shop/' + utils.getCurrentShopSlug() + '/boat-specification/', data).then(function (res) {
        return res.data;
      });
    };

    shop.setBoatParameters = function (data) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/parameters/', data).then(function (res) {
        return res.data;
      });
    };

    shop.getStaffSettings = function (data) {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/staff/', data).then(function (res) {
        return res.data;
      });
    };

    shop.setStaffSettings = function (data) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/staff/', data).then(function (res) {
        return res.data;
      });
    };

    shop.getShopData = (id) => Request.get(`shop/${id}`).then(res => res.data);

    shop.getShopLanguage = () => Request.get(`shop/${utils.getCurrentShopSlug()}/language`).then(res => res.data);

    shop.setShopLanguage = (data) => Request.put(`shop/${utils.getCurrentShopSlug()}/language`, data).then(res => res.data);

    return shop;
  });
