'use strict';

angular.module('app')
  .service('recalcService', function ($q, $http, Request, $location, $rootScope, diviacLocalStorage, localStorageService, $uibModal, utils) {
    var recalc = {};


    recalc.recalcPrices = function () {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/recalculate-prices/').then(function (res) {
        // $rootScope.recalcStatus = 2;
        return res.data;
      }, function (err) {
        return err;
      });
    };

    recalc.setStatus = function (status) {
      // var storageObject = {};
      // storageObject[utils.getCurrentShopSlug()] = status;
      $rootScope.recalcStatus = status;
      // diviacLocalStorage.set("recalcStatus", storageObject);
    };

    recalc.getRecalcUpdates = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/recalculate-prices/').then(function (res) {
        // return res.data;
        var recalcDate = ' ',
          recalcRequired = res.data.isBeingChanged,
          recalcInProcess = res.data.isBeingRecalculated;

        if (recalcRequired && !recalcInProcess) {
          recalc.setStatus(1);
        }
        if (recalcInProcess) {
          recalc.setStatus(2);
        }
        if (!recalcInProcess && !recalcRequired) {
          recalcDate = res.data.endTime || 'never';
          recalc.setStatus(3);
        }
        if (res.data.status === 'FAILURE' || res.data.status === 'TERMINATED') {
          recalc.setStatus(4);
        }
        return recalcDate;
      }, function (err) {
        return err;
      });
    };
    return recalc;
  });
