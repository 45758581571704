'use strict';

angular.module('app').directive('loadScript', function() {
    return {
      restrict: 'E',
      scope: false,
      link: function(scope, elem, attr) {
        var script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = attr.src;
          document.body.appendChild(script);
      }
    };
  });