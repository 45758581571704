'use strict';

angular.module('app').directive('drInclusions', function ($uibModal, $timeout, utils) {
  return {
    restrict: 'A',
    scope: {
      slug: '=',
      currency: '=',
      queryParams: '=',
      insurance: '=',
      room: '=',
      duration: '=',
      mealPlanDescription: '=',
      isPackage: '=',
    },
    link: function (scope, elem) {
      elem.on('touchstart, click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var modalInstance = $uibModal.open({
          templateUrl: window.templatePath ? window.templatePath + 'templates/_inclusions.html?v3' : '../templates/_inclusions.html?v3',
          controller: function ($uibModalInstance, Request) {
            var vm = this;

            vm.isLastMinuteBooking = false;
            vm.currency = scope.currency;
            vm.close = function () {
              $uibModalInstance.close(1);
            };

            vm.getMilestoneAmountType = getMilestoneAmountType;

            function getMilestoneAmountType(amountTypeKey) {
              return utils.getTextFromInfo(amountTypeKey, 'shop', 'MILESTONE_AMOUNT_TYPE') || vm.currency;
            }

            vm.inclusions = {
              loading: true,
            };

            vm.isLastMinuteBooking = scope.queryParams.date_after && utils.isLastMinuteBooking(scope.queryParams.date_after);

            Request.get('booking/resort/inclusions/' + scope.slug + '/', scope.queryParams).then(function (res) {
              vm.inclusions = res.data;
              vm.inclusions.insurance = scope.insurance;
              vm.inclusions.room = scope.room;
              vm.inclusions.duration = scope.duration;
              vm.inclusions.mealsDescription = scope.mealsDescription;
              vm.inclusions.isFixedPackage = scope.isPackage;
            });
          },
          controllerAs: 'ctrl',
          windowClass: 'uib-itinerary-popup',
          scope: scope,
        });

        return modalInstance.result.then(function () {
          if (scope.updateUrl && window.shopUrl) {
            $timeout(function () {
              window.history.replaceState({}, null, window.shopUrl);
            });
          }
        }, function () {
          if (scope.updateUrl && window.shopUrl) {
            $timeout(function () {
              window.history.replaceState({}, null, window.shopUrl);
            });
          }
        });
      });
    },
  };
});
