/* eslint-disable no-undefined */
/* eslint-disable no-shadow, consistent-return */
'use strict';

angular
  .module('app')
  .controller('DiveSitesManagementCtrl', function (
    $scope,
    $q,
    $location,
    $rootScope,
    $timeout,
    diveSiteService,
    gettextCatalog,
    GTM,
    utils,
    config,
    info,
    diveGuideService,
    leafletData,
    $uibModal,
    Upload,
    shopService
  ) {
    var vm = this,
      map,
      firstLoadingIsDone = false,
      openDiveSite = $location.search().diveSiteID;

    vm.saveInfo = saveInfo;
    vm.toggleTop5 = toggleTop5;
    vm.toggleSkippedDS = toggleSkippedDS;
    vm.selectAll = selectAll;
    vm.openPopup = openPopup;
    vm.deleteItem = deleteItem;
    vm.openInfoWindow = openInfoWindow;
    vm.closeInfoWindow = closeInfoWindow;
    vm.closePopup = closePopup;
    vm.getShopList = getShopList;
    vm.selectForMerge = selectForMerge;
    vm.mergeSelected = mergeSelected;
    vm.editDiveSite = editDiveSite;
    vm.deleteDiveSiteHandler = deleteDiveSiteHandler;
    vm.setCoords = setCoords;
    vm.showHistory = showHistory;
    vm.calculateStringLength = calculateStringLength;
    vm.saveDiveSite = saveDiveSite;
    vm.toogleTopography = toogleTopography;
    vm.addDiveSiteBtn = addDiveSiteBtn;
    vm.fileUpload = fileUpload;
    vm.deletePhoto = deletePhoto;
    vm.referrer = $location.search().referrer;
    vm.getImage = utils.getCorrectImage;
    vm.marineLifeList = [];
    vm.searchMarineLife = searchMarineLife;
    vm.deleteVideo = deleteVideo;
    vm.transformCoordinates = transformCoordinates;
    vm.isEmpty = _.isEmpty;
    vm.roundToSix = roundToSix;
    vm.receiveTranslatedData = receiveTranslatedData;
    vm.rejectDiveSite = rejectDiveSite;

    vm.diveSiteList = [];
    vm.adventureDiveSite = info.adventureDiveSite;
    vm.localPlug = info.places.LOCAL_PLUG_TYPE;
    vm.distanceUnits = info.account.DISTANCE_HEIGHT_UNIT;
    vm.MAXIMUM_DEPTH_UNIT = info.places.MAXIMUM_DEPTH_UNIT;
    vm.ESTIMATED_AREA_UNIT = info.places.ESTIMATED_AREA_UNIT;
    vm.CONSERVATION_DRIVER_OPTION = info.places.CONSERVATION_DRIVER_OPTION;
    vm.selectedDiveSite = {};
    vm.isCreateNewInfoPopupShown = false;
    vm.isInfoPopupShown = false;
    vm.countryList = info.countryList;
    vm.countrySelected = countrySelected;
    vm.clearCountry = clearCountry;
    vm.isProDiverShop = utils.isProDiverShop;
    vm.form = {};
    $scope.center = {};

    $rootScope.hideHeader = true;

    activate();

    function activate() {
      vm.top5 = [];
      vm.selectedDS = {};
      vm.allSelected = true;
      vm.loading = true;
      vm.diveSiteToEdit = {};
      vm.errorPhoto = {
        photo: [],
        map: [],
      };
      vm.errorFormat = {};
      vm.disableLoading = [];
      vm.editedDS = null;
      vm.options = {};
      const dsId = $location.search().id,
        lang = $location.search().lang;
      if (dsId) {
        diveSiteService
          .getInfo()
          .then(function (data) {
            vm.allDiveSites = data.allDiveSites;
            vm.form = data;
            $rootScope.refLocation = data.referenceLocationTitle;
          })
          .then(() => {
            getStaffForms(dsId, lang);
          });
      } else {
        shopService.getInfo().then(function (data) {
          vm.shopCoords = {
            lat: data.latitude,
            lng: data.longitude,
            icon: config.mapIcons.withoutPrice,
            message: data.title,
            id: 'shop',
          };
          if (utils.isProDiver() && data.latitude && data.longitude) {
            $scope.center = {
              ...vm.shopCoords,
              zoom: 10,
            };
            firstLoadingIsDone = true;
            mapMoved();
          } else {
            diveSiteService.getInfo().then(function (data) {
              vm.allDiveSites = data.allDiveSites;
              vm.form = data;
              $rootScope.refLocation = data.referenceLocationTitle;
              getShopList(utils.isProDiver()).then(function () {
                if (openDiveSite && !firstLoadingIsDone) {
                  openPopup({ id: openDiveSite });
                  openInfoWindow(openDiveSite);
                  $location.search('diveSiteID', null);
                }
                firstLoadingIsDone = true;
                vm.loading = false;
              });
            });
          }
        });
      }
    }

    function setProdiverDS() {
      if (utils.isProDiver()) {
        diveSiteService.getDiveSites().then((data) => {
          data.forEach((site) => {
            if (!vm.form.adventureDiveSites) {
              vm.form.adventureDiveSites = [];
            }
            vm.form.adventureDiveSites.push(site.id);
          });
        });
      }
    }

    function getStaffForms(dsId, lang, updateLang) {
      $q.all([
        diveSiteService.getDiveSite(dsId, lang),
        diveSiteService.getDiveSite(dsId, lang, true).catch(() => null),
      ])
        .then((data) => {
          const diveSite = data[0],
            stableDiveSite = data[1],
            pin = {
              id: diveSite.id,
              lat: diveSite.point[1],
              lng: diveSite.point[0],
              icon: config.mapIcons.withPrice,
              draggable: false,
            };
          if (updateLang) {
            diveSite.inputLanguage = lang;
          }
          diveSite.basedOn = dsId;
          vm.selectedDiveSite = diveSite;
          vm.selectedDS[`selected${dsId}`] = true;
          $scope.markers = [pin];
          leafletData.getMap('search-map').then(function (m) {
            m.fitBounds($scope.markers);
          });
          editDiveSite(vm.selectedDiveSite, true);
          if (stableDiveSite) {
            setStableVersionPopup(stableDiveSite, vm.selectedDS, pin);
          } else {
            vm.editMode = false;
          }
        })
        .finally(() => {
          vm.loading = false;
          firstLoadingIsDone = true;
        });
    }

    function saveInfo(form) {
      if (form.$valid) {
        vm.loading = true;
        diveSiteService
          .setInfo(vm.form)
          .then(function () {
            GTM.event('business_listing_dive_sites_complete');
            GTM.variables({
              business_listing_dive_sites: 'Complete',
            });

            $location.path(utils.createURL('photos'));
            $rootScope.checkedList.diveSites = true;
            vm.loading = false;
          })
          .catch(function (error) {
            _.each(error.data, function (val, key) {
              $scope.extranetForm[key].$setValidity('required', false);
            });
          });
      }
    }

    function countrySelected() {
      if (vm.form.country) {
        vm.options.country_id = vm.form.country;
        getShopList();
      }
    }

    function clearCountry($select) {
      $select.selected = undefined;
      $select.search = undefined;
      vm.form.country = undefined;
      vm.options.country_id = undefined;
      getShopList();
    }

    function toggleTop5(id) {
      var element = _.filter(vm.form.top_5_diveSites, function (elem) {
        return elem === id;
      });

      if (element && element.length) {
        vm.form.top_5_diveSites = _.reject(vm.form.top_5_diveSites, function (
          elem
        ) {
          return elem === id;
        });
      } else {
        vm.form.top_5_diveSites.push(id);
      }

      if (vm.top5['selected' + id]) {
        vm.selectedDS['selected' + id] = true;
        vm.form.skippedDiveSites = _.reject(vm.form.skippedDiveSites, function (
          elem
        ) {
          return elem === id;
        });
      }

      prepareSelected();

      diveSiteService.setInfo(vm.form, true);
    }

    function receiveTranslatedData() {
      if (vm.editMode) {
        vm.loading = true;
        const lang = vm.diveSiteToEdit.inputLanguage;
        getStaffForms(vm.diveSiteToEdit.id, lang, true);
      }
    }

    function toggleSkippedDS(id) {
      if (!vm.form.adventureDiveSites) {
        vm.form.adventureDiveSites = [];
      }
      if (id) {
        var element = _.filter(vm.form.adventureDiveSites, function (elem) {
          return elem === id;
        });

        if (element && element.length) {
          vm.form.adventureDiveSites = _.reject(
            vm.form.adventureDiveSites,
            function (elem) {
              return elem === id;
            }
          );
        } else {
          vm.form.adventureDiveSites.push(id);
          vm.allSelected = false;
        }
        // if (!vm.selectedDS['selected' + id]) {
        //   vm.top5['selected' + id] = false;
        //   vm.form.top_5_diveSites = _.reject(vm.form.top_5_diveSites, function (elem) {
        //     return elem === id;
        //   });
        // }
        prepareSelected();
        // old request
        // diveSiteService.setInfo(vm.form, true);
        diveSiteService.setInfo(
          { adventureDiveSites: vm.form.adventureDiveSites },
          true
        );
      }
    }
    function prepareSelected() {
      vm.form.diveSitesGoTo = [];

      _.each(vm.selectedDS, function (val, key) {
        if (val === true) {
          var selected = key.split('selected');
          vm.form.diveSitesGoTo.push(parseInt(selected[1], 10));
        }
      });
    }

    function selectAll() {
      if (vm.allSelected) {
        vm.form.skippedDiveSites = [];

        _.forEach(vm.form.allDiveSites, function (val) {
          vm.selectedDS['selected' + val.id] = true;
        });
      } else {
        _.forEach(vm.form.allDiveSites, function (val) {
          vm.selectedDS['selected' + val.id] = false;
          vm.top5['selected' + val.id] = false;

          vm.form.top_5_diveSites = [];

          vm.form.skippedDiveSites.push(val.id);
        });
      }
    }

    var draggedPin;

    leafletData.getMap('search-map').then(function (m) {
      map = m;

      map.on('mousemove', function (e) {
        draggedPin = e.latlng;
      });
      map.on('dragend', mapMoved);
      map.on('zoomend', mapMoved);
    });

    angular.extend($scope, {
      defaults: {
        minZoom: 2,
        maxZoom: 17,
        zoomControlPosition: 'topright',
        worldCopyJump: true,
      },
      layers: {
        baselayers: {
          test: {
            name: 'Satellite',
            url:
              '//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            type: 'xyz',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          },
          mapbox_light: {
            name: 'PADI Travel Map',
            url:
              'https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZGl2aWFjIiwiYSI6ImNiZjU5MGVlY2VkZmQ4YWFkNGJmMWVjMDVjMDUzNDdlIn0.62dEH0gmRXAGO5e2x1JnRg',
            type: 'xyz',
          },
        },
      },
    });

    vm.setPin = function (coords) {
      var coordinates = coords || draggedPin,
        pinIndex = _.findIndex($scope.markers, {
          id: 'draggedPin',
        });
      if (pinIndex !== -1) {
        $timeout(function () {
          $scope.markers[pinIndex].lat = parseFloat(coordinates.lat);
          $scope.markers[pinIndex].lng = parseFloat(coordinates.lng);
        });
      } else {
        $scope.markers.push({
          id: 'draggedPin',
          lat: parseFloat(coordinates.lat),
          lng: parseFloat(coordinates.lng),
          icon: config.mapIcons.withPrice,
          draggable: true,
        });
      }

      vm.diveSiteToEdit.latLng = transformCoordinates(
        coordinates.lat,
        coordinates.lng
      );
      vm.diveSiteToEdit.latitude = coordinates.lat;
      vm.diveSiteToEdit.longitude = coordinates.lng;
    };

    function setCoords() {
      if (vm.diveSiteToEdit.latLng) {
        var coords = vm.diveSiteToEdit.latLng.split(',');

        vm.setPin({
          lat: coords[0].substring(0, 6),
          lng: coords[1].substring(0, 6),
        });
      }
    }

    function openPopup(item) {
      vm.selectedDiveSite = {};

      $q.all([
        diveSiteService
          .getDiveSite(item.id, item.inputLanguage, false, true)
          .catch(() => false),
        diveSiteService
          .getDiveSite(item.id, item.inputLanguage)
          .catch(() => false),
      ]).then(([userVersion, lastVersion]) => {
        const diveSite = userVersion || lastVersion;
        vm.selectedDiveSite = diveSite;
        vm.isCreateNewInfoPopupShown = false;
        vm.isInfoPopupShown = true;

        if (userVersion) {
          vm.showLastVersion = true;
          setStableVersionPopup(lastVersion, diveSite);
        } else {
          vm.showLastVersion = false;
        }
      });
      _.each(vm.diveSiteList, function (el) {
        el.active = false;
        if (el.id === item.id) {
          el.active = true;
        }
      });

      _.each($scope.markers, function (val, key) {
        if (val && val.id === 'draggedPin') {
          $scope.markers[key] = vm.editedDS;
        }
      });

      // item.active = true;
    }

    $scope.$on('leafletDirectiveMarker.search-map.click', function (e, args) {
      var infoId = _.get(args.model, 'id');
      if (args.model.info && vm.selectedDiveSite.id !== infoId) {
        vm.isInfoPopupShown = true;
        openPopup(args.model.info);
        scrollToDiveSite(infoId);
      }
    });

    $scope.$on('leafletDirectiveMarker.search-map.mouseover', function (
      e,
      args
    ) {
      args.leafletEvent.target.openPopup();
    });

    $scope.$on('leafletDirectiveMarker.search-map.dragend', function (e, args) {
      if (args.model) {
        vm.diveSiteToEdit.latLng = args.model.lat + ',' + args.model.lng;
        vm.setPin(args.model);
      }
    });

    $scope.$on('leafletDirectiveMarker.search-map.mouseout', function (
      e,
      args
    ) {
      args.leafletEvent.target.closePopup();
    });

    $scope.$on('leafletDirectiveMarker.search-map.popupopen', function (
      e,
      args
    ) {
      args.leafletEvent.popup._wrapper.addEventListener('click', () => {
        const info = _.get(args, 'model.info'),
          infoId = _.get(info, 'id');
        if (infoId && vm.selectedDiveSite.id !== infoId) {
          vm.isInfoPopupShown = true;
          openPopup(info);
          scrollToDiveSite(infoId);
        }
        args.leafletEvent.target.closePopup();
      });
    });

    function deleteItem(ev) {
      ev.stopPropagation();
    }

    function scrollToDiveSite(id) {
      if (!id) return;
      var list = $('#dive-site-list'),
        item = $('#diveSite' + id);

      list.animate(
        {
          scrollTop:
            list.scrollTop() +
            item.position().top -
            list.height() / 2 +
            item.height(),
        },
        600
      );
    }

    function closePopup() {
      _.each(vm.diveSiteList, function (el) {
        el.active = false;
      });
      vm.isCreateNewInfoPopupShown = false;
      vm.isInfoPopupShown = false;
      vm.editMode = false;
      mapMoved();
      _.each($scope.markers, function (val, key) {
        if (val && val.id === 'draggedPin') {
          $scope.markers[key] = vm.editedDS;
        }
      });

      $scope.markers = _.reject($scope.markers, _.isEmpty);
      if ($rootScope.user.isStaff) {
        $scope.markers = _.reject($scope.markers, { id: 'stablePin' });
      }

      vm.diveSiteToEdit = {};
      vm.selectedDiveSite = {};
      invalidateMapSize();

      if ($rootScope.user.isStaff) {
        $('.dive-site-popup-container').off('scroll');
      }
    }

    function openInfoWindow(id) {
      _.each($scope.markers, function (el) {
        if (el) {
          if (el.id === id) {
            el.focus = true;
            $scope.center = {
              lat: el.lat,
              lng: el.lng,
            };
          } else {
            el.focus = false;
          }
        }
      });
    }

    function closeInfoWindow() {
      _.each($scope.markers, function (el) {
        el.focus = false;
      });
    }

    function getShopList(updateMap) {
      vm.loading = updateMap ? false : true;
      $scope.markers = [vm.shopCoords];

      setProdiverDS();

      return diveSiteService.getShopList(vm.options).then(function (res) {
        if (res === false) {
          return;
        }
        vm.diveSiteList = res.results;
        _.each(res.results, function (diveSite) {
          if (vm.form.adventureDiveSites) {
            vm.top5['selected' + diveSite.id] = diveSite.isTop_5;

            // vm.selectedDS['selected' + diveSite.id] = !diveSite.isSkipped;
            vm.selectedDS['selected' + diveSite.id] =
              vm.form.adventureDiveSites.indexOf(diveSite.id) !== -1;
          }

          if (diveSite.point && diveSite.point.length) {
            $scope.markers.push({
              id: diveSite.id,
              lat: diveSite.point[1],
              lng: diveSite.point[0],
              message: diveSite.title,
              info: diveSite,
              icon: config.mapIcons.diveSites,
              focus: false,
            });
          }
        });

        // if ($scope.markers.length && !updateMap) {
        //   $scope.center = {
        //     lat: $scope.markers[0].lat,
        //     lng: $scope.markers[0].lng,
        //     zoom: 10,
        //   };
        // }
        leafletData.getMap('search-map').then(function (m) {
          if (!updateMap && $scope.markers && $scope.markers.length) {
            m.fitBounds($scope.markers);
          }
        });

        vm.loading = false;
      });
    }

    vm.slectedForMerge = [];

    function selectForMerge(id) {
      var index,
        list = _.filter(vm.slectedForMerge, function (diveSiteId, i) {
          if (diveSiteId === id) {
            index = i;
            return diveSiteId;
          }
        });

      if (list.length) {
        vm.slectedForMerge.splice(index, 1);
      } else {
        vm.slectedForMerge.push(id);
      }

      return true;
    }

    function mergeSelected() {
      if (vm.slectedForMerge.length < 2) {
        return 1;
      }

      var modal = $uibModal.open({
        templateUrl: '../templates/_merge-popup.html',
        resolve: {
          items: function () {
            return _.filter(vm.diveSiteList, function (val) {
              return vm.slectedForMerge.indexOf(val.id) !== -1;
            });
          },
        },
        controller: function ($scope, items) {
          $scope.items = items;

          $scope.merge = function () {
            modal.close();
            var loading = $uibModal.open({
                templateUrl: '../templates/_loading.html',
                keyboard: false,
                backdrop: 'static',
              }),
              index = vm.slectedForMerge.indexOf($scope.selectedDs),
              newArray = _.clone(vm.slectedForMerge);
            newArray.splice(index, 1);

            return diveSiteService
              .merge({
                source: $scope.selectedDs,
                toMerge: newArray,
              })
              .then(function () {
                getShopList()
                  .then(loading.close)
                  .then(function () {
                    $rootScope.showNotify('Dive sites was succesfully merged');
                    vm.slectedForMerge = [];
                    vm.isCreateNewInfoPopupShown = false;
                    vm.isInfoPopupShown = false;
                  });
              })
              .catch(function () {
                loading.close();
                $rootScope.showNotify('Something went wrong!');
              });
          };
        },
        openedClass: 'merge-ds',
      });

      return true;
    }

    function editDiveSite(diveSite, preventStable = false) {
      vm.diveSiteToEdit = _.clone(diveSite);
      vm.alreadyAdopted = diveSite.isAdopted;

      if (vm.alreadyAdopted) {
        if ($rootScope.user.isStaff) {
          vm.showAdoptSection = true;
        } else if (
          vm.diveSiteToEdit.adoptedBy === $rootScope.user.id ||
          vm.diveSiteToEdit.adoptedByShop === utils.getCurrentShopId()
        ) {
          vm.showAdoptSection = true;
        } else {
          vm.showAdoptSection = false;
        }
      }

      vm.diveSiteToEdit.latLng = transformCoordinates(
        diveSite.point[1],
        diveSite.point[0]
      );
      vm.diveSiteToEdit.topography = {};
      _.each(vm.diveSiteToEdit.diveSiteType, function (val) {
        vm.diveSiteToEdit.topography['selected_' + val] = true;
      });

      if (!preventStable) {
        vm.diveSiteToEdit.basedOn = vm.diveSiteToEdit.id;
      }

      if (vm.diveSiteToEdit.photos.length) {
        var photos = _.reject(vm.diveSiteToEdit.photos, {
          isAdditional: false,
        });

        vm.diveSiteToEdit.additionalPhotos = photos ? photos : [];
        vm.diveSiteToEdit.images = _.map(
          _.clone(vm.diveSiteToEdit.photos),
          'id'
        );
        vm.diveSiteToEdit.photos = _.reject(vm.diveSiteToEdit.photos, {
          isAdditional: true,
        });
      }

      toogleTopography();

      vm.editMode = true;
      vm.isInfoPopupShown = false;
      vm.isCreateNewInfoPopupShown = true;

      var pin = _.find($scope.markers, {
        id: diveSite.id,
      });
      if (pin) {
        vm.editedDS = _.cloneDeep(pin);
        pin.id = 'draggedPin';
        pin.icon = config.mapIcons.withPrice;
        pin.draggable = true;
        pin.zIndexOffset = 10;
        pin.message = null;
      }

      if (vm.diveSiteToEdit.createdByShopId !== utils.getCurrentShopId()) {
        vm.diveSiteToEdit.hidePrivateField = true;
      }

      if ($rootScope.user.isStaff && !preventStable) {
        setStableVersionPopup(vm.diveSiteToEdit, vm.selectedDS, pin);
      }

      invalidateMapSize();

      return true;
    }

    function setStableVersionPopup(stableInfo, selectedDs, pin) {
      vm.diveSiteStable = _.cloneDeep(stableInfo);
      vm.selectedDSStable = _.cloneDeep(selectedDs);

      if (pin) {
        const stablePin = _.cloneDeep(pin);
        stablePin.id = 'stablePin';
        stablePin.draggable = false;
        stablePin.clickable = false;
        stablePin.icon = config.mapIcons.diveSites;
        stablePin.zIndexOffset = 9;
        stablePin.message = 'Stable version coordinates';

        $scope.markers.push(stablePin);
      }
      /*
        (   )
        (   ) (
        ) _   )
          ( \_
        _(_\ \)__
      (____\___))

      MMMM А пахне як....
      */
      setTimeout(() => {
        const target = $('.dive-site-popup-container');
        target.on('scroll', function () {
          target
            .prop('scrollTop', this.scrollTop)
            .prop('scrollLeft', this.scrollLeft);
        });
      }, 1000);
    }

    function deleteDiveSiteHandler(diveSite) {
      if (
        !diveSite.hasOwnActivities &&
        !diveSite.hasUsedByOtherShopsOrActivities
      ) {
        deleteDiveSite(diveSite.id);
        return;
      }
      const modal = $uibModal.open({
        templateUrl: '../templates/_dive-site-delete-warning.html',
        controller: function ($scope) {
          $scope.diveSite = diveSite;
          $scope.disableDelete =
            !$rootScope.user.isStaff &&
            diveSite.hasUsedByOtherShopsOrActivities;
          $scope.save = () => {
            modal.close();
            deleteDiveSite(diveSite.id);
          };

          $scope.close = modal.close;
        },
        controllerAs: 'ctrl',
        keyboard: false,
      });

      return true;
    }

    function deleteDiveSite(diveSiteID) {
      diveSiteService
        .delete(diveSiteID)
        .then(getShopList)
        .then(function () {
          $rootScope.showNotify('Dive site was succesfully deleted!');

          vm.isCreateNewInfoPopupShown = false;
          vm.isInfoPopupShown = false;
        })
        .catch(function (res) {
          $rootScope.showNotify(res.data.detail);
        });
    }

    function showHistory(id) {
      $uibModal.open({
        templateUrl: '../templates/_ds_history.html',
        controller: function ($scope, history) {
          $scope.history = history;
        },
        controllerAs: 'claimModal',
        keyboard: false,
        resolve: {
          history: function () {
            return diveSiteService.getHistory(id).then(function (res) {
              return res;
            });
          },
        },
      });
    }

    function fileUpload(files, kind, maxLength) {
      if (!_.isArray(vm.diveSiteToEdit[kind])) {
        vm.diveSiteToEdit[kind] = [];
      }

      if (files && vm.diveSiteToEdit[kind].length <= maxLength) {
        vm.errorPhoto[kind] = [];
        vm.errorFormat[kind] = [];

        _.each(files, function (file) {
          var acceptedFormat = _.find(
            ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
            function (v) {
              return v === file.type;
            }
          );

          if (!acceptedFormat) {
            return vm.errorFormat.push(file.name);
          }

          var randomId = parseInt(Math.random() * 1000000, 10);

          Upload.imageDimensions(file).then(
            function (d) {
              if (d.width < 800 || d.height < 600) {
                vm.errorPhoto[kind].push(file.name);
              } else if (vm.diveSiteToEdit[kind].length + 1 <= maxLength) {
                vm.disableLoading.push(randomId);

                vm.diveSiteToEdit[kind].push({
                  id: randomId,
                  absoluteImage: '/images/loading-photo.jpg',
                  type: 'loader',
                });

                diveSiteService.uploadPhotos(file, kind).then(
                  function (data) {
                    vm.diveSiteToEdit[kind] = _.reject(
                      vm.diveSiteToEdit[kind],
                      function (val) {
                        return val.id === randomId;
                      }
                    );

                    vm.diveSiteToEdit[kind].push(data);

                    if (!_.isArray(vm.diveSiteToEdit.images)) {
                      vm.diveSiteToEdit.images = [];
                    }
                    vm.diveSiteToEdit.images.push(data.id);

                    vm.disableLoading = _.without(vm.disableLoading, randomId);
                  },
                  function () {
                    vm.diveSiteToEdit[kind] = _.reject(
                      vm.diveSiteToEdit[kind],
                      function (val) {
                        return val.id === randomId;
                      }
                    );

                    vm.disableLoading = _.without(vm.disableLoading, randomId);
                  },
                  function (progress) {
                    _.each(vm.diveSiteToEdit[kind], function (val) {
                      if (val.id === randomId) {
                        val.progress = progress;

                        if (progress === 100) {
                          val.processing = true;
                        }
                      }
                    });
                  }
                );
              }
            },
            function (err) {
              // eslint-disable-next-line no-console
              console.err(err);
            }
          );
        });
      }

      return true;
    }

    function calculateStringLength() {
      if (!vm.diveSiteToEdit.description) {
        vm.trueDescriptionLength = 0;
        return true;
      }
      var additions =
          vm.diveSiteToEdit.description.match(/(\r\n|\n|\r)/g) || [],
        length = vm.diveSiteToEdit.description.length + additions.length;

      vm.trueDescriptionLength = length > 500 ? 500 : length;

      return true;
    }

    function saveDiveSite(form, status, versionType) {
      vm.drafting = status === 10;
      form.$setSubmitted();

      // checkbox must be selected
      if (
        !vm.alreadyAdopted &&
        vm.diveSiteToEdit.isAdopted &&
        !vm.diveSiteToEdit.aware
      ) {
        return false;
      }

      const photosLength =
        _.get(vm, 'diveSiteToEdit.photos.length') ||
        vm.drafting ||
        $rootScope.user.isStaff;
      if (form.$valid && photosLength) {
        vm.loading = true;

        vm.diveSiteToEdit.status = status;

        if (versionType) {
          vm.diveSiteToEdit.versionType = versionType;
        }

        if (vm.diveSiteToEdit.id) {
          diveSiteService
            .edit(vm.diveSiteToEdit.id, vm.diveSiteToEdit)
            .then(getShopList)
            .then(function () {
              $rootScope.showNotify('Dive site was successfully edited');
              vm.selectedDiveSite = {};
              vm.isCreateNewInfoPopupShown = false;
            })
            .catch(function () {
              vm.loading = false;
            });
        } else {
          diveSiteService
            .addDiveSite(vm.diveSiteToEdit)
            .then(function (res) {
              vm.diveSiteToEdit = {};
              vm.isCreateNewInfoPopupShown = false;
              if (vm.selectedDS.selected) {
                vm.toggleSkippedDS(res.id);
                vm.selectedDS.selected = false;
              }
              return getShopList();
            })
            .then(function () {
              $rootScope.showNotify(
                gettextCatalog.getString('Dive site was succesfully saved')
              );
            })
            .then(() => {
              shopService.getChecklistStatus().then(function (data) {
                $rootScope.checkedList = data;
              });
            })
            .catch(function () {
              vm.loading = false;
            });
        }
      }
      return true;
    }

    function toogleTopography() {
      vm.diveSiteToEdit.diveSiteType = [];
      _.each(vm.diveSiteToEdit.topography, function (val, key) {
        if (val) {
          var id = key.split('_');

          vm.diveSiteToEdit.diveSiteType.push(+id[1]);
        }
      });

      return true;
    }

    function addDiveSiteBtn() {
      closePopup();
      vm.selectedDiveSite = {};
      vm.alreadyAdopted = false;
      vm.diveSiteToEdit = {
        currentShopGoThere: false,
        isOwnPhotos: false,
        videos: [{ videoUrl: '' }],
        maximumDepth: {
          value: null,
          unit: 10,
        },
      };
      $timeout(function () {
        vm.isCreateNewInfoPopupShown = true;
        vm.isInfoPopupShown = false;
      }, 150);
      invalidateMapSize();

      return true;
    }

    function deletePhoto(id) {
      vm.diveSiteToEdit.images = _.without(vm.diveSiteToEdit.images, id);
      vm.diveSiteToEdit.map = _.reject(vm.diveSiteToEdit.map, {
        id: id,
      });
      vm.diveSiteToEdit.photos = _.reject(vm.diveSiteToEdit.photos, {
        id: id,
      });

      return true;
    }

    function mapMoved() {
      if (firstLoadingIsDone) {
        var bounds = map.getBounds(),
          center = map.getCenter();

        vm.options.leftBound =
          bounds._southWest.lat + ',' + bounds._southWest.lng;
        vm.options.rightBound =
          bounds._northEast.lat + ',' + bounds._northEast.lng;
        vm.options.center = center.lat + ',' + center.lng;

        getShopList(true);
      }
    }

    function invalidateMapSize() {
      $timeout(() => map.invalidateSize(), 300);
    }

    function searchMarineLife(text, selectedMarineLifes) {
      if (text.length < 3) {
        return false;
      }
      return diveGuideService.searchMarineLife(text).then((data) => {
        vm.marineLifeList = data.filter((airport) => {
          return !_.find(selectedMarineLifes, { id: airport.id });
        });
      });
    }

    function deleteVideo(key) {
      vm.diveSiteToEdit.videos.splice(key, 1);
    }

    function transformCoordinates(lat, lng) {
      return `${roundToSix(lat)}, ${roundToSix(lng)}`;
    }

    function roundToSix(num) {
      return +(Math.round(num + 'e+6') + 'e-6');
    }

    function rejectDiveSite(id) {
      $uibModal.open({
        animation: true,
        templateUrl: '../templates/_reject-dg-ds.html',
        controller: function ($uibModalInstance) {
          this.REJECT_REASON = info.dive_guide.REJECT_REASON;
          this.save = () => {
            this.loading = true;
            diveSiteService
              .rejectDiveSite(id, this.form)
              .then(() => {
                $rootScope.showNotify(gettextCatalog.getString('Rejected'));
                // clear forms
                vm.diveSiteToEdit = {};
                vm.isCreateNewInfoPopupShown = false;
                $uibModalInstance.close();
              })
              .finally(() => {
                this.loading = false;
              });
          };
          this.close = () => {
            $uibModalInstance.close();
          };
        },
        controllerAs: '$modal',
      });
    }

    $scope.$on('$locationChangeStart', () => {
      $rootScope.hideHeader = false;
    });
  });
