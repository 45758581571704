'use strict';

angular
  .module('app')
  .controller('FormsCtrl', function (formsService, $uibModal) {
    var vm = this;

    vm.files = [];
    vm.fileUpload = fileUpload;
    vm.removeFile = removeFile;

    getFiles();

    function getFiles() {
      vm.loading = true;
      formsService.getFiles().then((files) => {
        vm.files = files;
        vm.loading = false;
      });
    }

    function fileUpload(files) {
      if (files && files.length) {
        vm.loading = true;
        files.forEach((file) => {
          formsService
            .uploadFiles(file)
            .then(getFiles)
            .finally(() => {
              vm.loading = false;
            });
        });
      }
    }

    function removeFile(e, file) {
      e.preventDefault();

      return $uibModal.open({
        animation: true,
        templateUrl: '../templates/_delete-warning.html',
        controller: function ($uibModalInstance) {
          this.close = function () {
            $uibModalInstance.dismiss('close');
          };

          this.save = function () {
            vm.loading = true;
            formsService.deleteFile(file.id).then(getFiles);
            $uibModalInstance.dismiss('close');
          };
        },
        controllerAs: '$modal',
      });
    }
  });
