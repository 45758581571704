'use strict';

angular.module('app', [
  'ui.bootstrap',
  'ngRoute',
  'ngCookies',
  'leaflet-directive',
  'uiGmapgoogle-maps',
  'ui.select',
  'ngSanitize',
  'slick',
  'LocalStorageModule',
  'ngFileUpload',
  'vcRecaptcha',
  'dndLists',
  'summernote',
  'gettext',
]);

angular
  .module('app')
  .run(function (
    $rootScope,
    $location,
    localStorageCheckService,
    selectedLanguage,
    gettextCatalog,
    $route
  ) {
    $rootScope.error404 = false;

    if (selectedLanguage === 'ach') {
      gettextCatalog.setCurrentLanguage('ach_UG');
    } else if (selectedLanguage === 'zh-hans') {
      gettextCatalog.setCurrentLanguage('zh_CN');
    } else if (selectedLanguage === 'zh-hant') {
      gettextCatalog.setCurrentLanguage('zh_TW');
    } else if (selectedLanguage === 'ko') {
      gettextCatalog.setCurrentLanguage('ko_KR');
    } else if (selectedLanguage === 'ja') {
      gettextCatalog.setCurrentLanguage('ja_JP');
    } else {
      gettextCatalog.setCurrentLanguage(
        selectedLanguage + '_' + selectedLanguage.toLocaleUpperCase()
      );
    }

    var translationFileName = '';

    switch (selectedLanguage) {
    case 'de':
      translationFileName = 'angular-de.json';
      break;
    case 'fr':
      translationFileName = 'angular-fr.json';
      break;
    case 'it':
      translationFileName = 'angular-it.json';
      break;
    case 'es':
      translationFileName = 'angular-es.json';
      break;
    case 'nl':
      translationFileName = 'angular-nl.json';
      break;
    case 'ja':
      translationFileName = 'angular-ja.json';
      break;
    case 'ko':
      translationFileName = 'angular-ko.json';
      break;
    case 'zh-hans':
      translationFileName = 'angular-zh-hans.json';
      break;
    case 'zh-hant':
      translationFileName = 'angular-zh-hant.json';
      break;
    case 'ach':
      translationFileName = 'angular-ach.json';
      break;
    default:
      break;
    }

    if (selectedLanguage !== 'en') {
      gettextCatalog
        .loadRemote('/translations-extranet/' + translationFileName)
        .then(function () {
          $rootScope.translationsUploaded = true;
        });
    }

    // check if localstorase is supported
    localStorageCheckService.check();

    var original = $location.path;
    $location.pathWithoutReload = function (path, reload) {
      if (reload === false) {
        var lastRoute = $route.current,
          un = $rootScope.$on('$locationChangeStart', function (event) {
            window.history.replaceState({}, path, path);
            event.preventDefault();

            $route.current = lastRoute;
            un();
          });
      }
      return original.apply($location, [path]);
    };

    // Facebook Init
    // (function(d){
    //   var js,
    //   id = 'facebook-jssdk',
    //   ref = d.getElementsByTagName('script')[0];

    //   if (d.getElementById(id)) {
    //     return;
    //   }

    //   js = d.createElement('script');
    //   js.id = id;
    //   js.async = true;
    //   js.src = "//connect.facebook.net/en_US/sdk.js";

    //   ref.parentNode.insertBefore(js, ref);

    // }(document));
    // $window.fbAsyncInit = function() {
    //   FB.init({
    //     appId: info.facebookAppKey,
    //     status: true,
    //     cookie: true,
    //     xfbml: true,
    //     version: 'v2.3'
    //   });
    // };
  })
  .config(function (
    $locationProvider,
    $compileProvider,
    localStorageServiceProvider,
    $httpProvider,
    uiGmapGoogleMapApiProvider
  ) {
    localStorageServiceProvider
      .setPrefix('diviac')
      .setStorageType('sessionStorage')
      .setNotify(true, true);

    uiGmapGoogleMapApiProvider.configure({
      key: 'AIzaSyBe7Op5XruoNc6gOFo-nHQLQ-hPVl0xOFQ',
      v: '3.20', // defaults to latest 3.X anyhow
      libraries: 'weather,geometry,visualization',
    });
    $compileProvider.debugInfoEnabled(false);

    $locationProvider.html5Mode(true);
    $httpProvider.interceptors.push('authHttpResponseInterceptor');
    $httpProvider.defaults.withCredentials = true;
  });
