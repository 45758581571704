'use strict';

angular
  .module('app')
  .controller('BasicLaCtrl', function (
    $q,
    $scope,
    $timeout,
    $location,
    diveCenterService,
    $rootScope,
    utils,
    shopService,
    info,
    uiGmapGoogleMapApi,
    diviacLocalStorage,
    localStorageService,
    GTM
  ) {
    var vm = this;

    vm.form = {
      tags: [],
    };
    vm.countryList = info.countryList;
    vm.saveShop = saveShop;
    vm.loading = false;
    vm.activate = activate;
    vm.toggleParameters = toggleParameters;
    vm.getGroupsParams = diveCenterService.getAmenities;
    vm.setPart = setPart;
    vm.updateTags = () => {}; // mock

    activate();

    function saveShop(form) {
      if (form.$valid) {
        vm.loading = true;
        shopService
          .setInfo(vm.form)
          .then(function (data) {
            GTM.event('business_listing_basics_complete');
            GTM.variables({ business_listing_basics: 'Complete' });

            $rootScope.checkedList.basics = true;

            if ($rootScope.choosenShop.slug !== data.slug) {
              $rootScope.choosenShop.slug = data.slug;
            }

            diviacLocalStorage.set('choosenShop', $rootScope.choosenShop);
            localStorageService.set('choosenShop', $rootScope.choosenShop);
            vm.loading = false;
          })
          .then(function () {
            vm.boatSpec.yearBuilt = formatYear(vm.boatSpec.yearBuilt);
            vm.boatSpec.yearRenovated = formatYear(vm.boatSpec.yearRenovated);
            shopService.setBoatSpec(vm.boatSpec);
            shopService.setBoatParameters({ parameters: vm.shopParams });
            $location.path(utils.createURL('photos'));
          })
          .catch(function (error) {
            vm.loading = false;
            _.each(error.data, function (val, key) {
              if ($scope.extranetForm[key]) {
                $scope.extranetForm[key].$setValidity(key, false);
              }
            });
          });
      }
    }

    function activate() {
      GTM.event('business_listing_basics_start');
      vm.selectedParams = {};
      vm.loading = true;

      shopService.getLAGroups().then(function (data) {
        vm.groupList = data;
      });

      $q.all([
        shopService.getInfo(),
        shopService.getBoatParameters(),
        shopService.getBoatSpec(),
      ]).then(function (data) {
        vm.form = data[0];
        vm.boatSpec = data[2];

        vm.boatSpec.yearBuilt = getYear(vm.boatSpec.yearBuilt);
        vm.boatSpec.yearRenovated = getYear(vm.boatSpec.yearRenovated);

        vm.shopParams = data[1].parameters;
        _.each(data[1].parameters, function (val) {
          vm.selectedParams['selected_' + val] = true;
        });

        vm.loading = false;
      });
    }

    function toggleParameters(id) {
      var element = _.filter(vm.shopParams, function (elem) {
        return elem === id;
      });

      if (element.length) {
        vm.shopParams = _.reject(vm.shopParams, function (elem) {
          return elem === id;
        });
      } else {
        vm.shopParams.push(id);
      }
    }

    function setPart(item) {
      vm.form.belongsTo = item.id;
    }

    function formatYear(year) {
      if (!year) {
        return null;
      }

      year = year.toString();

      if (year.length <= 4) {
        year += '-01-01';
      }
      return year;
    }

    function getYear(date) {
      if (!date) {
        return null;
      }

      return moment(date).format('YYYY');
    }
  });
