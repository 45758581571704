'use strict';

angular.module('app').directive('numberMin', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            scope.$watch(attr.ngMin, function () {
                ctrl.$setViewValue(ctrl.$viewValue);
            });
            var minValidator = function (value) {
                var min = parseInt(attr.ngMin) || 0;

                if (_.isNumber(value) && parseFloat(value) < min) {
                    ctrl.$setValidity('ngMin', false);
                    return value;
                } else {
                    ctrl.$setValidity('ngMin', true);
                    return value;
                }
            };

            ctrl.$parsers.push(minValidator);
            ctrl.$formatters.push(minValidator);
        }
    };
}).directive('numberMax', function ($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            scope.$watch(attr.numberMax, function () {
                var val = ctrl.$viewValue;
                ctrl.$setViewValue('');

                $timeout(function(){
                    ctrl.$setViewValue(val);
                });
            });

            var maxValidator = function (value) {
                var max = scope.$eval(attr.numberMax) || Infinity;
                if (_.isNumber(value) && value > max) {
                    ctrl.$setValidity('numberMax', false);
                    return value;
                } else {
                    ctrl.$setValidity('numberMax', true);
                    return value;
                }
            };

            ctrl.$parsers.push(maxValidator);
            ctrl.$formatters.push(maxValidator);
        }
    };
});
