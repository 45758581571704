'use strict';

angular.module('app').directive('priceValidation', function () {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function(scope, elm, attr, ctrl){
        var regex=/^\d{1,18}(\.\d{1,2})?$/;

        ctrl.$parsers.push(function(val){
            if (val) {
                var price = val.replace(/[^\.\s0-9]/g, '');

                ctrl.$setValidity('validPrice',regex.test(price));

                ctrl.$setViewValue(price);
                ctrl.$render();

                return parseFloat(price);
            }

            return null;
        });
      }
    };
});
