'use strict';

angular
  .module('app')
  .service('DocumentsService', function (utils, Upload, Request) {
    var documents = {};

    documents.getInformation = function () {
      return Request.get(
        `shop/${utils.getCurrentShopSlug()}/trip-document/`
      ).then(function (res) {
        return res.data;
      });
    };

    documents.deleteFile = function (id, itineraries) {
      return Request.del(
        `shop/${utils.getCurrentShopSlug()}/trip-document/${id}`,
        { itineraries }
      ).then(function (res) {
        return res.data;
      });
    };

    documents.addItineraries = function (id, itineraries) {
      return Request.patch(
        `shop/${utils.getCurrentShopSlug()}/trip-document/${id}`,
        { itineraries }
      ).then(function (res) {
        return res.data;
      });
    };

    documents.postInformation = function (data) {
      const header = {
          'Content-Type': 'application/json',
        },
        url = `shop/${utils.getCurrentShopSlug()}/trip-document/`;

      return Upload.upload({
        method: 'POST',
        url: `${utils.apiURL}${url}`,
        headers: header,
        arrayKey: '',
        data: data,
      });
    };

    return documents;
  });
