'use strict';

angular.module('app').directive('hideContent', function ($timeout, $compile, gettextCatalog) {
  return {
    restrict: 'A',
    scope: {
      trigger: '=',
      height: '=',
    },
    link: function (scope, elem) {
      scope.showMoreText = gettextCatalog.getString('Show more');
      scope.showLessText = gettextCatalog.getString('Show less');
      
      var $elem = $(elem),
        buttonText = window.isSSR ? '{$ trigger ? showLessText : showMoreText $}' : '{{ trigger ? showLessText : showMoreText }}',
        showMore = $compile('<div><span class="more" ng-class="{ active: trigger}" ng-click="trigger = !trigger">' +  buttonText + '</span></div>')(scope);

      $timeout(function () {
        if ($elem.innerHeight() > (scope.height || 150)) {
          $elem.addClass('collapsed-content');

          $elem.parent().append(showMore);
        }
      });
    },
  };
});
