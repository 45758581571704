'use strict';

angular.module('app').directive('multipleDates', function($timeout) {
  return {
    restrict: 'A',
    require: "ngModel",
    scope: {
    },
    link: function(scope, elem, attr, ngModelCtrl) {
      jQuery.datepicker.setDefaults(jQuery.datepicker.regional['en']);

      $timeout(function() {
        var mdp = $(elem).multiDatesPicker({
            dateFormat: 'yy-mm-dd',
            numberOfMonths: 2,
            defaultDate: ngModelCtrl.$viewValue && ngModelCtrl.$viewValue[0],
            minDate: 0,
            onSelect: function(a,b,c) {
              ngModelCtrl.$setViewValue(mdp.multiDatesPicker('getDates'))
            }
        })

        if (ngModelCtrl.$viewValue) {
          mdp.multiDatesPicker('addDates', ngModelCtrl.$viewValue)
        }
      });
    }
  }
})
