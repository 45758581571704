'use strict';

angular.module('app').directive('singleDatepicker', function ($timeout, utils) {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      startDate: '=',
      allDates: '=',
      notPrefill: '=',
      minDate: '=',
      callback: '&callback',
      clearCallback: '&clearCallback',
      checkClear: '=',
      single: '=',
      closePopupBtn: '=',
      showOnload: '=',
      showYearMonth: '=',
    },
    link: function (scope, elem, attr, ngModelCtrl) {
      var localeLanguage = window.navigator.userLanguage || window.navigator.language,
        element = $(elem),
        randomId = _.random(10000, 50000),
        clearDate = '<a class="clear-date ' + (scope.checkClear ? 'single random-class' + randomId : '') + '" id="clear-datepicker-date" random="' + randomId + '">Clear dates</a>',
        showDpOnload = _.once(function () {
          $timeout(function () {
            if (element.is(':visible')) {
              element.datepicker('show');
            }
          }, 300);
        });

      if (utils.isMobile()) {
        element.attr('readonly', 'readonly');
      }

      var options = {
        numberOfMonths: window.innerWidth < 768 ? 1 : 2,
        firstDay: 1,
        minDate: scope.allDates ? new Date('1900-01-01') : new Date(),
        maxDate: scope.allDates ? new Date() : null,
        changeMonth: scope.showYearMonth || false,
        changeYear: scope.showYearMonth || false,
        yearRange: '-100:+25',
        onSelect: function (dateText) {
          $timeout(function () {
            var date = element.datepicker('getDate');
            if (date) {
              ngModelCtrl.$setViewValue(moment(date).format('YYYY-MM-DD'));

              if (scope.callback) {
                scope.$eval(scope.callback);
              }
            }
          });

          $timeout(function () {
            if (!element.datepicker('getDate')) {
              return true;
            }

            if (element.hasClass('js-from')) {
              element.parent().next().find('.js-to').focus();
            } else if (element.hasClass('js-to')) {
              var prev = element.parent().prev().find('.js-from');

              if (!prev.datepicker('getDate')) {
                prev.focus();
              }
            }
          }, 400);
        },
        beforeShowDay: function (date) {
          var dateString = moment(date).format('YYYY-MM-DD'),
            startDate,
            endDate;

          if (element.hasClass('js-from')) {
            endDate = element.parent().next().find('.js-to').datepicker('getDate');
            startDate = element.datepicker('getDate');
          } else if (element.hasClass('js-to')) {
            endDate = element.datepicker('getDate');
            startDate = element.parent().prev().find('.js-from').datepicker('getDate');
          }

          var selectedRange = [];

          if (startDate) {
            for (var d = _.clone(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
              selectedRange.push(moment(d).format('YYYY-MM-DD'));
            }
            var startDateNew = moment(startDate).format('YYYY-MM-DD');

            // highlight first date
            if (startDateNew === dateString) {
              return [true, 'highlighted active', ''];
            }
          }

          if (selectedRange.indexOf(dateString) == -1) {
            return [true, '', ''];
          }
          // highlight last date
          if (selectedRange.indexOf(dateString) == (selectedRange.length - 1)) {
            return [true, 'highlighted active', ''];
          }
          // highlight first date
          if (selectedRange.indexOf(dateString) == 0) {
            return [true, 'highlighted active', ''];
          }

          return [true, 'highlighted', ''];
        },
        beforeShow: function () {
          $timeout(function () {
            var endDate, startDate;
            if (element.hasClass('js-from')) {
              endDate = element.parent().next().find('.js-to').datepicker('getDate');
              startDate = element.datepicker('getDate');
            } else if (element.hasClass('js-to')) {
              endDate = element.datepicker('getDate');
              startDate = element.parent().prev().find('.js-from').datepicker('getDate');
            }

            var datepickerDiv = $('#ui-datepicker-div');

            if (scope.closePopupBtn) {
              $('<button>', {
                text: 'X',
                class: 'close-datepicker-btn',
                click: function () {
                  jQuery.datepicker._hideDatepicker();
                },
              }).appendTo(datepickerDiv);
            }

            if (scope.checkClear) {
              if (scope.single) {

              } else if ((endDate || startDate) && datepickerDiv.children('#clear-datepicker-date').length === 0) {
                datepickerDiv.append(clearDate);
              }
            } else if ((sessionStorage.getItem('diviac.date') || endDate || startDate) && datepickerDiv.children('#clear-datepicker-date').length === 0) {
              datepickerDiv.append(clearDate);
            }
          });

          if (element.hasClass('js-from')) {
            $(document).off('mouseenter mouseleave', '#ui-datepicker-div td');
          }
          if (element.hasClass('js-to')) {
            // highlight datepicker date on mouseenter
            $(document)
              .on('mouseenter', '#ui-datepicker-div td', function () {
                $(this).parent().addClass('finalRow');
                $('.finalRow').prevAll().find('td:not(.ui-datepicker-unselectable)').addClass('highlighted');
                $(this).prevAll('td:not(.ui-datepicker-unselectable)').addClass('highlighted');
                if ($(this).closest('.ui-datepicker-group').hasClass('ui-datepicker-group-last')) {
                  $(this).closest('.ui-datepicker').find('.ui-datepicker-group-first td:not(.ui-datepicker-unselectable)').addClass('highlighted');
                }
              })
              .on('mouseleave', '#ui-datepicker-div td', function () {
                $(this).parent().removeClass('finalRow');
                $('#ui-datepicker-div td').removeClass('highlighted');
                $('td.active').prevAll('td:not(.ui-datepicker-unselectable)').addClass('highlighted');
                $('td.active').parent().prevAll().find('td:not(.ui-datepicker-unselectable)').addClass('highlighted');
              });
          }
        },
      };

      scope.$watch('minDate', function (newVal, oldVal) {
        if (newVal && oldVal != newVal && newVal.length == 10) {
          // var tomorrow = new Date((moment(newVal).toDate()).setDate((new Date(newVal)).getDate() + 1));
          var tomorrow = moment(newVal).add(1, 'days').toDate();

          element.datepicker('option', 'minDate', tomorrow);

          $timeout(function () {
            var newDate = element.datepicker('getDate');
            if (newDate) {
              ngModelCtrl.$setViewValue(moment(newDate).format('YYYY-MM-DD'));
            }
          }, 100);
        } else if (_.isEmpty(newVal)) {
          element.datepicker('option', 'minDate', moment(new Date()).add(1, 'days').toDate());
        }
      });

      scope.$watch(function () {
        return ngModelCtrl.$modelValue;
      }, function (newVal, oldVal) {
        if (newVal && (newVal.length === 10 || newVal.length === 20)) {
          var date;

          try {
            date = jQuery.datepicker.parseDate(jQuery.datepicker.regional[localeLanguage].dateFormat, newVal);
          } catch (e) {
            // set new date if date in wrong format
            date = moment(newVal).toDate();
          }

          if (date != 'Invalid Date') {
            if (!element.datepicker('getDate')) {
              $timeout(function () {
                element.datepicker('setDate', date);
                ngModelCtrl.$setViewValue(moment(date).format('YYYY-MM-DD'));
              }, 100);
            } else {
              element.datepicker('setDate', date);
              ngModelCtrl.$setViewValue(moment(date).format('YYYY-MM-DD'));
            }
          } else if (newVal !== oldVal) {
            // $timeout(function() {
            // element.datepicker('setDate', new Date());
            // ngModelCtrl.$setViewValue('');
            // },100);
          }
        } else if (newVal != oldVal && newVal === '' || newVal === null) {
          $timeout(function () {
            ngModelCtrl.$setViewValue('');
            jQuery.datepicker._clearDate(element);
          }, 100);
        }
      });

      scope.$watch('startDate', function (val) {
        var date = new Date();

        if (scope.startDate) {
          date = moment(scope.startDate).toDate();
        }

        $timeout(function () {
          try {
            jQuery.datepicker.regional[localeLanguage].dateFormat;
          } catch (e) {
            // if no language in jQuery.datepicker.regional we set default 'en'
            localeLanguage = moment.locale('en');
          }

          options.dateFormat = jQuery.datepicker.regional[localeLanguage].dateFormat;

          element.datepicker(options).on('keydown', function (e) {
            // on enter click
            if (e.which === 13 || e.which === 9) {
              $('.ui-datepicker-current-day').click();
            }
          }).on('blur', function (e) {
            if (!jQuery.datepicker._datepickerShowing) {
              // $('.ui-datepicker-current-day').click();
            } else {

            }
          });

          jQuery.datepicker.setDefaults(jQuery.datepicker.regional.en);

          if (!scope.notPrefill && val) {
            element.datepicker('setDate', date);

            ngModelCtrl.$setViewValue(moment(date).format('YYYY-MM-DD'));
          }

          if (scope.showOnload && !element.datepicker('getDate')) {
            showDpOnload();
          }
        });
      });


      $(document).on('click', scope.checkClear ? '.clear-date.single.random-class' + randomId : '.clear-date:not(.single)', function () {
        ngModelCtrl.$setViewValue('');
        var second,
          element = $(elem);

        if (element.hasClass('js-from')) {
          second = element.parent().next().find('.js-to');
        } else if (element.hasClass('js-to')) {
          second = element.parent().prev().find('.js-from');
        }

        jQuery.datepicker._clearDate(element);
        if (second) {
          jQuery.datepicker._clearDate(second);
        }

        if (scope.clearCallback) {
          scope.clearCallback();
        }

        element.datepicker('hide');
      });
    },
  };
});
