'use strict';

angular.module('app')
  .service('itineraryService',
    function ($http, $rootScope, Request, utils, Upload) {
      var itinerary = {};

      itinerary.getItinerary = function (id) {
        return Request.get('shop/itineraries/' + id + '/').then(function (res) {
          return res.data;
        });
      };


      itinerary.saveItinerary = function (data, itineraryId) {
        data.shop = utils.getCurrentShopSlug();

        if (itineraryId) {
          return Request.patch('shop/itineraries/' + itineraryId + '/', data);
        }

        return Request.post('shop/itineraries/add/', data);
      };

      itinerary.deleteItinerary = function (itineraryId) {
        return Request.del('shop/itineraries/' + itineraryId + '/');
      };

      itinerary.getList = function () {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/itineraries/').then(function (res) {
          return res.data;
        });
      };

      itinerary.deletePhoto = function (id) {
        return Request.del('shop/itineraries/photos/' + id + '/').then(function (data) {
          return data;
        });
      };

      itinerary.setPhoto = function (data) {
        return Request.put('shop/itineraries/photos-ordering/', data).then(function (data) {
          return data;
        });
      };


      itinerary.uploadPhotos = function (photo) {
        var header = {
            'Content-Type': 'application/json',
          },
          token = localStorage.getItem('token');

        if (token) {
          header.Authorization = 'Bearer ' + token;
        }

        return Upload.upload({
          url: utils.apiURL + 'shop/itineraries/photos/add/',
          headers: header,
          arrayKey: '',
          method: 'POST',
          data: {
            image: photo,
          },
        }).then(function (res) {
          // SUCCESS EVENT
          return res.data;
        }, function (err) {
          // ERROR EVENT
          console.warn('ERROR: ', err);
          throw err;
        }, function (evt) {
          // PROGRESS EVENT
          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
          return progressPercentage;
        });
      };

      itinerary.getHarbours = function () {
        return Request.get('places/harbour/').then(function (res) {
          return res.data;
        });
      };

      itinerary.getHighligts = function (val) {
        return Request.get('places/highlight/', {country: val}).then(function (res) {
          return res.data;
        });
      };

      itinerary.getLocations = function (val) {
        return Request.get('places/location/', {country: val}).then(function (res) {
          return res.data;
        });
      };

      itinerary.addDay = function (data) {
        return Request.post('shop/itineraries/days/add/', data).then(function (res) {
          return res.data;
        });
      };

      itinerary.getAirport = function (val) {
        if (val.length < 3) {
          return 1;
        }
        return Request.get('places/airport/', {q: val}).then(function (res) {
          return res.data;
        });
      };

      itinerary.getOptions = function () {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/prices-basic/').then(function (res) {
          return res.data;
        });
      };

      itinerary.saveOptions = function (data) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/prices-basic/', data).then(function (res) {
          return res.data;
        });
      };

      itinerary.getCabins = function () {
        return Request.get('booking/' + utils.getCurrentShopSlug() + '/cabins/').then(function (res) {
          return res.data;
        });
      };

      itinerary.getTrips = function (page) {
        query = {};
        if (page) {
          query.page = page;
        }

        return Request.get('booking/' + utils.getCurrentShopSlug() + '/trips/', query).then(function (res) {
          var groupedProducts = {};


          _.each(res.data.results, function (data) {
            var month = moment(data.startDate).month(),
              year = moment(data.startDate).year();

            if (!groupedProducts[year + '_' + month]) {
              groupedProducts[year + '_' + month] = [];
            }

            groupedProducts[year + '_' + month].push(data);
          });

          return {
            groupedProducts: groupedProducts,
            trips: res.data.results,
            allTrips: res.data,
          };
        });
      };

      itinerary.getItineraryList = function (options) {
        return Request.get('booking/' + utils.getCurrentShopSlug() + '/itineraries/', options).then(function (res) {
          var result = _.sortBy(res.data.results, 'title');
          return {
            allList: res.data,
            paginatedList: result,
          };
        });
      };

      itinerary.saveItineraryPrice = function (data) {
        return Request.patch('booking/itineraries/' + data.id + '/', data).then(function (res) {
          return res.data;
        }).catch(function (error) {
          console.log(error);
        });
      };

      itinerary.update = function (id, data) {
        return Request.patch('shop/itineraries/' + id + '/', data).then(function (res) {
          return res.data;
        }).catch(function (error) {
          console.log(error);
        });
      };

      itinerary.saveTrip = function (trip) {
        return Request.patch('booking/trips/' + trip.pk + '/', trip).then(function (data) {
          return data;
        });
      };

      itinerary.getMarineLife = function () {
        return Request.get('shop/tags/marine-life/').then(function (res) {
          return res.data;
        });
      };

      itinerary.getThemes = function () {
        return Request.get('shop/tags/themes/').then(function (res) {
          return res.data;
        });
      };

      itinerary.getSponsors = function () {
        return Request.get('shop/tags/sponsors/').then(function (res) {
          return res.data;
        });
      };

      itinerary.getRoutes = function () {
        return Request.get('shop/tags/routes/').then(function (res) {
          _.each(res.data, function (val) {
            val.title = val.countries[0] + ' - ' + val.title;
          });
          return res.data;
        });
      };

      itinerary.getCore = function () {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/itineraries/core/').then(function (res) {
          return res.data;
        });
      };

      itinerary.saveCore = function (itinerary) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/itineraries/core/' + itinerary.id + '/', itinerary).then(function (res) {
          return res.data;
        });
      };

      itinerary.addCore = function (itinerary) {
        return Request.post('shop/' + utils.getCurrentShopSlug() + '/itineraries/core/', itinerary).then(function (res) {
          return res.data;
        });
      };

      itinerary.getBase = function () {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/itineraries/base/').then(function (res) {
          return res.data;
        });
      };

      itinerary.saveBase = function (itinerary) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/itineraries/base/' + itinerary.id + '/', itinerary).then(function (res) {
          return res.data;
        });
      };

      itinerary.getRoute = function () {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/itineraries/routes/').then(function (res) {
          return res.data;
        });
      };

      itinerary.saveRoute = function (itinerary) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/itineraries/routes/' + itinerary.id + '/', itinerary).then(function (res) {
          return res.data;
        });
      };

      itinerary.getTripStart = function () {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/itineraries/start/').then(function (res) {
          return res.data;
        });
      };

      itinerary.saveTripStart = function (itinerary) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/itineraries/start/' + itinerary.id + '/', prepareTripStartInfo(itinerary)).then(function (res) {
          return res.data;
        });
      };

      itinerary.getTripEnd = function () {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/itineraries/end/').then(function (res) {
          return res.data;
        });
      };

      itinerary.saveTripEnd = function (itinerary) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/itineraries/end/' + itinerary.id + '/', prepareTripEndInfo(itinerary)).then(function (res) {
          return res.data;
        });
      };

      itinerary.getLogistics = function (id) {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/itineraries/logistics/' + id + '/').then(function (res) {
          return res.data;
        });
      };

      itinerary.getLogisticsSwitch = function () {
        return Request.get('shop/' + utils.getCurrentShopSlug() + '/itineraries/logistics-switch/').then(function (res) {
          return res.data;
        });
      };

      itinerary.setLogisticsSwitch = function (itinerary) {
        return Request.put('shop/' + utils.getCurrentShopSlug() + '/itineraries/logistics-switch/' + itinerary.id + '/', itinerary).then(function (res) {
          return res.data;
        });
      };

      itinerary.addLogisticsItem = function (id, item) {
        item.itinerary = id;

        return Request.post('shop/' + utils.getCurrentShopSlug() + '/itineraries/logistics/' + id + '/', item).then(function (res) {
          return res.data;
        });
      };

      itinerary.updateLogisticsItem = function (id, item) {
        return Request.patch('shop/' + utils.getCurrentShopSlug() + '/itineraries/logistics/' + id + '/' + item.id + '/', item).then(function (res) {
          return res.data;
        });
      };

      itinerary.removeLogisticsItem = function (id, item) {
        return Request.del('shop/' + utils.getCurrentShopSlug() + '/itineraries/logistics/' + id + '/' + item.id + '/').then(function (res) {
          return res.data;
        });
      };

      itinerary.massDelete = function (items) {
        return Request.del('shop/itineraries/bulk/delete/', items, true).then(function (res) {
          return res.data;
        });
      };

      itinerary.massCopy = function (items) {
        return Request.put('shop/itineraries/bulk/copy/', items, true).then(function (res) {
          return res.data;
        });
      };

      itinerary.previewFull = function (items) {
        return Request.get('shop/itineraries/bulk/preview-full/', items).then(function (res) {
          return res.data;
        });
      };

      itinerary.previewLogistics = function (items) {
        return Request.get('shop/itineraries/bulk/preview-logistics/', items).then(function (res) {
          return res.data;
        });
      };

      itinerary.massFill = function (items) {
        return Request.put('shop/itineraries/bulk/mass-fill/', items, true).then(function (res) {
          return res.data;
        });
      };

      itinerary.copyLogistics = function (items) {
        return Request.put('shop/itineraries/bulk/logistics-copy/', items, true).then(function (res) {
          return res.data;
        });
      };

      function prepareTripStartInfo(obj) {
        var data = _.clone(obj);

        if (data.isOtherCheckin) {
          data.checkInTo = null;
          data.checkInFrom = null;
        } else  {
          data.checkInOther = null;
        }

        if (data.isOtherTime) {
          data.timeDeparture = null;
          data.timeDepartureTo = null;
        } else {
          data.timeDepartureOther = null;
        }

        if (data.isAirportOther) {
          data.airportPickupTo = null;
          data.airportPickupFrom = null;
        } else {
          data.airportPickupOther = null;
        }

        if (data.isHotelPickupOther) {
          data.hotelPickupTo = null;
          data.hotelPickupFrom = null;
        } else {
          data.hotelPickupOther = null;
        }

        // data.timeDeparture = utils.formatDateTime(data.timeDeparture);
        // data.timeDepartureTo = utils.formatDateTime(data.timeDepartureTo);
        // data.checkInTo = utils.formatDateTime(data.checkInTo);
        // data.checkInFrom = utils.formatDateTime(data.checkInFrom);
        // data.airportPickupTo = utils.formatDateTime(data.airportPickupTo);
        // data.airportPickupFrom = utils.formatDateTime(data.airportPickupFrom);
        // data.hotelPickupTo = utils.formatDateTime(data.hotelPickupTo);
        // data.hotelPickupFrom = utils.formatDateTime(data.hotelPickupFrom);

        return data;
      }

      function prepareTripEndInfo(obj) {
        var data = _.clone(obj);

        if (data.isOtherDisembark) {
          data.guestDisembarkTo = null;
          data.guestDisembarkFrom = null;
        } else  {
          data.guestDisembarkOther = null;
        }

        if (data.isOtherTime) {
          data.timeArrival = null;
          data.timeArrivalTo = null;
        } else {
          data.timeArrivalOther = null;
        }

        if (data.isAirportOther) {
          data.dropoffAirportTo = null;
          data.dropoffAirportFrom = null;
        } else {
          data.dropoffAirportOther = null;
        }

        if (data.isHotelOther) {
          data.dropoffHotelTo = null;
          data.dropoffHotelFrom = null;
        } else {
          data.dropoffHotelOther = null;
        }

        // data.guestDisembarkTo = utils.formatDateTime(data.guestDisembarkTo);
        // data.guestDisembarkFrom = utils.formatDateTime(data.guestDisembarkFrom);
        // data.timeArrival = utils.formatDateTime(data.timeArrival);
        // data.timeArrivalTo = utils.formatDateTime(data.timeArrivalTo);
        // data.dropoffAirportTo = utils.formatDateTime(data.dropoffAirportTo);
        // data.dropoffAirportFrom = utils.formatDateTime(data.dropoffAirportFrom);
        // data.dropoffHotelTo = utils.formatDateTime(data.dropoffHotelTo);
        // data.dropoffHotelFrom = utils.formatDateTime(data.dropoffHotelFrom);

        return data;
      }

      return itinerary;
    });
