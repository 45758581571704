'use strict';

angular
  .module('app')
  .controller('BookingDetails', function (
    $routeParams,
    $location,
    $rootScope,
    bookings,
    info,
    utils,
    gettextCatalog
  ) {
    var vm = this;
    vm.uuid = $routeParams.uuid;
    vm.getFromInfoById = getFromInfoById;
    vm.displayBoolean = displayBoolean;
    vm.apiUrl = utils.apiURL;
    vm.getFromInfo = utils.getFromInfo;
    vm.formats = [
      {
        format: 'csv',
      },
      {
        format: 'xlsx',
      },
    ];

    bookings.getDetails(vm.uuid).then(function (response) {
      // vm.bookings = response.results;
      vm.guests = response;
      vm.guests = _.sortBy(vm.guests, function (guest) {
        return !guest.isLead;
      });

      _.each(vm.guests, function (guest) {
        _.each(guest, function (val, key) {
          if (!val && !_.isBoolean(val)) {
            guest[key] = '—';
          }
        });
      });
    });

    function getFromInfoById(collection, id, key) {
      var res = _.find(info[collection], { id: id });
      if (res) {
        return res[key];
      }
      return '—';
    }

    function displayBoolean(val) {
      if (_.isBoolean(val)) {
        return val
          ? gettextCatalog.getString('Yes')
          : gettextCatalog.getString('No');
      }
      return '—';
    }

    vm.loading = false;
  });
