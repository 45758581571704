"use strict";

angular.module('app').filter('localeDate', function () {
    return function (input, notUtc) {
        var localeLanguage = window.navigator.userLanguage || window.navigator.language,
            dateFormat = jQuery.datepicker.regional[localeLanguage] ? jQuery.datepicker.regional[localeLanguage].dateFormat : 'en';
        
        if (notUtc) {
            return input ? jQuery.datepicker.formatDate(dateFormat, moment(input).toDate()) : '-';
        }
        
        return input ? jQuery.datepicker.formatDate(dateFormat, moment(moment(input).utc().format('YYYY-MM-DD')).utc().toDate()) : '-';
    };
});