'use strict';

angular.module('app')
  .controller('DiveSitesCtrl', function ($scope, $location, $rootScope, diveSiteService, GTM, utils, gettextCatalog) {
    var vm = this;

    vm.saveInfo = saveInfo;
    vm.toggleTop5 = toggleTop5;
    vm.toggleSkippedDS = toggleSkippedDS;
    vm.selectAll = selectAll;
    vm.calculateStringLength = calculateStringLength;
    vm.toggleParameters = toggleParameters;
    vm.needTranslate = ['dive_site_description'];
    vm.goToDiveSite = goToDiveSite;


    activate();

    function activate() {
      GTM.event('business_listing_dive_sites_start');
      // GTM.variables({'business_listing_dive_sites': "Start"});

      vm.top5 = [];
      vm.selectedDS = [];
      vm.allSelected = true;
      vm.loading = false;

      diveSiteService.getInfo().then(function (data) {
        if(utils.isProDiver()) {
          vm.form = {
            diveSitesGoToData: data
          }
        } else {
          vm.allDiveSites = data.allDiveSites;

          if (utils.isArrayContainElementsFromOtherArray(data.autoTranslatedFields, vm.needTranslate)) {
            $rootScope.showNotify(gettextCatalog.getString('The text fields on this page have been auto-translated using Google Translate. You may edit them manually if you wish.'));
          }
  
  
          vm.form = data;
  
          _.each(vm.form.highlights, function (val) {
            var selectedElem = _.find(vm.form.closeHighlights, {id: val});
  
            if (selectedElem) {
              selectedElem.checked = true;
            }
          });
          calculateStringLength();
          _.forEach(vm.form.top_5_diveSites, function (val) {
            vm.top5['selected'  + val] = true;
          });
          _.forEach(vm.form.allDiveSites, function (val) {
            vm.selectedDS['selected' + val.id] = true;
            _.forEach(vm.form.skippedDiveSites, function (v) {
              if (v == val.id) {
                vm.selectedDS['selected' + val.id] = false;
                vm.allSelected = false;
              }
            });
          });
        }
      });
    }

    function saveInfo(form) {
      if (form.$valid) {
        vm.loading = true;
        diveSiteService.setInfo(vm.form).then(function () {
          GTM.event('business_listing_dive_sites_complete');
          GTM.variables({'business_listing_dive_sites': 'Complete'});

          $rootScope.checkedList.diveSites = true;
          vm.loading = false;
          if (utils.isProDiver()) {
            $location.path(utils.createURL('dive_guides'));
          } else {
            $location.path(utils.createURL('group_trips'));
          }
        }).catch(function (error) {
          _.each(error.data, function (val, key) {
            $scope.extranetForm[key].$setValidity('required', false);
          });
        });
      }
    }

    function toggleTop5(id) {
      var element = _.filter(vm.form.top_5_diveSites, function (elem) {
        return elem === id;
      });

      if (element.length) {
        vm.form.top_5_diveSites = _.reject(vm.form.top_5_diveSites, function (elem) {
          return elem === id;
        });
      } else {
        vm.form.top_5_diveSites.push(id);
      }

      if (vm.top5['selected' + id]) {
        vm.selectedDS['selected' + id] = true;
        vm.form.skippedDiveSites = _.reject(vm.form.skippedDiveSites, function (elem) {
          return elem === id;
        });
      }
    }

    function toggleSkippedDS(id) {
      var element = _.filter(vm.form.skippedDiveSites, function (elem) {
        return elem === id;
      });
      if (element.length) {
        vm.form.skippedDiveSites = _.reject(vm.form.skippedDiveSites, function (elem) {
          return elem === id;
        });
      } else {
        vm.form.skippedDiveSites.push(id);
        vm.allSelected = false;
      }


      if (!vm.selectedDS['selected' + id]) {
        vm.top5['selected' + id] = false;
        vm.form.top_5_diveSites = _.reject(vm.form.top_5_diveSites, function (elem) {
          return elem === id;
        });
      }
    }

    function selectAll() {
      if (vm.allSelected) {
        vm.form.skippedDiveSites = [];

        _.forEach(vm.form.allDiveSites, function (val) {
          vm.selectedDS['selected' + val.id] = true;
        });
      } else {
        _.forEach(vm.form.allDiveSites, function (val) {
          vm.selectedDS['selected' +  val.id] = false;
          vm.top5['selected' +  val.id] = false;

          vm.form.top_5_diveSites = [];

          vm.form.skippedDiveSites.push(val.id);
        });
      }
    }

    function calculateStringLength() {
      if (!vm.form.diveSiteDescription) {
        vm.trueDescriptionLength = 0;
        return true;
      }
      var additions = vm.form.diveSiteDescription.match(/(\r\n|\n|\r)/g) || [],
        length = vm.form.diveSiteDescription.length + additions.length;

      vm.trueDescriptionLength = length > 500 ? 500 : length;
    }

    function toggleParameters(params) {
      if (!_.isArray(vm.form.highlights)) {
        vm.form.highlights = [];
      }

      var index = vm.form.highlights.indexOf(params.id);

      if (index !== -1) {
        vm.form.highlights.splice(index, 1);
      } else {
        vm.form.highlights.push(params.id);
      }

      diveSiteService.setInfo({highlights: vm.form.highlights});
    }

    function goToDiveSite(id) {
      $location.path(utils.createURL('dive_sites_list')).search({diveSiteID: id});
    }
  });
