angular.module('app')
    .directive('placeholder', function(gettextCatalog, $rootScope, $timeout){
    return {
      restrict: 'A',
      link: function(scope, element, attrs){
        translate();
        var translationListener = $rootScope.$watch('translationsUploaded', function (newVal) {
          if (newVal) {
            translate()
            translationListener()
          }
        })

        function translate() {
          $timeout(function() {
            var translatedPlaceholder = gettextCatalog.getString(attrs.placeholder);
            element.attr('placeholder', translatedPlaceholder);
          })
        }
      }
    };
  });