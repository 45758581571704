'use strict';

angular
  .module('app')
  .service('diveSiteService', function (
    $q,
    Request,
    info,
    $rootScope,
    utils,
    Upload,
    gettextCatalog,
    diveGuideService
  ) {
    var diveSites = {},
      spCounter = 1,
      DSpromise = false;

    const multipleChoiceFields = ['marineLifeSightingsWrite', 'usefulCourses'],
      checkboxFields = [
        'diveSiteTypes',
        'suggestedEquipments',
        'waterEntries',
        'waterTypes',
      ];

    diveSites.getInfo = () => {
      const currentShop = utils.getCurrentShopSlug(),
        url =
          utils.isProDiver() || !currentShop
            ? 'account/dive-sites/'
            : `shop/${currentShop}/dive-sites/`;
      return Request.get(url).then((res) => {
        return res.data;
      });
    };

    diveSites.getDiveSites = function () {
      return Request.get(
        'shop/' + utils.getCurrentShopSlug() + '/dive-sites-to-go/'
      ).then(function (res) {
        return res.data;
      });
    };

    diveSites.getDiveSite = (diveSiteID, lang, stable, userVersion) => {
      const currentShop = utils.getCurrentShopSlug();
      let url =
        utils.isProDiver() || !currentShop
          ? `places/dive-sites/${diveSiteID}/`
          : `shop/${currentShop}/dive-sites/${diveSiteID}/`;

      if (userVersion) {
        url = `places/dive-sites/${diveSiteID}/user/`;
      }

      if (stable) {
        url = `places/dive-sites/${diveSiteID}/stable/`;
      }
      return Request.get(url, { lang }).then(({ data }) => {
        if (data.diveSiteTypes) {
          const diveSiteTypesList = info.adventureDiveSite.diveSiteTypes;
          data.typeVerbose = data.diveSiteTypes.reduce((acc, val) => {
            if (acc.length) {
              acc += ', ';
            }
            if (val) {
              const type = _.find(diveSiteTypesList, { id: val });

              if (type) {
                acc += type.title;
              }
            }
            return acc;
          }, '');
        }

        data.versionVerbose = diveGuideService.setVerboseVersion(data);
        if (data.usefulCourses) {
          data.usefulCourses = data.usefulCourses.map((item) =>
            _.find(info.adventureDiveSite.diveSiteTrainingCourses, {
              id: +item,
            })
          );
        }

        const videosLength = _.get(data, 'videos.length');
        if (!videosLength) {
          // eslint-disable-next-line no-undefined
          data.videos = [{ videoUrl: undefined }];
        } else {
          data.videos = data.videos.map((videoUrl) => ({ videoUrl }));
        }

        if (data.marineLifeSightings) {
          data.marineLifeSightingsWrite = data.marineLifeSightings;
        }

        checkboxFields.forEach((fieldName) => {
          if (data[fieldName]) {
            let formattedValues = {};
            data[fieldName].forEach((val) => (formattedValues[val] = true));

            data[fieldName] = formattedValues;
          }
        });

        data.isOwnPhotos = false;

        return data;
      });
    };

    diveSites.setInfo = function (data, hideMsg) {
      return Request.patch(
        'shop/' + utils.getCurrentShopSlug() + '/dive-sites/',
        data
      ).then(function (res) {
        if (!hideMsg) {
          $rootScope.showNotify(
            gettextCatalog.getString('Information was successfully saved')
          );
        }
        return res.data;
      });
    };

    diveSites.getShopList = function (coords) {
      var localCounter = spCounter;

      if (DSpromise) {
        DSpromise.resolve(false);
      }

      DSpromise = $q.defer();

      const slug = utils.getCurrentShopSlug(),
        url =
          utils.isProDiver() || !slug
            ? 'places/dive-sites/'
            : `shop/${slug}/dive-sites/list/`;

      Request.get(url, coords, false, DSpromise).then(function (res) {
        if (localCounter === spCounter - 1) {
          DSpromise.resolve(res.data);
        } else {
          DSpromise.reject('data is no more actual');
        }
      });

      spCounter += 1;

      return DSpromise.promise;
    };

    diveSites.getHistory = function (slug) {
      return Request.get('places/dive-sites/' + slug + '/history/').then(
        function (res) {
          return res.data;
        }
      );
    };

    diveSites.merge = function (data) {
      return Request.post('places/dive-sites/merge/', data).then(function (
        res
      ) {
        return res.data;
      });
    };

    diveSites.calculateLength = function (data) {
      if (!data) {
        return 0;
      }
      var additions = data.match(/(\r\n|\n|\r)/g) || [],
        length = data.length + additions.length;

      return length > 500 ? 500 : length;
    };

    diveSites.addDiveSite = (data) => {
      data.createdByShop = utils.getCurrentShopId();
      const dsInfo = transformData(data);
      return Request.post('places/dive-sites/add/', dsInfo).then((res) => {
        return res.data;
      });
    };

    diveSites.delete = (diveSiteID) => {
      return Request.del(
        `shop/${utils.getCurrentShopSlug()}/dive-sites/${diveSiteID}/`
      ).then((res) => {
        return res.data;
      });
    };

    diveSites.edit = (diveSiteID, data) => {
      const dsInfo = transformData(data);

      dsInfo.basedOnId = diveSiteID;

      // const url = utils.isProDiver() ? `places/dive-sites/${diveSiteID}/` : `shop/${utils.getCurrentShopSlug()}/dive-sites/${diveSiteID}/`
      return Request.post('places/dive-sites/add/', dsInfo).then((res) => {
        return res.data;
      });
    };

    diveSites.uploadPhotos = function (photo, kind) {
      var header = {
          'Content-Type': 'application/json',
        },
        token = localStorage.getItem('token');

      if (token) {
        header.Authorization = 'Bearer ' + token;
      }

      return Upload.upload({
        url: utils.apiURL + 'places/dive-sites/photo/add/',
        headers: header,
        arrayKey: '',
        method: 'POST',
        data: {
          is_additional: kind === 'additionalPhotos',
          image: photo,
        },
      }).then(
        function (res) {
          // SUCCESS EVENT
          return res.data;
        },
        function (err) {
          // ERROR EVENT
          console.warn('ERROR: ', err);
          throw err;
        },
        function (evt) {
          // PROGRESS EVENT
          var progressPercentage = parseInt(
            (100.0 * evt.loaded) / evt.total,
            10
          );
          return progressPercentage;
        }
      );
    };

    diveSites.rejectDiveSite = (id, values) =>
      Request.put(`places/dive-sites/${id}/reject/`, values).then(
        ({ data }) => data
      );

    function transformData(data) {
      const contibution = utils.getContributedBy();
      let transformedData = {
        ...data,
        ...contibution,
      };

      if (data.isAdopted) {
        if (contibution.contributedByType === 'account') {
          transformedData.adoptedBy = contibution.contributedBy;
        } else {
          transformedData.adoptedByShop = contibution.contributedBy;
        }
      }

      if (transformedData.videos && transformedData.videos.length) {
        let videos = [];
        // i'm not using map here, because I can't send empty videoURL. But angular generate field as empty string on init
        transformedData.videos.forEach((video) => {
          if (video.videoUrl) {
            videos.push(video.videoUrl);
          }
        });
        transformedData.videos = videos;
      }

      multipleChoiceFields.forEach((field) => {
        if (data[field]) {
          transformedData[field] = data[field].map((item) => item.id);
        }
      });

      checkboxFields.forEach((fieldName) => {
        const field = data[fieldName];
        if (field) {
          transformedData[fieldName] = [];
          _.each(field, (selected, key) => {
            if (selected) {
              transformedData[fieldName].push(+key);
            }
          });
        }
      });

      return transformedData;
    }

    return diveSites;
  });
