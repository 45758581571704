'use strict';

angular.module('app').directive('setLanguageMeta', function ($rootScope, utils) {
  function link() {
    var LANGUAGES = _.clone(window.LANGUAGES);

    LANGUAGES.push('x-default');

    $rootScope.$on('$viewContentLoaded', function () {
      _.each(LANGUAGES, function (val) {
        var metaLang = $('link[hreflang="' + val + '"]');

        if (metaLang.length) {
          metaLang.attr('href', window.location.origin + utils.generateLangUrl(val === 'x-default' ? 'en' : val));
        } else {
          $('head').append('<link rel="alternate" hreflang="' + val + '" href="' + window.location.origin + utils.generateLangUrl(val === 'x-default' ? 'en' : val) + '" />');
        }
      });
    });
  }

  return {
    restrict: 'A',
    link: link,
  };
});
