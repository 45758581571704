'use strict';

angular.module('app').directive('jscolor', function ($timeout) {
    return {
      restrict: 'A',
      scope: {
          jscolor: "@"
      },
      link: function (scope, element, attr) {
          jscolor.installByClassName(scope.jscolor);
      }
    };
});
