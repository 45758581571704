'use strict';

angular.module('app').service('bookings', function (Request, utils) {
  var bookings = {};

  bookings.getBookingList = function (page, filters) {
    var filterData = {
      page_size: 50,
      page: page || null,
      search: filters.search || null,
      date_from_after: filters.dateStart || null,
      date_from_before: filters.dateEnd || null,
      ordering: filters.ordering || null,
    };
    return Request.get(
      'checkout/' + utils.getCurrentShopSlug() + '/bookings/',
      filterData
    ).then(function (res) {
      return {
        bookings: res.data.results,
        allBookings: res.data,
      };
    });
  };

  bookings.getTripList = function (page, tripID) {
    var filterData = {
      page_size: 10,
      page: page || null,
      id: tripID || null,
    };
    return Request.get(
      'checkout/' + utils.getCurrentShopSlug() + '/trips/',
      filterData
    ).then(function (res) {
      return {
        trips: res.data.results,
        allTrips: res.data,
      };
    });
  };

  bookings.getTripListForFilter = function () {
    return Request.get(
      'checkout/' + utils.getCurrentShopSlug() + '/trips/'
    ).then(function (res) {
      return res.data;
    });
  };

  bookings.getTripBookings = function (tripID) {
    return Request.get(
      'checkout/' +
        utils.getCurrentShopSlug() +
        '/trips/' +
        tripID +
        '/bookings/'
    ).then(function (res) {
      return res.data;
    });
  };

  bookings.getDetails = function (uuid) {
    return Request.get('checkout/bookings/' + uuid + '/guests/').then(function (
      res
    ) {
      return res.data;
    });
  };

  bookings.getFieldsToManage = function () {
    return Request.get(
      'shop/' + utils.getCurrentShopSlug() + '/gis-fields/'
    ).then(function (res) {
      return res.data;
    });
  };

  bookings.updateGisField = function (data) {
    return Request.put(
      'shop/' + utils.getCurrentShopSlug() + '/gis-fields/',
      data
    ).then(function (res) {
      return res.data;
    });
  };

  bookings.updateGisSection = function (data) {
    return Request.put(
      'shop/' + utils.getCurrentShopSlug() + '/gis-sections/',
      data
    ).then(function (res) {
      return res.data;
    });
  };

  bookings.getGuestFormLink = function () {
    return Request.get(
      'shop/' + utils.getCurrentShopSlug() + '/guest-form-link/',
    ).then(function (res) {
      return res.data;
    });
  };


  bookings.setGuestFormLink = function (data) {
    return Request.put(
      'shop/' + utils.getCurrentShopSlug() + '/guest-form-link/',
      {guestFormLink: data}
    ).then(function (res) {
      return res.data;
    });
  };

  return bookings;
});
