'use strict';

angular.module('app')
  .service('landing',
    function ($http, Request, utils) {
      var landing = {};

      landing.getInfo = function () {
        return Request.get('info/').then(function (res) {
          return res.data;
        });
      };

      landing.search = function (value) {
        return Request.get('shop/autosuggest/', { search: value }).then(function (req) {
          return req.data;
        }, function (err) {
          return err;
        });
      };

      landing.checkUserStatus = function (shopSlug) {
        return Request.get('shop/' + shopSlug + '/status/').then(function (res) {
          return res.data;
        }, function (err) {
          return err;
        });
      };

      return landing;
    });
