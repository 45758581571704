'use strict';

angular.module('app')
  .service('Account', function ($http, $location, $q, $rootScope, Request,  diviacLocalStorage, localStorageService, GTM, utils) {
    var Account = {};


    Account.getPreferences = function () {
      var q = $q.defer();

      if (diviacLocalStorage.get('userSettings')) {
        q.resolve(diviacLocalStorage.get('userSettings'));

        Account.applySettings();
      } else if (!diviacLocalStorage.get('userSettings')) {
        Request.get('account/settings/travel-settings/').then(function (res) {
          diviacLocalStorage.set('userSettings', res.data);

          q.resolve(res.data);

          GTM.variables({
            user_currency: res.data.currency ? res.data.currency : 'USD',
            user_default_measurements: res.data.distanceMeasurement == 0 ? 'Metric' : 'Imperial',
            user_default_temperature: res.data.temperature == 0 ? 'Celsius' : 'Fahrenheit',
            user_preferred_departure_airport: res.data.closestAirport ? res.data.closestAirport : 'Not set',
          });

          Account.applySettings();
        });
      }

      return q.promise;
    };

    Account.setPreferences = function (data, showMsg) {
      return Request.patch('account/settings/travel-settings/', data).then(function (res) {
        if (showMsg) {
          $rootScope.showNotify('Your settings successfully updated');
        }

        diviacLocalStorage.set('userSettings', res.data);

        Account.applySettings();

        return res.data;
      });
    };

    Account.applySettings = function () {
      var settings = diviacLocalStorage.get('userSettings');

      if (settings) {
        $rootScope.currency = utils.getCookie('currency_code') || settings.currency || 'USD';
        localStorageService.set('currency', $rootScope.currency);
        $rootScope.distance = settings.distanceMeasurement;
        $rootScope.temperature = settings.temperature;
      }
    };

    Account.changePassword = function (data) {
      return Request.post('account/password/change/', data).then(function (data) {
        return true;
      }, function (error) {
        throw Account.handleError(error);
      });
    };

    Account.changeEmail = function (data) {
      return Request.post('account/email/change/', data).then(function (res) {
        var user = diviacLocalStorage.get('user');
        user.email = data.newEmail;

        localStorage.setItem('token', res.data.token);
        diviacLocalStorage.set('user', user);

        $rootScope.user = user;
        return data.newEmail;
      }, function (error) {
        throw Account.handleError(error);
      });
    };

    Account.handleError = function (error) {
      if (error.data) {
        var messages = [];

        _.each(error.data, function (val) {
          messages.push(val[0]);
        });

        return messages;
      }
    };

    return Account;
  });
