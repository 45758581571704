(function () {
  'use strict';

  window.LANGUAGES = [
    'en',
    'de',
    'fr',
    'it',
    'es',
    'nl',
    'ja',
    'ko',
    'zh-hans',
    'zh-hant',
    'other',
    'ach'
  ];

  _.once(function () {
    // fetch info data before start app
    var $http = angular.injector(['ng']).get('$http'),
      isLocalDev = _.find(
        ['diviaclocal', '192.168', 'localhost'],
        function (str) {return _.contains(window.location.host, str);}
      ),
      isDev = _.find(
        ['nearlydone.rocks'],
        function (str) {return _.contains(window.location.host, str);}
      ),
      isInprogress = _.find(
        ['inprogress.rocks'],
        function (str) {return _.contains(window.location.host, str);}
      ),
      isStage = _.find(['comingsoon.rocks'], function (str) { return _.includes(window.location.host, str); }),
      isStageCN = _.find(['travel-qa.padi.com.cn'], function (str) { return _.includes(window.location.host, str); }),
      server = '//travel.padi.com/api/v2/extranet/',
      aud = '503u87mvc25i53veank5m32e6i',
      redirectUri = 'https://travel.padi.com',
      cognitoURL = 'https://account.padi.com/login/sign-in?client_id=503u87mvc25i53veank5m32e6i',
      loader = angular.element(document.getElementById('first-loader'));

    if (isLocalDev) {
      aud = '7u0q6o7jbqck26k2adbdu6qbjk';
      server = 'https://travel.padi.localhost/api/v2/extranet/';
      redirectUri = 'https://travel.padi.localhost';
      cognitoURL = 'https://account.global-np.padi.com/login/sign-in/en-US?client_id=7u0q6o7jbqck26k2adbdu6qbjk';
    } else if (isStage) {
      aud = '7u0q6o7jbqck26k2adbdu6qbjk';
      cognitoURL = 'https://account.global-np.padi.com/login/sign-in/en-US?client_id=7u0q6o7jbqck26k2adbdu6qbjk';
      redirectUri = 'https://travel.padi.comingsoon.rocks';
      server = 'https://travel.padi.comingsoon.rocks/api/v2/extranet/';
    } else if (isStageCN) {
      aud = '7u0q6o7jbqck26k2adbdu6qbjk';
      cognitoURL = 'https://account.global-np.padi.com/login/sign-in/en-US?client_id=7u0q6o7jbqck26k2adbdu6qbjk';
      redirectUri = 'https://travel.travel-qa.padi.com.cn';
      server = 'https://travel.travel-qa.padi.com.cn/api/v2/extranet/';
    } else if (isDev) {
      aud = '7u0q6o7jbqck26k2adbdu6qbjk';
      cognitoURL = 'https://account.global-np.padi.com/login/sign-in/en-US?client_id=7u0q6o7jbqck26k2adbdu6qbjk';
      redirectUri = 'https://travel.padi.nearlydone.rocks';
      server = 'https://travel.padi.nearlydone.rocks/api/v2/extranet/';
    } else if (isInprogress) {
      aud = '7u0q6o7jbqck26k2adbdu6qbjk';
      cognitoURL = 'https://account.global-np.padi.com/login/sign-in/en-US?client_id=7u0q6o7jbqck26k2adbdu6qbjk';
      redirectUri = 'https://travel.padi.inprogress.rocks';
      server = 'https://travel.padi.inprogress.rocks/api/v2/extranet/';
    }

    var localStorageIsSupported = true;
    if (typeof localStorage === 'object') {
      try {
        localStorage.setItem('localStorage', 1);
        localStorage.removeItem('localStorage');
        localStorageIsSupported = true;
      } catch (e) {
        localStorageIsSupported = false;
      }
    }

    if (localStorageIsSupported && localStorage.getItem('testMode') && localStorage.getItem('testMode') === 'test') {
      server = 'https://travel.padi.comingsoon.rocks/api/v2/extranet/';
    } else {
      var storage = angular.injector(['ngCookies']).get('$cookies');

      if (storage.get('testMode') && storage.get('testMode') === 'test') {
        server = 'https://travel.padi.comingsoon.rocks/api/v2/extranet/';
      }
    }

    if (!getCookie('travel_language')) {
      var language, langCode;

      if (window.navigator.languages && window.navigator.languages.length) {
        _.each(window.navigator.languages, function (val) {
          langCode = val.split('-');
          if (window.LANGUAGES.indexOf(langCode[0]) !== -1 && !window.requireRedirect) {
            window.requireRedirect = langCode[0];
          }
        });
      } else {
        language = window.navigator.language || window.navigator.userLanguage;
        if (language) {
          langCode = language.split('-');

          if (window.LANGUAGES.indexOf(langCode[0]) !== -1) {
            window.requireRedirect = langCode[0];
          }
        }
      }
    }

    var langPrefix = window.location.pathname.match(/^\/[a-z]{2,3}(?!\-)\/|zh-han[t|s]\/|other\//),
      lang = 'en';
    if (langPrefix) {
      lang = langPrefix[0].replace(/\//g, '');
    }

    document.documentElement.setAttribute('lang', lang);
    document.body.classList.add('lang-' + lang);

    setCookie('travel_language', lang, {
      path: '/',
      domain: '.' + window.location.hostname.split('.').splice(1).join('.'),
      expires: 3600 * 24 * 7, /* 7 days */
    });

    window.lang = lang;


    angular.module('app').constant('server', server);
    angular.module('app').constant('selectedLanguage', lang);

    const sub = getCookie(`CognitoIdentityServiceProvider.${aud}.LastAuthUser`),
      idToken = getCookie(`CognitoIdentityServiceProvider.${aud}.${sub}.idToken`);
    if (!idToken) {
      const options = {
        domain: '.' + window.location.hostname.split('.').splice(1).join('.'),
        path: '/',
        expires: 3600, // 1 hour
      };
      setCookie('login_next_page', window.location.href, options);
      const userData = getCookieByRegexp('CognitoIdentityServiceProvider.*.*.userData');

      if (userData) {
        const parsedUserData = JSON.parse(userData),
          subValue = parsedUserData.UserAttributes.find(item => item.Name === 'sub').Value,
          audValue = parsedUserData.UserAttributes.find(item => item.Name === 'aud').Value,
          tokenCookieName = `CognitoIdentityServiceProvider.${audValue}.${subValue}.idToken`,
          refreshTokenCookieName = `CognitoIdentityServiceProvider.${audValue}.${subValue}.refreshToken`,
          token = getCookie(tokenCookieName),
          refreshToken = getCookie(refreshTokenCookieName);
        if (token && refreshToken) {
          const refershUrl = window.location.origin === 'https://business.padi.com'
            ? 'https://api.global-prod.padi.com/auth/api/oauth/refresh'
            : 'https://api-stage.global-np.padi.com/auth/api/oauth/refresh/';

          return window.fetch(refershUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
            },
            body: JSON.stringify({
              refreshToken,
              clientId: aud,
              idToken: token,
            }),
          }).then(response => response.json()).then(newToken => {
            if (newToken && newToken.idToken) {
              window.location.href = `/cognito_auth?id_token=${newToken.idToken}&refresh_token=${newToken.refreshToken}`;
            } else {
              deleteCookie(tokenCookieName);
              deleteCookie(refreshTokenCookieName);
              deleteCookie(`CognitoIdentityServiceProvider.${audValue}.${subValue}.userData`);
              window.location.reload();
            }
          }).catch(() => {
            window.location.href = `${cognitoURL}&redirect_uri=${redirectUri}/cognito_auth`;
          });
        }
      }
      window.location.href = `${cognitoURL}&redirect_uri=${redirectUri}/cognito_auth`;
      return false;
    }

    $http.get(server + 'info/', {withCredentials: true})
      .then(
        function (res) {
          return res.data;
        }
      )
      .then(function (data) {
        var shop = localStorage.getItem('choosenShop'),
          token = localStorage.getItem('token');

        if (shop && token) {
          shop = JSON.parse(shop);

          return $http({
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
            },
            url: server + 'shop/' + shop.id + '/fetch-slug/',
          }).then(function (res) {
            if (shop.slug !== res.data.slug) {
              shop.slug = res.data.slug;

              localStorage.setItem('choosenShop', JSON.stringify(shop));
            }
            return data;
          }).catch(function () {
            return data;
          });
        }

        return data;
      })
      .then(function (data) {
        if (data) {
          angular.element(document).ready(function () {
            angular.module('app').constant('info', data);
            angular.bootstrap(document, ['app']);
          });

          loader.remove();
        }
        return true;
      });

    function setCookie(name, value, options) {
      options = options || {};

      var expires = options.expires;

      if (typeof expires === 'number' && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
      }
      if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
      }

      value = encodeURIComponent(value);

      var updatedCookie = name + '=' + value;

      for (var propName in options) {
        updatedCookie += '; ' + propName;
        var propValue = options[propName];
        if (propValue !== true) {
          updatedCookie += '=' + propValue;
        }
      }
      document.cookie = updatedCookie;
    }

    function getCookie(name) {
      var matches = document.cookie.match(new RegExp(
        '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function deleteCookie(name) {
      document.cookie =
        name +
        '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' +
        '.' +
        window.location.hostname.split('.').splice(1).join('.')
      return true;
    }

    function getCookieByRegexp(name) {
      var matches = document.cookie.match(new RegExp(
        '(?:^|; )' + name + '=([^;]*)'
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }
  })();
})(window);
