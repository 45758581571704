'use strict';

angular.module('app')
  .controller('PhotosCtrl', function ($scope, $rootScope, $location, utils, fileReader, Upload, $q, photoService, GTM, type, gettextCatalog, videoService) {
    var vm = this;

    vm.fileUpload = fileUpload;
    vm.deletePhoto = deletePhoto;
    vm.validate = validate;
    vm.deleteVideo = deleteVideo;
    vm.dragEnd = dragEnd;
    vm.setRemarketing = setRemarketing;
    vm.videos = [{videoUrl: undefined}];

    activate();

    function activate() {
      vm.loading = false;

      GTM.event('business_listing_photos_start');
      vm.imagesThumb = {
        logo: [],
        species: [],
        background: [],
        'boat-photos': [],
        photos: [],
        restaurant: [],
        resort: [],
        spa: [],
        layout: [],
      };

      vm.errorPhoto = {
        logo: [],
        species: [],
        background: [],
        photos: [],
        'boat-photos': [],
        restaurant: [],
        resort: [],
        spa: [],
        layout: [],
      };

      vm.errorFormat = {
        logo: [],
        species: [],
        background: [],
        photos: [],
        'boat-photos': [],
        restaurant: [],
        resort: [],
        spa: [],
        layout: [],
      };


      vm.disableLoading = [];

      if (type === 'DR') {
        photoService.getRestaurant().then(function (data) {
          vm.imagesThumb.restaurant = data;
        });

        photoService.getSpa().then(function (data) {
          vm.imagesThumb.spa = data;
        });

        photoService.getResort().then(function (data) {
          vm.imagesThumb.resort = data;
        });

        videoService.getResortVideos().then(function (data) {
          if (data.length) {
            vm.videos = data;

            vm.videosArray = new Array(data.length);
          } else {
            vm.videosArray = [1];
          }
        });
      } else if (type === 'DC') {
        photoService.getLogo().then(function (data) {
          if (data.absoluteImage) {
            vm.imagesThumb.logo = [data];
          }
        });
        photoService.getSpecies().then(function (data) {
          vm.imagesThumb.species = data;
        });

        videoService.getVideos().then(function (data) {
          if (data.length) {
            vm.videos = data;

            vm.videosArray = new Array(data.length);
          } else {
            vm.videosArray = [1];
          }
        });

        if ($rootScope.selectedShop.kind == 10) {
          photoService.getBoatPhotos().then(function (data) {
            vm.imagesThumb['boat-photos'] = data;
          });
          photoService.getBoatLayout().then(function (data) {
            vm.imagesThumb.layout = data;
          });
        } else {
          photoService.getPhotos().then(function (data) {
            vm.imagesThumb.photos = data;
          });
        }
      }

      vm.tail = '';
      if (+$rootScope.selectedShop.resortKind === 10 && type === 'DC') {
        vm.tail = 'dive-center/';
      }

      photoService.getBackground(undefined, vm.tail).then(function (data) {
        if (data.absoluteImage) {
          vm.imagesThumb.background = [data];
        }

        vm.copyrights = {
          isOwnBackground: data.isOwnBackground || false,
          isOwnBoatPhoto: data.isOwnBoatPhoto || false,
          isOwnLayout: data.isOwnLayout || false,
          isOwnPhoto: data.isOwnPhoto || false,
          isOwnPicture: data.isOwnPicture || false,
          isOwnSpecies: data.isOwnSpecies || false,
          isOwnResortPhoto: data.isOwnResortPhoto || false,
          isOwnRestaurantPhoto: data.isOwnRestaurantPhoto || false,
          isOwnSpaPhoto: data.isOwnSpaPhoto || false,
        };

        vm.virtualTourUrl = data.virtualTourLink;
      });
    }

    $scope.$on('$locationChangeStart', function (event) {
      if (($scope.photoForm.$invalid && $scope.photoForm.$dirty) || (!$scope.photoForm.$submitted && $scope.photoForm.$dirty )) {
        event.preventDefault();
        utils.warningPopup('../templates/_copyright-popup.html').then(function () {
          return;
        }).catch(function () {
          $rootScope.currentUrl = '/photos/';
          return false;
        });
      }
    });

    function fileUpload(files, type, maxLength, copyright) {
      maxLength = maxLength ? maxLength : 100;

      vm.errorPhoto[type] = [];
      vm.errorFormat[type] = [];

      function uploadImage(file) {
        var randomId = parseInt(Math.random() * 1000000, 10);

        vm.disableLoading.push(randomId);
        vm.imagesThumb[type].push({id: randomId, absoluteImage: '/images/loading-photo.jpg'});

        photoService.fileUpload(file, type, vm.tail).then(function (data) {
          // if (vm.imagesThumb.photos.length > 2 && vm.imagesThumb.logo.length && vm.imagesThumb.background.length) {
          //  $rootScope.checkedList.photos = true;
          // }
          checkPhotosIsFinished();
          if (data) {
            if (type === 'logo' || type === 'background') {
              vm.imagesThumb[type] = [data];
            } else {
              vm.imagesThumb[type] = _.reject(vm.imagesThumb[type], function (val) {
                return val.id == randomId;
              });

              vm.imagesThumb[type].push(data);
            }
          }
          // uncheck corresponding copyright tick;
          vm.copyrights[copyright] = false;

          vm.disableLoading = _.without(vm.disableLoading, randomId);
        }, function (error) {
          vm.imagesThumb[type] = _.reject(vm.imagesThumb[type], function (val) {
            return val.id == randomId;
          });

          vm.disableLoading = _.without(vm.disableLoading, randomId);
        }, function (data) {
          _.each(vm.imagesThumb[type], function (val) {
            if (val.id == randomId) {
              val.progress = data;

              if (data == 100) {
                val.processing = true;
              }
            }
          });
        });
      }

      _.each(files, function (file) {
        var acceptedFormat = _.find(['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'], function (v) {
          return v == file.type;
        });
        if (!acceptedFormat) {
          vm.errorFormat[type].push(file.name);
          return;
        }

        Upload.imageDimensions(file).then(function (d) {
          if (type == 'logo' && (d.width < 180 || d.height < 180)) {
            return vm.errorPhoto[type].push(file.name);
          } else if (type != 'logo' && d.width < 600) {
            return vm.errorPhoto[type].push(file.name);
          }

          if (vm.imagesThumb[type].length + 1 <= maxLength) {
            uploadImage(file);
          } else if (maxLength === 1 && vm.imagesThumb[type].length === 1) {
            // replace photo;
            utils.warningPopup('../templates/_replace-photo.html').then(function () {
              // if yes delete prev photo
              return photoService.deletePhoto(vm.imagesThumb[type][0].id, type, vm.tail).then(function () {
                vm.imagesThumb[type] = [];
                uploadImage(file);
              });
            }).catch(function () {
              return false;
            });
          }
        });
      });
    }

    function deletePhoto(id, type, index) {
      return photoService.deletePhoto(id, type, vm.tail).then(function () {
        removeThumbnail();
        checkPhotosIsFinished();
      });

      function removeThumbnail() {
        if (type == 'logo' || type == 'background') {
          vm.imagesThumb[type] = [];
        } else {
          vm.imagesThumb[type].splice(index, 1);
        }
      }
    }

    function validate(form) {
      form.$submitted = true;
      if (form.$valid) {
        var videosAPIcall = [];

        _.each(vm.videos, function (val, key) {
          if (form['video' + key] && form['video' + key].$touched && val.videoUrl && !val.id) {
            videosAPIcall.push(videoService.postVideo(val, type));
          }
        });

        if (form.virtualTourUrl && form.virtualTourUrl.$touched) {
          videosAPIcall.push(photoService.setVirtualTour({ virtualTourLink: vm.virtualTourUrl }));
        }

        videosAPIcall.push(photoService.setVirtualTour(vm.copyrights, vm.tail));

        if (videosAPIcall.length) {
          vm.loading = true;
          $q.all(videosAPIcall).then(function () {
            vm.loading = false;
            pageExit();
          }).catch(function () {
            vm.loading = false;
          });
        } else {
          pageExit();
        }
      } else {
        form.$submitted = false;
      }

      function pageExit() {
        if (+$rootScope.selectedShop.kind === 20) {
          if ($location.$$path === utils.createURL('photos')) {
            return $location.path(utils.createURL('payment_details'));
          }
          return $location.path(utils.createURL('rooms'));
        } else if (+$rootScope.selectedShop.kind === 10) {
          $location.path(utils.createURL('cabins'));
        } else {
          $location.path(utils.createURL($rootScope.user.isStaff ? 'dc_inclusions' : 'admin'));
        }
        $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved'));
      }

      return true;
    }

    function checkPhotosIsFinished() {
      if ($rootScope.selectedShop.kind == 20) {
        if ($location.$$path === '/photos/') {
          if (vm.imagesThumb.logo.length) {
            $rootScope.checkedList.photos = true;
          } else {
            $rootScope.checkedList.photos = false;
          }
        } else if (vm.imagesThumb.resort.length && vm.imagesThumb.background.length) {
          $rootScope.checkedList.resortPhotos = true;
        } else {
          $rootScope.checkedList.resortPhotos = false;
        }
      } else if ($rootScope.selectedShop.kind == 0) {
        if (vm.imagesThumb.logo.length && vm.imagesThumb.background.length) {
          $rootScope.checkedList.photos = true;
        } else {
          $rootScope.checkedList.photos = false;
        }
      } else if ($rootScope.selectedShop.kind == 10) {
        if (vm.imagesThumb.logo.length && vm.imagesThumb.background.length && vm.imagesThumb['boat-photos'].length) {
          $rootScope.checkedList.liveaboardPhotos = true;
        } else {
          $rootScope.checkedList.liveaboardPhotos = false;
        }
      }
      if ($rootScope.checkedList.photos) {
        GTM.event('business_listing_photos_complete');
        GTM.variables({'business_listing_photos': 'Complete'});
      }
    }

    function deleteVideo(video, key) {
      if (video.id) {
        vm.loading = true;
        videoService.removeVideo(video.id, 'resort').then(function () {
          vm.videos = _.reject(vm.videos, {id: video.id});

          vm.loading = false;

          if (!vm.videos.length) {
            vm.videos = [{videoUrl: undefined}];
          }

          $rootScope.showNotify(gettextCatalog.getString('Video was deleted'));
        });
      } else {
        vm.videos.splice(key, 1);
      }

      if (!vm.videos.length) {
        vm.videos = [{videoUrl: undefined}];
      }
    }

    function dragEnd(type, api) {
      _.each(vm.imagesThumb[type], function (val, index) {
        val.order = index;
      });

      photoService[api](vm.imagesThumb[type]);
    }

    function setRemarketing(id, photos, api) {
      _.each(photos, function (val) {
        if (val.id === id) {
          val.isRemarketing = !val.isRemarketing;
        } else {
          val.isRemarketing = false;
        }
      });

      photoService[api](photos);
    }

    // help text translations
    vm.mainPhotoHelpText = gettextCatalog.getString(
      `<div>
        <p class='subtitle'>Photo resolution</p>
        <ul>
          <li>Ideal: > 2240 x 1680 px</li>
          <li>Good: > 1600 x 1200 px</li>
          <li>Acceptable: > 800 x 600 px</li>
          <li>Minimum: 600 pixels wide</li>
        </ul>
      </div>`);

    vm.underwaterPhotoHelpText = gettextCatalog.getString(
      `<ul>
        <li>Have some great underwater images?</li>
        <li>Make sure you upload them here and inspire divers to come and dive with you</li>
      </ul>`) + vm.mainPhotoHelpText;

    vm.otherPhotoHelpText = gettextCatalog.getString(
      `<ul>
        <li>Use your smartphones or a digital camera</li>
        <li>Adjust the camera settings to the highest resolution possible</li>
        <li>Using a horizontal (landscape) layout, have fun taking photos</li>
        <li>Transfer the photos to your computer</li>
        <li>Upload them here</li>
      </ul>`) + vm.mainPhotoHelpText;
  });
