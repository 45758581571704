'use strict';

angular.module('app').directive('nextInput', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            $(element).on('keydown', 'input', function (e) {
                var keyCode = e.keyCode || e.which,
                    content;
                if (keyCode === 9 || keyCode === 13) {
                    var _this = $(this),
                        parent = _this.parents(".input-wrapper"),
                        nextRow = _this.parents('.occupancy').next(),
                        nextItinerary = _this.parents('.table-content').next(),
                        nextMonth = _this.parents('.month-row').next(),
                        nextElem = parent.next();

                    if (nextElem.length) {
                        nextElem.find('input').focus().select();
                    } else if (nextRow.length) {
                        nextRow.find('.input-wrapper input').eq(0).focus().select();
                    } else if (nextItinerary.length) {
                        content = nextItinerary.find('.content-row');

                        if (content.hasClass('hidden')) {
                            content.removeClass('hidden');
                            nextItinerary.find('.expand-btn').addClass('active');
                        }
                        nextItinerary.find('.input-wrapper input').eq(0).focus().select();
                    } else if (nextMonth.length) {
                        content = nextMonth.find('.content-row').eq(0);
                        if (content.hasClass('hidden')) {
                            content.removeClass('hidden');
                            nextMonth.find('.expand-btn').eq(0).addClass('active');
                        }

                        nextMonth.find('.table-content .input-wrapper input').eq(0).focus().select();
                    }

                    return false;
                }
            });
        }
    };
});


angular.module('app').directive('expandBlock', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            var elem  = $(element);
            elem.click(function (e) {
                elem.toggleClass('active');
                elem.next('.content-row').toggleClass('hidden');
            });
        }
    };
});