'use strict';

angular
  .module('app')
  .controller('PaymentDetails', function (
    walletService,
    hyperwalletInstance,
    adminService,
    gettextCatalog,
    $timeout
  ) {
    var vm = this;
    vm.noUser = false;
    vm.loading = true;
    vm.payoutInfo = {};
    vm.isEditing = false;
    vm.isAdventures = hyperwalletInstance === 10;
    vm.form = {};

    const wallet = new walletService.Wallet();

    getInfo();

    vm.updatePaypalAccount = (form) => {
      if (form.$invalid) {
        return;
      }
      wallet.setAccountIdentifier(vm.payoutInfo.id, vm.form).then((res) => {
        vm.isEditing = false;
        vm.accountIdentifier = res.data.accountIdentifier;
      });
    };

    vm.cancelEdit = () => {
      vm.isEditing = false;
      vm.form.accountIdentifier = vm.accountIdentifier;
    };

    vm.loadingCallback = () => {
      vm.loading = false;
    };

    const getInstance = (info) =>
      _.find(info, { instance: hyperwalletInstance });

    function getInfo() {
      return wallet.getHWInfo().then((payoutInfo) => {
        const currentInstance = getInstance(payoutInfo.hyperwalletUsers);
        vm.payoutInfo = _.get(currentInstance, 'lastTransferMethod', false);
        vm.form = {
          accountIdentifier: vm.payoutInfo.accountIdentifier,
        };
        vm.accountIdentifier = vm.payoutInfo.accountIdentifier;
      });
    }

    vm.getAccountType = (type) => {
      switch (type) {
      case 'PAYPAL_ACCOUNT':
        return 'Paypal';
      case 'BANK_ACCOUNT':
        return gettextCatalog.getString('Bank account');
      case 'WIRE_ACCOUNT':
        return gettextCatalog.getString('Wire account');
      case 'BANK_CARD':
        return gettextCatalog.getString('Bank card');
      case 'PAPER_CHECK':
        return gettextCatalog.getString('Paper check');
      default:
        return gettextCatalog.getString('n/a');
      }
    };

    adminService.getInfo().then(function (data) {
      const currentInstance = getInstance(data.hyperwalletUsers);
      if (!data.basedOn) {
        if (currentInstance && currentInstance.token) {
          var profileType;
          if (+data.businessType === 10) {
            profileType = 'INDIVIDUAL';
          } else if (+data.businessType === 20 || +data.businessType === 30) {
            profileType = 'BUSINESS';
          }
          wallet.loadTransferMethods(
            currentInstance.token,
            {
              show: () => {
                $timeout(() => {
                  vm.loading = true;
                });
              },
              hide: () => {
                $timeout(() => {
                  vm.loading = false;
                });
              },
            },
            profileType,
            _.get(currentInstance, 'lastTransferMethod.token')
          );
        } else {
          vm.noUser = true;
          vm.loading = false;
        }
      } else {
        vm.paymentLinkedToAnotherShop = _.get(
          _.find(data.masterShops, (item) => item.pk === data.basedOn),
          'title'
        );
        vm.loading = false;
      }
    });
  });
