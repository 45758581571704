'use strict';

angular.module('app')
  .controller('CabinsCtrl', function ($scope, $routeParams, $location, $timeout, $rootScope, info, gettextCatalog, shopService, roomService, Upload, cabinService, utils) {
    console.log('CabinsCtrl!');

    var vm = this,
      formSubmited = false;

    vm.fileUpload = fileUpload;
    vm.saveCabin = saveCabin;
    vm.deletePhoto = deletePhoto;
    vm.toggleParameters = toggleParameters;
    vm.dragEnd = dragEnd;
    // vm.getMaxDiscount = getMaxDiscount;
    // vm.getPricePerGuest = getPricePerGuest;
    vm.changeOccupancy = changeOccupancy;

    activate();

    // $scope.$on('$locationChangeStart', function (event, toState, oldState) {
    //   if (!formSubmited) {
    //     if ($scope.extranetForm.$dirty && $scope.extranetForm.$invalid) {
    //       event.preventDefault();
    //       utils.warningPopup().then(function () {
    //         $scope.extranetForm.$setPristine();
    //         $location.path($rootScope.currentUrl);
    //       }).catch(function () {
    //         $rootScope.currentUrl = '/rooms/';
    //       });
    //     }

    //     if ($scope.extranetForm.$dirty && $scope.extranetForm.$valid) {
    //       if (checkTotalNumbers()) {
    //         event.preventDefault();
    //         utils.warningPopup('../templates/_number-of-rooms-popup.html', $scope).then(function () {
    //           vm.showWarning = false;
    //           saveCabinOnLeave($routeParams.id);

    //           $location.path($rootScope.currentUrl);
    //         }).catch(function () {
    //           vm.loading = false;
    //           $rootScope.currentUrl = '/cabins/';
    //         });

    //         return true;
    //       }
    //       saveCabinOnLeave($routeParams.id);


    //       function saveCabinOnLeave(oldRoom) {
    //         vm.add.pricesWrite = _.clone(vm.add.pricesRead);
    //         cabinService.saveCabin(vm.add, $routeParams.id).then(function () {
    //           formSubmited = true;

    //           if (oldRoom) {
    //             $rootScope.showNotify('Room was successfully added');
    //           } else {
    //             $rootScope.showNotify('Room was successfully changed');
    //           }
    //         });
    //       }
    //     }
    //   }
    // });

    function activate() {
      vm.roomType = info.cabinTypes;
      vm.bedType = info.shop.BED_TYPE;
      vm.imagesThumb = [];
      vm.errorPhoto = [];
      vm.errorFormat = [];
      vm.disableLoading = [];
      vm.roomAmenities = info.roomAmenities;
      vm.selectedParams = {};
      vm.roomsNumber = 0;
      vm.showWarning = true;


      vm.add = {
        roomType: '',
        standardName: '',
        numberOfRooms: '',
        title: '',
        description: '',
        size: null,
        bedType: '',
        standardOccupancy: '',
        maxOccupancy: '',
        roomPrice: '',
        imagesRead: [],
        photos: [],
        writeAmenities: [],
        price: '',
        whatsIncluded: null,
        whatsNotIncluded: null,
        lowerRate: 'no',
        isDiscountPercent: true,
      };

      shopService.getShopCurrency().then(function (data) {
        vm.currency = data.currency;

        vm.discountVariants = [
          {title: '%', type: true},
          {title: vm.currency || 'USD', type: false}
        ];
      });

      cabinService.getList().then(function (data) {
        _.each(data, function (val) {
          if (!$routeParams.id || $routeParams.id && val.roomId != $routeParams.id) {
            vm.roomsNumber += val.roomNumber;
          }
        });
      });

      roomService.getInfo().then(function (data) {
        vm.params = data;
      });

      if ($routeParams.id) {
        cabinService.getCabin($routeParams.id).then(function (data) {
          vm.add = data;
          vm.add.writeAmenities = [];
          vm.add.lowerRate = data.discountAmount ? 'yes' : 'no';
          // getMaxDiscount(true);

          if (data.pricesRead) {
            vm.selectedPriceTab = data.pricesRead[0].id;


            if (vm.add.pricesRead.length > 4) {
              vm.from = 0;
              vm.limit = 3;
            }
          }
          _.each(data.readAmenities, function (val) {
            vm.selectedParams['selected_' + val.id] = true;

            vm.add.writeAmenities.push(val.id);
          });

          changeOccupancy();
        });
      }
    }


    function saveCabin(form) {
      if (vm.add.standardOccupancy > vm.add.maxOccupancy || !vm.add.imagesRead.length) {
        return true;
      }

      if (form.$valid) {
        vm.loading = true;
        vm.add.pricesWrite = _.clone(vm.add.pricesRead);

        _.each(vm.add.pricesWrite, function (price) {
          if (_.size(price.seasonPrices) > vm.add.maxOccupancy) {
            _.each(price.seasonPrices, function (val, key) {
              if (+key > vm.add.maxOccupancy) {
                delete price.seasonPrices[key];
              }
            });
          }
        });
        // checkTotalNumbers(form, saveCabin);
        if (checkTotalNumbers()) {
          utils.warningPopup('../templates/_number-of-rooms-popup.html', $scope).then(function () {
            vm.showWarning = false;
            saveCabin(form);
          }).catch(function () {
            vm.loading = false;
          });

          return true;
        }

        cabinService.saveCabin(vm.add, $routeParams.id).then(function () {
          formSubmited = true;

          $location.path(utils.createURL('cabins'));

          vm.loading = false;

          $rootScope.checkedList.rooms = true;

          if ($routeParams.id) {
            $rootScope.showNotify(gettextCatalog.getString('The product was successfully changed'));
          } else {
            $rootScope.showNotify(gettextCatalog.getString('The product was successfully added'));
          }
        }, function (error) {
          vm.loading = false;
          if (error.data.numberOfRooms) {
            form.numberOfRooms.$invalid = true;
          }
        });
      }
    }

    function fileUpload(files, maxLength) {
      if (files && (vm.add.imagesRead.length <= maxLength)) {
        vm.errorPhoto = [];
        vm.errorFormat = [];

        _.each(files, function (file) {
          var acceptedFormat = _.find(['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'], function (v) {
            return v == file.type;
          });

          if (!acceptedFormat) {
            return vm.errorFormat.push(file.name);
          }

          var randomId = parseInt(Math.random() * 1000000);

          Upload.imageDimensions(file).then(function (d) {
            if (d.width < 600 || d.height < 400) {
              vm.errorPhoto.push(file.name);
            } else if (vm.add.imagesRead.length + 1 <= 6) {
              vm.disableLoading.push(randomId);

              vm.add.imagesRead.push({id: randomId, absoluteImage: '/images/loading-photo.jpg', type: 'loader'});

              cabinService.uploadPhotos(file).then(function (data) {
                vm.add.imagesRead = _.reject(vm.add.imagesRead, function (val) {
                  return val.id == randomId;
                });

                vm.add.imagesRead.push(data);

                if (!_.isArray(vm.add.photos)) {
                  vm.add.photos = [];
                }
                vm.add.photos.push(data.id);
                vm.add.isOwnPhoto = false;

                vm.disableLoading = _.without(vm.disableLoading, randomId);
              }, function (error) {
                vm.add.imagesRead  = _.reject(vm.add.imagesRead, function (val) {
                  return val.id == randomId;
                });

                vm.disableLoading = _.without(vm.disableLoading, randomId);
              }, function (progress) {
                _.each(vm.add.imagesRead, function (val) {
                  if (val.id == randomId) {
                    val.progress = progress;

                    if (progress == 100) {
                      val.processing = true;
                    }
                  }
                });
              });
            }
          }, function (err) {
            console.log(err);
          });
        });
      }
    }

    function toggleParameters(id) {
      var element = _.filter(vm.add.writeAmenities, function (elem) {
        return elem === id;
      });

      if (element.length) {
        vm.add.writeAmenities = _.reject(vm.add.writeAmenities, function (elem) {
          return elem === id;
        });
      } else {
        vm.add.writeAmenities.push(id);
      }
    }

    // function getMaxDiscount (update) {
    //    if (update) {
    //        var data = _.clone(vm.add.discountAmount);
    //
    //        vm.add.discountAmount = undefined;
    //
    //        $timeout(function() {
    //            vm.add.discountAmount = data;
    //        });
    //
    //        $scope.extranetForm.discount.$dirty = true;
    //    }
    //
    //    if (vm.add.isDiscountPercent) {
    //        vm.max = 100;
    //    } else {
    //        vm.max = _.clone(vm.add.roomPrice);
    //    }
    // }
    //
    // function getPricePerGuest () {
    //    if (!_.isNumber(vm.add.discountAmount)) {
    //        return '-';
    //    }
    //
    //    if (vm.add.isDiscountPercent) {
    //        var value = vm.add.roomPrice - (vm.add.roomPrice * (vm.add.discountAmount / 100));
    //        if (value < 0) {
    //            return '-';
    //        }
    //        return Math.round(value * 100) / 100;
    //    } else {
    //        var data = vm.add.roomPrice - vm.add.discountAmount;
    //
    //        return data < 0 ? '-' : data;
    //    }
    // }

    function deletePhoto(id) {
      return cabinService.deletePhoto(id).then(function () {
        vm.add.imagesRead = _.reject(vm.add.imagesRead, {id: id});
        vm.add.photos = _.without(vm.add.photos, id);
      });
    }

    function changeOccupancy() {
      if (vm.add.maxOccupancy) {
        vm.pricingFields = new Array(parseInt(vm.add.maxOccupancy));
      }
    }

    function checkTotalNumbers() {
      return false;
      $scope.rooms = vm.roomsNumber + vm.add.numberOfRooms;
      $scope.total = vm.params.totalNumberOfRooms;

      return $scope.rooms > $scope.total && vm.showWarning === true;
    }

    function dragEnd() {
      _.each(vm.add.imagesRead, function (val, index) {
        val.position = index;
      });

      cabinService.setPhoto(vm.add.imagesRead);
    }
  });
