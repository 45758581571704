'use strict';

angular.module('app').directive('dateOfBirth', function ($timeout, gettextCatalog) {
  return {
    restrict: 'E',
    require: 'ngModel',
    scope: {
      startDate: '=',
      minAge: '=',
      maxAge: '=',
    },
    template: '<div class="dob-selector"><select id="dobday" ng-model="date.dobDay"></select>' +
        '<select id="dobmonth" ng-model="date.dobMonth"></select>' +
        '<select id="dobyear" ng-model="date.dobYear"></select></div>',
    link: function (scope, attr, _, ngModelCtrl) {
      $timeout(function () {
        $.dobPicker({
          daySelector: '#dobday', /* Required */
          monthSelector: '#dobmonth', /* Required */
          yearSelector: '#dobyear', /* Required */
          dayDefault: gettextCatalog.getString('Day'), /* Optional */
          monthDefault: gettextCatalog.getString('Month'), /* Optional */
          yearDefault: gettextCatalog.getString('Year'), /* Optional */
          minimumAge: Number.isInteger(scope.minAge) ? scope.minAge : 1, /* Optional */
          maximumAge: Number.isInteger(scope.maxAge) ? scope.maxAge : 88,
        });

        // console.log(ngModelCtrl.$modelValue, 'model');
        // if (ngModelCtrl.$modelValue) {
        //   var dob = ngModelCtrl.$modelValue.split('-');

        //   scope.date = {
        //     dobYear: dob[0],
        //     dobMonth: dob[1],
        //     dobDay: dob[2],
        //   };
        // }

        scope.$watch(function () {
          return ngModelCtrl.$modelValue;
        }, function (newVal) {
          if (newVal) {
            var dobValue = newVal.split('-');

            scope.date = {
              dobYear: dobValue[0],
              dobMonth: dobValue[1],
              dobDay: dobValue[2],
            };

            $timeout(function () {
              $('#dobday').val(dobValue[2]);
              $('#dobmonth').val(dobValue[1]);
              $('#dobyear').val(dobValue[0]);
            });
          }
        });

        scope.$watchCollection('date', function (newVal) {
          if (newVal && newVal.dobDay && newVal.dobMonth && newVal.dobYear) {
            $timeout(function () {
              ngModelCtrl.$setViewValue(newVal.dobYear + '-' + newVal.dobMonth + '-' + newVal.dobDay);
            });
          } else {
            $timeout(function () {
              ngModelCtrl.$setViewValue(undefined);
            });
          }
        });
      });
    },
  };
});
