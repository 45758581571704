'use strict';

angular
  .module('app')
  .controller('AvailabilityCtrl', function (
    $q,
    $rootScope,
    $uibModal,
    availabilityService
  ) {
    var vm = this;

    vm.saveTrip = _.debounce(saveTrip, 350);
    vm.changeManageLevel = changeManageLevel;
    vm.changedCabin = _.debounce(changedCabin, 350);
    vm.nextPage = nextPage;
    vm.onBlurHandler = onBlurHandler;

    activate();

    function activate() {
      vm.loading = true;

      $q.all([
        availabilityService.getList(),
        availabilityService.getRoomList(),
        availabilityService.getInfo(),
      ]).then(function (data) {
        vm.tripLevel = data[0].groupedTrips;
        vm.tripList = data[0].allList;

        vm.cabinLevel = data[1].groupedTrips;
        vm.cabins = data[1].list;
        vm.cabinsList = data[1].allList;
        vm.manage = data[2].isTripLevelAvailabilityManagement;

        vm.loading = false;
      });
    }

    function nextPage(page) {
      vm.loading = true;

      if (vm.manage) {
        availabilityService.getList(page).then(function (data) {
          vm.tripLevel = data.groupedTrips;
          vm.tripList = data.allList;

          vm.loading = false;
        });
      } else {
        availabilityService.getRoomList(page).then(function (data) {
          vm.cabinLevel = data.groupedTrips;
          vm.cabins = data.list;
          vm.cabinsList = data.allList;

          vm.loading = false;
        });
      }
    }

    function changeManageLevel(data) {
      return availabilityService.setInfo(data).then(function () {
        nextPage();
      });
    }

    function changedCabin(trip, pack) {
      var checkMark;
      _.each(trip.packages, function (val) {
        val.availableSpotsWrite = val.availableSpotsRead;

        if (val.availableSpotsWrite && !checkMark) {
          checkMark = true;
        }
      });
      $rootScope.checkedList.availability = checkMark;
      if (pack.maxAvailabilitySpots > 1 && pack.availableSpotsWrite === 1) {
        onBlurHandler(pack, availabilityService.saveRoom, trip);
      }
      availabilityService.saveRoom(trip);
    }

    function saveTrip(trip) {
      if (trip.maxAvailabilitySpots > 1 && trip.availableSpots === 1) {
        onBlurHandler(trip, availabilityService.saveItem);
      }
      availabilityService.saveItem(trip);
    }

    function onBlurHandler(pack, callback, trip) {
      var modal = $uibModal.open({
        animation: true,
        templateUrl: '../templates/_last-spot.html',
        openedClass: 'itinerary-modal modal-open',
        resolve: {
          currentPackage: pack,
          currentTrip: trip,
        },
        controller: function (currentPackage, info, currentTrip, $uibModalInstance) {
          var _this = this;
          _this.filter = {};
          _this.items = [];
          _this.today = new Date();
          _this.package = currentPackage;
          _this.shareType = info.booking.PACKAGE_SHARE_TYPE;
          _this.close = function () {
            $uibModalInstance.dismiss('close');
          };

          _this.save = function () {
            _this.loading = true;
            let newData = {..._this.package };
            if (currentTrip) {
              _.each(currentTrip.packages, function (val) {
                if (val.id === _this.package.id) {
                  val.shareType = _this.package.shareType;
                }
              });
              newData = { ...currentTrip };
            }

            callback(newData).then(() => {
              _this.close();
            });
          };
        },
        controllerAs: '$modal',
      });

      modal.result.then(function () {
        vm.getList();
      });
    }
  });
