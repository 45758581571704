'use strict';

angular
  .module('app')
  .directive('onlyDigits', function () {
    return {
      require: 'ngModel',
      restrict: 'A',
      scope: {
        maxNumber: '=',
        phoneNumber: '=',
        minValue: '=',
        parseInt: '=',
        parseFloat: '=',
      },
      link: function (scope, element, attr, ctrl) {
        function inputValue(val) {
          if (val) {
            var digits;
            if (scope.phoneNumber) {
              digits = val.replace(/[^\+\s0-9]/g, '');
            } else if (scope.parseFloat) {
              digits = val.replace(/[^\.\+\s0-9]/g, '');
            } else {
              digits = val.toString().replace(/[^0-9]/g, '');
            }

            if (
              (scope.maxNumber || scope.maxNumber === 0) &&
              parseInt(digits) > parseInt(scope.maxNumber)
            ) {
              digits = '' + scope.maxNumber;
            }

            if (scope.minValue && +val < +scope.minValue) {
              digits = scope.minValue;
            }
            if (digits !== val) {
              ctrl.$setViewValue(digits);
              ctrl.$render();
            }
            if (scope.phoneNumber) {
              return digits;
            }
            if (scope.parseInt) {
              return +digits;
            }

            if (!digits.length) {
              return null;
            }

            if (scope.parseFloat) {
              return parseFloat(digits);
            }

            return digits;
          }
          return null;
        }

        ctrl.$parsers.push(inputValue);
      },
    };
  })

  .directive('moneyDiviac', function () {
    return {
      require: 'ngModel',
      restrict: '',
      link: function (scope, elm, attrs, ctrl) {
        function inputValue(val) {
          if (val) {
            var text = val.replace(/[^\.\+\s0-9]/g, ''),
              money = /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/;

            ctrl.$setViewValue(text);

            if (money.test(text)) {
              $(elm).removeClass('has-error');
              ctrl.$setValidity('money', true);
              ctrl.$render();
            } else {
              $(elm).addClass('has-error');
              ctrl.$setValidity('money', false);
              ctrl.$render();
              return null;
            }

            return parseFloat(text);
          }
          return null;
        }

        ctrl.$parsers.push(inputValue);
      },
    };
  });
