'use strict';

angular.module('app').directive('ptTimepicker', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      theme: '=',
      lang: '=',
    },
    link: function (scope, elem, attr, model) {
      var timepicker = new TimePicker(elem[0], {
        theme: 'dark', // or 'blue-grey'
        lang: 'en', // 'en', 'pt' for now
        appendTo: elem[0].parentElement,
      });

      timepicker.on('change', function (evt) {
        var value = (evt.hour || '00') + ':' + (evt.minute || '00');
        evt.element.value = value;
        model.$setViewValue(value);
      });

      scope.$on('$routeChangeStart', function () {
        var timepickerContainer = document.getElementById('-container-id');
        if (timepickerContainer) {
          timepickerContainer.remove();
        }
      });
    },
  };
});


angular.module('app').directive('ptTimepickerMultiple', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (_, elem, __, model) {
      var tp = $(elem).timepicker({
        timeFormat: 'H:i',
        step: 15,
      });

      tp.on('changeTime', function () {
        model.$setViewValue(tp.val());
      });
    },
  };
});
