'use strict';

angular
  .module('app')
  .controller('GroupCtrl', function (Request, utils, $rootScope, groupTrips, gettextCatalog, info) {
    var vm = this;
    vm.getMinMilestoneValue = getMinMilestoneValue;
    vm.addMilestone = addMilestone;
    vm.deleteMilestone = deleteMilestone;
    vm.validateMilestones = validateMilestones;
    vm.setPaymentCollected = setPaymentCollected;
    vm.handleAutoGenChange = handleAutoGenChange;
    vm.milestoneTypes = info.shop.MILESTONE_AMOUNT_TYPE;

    vm.loading = true;

    groupTrips.getGroupPageInfo().then(function (res) {
      vm.form = res;

      vm.form.groupBookingConditionsSaved = moment(
        vm.form.groupBookingConditionsSaved
      ).format('YYYY-MM-DD');

      vm.loading = false;

      getMilestones();
      _.each(vm.milestoneTypes, function (type) {
        if (type[0] === 20) type[1] = vm.form.shopCurrency;
      });
    });

    vm.save = function (form) {
      form.$submitted = true;
      if (form.$valid) {
        vm.loading = true;

        groupTrips.saveGroupPageInfo(vm.form)
          .then(function (res) {
            vm.form = res.data;
            vm.form.groupBookingConditionsSaved = moment(
              vm.form.groupBookingConditionsSaved
            ).format('YYYY-MM-DD');
            vm.loading = false;

            // $rootScope.showNotify('Information was successfully saved');
            vm.saveMilestones(form);
          });
      }
    };

    // MILESTONES

    function getMilestones() {
      groupTrips.getGroupMilestones().then(function (result) {
        vm.milestones = result;
        if (vm.milestones.customerPaymentMilestones.length && vm.milestones.supplierPaymentMilestones.length) {
          // don't send days for first milestones;
          vm.milestones.customerPaymentMilestones[0].days = undefined;
          vm.milestones.supplierPaymentMilestones[0].days = undefined;
        }
      });
    }

    function getMinMilestoneValue(index, milestonesArray, amountType) {
      if (amountType !== 10) return '';
      if (index !== 0) {
        return milestonesArray[index - 1].amount || 0;
      }
      return 0;
    }

    function addMilestone(type) {
      var curArray = [],
        otherArray = [],
        otherArrayType = '';
      if (type === 'customer') {
        curArray = vm.milestones.customerPaymentMilestones;
        otherArray = vm.milestones.supplierPaymentMilestones;
        otherArrayType = 'supplier';
      } else if (type === 'supplier') {
        curArray = vm.milestones.supplierPaymentMilestones;
        otherArray = vm.milestones.customerPaymentMilestones;
        otherArrayType = 'customer';
      } else {
        return;
      }


      var prevMilestone = curArray[curArray.length - 1];
      curArray.push({amount: 100, amountType: 10, isDueNow: false});

      if (prevMilestone && prevMilestone.amountType == 10 && prevMilestone.amount >= 100) {
        // last milestone should be 100% so prev can't be 100;
        prevMilestone.amount = 99;
      }

      if (prevMilestone.isPaymentCollectedBySupplier) {
        // payment collected by supplier" should be specified only for last milestone;
        prevMilestone.isPaymentCollectedBySupplier = false;
      }

      if (otherArray.length < 1) {
        // to prevent errors, add milestone to other array if there's only one milestone;
        addMilestone(otherArrayType);
      }
    }

    function deleteMilestone($index, milestones, type) {
      milestones.splice($index, 1);
      if (type === 'payment' ) {
        checkLastMilestone();
      }
    }

    function checkLastMilestone() {
      if (vm.milestones.customerPaymentMilestones.length === 1 || vm.milestones.supplierPaymentMilestones.length === 1) {
        vm.milestones.customerPaymentMilestones.splice(1);
        vm.milestones.supplierPaymentMilestones.splice(1);
      }

      var lastSupplierMilestone = _.last(vm.milestones.supplierPaymentMilestones),
        lastCustomerMilestone = _.last(vm.milestones.customerPaymentMilestones);

      lastSupplierMilestone.amount = 100;
      lastSupplierMilestone.amountType = 10;

      if (lastCustomerMilestone) {
        lastCustomerMilestone.amount = 100;
        lastCustomerMilestone.amountType = 10;
      }
    }

    function validateMilestones(milestonesArray, form) {
      _.each(milestonesArray, function (milestone, index) {
        var prevMilestone = milestonesArray[index - 1],
          isLast = (index === milestonesArray.length - 1),
          isFirst = (index === 0);

        if (isFirst && !vm.milestones.groupTripPaymentInformation.isAutoCustomerMilestones) {
          vm.milestones.customerPaymentMilestones[0] = milestone;
          vm.milestones.supplierPaymentMilestones[0] = milestone;
        }

        if (isLast) {
          return;
        }

        if (milestone.amountType == 10) {
          if (milestone.amount >= 100) {
            // if this milestone amount more than 100 and it is not the last one
            milestone.error = gettextCatalog.getString('This is not the last milestone, so the percentage of the total amount paid by this milestone must be less than 100%');
            form['milestones' + index].$setValidity('wrong-value', false);
          } else if (prevMilestone && prevMilestone.amountType == 10 && milestone.amount <= prevMilestone.amount) {
            // if current and previous milestone is in % and current one is bigger than prev
            milestone.error = gettextCatalog.getString('The percentage of the total amount paid for this booking must be higher than the total amount paid in the previous milestone');
            form['milestones' + index].$setValidity('wrong-value', false);
          } else {
            milestone.error = '';
            form['milestones' + index].$setValidity('wrong-value', true);
          }
        } else {
          milestone.error = '';
          form['milestones' + index].$setValidity('wrong-value', true);
        }

        // if (milestone.error) {
        //   form.$invalid = true;
        //   form.$valid = false;
        // }
      });
    }

    function setPaymentCollected(key, val) {
      _.last(vm.milestones[key]).isPaymentCollectedBySupplier = val;
    }

    function handleAutoGenChange() {
      if (vm.milestones.groupTripPaymentInformation.isAutoCustomerMilestones) {
        vm.milestones.customerPaymentMilestones = [];
      } else {
        vm.milestones.customerPaymentMilestones = _.clone(vm.milestones.supplierPaymentMilestones);
      }
    }

    vm.saveMilestones = function (form) {
      // delete customer milestones to avoid validation errors
      if (vm.milestones.groupTripPaymentInformation.isAutoCustomerMilestones) {
        vm.milestones.customerPaymentMilestones = [];
      }
      form.customerMilestonesForm.$submitted = true;
      form.supplierMilestonesForm.$submitted = true;

      // prepare cancellation milestones before sending;
      var customerCancellationLength = vm.milestones.customerCancellationMilestones.length,
        supplierCancellationLength = vm.milestones.supplierCancellationMilestones.length;

      if (customerCancellationLength > 1) {
        vm.milestones.customerCancellationMilestones[customerCancellationLength - 1].days = vm.milestones.customerCancellationMilestones[customerCancellationLength - 2].days;
      }
      if (supplierCancellationLength > 1) {
        vm.milestones.supplierCancellationMilestones[supplierCancellationLength - 1].days = vm.milestones.supplierCancellationMilestones[supplierCancellationLength - 2].days;
      }

      if (form.customerMilestonesForm.$valid && form.supplierMilestonesForm.$valid) {
        vm.loading = true;
        groupTrips.saveGroupMilestones(vm.milestones).then(function () {
          // $location.path('/agreement/');

          $rootScope.showNotify('Information was successfully saved');
          vm.loading = false;
        }).catch(function (err) {
          vm.loading = false;
          if (err.status == 500) return;
          _.each(Object.keys(err.data), function (key) {
            $rootScope.showNotify(err.data[key]);
            return;
          });
        });
      }
    };
  });
