'use strict';

angular.module('app').directive('callIntercom', function($rootScope, gettextCatalog) {
  return function(scope, elem, attr) {
    $(elem).on('click', function(e) {
      e.preventDefault();

      if (window.Intercom) {
        Intercom('show')
      } else {
        if (_.where(window.navigator.languages,'ru').length && window.location.host.search('padi.com') === -1) {
          $rootScope.showNotify('Владимировна!!! Я НЕ ЛОМАЛ, НЕ ЗАВОДИ БАГ!!!');
        } else {
          $rootScope.showNotify(gettextCatalog.getString('Please, disable AdBlock to see chat.'))
        }
      }
    });
  };
})
