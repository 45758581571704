'use strict';

angular
  .module('app')
  .controller('DiveGuidesCtrl', function (
    info,
    diveGuideService,
    $location,
    $rootScope,
    gettextCatalog,
    utils,
    Upload,
    guideConstants,
    shopService,
    $uibModal
  ) {
    const vm = this,
      params = $location.search();

    vm.countryList = info.countryList;
    vm.currencyList = info.shop.CURRENCY_CHOICES;
    vm.topDiveSites = [];
    vm.form = {};
    vm.form2 = false;
    vm.photos = [];
    vm.onLanguageChange = onLanguageChange;
    vm.getAreas = getAreas;
    vm.getLocations = getLocations;
    vm.saveInfo = saveInfo;
    vm.fileUpload = fileUpload;
    vm.getMarineLife = getMarineLife;
    vm.createNewLocation = createNewLocation;
    vm.getLocationInfo = getLocationInfo;
    vm.deletePhoto = deletePhoto;
    vm.getLocationType = getLocationType;
    vm.rejectGuide = rejectGuide;
    vm.dragEnd = dragEnd;

    function dragEnd() {
      _.each(vm.photos, function (val, index) {
        val.order = index;
      });

      if (vm.form.photos) {
        vm.form.photos = vm.photos.map((photo) => ({ id: photo.id, order: photo.order }));
      }
    }

    vm.EDIT_COUNTRY_KEY = guideConstants.EDIT_COUNTRY_KEY;

    if (params.id) {
      vm.isLoading = true;
      diveGuideService.getById(params.id, params.lang).then((locationInfo) => {
        // Create two forms
        vm.form = setFormValue(vm.form, locationInfo);
        vm.form.basedOn = params.id;
        vm.photos = _.clone(locationInfo.images);

        // Set marine life to separate object
        vm.marineLife = locationInfo.marineLifeVisibility;
        getAreas(locationInfo.country, true).then(() => {
          if (locationInfo.area) {
            getLocationsList().then(() => {
              loadStableVersion(true);
            });
          } else {
            loadStableVersion(true);
          }
        });
      });
    }

    if (params.country_id) {
      vm.form = {
        country: Number(params.country_id),
        inputLanguage: params.lang || 'en',
      };
      getAreas(vm.form.country, true).then(() => {
        if (params.area_id) {
          vm.form.area = Number(params.area_id);
          getLocations();
          if (params.location_id) {
            vm.form.location = Number(params.location_id);
          }
        }
      });
    }

    function onLanguageChange() {
      vm.form = {
        inputLanguage: vm.form.inputLanguage,
      };
    }

    function getAreas(location, disableClearForm) {
      // Clear previous selected values
      if (!disableClearForm) {
        vm.form = {
          country: vm.form.country,
          inputLanguage: vm.form.inputLanguage,
        };
      }

      return diveGuideService.getAreas(location).then((areas) => {
        if (!areas.length || $rootScope.user.isStaff) {
          areas.unshift({
            id: guideConstants.EDIT_COUNTRY_KEY,
            title: gettextCatalog.getString('None - edit country guide'),
          });
        }
        vm.areasList = areas;
        vm.isLoading = false;
      });
    }

    function loadStableVersion(preventLoadingUserVersion) {
      const parameters = getLocationType();
      vm.topDiveSites = [];

      diveGuideService
        .topDiveSites(parameters.location, parameters.type)
        .then((topDiveSites) => (vm.topDiveSites = topDiveSites));

      diveGuideService
        .get(
          parameters.location,
          parameters.type,
          vm.form.inputLanguage,
          preventLoadingUserVersion
        )
        .then(({ stableVersion }) => {
          if (stableVersion) {
            stableVersion.imageCopyright = false;
            stableVersion.videoCopyright = false;
            vm.stableVideoUrl = stableVersion.videoUrl;
            vm.stablePhotos = _.clone(stableVersion.images);
            vm.form2 = setFormValue(vm.form2, _.cloneDeep(stableVersion));
            vm.stableMarineLife = stableVersion.marineLifeVisibility;
          }
        })
        .finally(() => {
          vm.isLoading = false;
        });
      vm.divingIn = parameters.inTitle;
    }

    function getLocations() {
      // Clear previous selected values
      vm.form = {
        location: null,
        area: vm.form.area,
        country: vm.form.country,
        inputLanguage: vm.form.inputLanguage,
      };

      vm.photos = [];
      vm.stablePhotos = [];
      if (vm.form.area === guideConstants.EDIT_COUNTRY_KEY) {
        getLocationInfo();
        return false;
      }
      getLocationsList();
      return true;
    }

    function getLocationsList() {
      vm.isLoading = true;

      const location = vm.areasList.find((val) => val.id === vm.form.area);
      return diveGuideService
        .getLocations(location && location.slug)
        .then((locations) => {
          locations.unshift({
            id: guideConstants.EDIT_AREA_KEY,
            title: gettextCatalog.getString('None - edit area guide'),
          });
          vm.locationsList = locations;
        })
        .finally(() => (vm.isLoading = false));
    }

    function fileUpload(files, maxLength) {
      function uploadFile(file) {
        return diveGuideService.uploadPhotos(file).then(
          function (data) {
            var photo = {
              id: data.id,
              description: '',
              video_url: null,
              order: vm.photos.length,
              thumbnails: data.thumbnails,
            };
            vm.photos.push(photo);
            vm.form.photos.push({id: photo.id, order: photo.order});
            vm.loading = false;
          },
          function () {
            vm.loading = false;
          }
        );
      }

      if (files) {
        vm.loading = true;
        vm.errorPhoto = [];
        vm.errorFormat = [];

        _.each(files, function (file) {
          if (!utils.acceptedFileFormat(file.type)) {
            vm.loading = false;
            return $rootScope.showNotify(
              gettextCatalog.getString('unaccepted file format:') + file.type
            );
          }

          return Upload.imageDimensions(file).then(
            function (d) {
              if (d.width < 600 || d.height < 200) {
                vm.loading = false;
                return $rootScope.showNotify(
                  file.name +
                    gettextCatalog.getString(
                      ':image must me at least 600px wide and 200px tall'
                    )
                );
              } else if (vm.photos.length < maxLength) {
                uploadFile(file);
              } else if (vm.photos.length >= maxLength) {
                vm.loading = false;
                $rootScope.showNotify(
                  gettextCatalog.getString(
                    'You already uploaded maximum number of images.'
                  )
                );
              }
              return true;
            },
            function () {
              vm.loading = false;
              return false;
            }
          );
        });
      }

      return true;
    }

    function createNewLocation() {
      diveGuideService.createNewLocation(vm.form.country).then((location) => {
        diveGuideService
          .addNewLocation(vm.form.area, location.placeId)
          .then(getLocations);
      });
    }
    /**
     * Submit dive guide for review or publish
     * @param  {object} form - Angular form object
     * @param  {number} status - guide status (Draft, Publish, etc..)
     * @param  {string} versionType - "minor" or "major". Staff only
     * @returns {boolean} Just true :-)
     */
    function saveInfo(form, status, versionType) {
      if (form.$valid) {
        vm.isLoading = true;
        vm.form.marineLifeVisibility = vm.marineLife;
        vm.form.status = status;
        if (versionType) {
          vm.form.versionType = versionType;
        }
        if (!vm.form.photos) {
          vm.form.photos = [];
        }

        diveGuideService
          .save(vm.form)
          .then(() => {
            $rootScope.showNotify(
              versionType
                ? gettextCatalog.getString('The new version has been saved.')
                : gettextCatalog.getString(
                  'Thank you for submitting this content. It has now been saved and sent for review.'
                )
            );

            shopService.getChecklistStatus().then(function (data) {
              $rootScope.checkedList = data;
            });
            // clear forms
            vm.form = {
              inputLanguage: vm.form.inputLanguage,
            };
            vm.form2 = {};
            form.$setPristine();
          })
          .finally(() => {
            vm.isLoading = false;
          });
      }

      return true;
    }
    function getMarineLife() {
      const parameters = getLocationType();
      diveGuideService
        .getMarineLife(parameters.locationSlug, parameters.type)
        .then((data) => setMarineLife(data));
    }

    function getLocationType() {
      if (vm.form.area === guideConstants.EDIT_COUNTRY_KEY) {
        const countryObject = vm.countryList.find(
          (country) => country.id === vm.form.country
        );
        return {
          location: vm.form.country,
          title: countryObject.title,
          inTitle: countryObject.in_title,
          locationSlug: countryObject.slug,
          type: 'country',
        };
      } else if (vm.form.location === guideConstants.EDIT_AREA_KEY) {
        const areaObject = vm.areasList.find(
          (area) => area.id === vm.form.area
        );
        return {
          location: vm.form.area,
          title: areaObject.title,
          inTitle: areaObject.inTitle,
          locationSlug: areaObject.slug,
          type: 'area',
        };
      } else if (vm.locationsList) {
        const locationObject = vm.locationsList.find(
          (location) => location.id === vm.form.location
        );
        return {
          location: vm.form.location,
          title: locationObject.title,
          inTitle: locationObject.inTitle,
          locationSlug: locationObject.slug,
          type: 'location',
        };
      }

      return {};
    }

    function getLocationInfo() {
      const parameters = getLocationType();
      vm.divingIn = parameters.inTitle;
      // vm.topDiveSites = [];
      vm.isLoading = true;

      vm.form = {
        location: vm.form.location,
        area: vm.form.area,
        country: vm.form.country,
        inputLanguage: vm.form.inputLanguage,
      };

      // diveGuideService
      //   .topDiveSites(parameters.location, parameters.type)
      //   .then((topDiveSites) => (vm.topDiveSites = topDiveSites));

      // Check if we have stable versions of dive guide.
      return diveGuideService
        .get(parameters.location, parameters.type, vm.form.inputLanguage)
        .then(({ stableVersion, userVersion }) => {
          const lastVersionForUser = userVersion || stableVersion;
          if (lastVersionForUser) {
            if (vm.form.inputLanguage) {
              lastVersionForUser.inputLanguage = vm.form.inputLanguage;
            }
            lastVersionForUser.imageCopyright = false;
            lastVersionForUser.videoCopyright = false;
            // Create two forms
            vm.form = setFormValue(vm.form, lastVersionForUser);
            vm.form.basedOn = lastVersionForUser.id;
            vm.photos = _.clone(vm.form.images);

            if (stableVersion) {
              vm.form2 = setFormValue(vm.form2, _.cloneDeep(stableVersion));
              vm.stableVideoUrl = vm.form.videoUrl;
              vm.stablePhotos = _.clone(vm.form.images);
            } else {
              vm.form2 = false;
              vm.stablePhotos = false;
            }
            // Set marine life to separate object
            setMarineLife(lastVersionForUser.marineLifeVisibility);
          } else {
            // if we don't have any stable guides.
            // In that case we need to get country information and marine life
            vm.form2 = false;
            // We don't need second form in that case
            getMarineLife();

            return diveGuideService
              .getCountryInfo(vm.form.country)
              .then((data) => {
                vm.form = setFormValue(vm.form, data);
                vm.isLoading = false;
              });
          }

          return true;
        })
        .finally(() => {
          vm.isLoading = false;
        });
    }

    function setFormValue(form, newData) {
      return {
        ...form,
        ...newData,
      };
    }

    function setMarineLife(marineLife) {
      vm.marineLife = marineLife;
      vm.stableMarineLife = _.cloneDeep(marineLife);
    }

    function deletePhoto(id) {
      vm.photos = _.reject(vm.photos, { id });
      vm.form.photos = _.reject(vm.form.photos, { id });
    }

    function rejectGuide(form, id) {
      $uibModal.open({
        animation: true,
        templateUrl: '../templates/_reject-dg-ds.html',
        controller: function ($uibModalInstance) {
          this.REJECT_REASON = info.dive_guide.REJECT_REASON;
          this.save = () => {
            this.loading = true;
            diveGuideService
              .rejectGuide(id, this.form)
              .then(() => {
                $rootScope.showNotify(gettextCatalog.getString('Rejected'));
                // clear forms
                vm.form = {
                  inputLanguage: vm.form.inputLanguage,
                };
                vm.form2 = {};
                form.$setPristine();
                $uibModalInstance.close();
              })
              .finally(() => {
                this.loading = false;
              });
          };
          this.close = () => {
            $uibModalInstance.close();
          };
        },
        controllerAs: '$modal',
      });
    }
  });
