'use strict';

angular.module('app')
  .controller('AcitivyPhotos', function ($q, $scope, Upload, utils, product, $routeParams, SeoService, $rootScope, gettextCatalog, $location) {
    var vm = this;
    vm.photosPage = true;
    vm.productId = $routeParams.id;

    vm.selectPhoto = selectPhoto;
    vm.fileUpload = fileUpload;
    vm.saveImages = saveImages;
    vm.removeItem  = removeItem;
    vm.addVideo = addVideo;
    vm.redirectNewExtranet = product.redirectNewExtranet;

    SeoService.generateMetaTags(gettextCatalog.getString('Acitivy photos & videos - PADI Travel for Business'));

    vm.photos = [];
    vm.coverPhotos = [];
    vm.coverVideos = [];
    vm.videos = [];
    vm.loading = true;

    getPhotos();

    function getPhotos() {
      product.getActivityPhotos(vm.productId).then(function (photos) {
        vm.coverPhotos = _.filter(photos, { isCover: true });
        vm.photos = _.filter(photos, { isCover: false });
        vm.coverVideos = [];
        vm.videos = [];
        vm.loading = false;
      });

      product.getProduct($routeParams.id).then(function (data) {
        vm.finishedTabs = data.finishedTabs;
        vm.productData = data;
      });
    }

    function selectPhoto(images, isCover) {
      vm.loading = true;
      var requests = [];

      if (isCover && vm.coverPhotos.length === 1 || isCover && vm.coverVideos.length === 1) {
        replaceCoverItem(addCopiedPhotos);
      } else {
        addCopiedPhotos();
      }

      function addCopiedPhotos() {
        if (_.isArray(images)) {
          _.each(images, function (img) {
            requests.push(product.copyPhotos(img, {id: img.id}));
          });
        } else {
          requests = [product.copyPhotos(images, {id: images.id})];
        }
        $q.all(requests).then(function (data) {
          _.each(data, function (img) {
            vm[isCover ? 'coverPhotos' : 'photos'].push({
              pk: img.imagePk,
              description: '',
              isCover: isCover,
            });
          });

          saveImages(false, true);
        }).catch(function () {
          vm.loading = false;
        });
      }
    }

    function fileUpload(files, maxLength, isCover) {
      var imagesRead = isCover ? vm.coverPhotos : vm.photos;

      function uploadFile(file) {
        product.uploadPhotos(file).then(function (data) {
          var photo = {
            pk: data.id,
            isCover: isCover,
            description: '',
            video_url: null,
            position: imagesRead.length,
            thumbnails: data.thumbnails,
            importSource: 'uploaded',
          };

          if (isCover) {
            // photo.description = 'Cover image';
            photo.position = 1;
            vm.coverPhotos.push(photo);
          } else {
            vm.photos.push(photo);
          }

          vm.loading = false;
        }, function () {
          vm.loading = false;
        });
      }

      if (files) {
        vm.loading = true;
        vm.errorPhoto = [];
        vm.errorFormat = [];

        _.each(files, function (file) {
          if (!utils.acceptedFileFormat(file.type)) {
            vm.loading = false;
            return $rootScope.showNotify(gettextCatalog.getString('unaccepted file format:') + file.type);
          }

          return Upload.imageDimensions(file).then(function (d) {
            if (d.width < 600 || d.height < 400) {
              vm.loading = false;
              return $rootScope.showNotify(file.name + gettextCatalog.getString(':image must me at least 600px wide and 400px tall'));
            } else if (file.size > 15 * 1024 * 1024) {
              vm.loading = false;
              return $rootScope.showNotify(file.name + gettextCatalog.getString(': maximum file size - 15Mb.'));
            } else if (isCover && (vm.coverPhotos.length || vm.coverVideos.length)) {
              replaceCoverItem(uploadFile, file);
            } else if (imagesRead.length < maxLength) {
              uploadFile(file);
            } else if (imagesRead.length >= maxLength) {
              vm.loading = false;
              $rootScope.showNotify(gettextCatalog.getString('You already uploaded maximum number of images.'));
            }
          }, function () {
            vm.loading = false;
          });
        });
      }
    }

    function saveImages(redirect, skipValidation) {
      $scope.extranetForm.$setSubmitted();

      if ($scope.extranetForm.$valid || skipValidation) {
        vm.loading = true;
        var images = _.union(vm.coverPhotos, vm.photos, vm.videos, vm.coverVideos),
          coverAdded = false;
        _.each(images, function (image, index) {
          image.position = index;

          if (image.isCover) {
            coverAdded = true;
          }
        });

        if (!coverAdded) {
          vm.loading = false;
          return $rootScope.showNotify(gettextCatalog.getString('Please upload cover image/video'), 5000);
        }

        product.saveActivityPhotos(vm.productId, { images: images }).then(function () {
          return product.addNew(vm.productData, vm.productId).then(function () {
            if (redirect) {
              $location.path(utils.createURL('product_pricing', {id: vm.productId }));
            } else {
              getPhotos();
            }
          });
        });
      } else {
        vm.loading = false;
      }
      return false;
    }

    function removeItem(index, array) {
      array.splice(index, 1);
    }

    function addVideo(type) {
      if (type === 'videos') {
        if (vm.photos.length + vm[type].length < 5) {
          vm[type].push({trackId: ~~(Math.random() * 10000) + 1 });
        }
      } else if (vm.coverPhotos.length || vm.coverVideos.length) {
        replaceCoverItem(function () {
          // if yes - create new video obj
          vm.coverVideos.push({});
        });
      } else if (!vm.coverPhotos.length && !vm.coverVideos.length) {
        vm[type].push({});
      }
    }

    function replaceCoverItem(callback, arg) {
      // returns popup and when user presses 'yes' performs a callback;
      return utils.warningPopup('../templates/_replace-photo.html').then(function () {
        // if yes delete prev photos and videos
        vm.coverPhotos = [];
        vm.coverVideos = [];
        if (typeof callback === 'function') {
          callback(arg);
        }
      }).catch(function () {
        vm.loading = false;
        return false;
      });
    }
  });
