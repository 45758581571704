'use strict'

angular.module('app').directive('toggleForm', function() {
  return {
    scope: {
      toggleForm: '@'
    },
    restrict: '',
    link: function(scope, elem, attrs, ctrl) {
      $(elem).on('click',function (argument) {
        $(scope.toggleForm).toggleClass('hidden-xs');
      })
    }
  };
});