'use strict';

angular.module('app').directive('showPlugTypes', function () {
  return {
    restrict: 'A',
    link: function (_, element) {
      $(element).fancybox();
    },
  };
});
