'use strict';

angular
  .module('app')
  .controller('TripDocuments', function (
    $location,
    $rootScope,
    $routeParams,
    $scope,
    $timeout,
    utils,
    info,
    DocumentsService,
    itineraryService,
    gettextCatalog,
  ) {
    var vm = this;
    vm.uploadFiles = uploadFiles;
    vm.deleteFile = deleteFile;
    vm.addItineraries = addItineraries;
    vm.onRemove = onRemove;
    vm.groupItineraries = groupItineraries;
    vm.filesPreview = [];
    vm.urlsPreview = [];
    vm.itineraryList = [];
    vm.allItineraries = [];
    vm.shopKind = $rootScope.selectedShop.kind;
    vm.isAllSelected = false;
    vm.isItemsSelected = false;
    vm.loading = false;
    vm.isFilesValid = true;
    vm.uploadUrl = uploadUrl;

    init();

    function init() {
      vm.loading = true;
      DocumentsService.getInformation()
        .then((res) => {
          vm.filesPreview = res.filter((el) => el.document);
          vm.urlsPreview = res.filter((el) => el.url);
          itineraryService.getItineraryList().then((response) => {
            vm.itineraryList = [
              {
                title: gettextCatalog.getString('Apply to all itineraries'),
                id: 'all',
                group: gettextCatalog.getString('Select all'),
              },
              ...response.paginatedList,
            ];
            vm.allItineraries = response.paginatedList.map((item) => item.id);
            vm.filesPreview = vm.filesPreview.map(patchFilePreview);
            vm.urlsPreview = vm.urlsPreview.map(patchFilePreview);
          });
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function excludePreselected(file) {
      const { itineraries } = file,
        itinerariesId = itineraries.map((el) => el.id || el);

      return vm.itineraryList.filter((el) => {
        return !itinerariesId.includes(el.id);
      });
    }

    function patchFilePreview(file) {
      const modFile = _.cloneDeep(file);
      modFile.itineraryList = excludePreselected(file);

      if (file.itineraries.length >= vm.itineraryList.length - 1) {
        modFile.itineraries = [
          {
            title: gettextCatalog.getString('Apply to all itineraries'),
            id: 'all',
            group: gettextCatalog.getString('Select all'),
          },
        ];
        modFile.itineraryList = modFile.itineraryList.map((el) => ({
          ...el,
          inactive: true,
        }));
      } else if (file.itineraries.length) {
        modFile.itineraryList = modFile.itineraryList.map((el) => {
          if (el.id === 'all') {
            return {
              ...el,
              inactive: true,
            };
          }
          return el;
        });
      }
      return modFile;
    }

    function addItineraries(items, file) {
      vm.loading = true;

      if (_.find(items, { id: 'all' })) {
        file.itineraryList = file.itineraryList.map((el) => ({
          ...el,
          inactive: true,
        }));
        DocumentsService.addItineraries(file.id, vm.allItineraries).finally(
          () => {
            vm.loading = false;
          },
        );
      } else {
        file.itineraryList = file.itineraryList.map((el) => {
          if (el.id === 'all') {
            return {
              ...el,
              inactive: true,
            };
          }
          return el;
        });
        DocumentsService.addItineraries(file.id, file.itineraries).finally(
          () => {
            vm.loading = false;
          },
        );
      }
    }

    function onRemove(item, items, file) {
      if (item.id === 'all' || items.length === 0) {
        file.itineraryList.forEach((el) => (el.inactive = false));
      }
      file.itineraryList = excludePreselected(file);
    }

    function uploadFiles(files) {
      if (!files) {
        return;
      }
      const validDocumentsTypes = ['doc', 'docx', 'pdf', 'xls', 'xlsx'];
      vm.isFilesValid = files.every((file) => {
        const extension = file.name.split('.').pop();
        return validDocumentsTypes.includes(extension);
      });

      if (!vm.isFilesValid) {
        return;
      }

      vm.loading = true;
      const data = files.map((file) => {
          return {
            document: file,
            name: file.name,
          };
        }),
        request = data.map(DocumentsService.postInformation);
      Promise.all(request)
        .then((res) => {
          _.each(res, (file) => {
            $timeout(function () {
              const modFile = patchFilePreview(file.data);
              vm.filesPreview.push(modFile);
            }, 500);
          });
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function deleteFile(id) {
      vm.loading = true;
      DocumentsService.deleteFile(id)
        .then(() => {
          init();
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function uploadUrl(url) {
      DocumentsService.postInformation({name: url, url })
        .then((res) => {
          const modFile = patchFilePreview(res.data);
          vm.urlsPreview.push(modFile);
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function groupItineraries(item) {
      return item.group ? 'Select all' : 'Itineraries';
    }
  });
