'use strict';

angular.module('app').directive('setAffiliate', function ($timeout) {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, element, attr, ctrl) {
        if (!window.PostAffTracker) {
          return false;
        }

        window.PostAffTracker.writeAffiliateToCustomField(attr.id);

        $timeout(function(){
          angular.element(element).triggerHandler('change');
        },5000)
      }
    };
});