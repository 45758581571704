'use strict';

angular.module('app').directive('phoneMask', function ($timeout, $rootScope, localStorageService) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elem, attr, ctrl) {
      var phoneField = intlTelInput(elem[0], {
        preferredCountries: [
          'us', 'gb', 'de', 'ch'
        ],
        geoIpLookup: function (callback) {
          callback(localStorageService.get('countryCode') || 'US');
        },
        utilsScript: 'https://d2p1cf6997m1ir.cloudfront.net/static/phone-utils/phone-utils.js',
        initialCountry: 'auto',
      });

      window.phoneField = phoneField
      // Set model value to valid, formatted version.
      ctrl.$validators.ngIntlTelInput = function (value) {
        // if phone number is deleted / empty do not run phone number validation
        if (value || _.get(phoneField, 'telInput.length') > 0) {
          return phoneField.isValidNumber();
        }
        return true;
      };

      ctrl.$parsers.push(function (value) {
        return phoneField.getNumber();
      });
      // Set input value to model value and trigger evaluation.
      ctrl.$formatters.push(function (value) {
        if (value) {
          if (value.charAt(0) !== '+') {
            value = '+' + value;
          }
          setTimeout(function () {
            phoneField.setNumber(value)
          }, 300)
          
          return value;
        }
        return value;
      });
    },
  };
});
