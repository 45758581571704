'use strict';

angular.module('app')
    .controller('TripEndCtrl', function ($q, $timeout, info, $uibModalInstance, utils, itineraryService, itinerary) {

        var vm = this;

        vm.close = function() {
          $uibModalInstance.dismiss('cancel');
        };
        vm.ITINERARY_TIME_APPROXIMATION = info.shop.ITINERARY_TIME_APPROXIMATION;
        vm.save = save;
        vm.getAirport = itineraryService.getAirport;
        vm.itinerary = itinerary;
        vm.setId = setId;
        vm.isSecondToLastDivesOptions = [
          {val: false, title : "last day"},
          {val: true, title : "second to last day"}
        ];

        vm.itinerary.isOtherDisembark = itinerary.guestDisembarkOther ? true : false;
        vm.itinerary.isOtherTime = itinerary.timeArrivalOther ? true : false;
        vm.itinerary.isAirportOther = itinerary.dropoffAirportOther ? true : false;
        vm.itinerary.isHotelOther = itinerary.dropoffHotelOther ? true : false;


        if (vm.itinerary.timeArrival) {
            vm.itinerary.timeArrival = vm.itinerary.timeArrival.slice(0,-3);
        }
        if (vm.itinerary.timeArrivalTo) {
            vm.itinerary.timeArrivalTo = vm.itinerary.timeArrivalTo.slice(0,-3);
        }
        if (vm.itinerary.guestDisembarkTo) {
            vm.itinerary.guestDisembarkTo = vm.itinerary.guestDisembarkTo.slice(0,-3);
        }
        if (vm.itinerary.guestDisembarkFrom) {
            vm.itinerary.guestDisembarkFrom = vm.itinerary.guestDisembarkFrom.slice(0,-3);
        }
        if (vm.itinerary.dropoffAirportTo) {
            vm.itinerary.dropoffAirportTo = vm.itinerary.dropoffAirportTo.slice(0,-3);
        }
        if (vm.itinerary.dropoffAirportFrom) {
            vm.itinerary.dropoffAirportFrom = vm.itinerary.dropoffAirportFrom.slice(0,-3);
        }
        if (vm.itinerary.dropoffHotelFrom) {
            vm.itinerary.dropoffHotelFrom = vm.itinerary.dropoffHotelFrom.slice(0,-3);
        }
        if (vm.itinerary.dropoffHotelTo) {
            vm.itinerary.dropoffHotelTo = vm.itinerary.dropoffHotelTo.slice(0,-3);
        }

        itineraryService.getHarbours().then(function (data) {
            vm.harbours = data;
            vm.loading = false;
        });

        function save(form) {
          form.$setSubmitted();

          if (form.$valid) {
            vm.loading = true;

            itineraryService.saveTripEnd(vm.itinerary).then(function() {
                $uibModalInstance.close('saved');
            });
          }
        }

        function setId($item, field) {
            vm.itinerary[field] = $item.id;
        }

    });
