'use strict';

angular.module('app')
  .controller('ResortCtrl', function ($scope, $rootScope, $location, diveResortService, diveCenterService, utils, info) {
    var vm = this,
      formSubmited = false;

    vm.selectedParams = {};
    vm.loading = false;
    vm.getGroupsParams = diveCenterService.getAmenities;
    vm.resortCategories = info.shop.RESORT_CATEGORY;
    vm.resortLocationTypes = info.shop.RESORT_LOCATION_TYPE;
    vm.diversPercents = info.shop.DIVERS_PERCENT;
    vm.languagesSpoken = _.get(info, "parameters['0']['Languages spoken'].parameters");
    vm.languagePercents = info.shop.LANGUAGE_PERCENT;

    // $scope.$on('$locationChangeStart', function (event) {
    //     if (!formSubmited) {
    //         if ($scope.extranetForm.$dirty && $scope.extranetForm.$invalid) {
    //             event.preventDefault();
    //             utils.warningPopup().then(function () {
    //                 $scope.extranetForm.$setPristine();
    //                 $location.path($rootScope.currentUrl);
    //             }).catch(function () {
    //                 $rootScope.currentUrl = '/resort/';
    //             });
    //         }

    //         if ($scope.extranetForm.$dirty && $scope.extranetForm.$valid) {
    //             diveResortService.setInfo(vm.form).then(function () {
    //                 $rootScope.checkedList.diveResort = true;
    //             });
    //         }
    //     }
    // });

    diveResortService.getInfo().then(function (res) {
      vm.form = res;

      diveCenterService.getParameters().then(function (data) {
        vm.form.parameters = data.parameters;
        _.each(data.parameters, function (val) {
          vm.selectedParams['selected_' + val] = true;
        });
      });
    });

    vm.saveInfo = function (form) {
      if (form.$valid) {
        vm.loading = true;

        if (vm.form.howToGetThere) {
          vm.form.howToGetThereWrite = vm.form.howToGetThere;
        }

        if (vm.form.highlightDescription) {
          vm.form.highlightDescriptionWrite = vm.form.highlightDescription;
        }

        diveResortService.setInfo(vm.form).then(function () {
          formSubmited = true;

          $location.path($rootScope.choosenShop.resortKind == 20 ? utils.createURL('resort_photos') : utils.createURL('dive_center'));

          $rootScope.checkedList.diveResort = true;

          vm.loading = false;
        }).catch(function (error) {
          vm.loading = false;
          _.each(error.data, function (val, key) {
            $scope.extranetForm[key].$setValidity('required', false);
          });
        });
      }
    };


    vm.toggleParameters = function (id) {
      var element = _.filter(vm.form.parameters, function (elem) {
        return elem === id;
      });

      if (element.length) {
        vm.form.parameters = _.reject(vm.form.parameters, function (elem) {
          return elem === id;
        });
      } else {
        vm.form.parameters.push(id);
      }
    };
  });
