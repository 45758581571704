'use strict';

angular.module('app')
  .service('Request', function ($http, $q, utils) {
    var Request = {};


    Request.get = function (link, data, nextPage, cancelation) {
      // var header = {},
      //     token = localStorageService.get('token'),
          var deferredAbort = cancelation;

      // if (token) {
      //    header["Authorization"] = "JWT " + token;
      // }
      
      if (cancelation) {
        var request = $http({
            method: "GET",
            url: nextPage ? link : utils.makeURL(link),
            params: data,
            timeout: deferredAbort.promise
        }),
        promise = request.then(
            function (response) {
                return response ;
            },
            function (response) {
                return $q.reject( "Something went wrong" );
            }
        );

        return promise;
      }

      return $http({
        method: 'GET',
        url: nextPage ? link : utils.makeURL(link),
        params: data
      });
    };

    Request.post = function (link, data) {
      var header = {
            "Content-Type": "application/json"
          };
          // token = localStorageService.get('token');

      // if (token) {
      //    header["Authorization"] = "JWT " + token;
      // }

      return $http({
        method: 'POST',
        headers: header,
        url: utils.makeURL(link),
        data: data
      });
    };

    Request.del = function (link, data, sendQuery) {
      var header = {
            "Content-Type": "application/json"
          };
          // token = localStorageService.get('token');

      // if (token) {
      //    header["Authorization"] = "JWT " + token;
      // }

      return $http({
        method: 'DELETE',
        headers: header,
        url: utils.makeURL(link),
        data: !sendQuery ? data : null,
        params: sendQuery ? data : null
      });
    };

    Request.patch = function (link, data, sendQuery) {
      var header = {
            "Content-Type": "application/json"
          };
          // },
          // token = localStorageService.get('token');

      // if (token) {
      //    header["Authorization"] = "JWT " + token;
      // }

      return $http({
        method: 'PATCH',
        headers: header,
        url: utils.makeURL(link),
        data: data,
        params: sendQuery ? sendQuery : null
      });
    };

    Request.put = function (link, data, sendQuery) {
      var header = {
            "Content-Type": "application/json"
          };
          // token = localStorageService.get('token');

      // if (token) {
      //    header["Authorization"] = "JWT " + token;
      // }

      return $http({
        method: 'PUT',
        headers: header,
        url: utils.makeURL(link),
        data: !sendQuery ? data : null,
        params: sendQuery ? data : null
      });
    };

    return Request;


  });
