'use strict';

angular.module('app')
    .controller('CoreItineraryCtrl', function ($q, $uibModalInstance, Upload, utils, itineraryService, itinerary, $rootScope) {

        var vm = this;

        vm.close = function() {
          $uibModalInstance.dismiss('cancel');
        };

        vm.itinerary = itinerary;
        vm.save = save;
        vm.fileUpload = fileUpload;
        vm.deletePhoto = deletePhoto;
        vm.dragEnd = dragEnd;

        vm.photosThumb = [];
        vm.errorPhoto = [];
        vm.errorFormat = [];
        vm.disableLoading = []

        if (!itinerary) {
          vm.itinerary = {
            photosRead: []
          }
        }

        function save (form) {
          form.$setSubmitted();

          if (form.$valid) {
            vm.loading = true;
            itineraryService[itinerary ? 'saveCore' : 'addCore'](vm.itinerary).then(function() {
                $uibModalInstance.close('saved');
                $rootScope.checkedList.itineraries = true;
            })
            .finally(function() {
              vm.loading = false;
            });
          }
        }

        function fileUpload(files, maxLength) {
            if (files && (vm.itinerary.photosRead.length <= maxLength)) {
                vm.errorPhoto = [];
                vm.errorFormat = [];

                _.each(files, function (file) {

                    var acceptedFormat = _.find(['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'], function (v) {
                        return v == file.type;
                    });

                    if (!acceptedFormat) {
                        return vm.errorFormat.push(file.name);
                    }

                    var randomId = parseInt(Math.random() * 1000000);

                    Upload.imageDimensions(file).then(function (d) {
                        if (d.width < 600 || d.height < 400) {
                            vm.errorPhoto.push(file.name);
                        } else {
                            if (vm.itinerary.photosRead.length + 1 <= 6) {
                                vm.disableLoading.push(randomId);

                                vm.itinerary.photosRead.push({
                                    id: randomId,
                                    absoluteImage: '/images/loading-photo.jpg',
                                    type: 'loader'
                                });

                                itineraryService.uploadPhotos(file).then(function (data) {
                                    vm.itinerary.photosRead = _.reject(vm.itinerary.photosRead, function (val) {
                                        return val.id == randomId;
                                    });

                                    vm.itinerary.photosRead.push(data);

                                    if (!_.isArray(vm.itinerary.photos)) {
                                        vm.itinerary.photos = [];
                                    }
                                    vm.itinerary.photos.push(data.id);

                                    vm.disableLoading = _.without(vm.disableLoading, randomId);
                                }, function (error) {
                                    vm.itinerary.photosRead = _.reject(vm.itinerary.photosRead, function (val) {
                                        return val.id == randomId;
                                    });

                                    vm.disableLoading = _.without(vm.disableLoading, randomId);
                                }, function (progress) {
                                    _.each(vm.itinerary.photosRead, function (val) {
                                        if (val.id == randomId) {
                                            val.progress = progress;

                                            if (progress == 100) {
                                                val.processing = true;
                                            }
                                        }
                                    });
                                });
                            }
                        }
                    }, function (err) {
                        console.log(err);
                    });
                });
            }
        }


        function deletePhoto(id) {
            itineraryService.deletePhoto(id).then(function () {
                vm.itinerary.photosRead = _.reject(vm.itinerary.photosRead, {id: id});
                vm.itinerary.photos = _.without(vm.itinerary.photos,id);
            });
        }


        function dragEnd () {
          _.each(vm.itinerary.photosRead, function(val, index) {
            val.order = index;
          });

          itineraryService.setPhoto(vm.itinerary.photosRead)
        }
    });
