'use strict';

angular
  .module('app')
  .controller('ProductInclusions', function (
    info,
    shopService,
    inclusionsService,
    config,
    $rootScope,
    product,
    $routeParams,
    utils,
    gettextCatalog,
    $location,
    $timeout
  ) {
    var vm = this;

    $rootScope.showActivity = true;
    vm.productInclusionsPage = true;
    vm.productId = $routeParams.id;

    vm.redirectNewExtranet = product.redirectNewExtranet;
    vm.updateRow = _.debounce(updateRow, 300);
    vm.addNewInclusion = addNewInclusion;
    vm.updateTitle = updateTitle;
    vm.deleteItem = deleteItem;
    vm.itinerariesForm = {};
    vm.featuredOrderChoices = config.featuredOrderChoices;
    vm.needTranslate = ['title'];
    vm.saveInclusions = saveInclusions;
    vm.equipChange = equipChange;
    vm.loadedOnce = false;

    init();

    function init() {
      vm.loading = true;
      vm.pricePerOptions = info.shop.LIVEABOARD_EXTRA_PAYED_PER;
      vm.validForOptions = inclusionsService.validForOptions;
      vm.paybleOptions = inclusionsService.paybleOptions;

      shopService.getShopCurrency().then(function (data) {
        vm.currency = data.currency;
      });

      getList();

      product.getProduct($routeParams.id).then(function (data) {
        vm.finishedTabs = data.finishedTabs;
        vm.productData = data;
      });

      vm.loadedOnce = true;
    }

    function getTranslateArray(data) {
      if (data) {
        let result = [];
        _.each(data, function (elem) {
          _.each(elem.inclusions, function (incl) {
            if (incl.autoTranslatedFields) {
              result = result.concat(incl.autoTranslatedFields);
            }
          });
        });
        return result;
      }
      return false;
    }

    function getList() {
      vm.inclusions = [];
      return product.getInclusions(vm.productId).then(function (result) {
        var groupedInclusions = {};
        if (result) {
          groupedInclusions = groupInclusions(result);
          _.each(groupedInclusions, function (inclusionGroup) {
            inclusionGroup.isActive = true;
            inclusionGroup.inclusions.push({
              title: null,
              addField: true,
              groupId: inclusionGroup.id,
              groupCode: inclusionGroup.groupCode,
              includedInPrice: inclusionGroup.groupCode === 'full-scuba-equipment' ? 10 : 0,
              bookableInAdvance: inclusionGroup.groupCode === 'full-scuba-equipment',
              activityId: vm.productId,
              genericId: null,
              extraOptionId: null,
              payableOnSite: false,
              price: null,
            });
          });
        }
        vm.inclusions = groupedInclusions;
        vm.loading = false;
        if (!vm.loadedOnce && utils.isArrayContainElementsFromOtherArray(getTranslateArray(vm.inclusions), vm.needTranslate)) {
          $rootScope.showNotify(gettextCatalog.getString('The text fields on this page have been auto-translated using Google Translate. You may edit them manually if you wish.'));
        }
      });
    }

    function groupInclusions(inclusions) {
      var list = {};
      _.each(inclusions, function (inclusion) {
        // find and mark equipment for extra validation
        if (inclusion.groupCode === 'other-scuba-equipment') {
          inclusion.isOtherScubaEquipment = true;
        } else if (inclusion.groupCode === 'full-scuba-equipment') {
          inclusion.isFullScubaEquipment = true;
        }

        // grouping inclusions
        if (!list[inclusion.groupId]) {
          list[inclusion.groupId] = {
            groupCode: inclusion.groupCode,
            title: inclusion.groupTitle,
            order: inclusion.groupOrder,
            id: inclusion.groupId,
            inclusions: [],
          };
        }
        list[inclusion.groupId].inclusions.push(inclusion);
      });
      return list;
    }

    function equipChange(data) {
      // auto-select bookable in advance vhen set to Included in price = NO;
      if (data.isOtherScubaEquipment && +data.includedInPrice === 10 && !data.bookableInAdvance) {
        data.bookableInAdvance = true;
        return;
      } else if (data.isFullScubaEquipment && !data.bookableInAdvance) {
        data.bookableInAdvance = true;
      }
    }

    function updateRow(form, data) {
      if (data.addField) {
        return;
      }

      if (form.$valid) {
        vm.loading = true;
        // a check just in case if somehow user selects two self-excluding options;
        if (+data.includedInPrice === 10 && data.bookableInAdvance) {
          data.payableOnSite = false;
        }

        if (+data.includedInPrice === 0 || +data.bookableInAdvance === 20) {
          data.price = null;
          data.bookableInAdvance = false;
        }

        product.updateInclusion(data, vm.productId).then(function (res) {
          if (!data.extrOptionId && res.extraOptionId) {
            data.extraOptionId = res.extraOptionId;
          }
        }).finally(function () {
          vm.loading = false;
        });
      } else {
        return;
      }
    }

    function isValidaInclusions() {
      let isValid = true;
      _.each(vm.inclusions, (group) => {
        _.each(group.inclusions, (inclusion) => {
          // validating only price for now;
          if (inclusion.addField) { return; }
          if (isValid && +inclusion.includedInPrice === 10 && inclusion.bookableInAdvance && !inclusion.price) {
            isValid = false;
          }
        });
      });
      return isValid;
    }

    function updateTitle(data) {
      if (data.title) {
        vm.loading = true;
        product.updateInclusion(data, vm.productId).then(function () {
          data.edit = false;
          getList();
        });
      }
    }
    function showTableBody(title) {
      _.each(vm.inclusions, function (item) {
        if (item.title === title) {
          item.isActive = true;
        }
      });
    }

    function deleteItem(data, title) {
      vm.loading = true;
      product.deleteInclusion(data.extraOptionId, vm.productId).then(getList).then(function () {
        showTableBody(title);
      });
    }

    function addNewInclusion(incl, title) {
      incl.submitted = true;

      if (incl.title) {
        vm.loading = true;
        product.updateInclusion(incl, vm.productId).then(getList).then(function () {
          showTableBody(title);
        }).catch(() => {
          vm.loading = false;
        });
      }
    }

    function saveInclusions() {
      if (isValidaInclusions()) {
        vm.loading = true;
        // timeout here because of debounce on some form fields
        $timeout(()=>{
          if (!vm.productData.inclusionsSaved) {
            vm.productData.inclusionsSaved = true;
            product.addNew(vm.productData, $routeParams.id).then(function () {
              vm.loading = false;
              $location.path(utils.createURL('product_complete', {id: vm.productId}));
            }, function () {
              vm.loading = false;
            });
          } else {
            $location.path(utils.createURL('product_complete', {id: vm.productId}));
          }
        }, 550);
      }
    }
  });
