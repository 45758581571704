'use strict';

angular
  .module('app')
  .controller('TripInclusionsCtrl', function (
    $routeParams,
    $rootScope,
    SeoService,
    groupTrips,
    $location,
    gettextCatalog,
    utils
  ) {
    var vm = this;
    vm.inclusionsPage = true;
    vm.includedList = [];
    vm.excludedList = [];
    vm.saveInclusion = saveInclusion;
    vm.deleteInclusion = deleteInclusion;
    vm.tripId = $routeParams.id;
    vm.isEditMode = false;
    vm.nextPage = nextPage;

    activate();

    function activate() {
      vm.loading = true;
      groupTrips.getTrip($routeParams.id).then(function (data) {
        vm.tripData = _.clone(data);
        SeoService.generateMetaTags(vm.tripData.title + gettextCatalog.getString(' - Group Trips - PADI Travel for Business'));
        vm.loading = false;
        getInclusions();
      });
    }

    function getTranslateArray(data) {
      if (data) {
        return _.flatten(_.map(data, 'autoTranslatedFields'));
      }
    }

    function getInclusions() {
      vm.loading = true;
      groupTrips.getInclusions(vm.tripId).then(function (res) {
        if (utils.isArrayContainElementsFromOtherArray(getTranslateArray(res), vm.needTranslate)) {
          $rootScope.showNotify(gettextCatalog.getString('The text fields on this page have been auto-translated using Google Translate. You may edit them manually if you wish.'));
        }
        vm.includedList = _.filter(res, { isIncluded: true });
        vm.excludedList = _.filter(res, { isIncluded: false });
        vm.loading = false;
        vm.isEditMode = false;
      }).catch(function () {
        vm.loading = false;
        vm.isEditMode = false;
      });
    }


    function deleteInclusion(inclusionId) {
      groupTrips.deleteInclusion(vm.tripId, inclusionId).then(function () {
        getInclusions();
      });
    }

    function saveInclusion(inclusion) {
      if (!inclusion.title) {
        inclusion.error = true;
        inclusion.editing = true;
        return;
      }

      if (inclusion.id) {
        groupTrips.updateInclusion(vm.tripId, inclusion).then(function () {
          getInclusions();
        });
      } else {
        groupTrips.addInclusion(vm.tripId, inclusion).then(function () {
          getInclusions();
        });
      }
    }

    function nextPage() {
      $location.path(utils.createURL('group_trips_description', {id: vm.tripId }));
    }
  });
