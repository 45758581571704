'use strict';

angular.module('app').directive('touchDevicesClick', function() {
  return function(scope, elem, attr) {
  	var element = angular.element(elem);
    element.on('touchstart', function(e) {
      // element.triggerHandler('click')
    });
  };
})
