'use strict';

angular
  .module('app')
  .service('formsService', function (Upload, utils, Request) {
    const forms = {
      getFiles: () => {
        return Request.get(
          `shop/${utils.getCurrentShopSlug()}/gis-forms/`
        ).then(function (res) {
          return res.data;
        });
      },
      deleteFile: (id) => {
        return Request.del(
          `shop/${utils.getCurrentShopSlug()}/gis-forms/${id}/`
        ).then(function (res) {
          return res.data;
        });
      },
      uploadFiles: (document) => {
        const headers = {
            'Content-Type': 'application/json',
          },
          token = localStorage.getItem('token');

        if (token) {
          headers.Authorization = 'Bearer ' + token;
        }

        return Upload.upload({
          url: `${utils.apiURL}shop/${utils.getCurrentShopSlug()}/gis-forms/`,
          headers,
          arrayKey: '',
          method: 'POST',
          data: {
            document,
            name: document.name,
          },
        }).then(
          function (res) {
            // SUCCESS EVENT
            return res.data;
          },
          function (err) {
            // ERROR EVENT
            console.warn('ERROR: ', err);
            throw err;
          },
          function (evt) {
            return parseInt((100.0 * evt.loaded) / evt.total, 10);
          }
        );
      },
    };
    return forms;
  });
