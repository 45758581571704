'use strict';

angular.module('app')
  .controller('RegistrationCtrl', function ($scope, $routeParams, AuthExtranet, GTM, $rootScope) {
    var vm = this;

    vm.auth = AuthExtranet;
    vm.loading = false;
    $rootScope.hideHeader = true;

    // Triggered when the email login form is viewed
    GTM.event('user_login_email_viewed');

    vm.login = function (credentials) {
      vm.loading = true;
      if ($scope.loginForm.$invalid) {
        vm.loginSubmited = true;
        vm.loading = false;
        return;
      }

      vm.loginErrors = false;
      GTM.event('user_login_email_submitted');

      vm.auth.login(credentials).then(function () {
        vm.loading = false;
      }).catch(function (data) {
        vm.loginErrors = data;

        GTM.event('user_login_email_error');
        vm.loading = false;
      });
    };

    vm.recoverPassword = function (email) {
      vm.loading = true;
      if ($scope.recoverForm.$invalid) {
        vm.recoverSubmited = true;
        vm.loading = false;
        return;
      }

      vm.recoverErrors = false;

      vm.auth.recoverPassword(email).then(function () {
        $scope.forgotten = false;
        vm.loading = false;
      }, function (error) {
        vm.recoverErrors = error;
        vm.loading = false;
      });
    };

    vm.resetPassword = function (resetPass) {
      vm.loading = true;
      if ($scope.resetForm.$invalid) {
        vm.loading = false;
        vm.resetSubmited = true;
        return;
      }

      resetPass.token = $routeParams.token;
      resetPass.uid = $routeParams.uid;

      vm.resetSubmited = false;

      vm.auth.resetPassword(resetPass).then(function () {
        $scope.forgotten = false;
        vm.loading = false;
      }, function (error) {
        vm.recoverErrors = error;
        vm.loading = false;
      });
    };
  });
