'use strict'

angular.module('app').directive('resizeSvg', function($location) {
  return {
    restrict: 'A',
    scope: {
      resizeSvg: '=',
      allCountries: '=',
      highlight: '=',
      prefilledCountries: '=',
      watchPrefilled: '='
    },
    link: function(scope, elem, attrs, ctrl) {
      $(window).on('resize', function (val){
        var _this = $(this),
            width = _this.width();

        if (width < 1025) {
          var proportions = width / scope.resizeSvg;

          $(elem).css('zoom', proportions); 
        } else {
          $(elem).css('zoom','');
        }
      }).resize();

      if (scope.watchPrefilled) {

        scope.$watch('prefilledCountries', function(){
          $('.land').css('fill','#dddddd');
          
          _.each(scope.prefilledCountries,function(val) {
            var land = $('#' + val.countryCode);
            if (land) {
              land.css('fill','#f23d4e');
            }
          });
        });
      }

      if (scope.highlight) {
        var land = $('.land'),
            countryBlock = $('.country-title');

        land.on('click touchend', function () {
          var elem = $(this),
              code = elem.attr('id');

          _.each(scope.allCountries, function(val) {
            if (code == val.code) {
              scope.$apply(function(){
                $location.path('/d/' + val.slug + '/');
              });
            }
          });
        });

        land.hover(
          function (event) {
            var elem = $(this),
                title = elem.attr('title'),
                id = elem.attr('id'),
                link = $('.countries-list a[data-code="'+id+'"]');

            elem.css('fill', link.length ? '#f23d4e' : '#666666');

            countryBlock.html(title).css({top: event.pageY - 30, left: event.pageX });
        },function(event) {
            $(this).css('fill', '');
            countryBlock.html('');
        });
      }
    }
  };
});