'use strict';

angular.module('app')
  .controller('RateAddEditCtrl', function ($q, $routeParams, $rootScope, $location, rateService, gettextCatalog, roomService, shopService, recalcService, utils) {
    var vm = this;

    vm.saveRate = saveRate;
    vm.updateRoomCategories = updateRoomCategories;
    vm.delItem = delItem;

    var IS_COPY = window.location.href.search('copy') !== -1;

    vm.isCopy = IS_COPY;

    init();

    function init() {
      vm.loading = true;
      vm.form = {
        minimalStay: 1,
        items: [],
      };

      var API_CALLS = [
        shopService.getShopCurrency(),
        roomService.getList(),
        rateService.getList(),
        roomService.getInfo()
      ];

      if ($routeParams.id) {
        API_CALLS.push(rateService.getRate($routeParams.id));
      }

      $q.all(API_CALLS)
        .then(function (data) {
          vm.currency = data[0].currency;
          vm.rooms = data[1];

          vm.allRates = data[2];
          vm.standard = _.find(data[2], {basedOn: null, title: 'Standard'});
          // vm.form.basedOn = vm.standard.id;
          vm.selectedMealPlan = data[3].mealPlan;


          if (data[4]) {
            vm.form = data[4];

            vm.form.isIncreasing = vm.form.isIncreasing.toString();
            vm.form.isPercentageValue = vm.form.isPercentageValue.toString();
            vm.form.basedOn = vm.form.basedOn ? vm.form.basedOn.toString() : null;
            vm.form.rooms = {};
            vm.moreOptions = true;

            if (IS_COPY) {
              vm.form.title = '';
              vm.form.id = null;
            }

            _.each(vm.form.roomCategories, function (val) {
              vm.form.rooms[val] = val;
            });
          }
          vm.loading = false;
        });
    }

    function updateRoomCategories() {
      vm.form.roomCategories = _.reject(vm.form.rooms, function (val) { return val === false; });
    }

    function saveRate(extranetForm) {
      // check if any room is selected
      if (!vm.form.roomCategories.length) {
        extranetForm.$valid = false;
        $rootScope.scrollToError();
        return false;
      }

      if (vm.form.kind != 40) {
        // when user select custom rate display other that date ranges - clear date ranges;
        vm.form.dateFrom = null;
        vm.form.dateTo = null;
      }

      vm.loading = true;

      recalcService.getRecalcUpdates().then(function () {
        if ($rootScope.recalcStatus === 2) {
          $rootScope.showNotify(gettextCatalog.getString('Editing rates is disabled during price recalculations, please wait for recalculation to complete'), 8000);
          vm.loading = false;
          return false;
        }

        vm.form.basedOn = +vm.form.basedOn;
        rateService[vm.form.id ? 'updateRate' : 'createRate'](vm.form).then(function () {
          recalcService.setStatus(1);
          vm.loading = false;
          $location.path(utils.createURL('rates'));
        }).catch(function (error) {
          vm.loading = false;
        });
      });
    }

    function delItem(index) {
      vm.form.items.splice(index, 1);
    }
  });
