'use strict';

angular.module('app')
  .controller('InclusionsDcCtrl', function ($q, $rootScope, $uibModal, $scope, info, shopService, inclusionsService, config) {
    var vm = this;

    vm.updateRow = _.debounce(updateRow, 300);
    vm.updateGeneral = _.debounce(updateGeneral, 300);
    vm.updateRentalEquipment = _.debounce(updateRentalEquipment, 300);
    vm.showRates = showRates;
    vm.saveRates = saveRates;
    vm.addNewInclusion = addNewInclusion;
    vm.updateTitle = updateTitle;
    vm.setValidFromTo = setValidFromTo;
    vm.deleteItem = deleteItem;
    vm.ratesForm = {};
    vm.featuredOrderChoices = config.featuredOrderChoices;
    vm.isDynamicPackages = true;

    init();

    function init() {
      vm.loading = true;
      vm.pricePerOptions = _.filter(info.shop.RESORT_EXTRA_PAYED_PER, function (val) {
        // Day / room && Night / room
        return val[0] !== 15 && val[0] !== 5;
      });
      vm.validForOptions = inclusionsService.validForOptionsResort;
      vm.paybleOptions = inclusionsService.paybleOptionsResort;

      shopService.getShopCurrency().then(function (data) {
        vm.currency = data.currency;
      });

      inclusionsService.getIsPackageDynamic().then(function (data) {
        vm.isDynamicPackages = data;
      });

      getDcItems();
    }

    function updateRow(form, data) {
      if (data.addField) {
        return true;
      }

      if (data.payedPer === 110) {
        data.payedInAdvance = true;
      }

      if (data.status == 0 || data.isMandatory || data.payedInAdvance) {
        data.isBookableInAdvance = true;
      }

      if (form.$valid) {
        if (data.subkind) {
          var general = _.filter(vm.inclusions[1].data, {subkind: 'general'}),
            obj = {};

          _.each(general, function (val) {
            obj[val.kind] = val.status;
          });

          data = obj;
          data.kind = 'general';
        }

        if (data.payedInAdvance === false && data.extraValue && data.extraValue.trim()) {
          data.price = !isNaN(+data.extraValue) ? data.extraValue : null;
        }

        inclusionsService.updateResort(data);
      }
    }

    function updateTitle(data) {
      if (data.title) {
        vm.loading = true;
        inclusionsService.updateResort(data).then(function () {
          data.edit = false;
          vm.loading = false;
        });
      }
    }

    function deleteItem(data, title) {
      vm.loading = true;
      inclusionsService.delDC(data).then(getDcItems).then(function () {
        showTableBody(title);
      });
    }

    function showRates(incl) {
      if (incl.appliedToTrips) {
        return 1;
      }

      vm.disableScreen = true;
      vm.showTable = true;
      vm.loadingRates = true;
      vm.selectedInclusion = incl;

      inclusionsService.getRatesList(incl).then(function (val) {
        vm.loadingRates = false;
        vm.rates = val;
      });
    }

    function saveRates(cancel) {
      if (cancel) {
        vm.disableScreen = false;
        vm.showTable = false;
        return vm.loadingRates = false;
      }

      var touchedObjects = [];
      _.each(vm.ratesForm, function (val, key) {
        if (val.$dirty && val.$valid) {
          var rate = _.find(vm.rates, {id: +key});

          rate.pk = vm.selectedInclusion.pk;

          touchedObjects.push(
            inclusionsService.updateRate(rate)
          );
        }
      });

      if (touchedObjects.length) {
        vm.loadingRates = true;

        $q.all(touchedObjects).then(function () {
          vm.disableScreen = false;
          vm.showTable = false;
          vm.loadingRates = false;
        }).catch(function () {
          vm.loadingRates = false;
        });
      } else {
        vm.disableScreen = false;
        vm.showTable = false;
      }
    }

    function showTableBody(title) {
      _.each(vm.inclusions, function (item) {
        if (item.title === title) {
          item.isActive = true;
        }
      });
    }

    function addNewInclusion(incl, title) {
      incl.submitted = true;

      if (incl.title) {
        vm.loading = true;
        incl.kind = 360;
        incl.isDiveCenterExtra = true;

        inclusionsService.addNewDCItem(incl).then(getDcItems).then(function () {
          showTableBody(title);
        });
      }
    }

    function updateGeneral() {
      return inclusionsService.updateGeneral({isNewModelUsed: vm.isNewModelUsed});
    }

    function updateRentalEquipment(incl) {
      inclusionsService.updateRentalEquipment(incl);
    }

    function setValidFromTo(item) {
      inclusionsService.setValidFromTo(item, inclusionsService.updateResort, getDcItems);
    }

    function getDcItems() {
      vm.loading = true;
      return inclusionsService.getListDC().then(function (val) {
        vm.inclusions = val.list;
        vm.loading = false;
      });
    }
  });
