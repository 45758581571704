'use strict';

angular.module('app')
	.service('promotionsService',
		function ($q, $rootScope, $location, $uibModal, Request, Upload, utils, diviacLocalStorage, GTM) {

		return {
			getList: function() {
				return Request.get('promotions/' + utils.getCurrentShopSlug() + '/').then(function(res) {
					return res.data;
				});
			},
			getItem: function (id) {
				return Request.get('promotions/' + utils.getCurrentShopSlug() + '/' + id + '/').then(function(res) {
					if (res.data) {
						res.data.dateFrom = moment(res.data.dateFrom).utc().format('YYYY-MM-DD');
						res.data.dateTo = moment(res.data.dateTo).utc().format('YYYY-MM-DD');
					}
					return res.data;
				});
			},
			patchPromo: function(promo, type) {
				var obj = {};
				console.log(type,promo[type]);

				obj[type] = promo[type];
				return Request.patch('promotions/' + utils.getCurrentShopSlug() + '/' + promo.id + '/', obj).then(function(res) {
					return res.data;
				});
			},
			savePromo: function (data, tripsList) {
				var header = {
							"Content-Type": "application/json"
						},
						token = localStorage.getItem('token');

				if (token) {
					header["Authorization"] = "Bearer " + token;
				}

				var obj = {};

				for (var key in data) {
				    obj[utils.camelToUnderscore(key)] = data[key] ? data[key] : undefined;
				}

				obj.date_from = moment(obj.date_from).format('YYYY-MM-DDThh:mm');
				obj.date_to = moment(obj.date_to).format('YYYY-MM-DDThh:mm');


				if (obj.kind !== 30) {
					obj.required_nights = undefined;
				}

				if (obj.kind === 40) {
					obj.value = undefined;
				}

				if (obj.promotion_application_kind == 20) {
					obj.itineraries = _.filter(_.map(obj.itineraries), function(val){
		        		return val !== false
		        	});
		        	obj.trip_in_range_from = undefined;
		        	obj.trip_in_range_to = undefined;
		        	obj.trips = [];
				} else if (obj.promotion_application_kind == 30) {
					obj.trips = _.filter(_.map(obj.trips), function(val){
		        		return val !== false
		        	});
		        	obj.trip_in_range_from = undefined;
		        	obj.trip_in_range_to = undefined;
		        	obj.itineraries = [];
				} else if (obj.promotion_application_kind == 40){
					obj.trips = [];
		        	obj.itineraries = [];

		        	obj.trip_in_range_from = moment(obj.trip_in_range_from).format('YYYY-MM-DDThh:mm');
					obj.trip_in_range_to = moment(obj.trip_in_range_to).format('YYYY-MM-DDThh:mm');
				} else {
					obj.trips = _.map(tripsList,'id');
		        	obj.itineraries = [];
		        	obj.trip_in_range_from = undefined;
		        	obj.trip_in_range_to = undefined;
				}

				// if (_.isString(obj.picture)) {
				// 	obj.picture = undefined;
				// }
				if (_.isUndefined(obj.picture)){
					obj.picture = "";
				}

				if (obj.tags) {
					var tags = []
					_.each(obj.tags, function(val, key) {
						obj['tags['+ key +']'] = JSON.stringify(val)
					})
					obj.tags = undefined
				}

				return Upload.upload({
					url: utils.apiURL + 'promotions/' + utils.getCurrentShopSlug() + '/' + (obj.id ? obj.id + "/" : ""), 
					headers: header,
					method: obj.id ? 'PATCH' : 'POST',
					arrayKey: '',
					data: obj
				}).then(function(res) {
				    //SUCCESS EVENT
			        return res.data;
			    }, function(err) {
			        //ERROR EVENT
			        console.warn('ERROR: ',err);
			        throw err;
			    }, function (evt) {
			          // PROGRESS EVENT
			          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
			          return progressPercentage;
			    });
			},
			deletePromo: function (id) {
				return Request.del('promotions/' + utils.getCurrentShopSlug() + '/' + id + '/').then(function (res) {
					return res.data;
				});
			}
		}
 })
