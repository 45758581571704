/* eslint-disable consistent-return */
'use strict';

angular
  .module('app')
  .controller('MainCtrl', function (
    $scope,
    $rootScope,
    $route,
    $q,
    $timeout,
    $interval,
    $templateCache,
    $location,
    AuthExtranet,
    info,
    diviacLocalStorage,
    localStorageService,
    shopService,
    diveCenterService,
    utils,
    GTM,
    landing,
    recalcService,
    groupTrips,
    selectedLanguage,
    gettextCatalog,
    config
  ) {
    var vm = this,
      isRedirected = false;
    vm.langList = config.langList;
    vm.config = config;
    vm.langListWithoutOther = _.reject(config.langList, { id: 'other' });
    vm.selectedLanguage = selectedLanguage;
    vm.changeLanguage = function (val) {
      utils.setLanguage(val);
      window.location.href = utils.generateLangUrl(val);
    };
    vm.tags = [];
    vm.tagTransform = utils.tagTransform;
    vm.onChange = function (s) {
      return utils.searchTag(s, function (tag) {
        vm.tags = tag;
      });
    };
    vm.generateGroupTripLink = utils.generateGroupTripLink;
    vm.loadingTypeahead = false;
    AuthExtranet.tokenRefresh();
    setTimeout(() => {
      AuthExtranet.tokenRefresh;
    }, 30 * 60 * 1000); // 30m
    AuthExtranet.checkShopLanguage();

    if (window.requireRedirect && window.requireRedirect !== selectedLanguage) {
      window.location.href = utils.generateLangUrl(window.requireRedirect);
    }

    if (selectedLanguage === 'ach') {
      var script = document.createElement('script');
      script.src = 'https://cdn.crowdin.com/jipt/jipt.js';
      document.getElementsByTagName('head')[0].appendChild(script);
    }

    $scope.$on('$routeChangeStart', function (__, toState, oldState) {
      $rootScope.currentUrl = toState.$$route && toState.$$route.originalPath;

      $rootScope.notFound = false;
      $rootScope.error404 = false;
      $rootScope.authorized = AuthExtranet.getUser();
      $rootScope.groupTrip = {
        id: null,
        isActive: false,
      };

      isRedirected = false;

      $rootScope.oldState = isRedirected || oldState;

      if (toState.controller === 'RegistrationCtrl as reg') {
        $rootScope.currentUrl = '/register/';
        $rootScope.oldState = false;
      }

      $rootScope.user = AuthExtranet.getUser();
      $rootScope.choosenShop = AuthExtranet.getChoosenShop();

      const { selectedShop } = $rootScope;

      if (!$rootScope.user) {
        GTM.variables({ user_type: 'Guest' });
      }

      GTM.event('page_new_load_start');

      if (toState.data) {
        if (toState.data.authorization === false && $rootScope.authorized) {
          return AuthExtranet.enterShop();
        }

        if (toState.data.authorization === true && !$rootScope.authorized) {
          isRedirected = true;
          return true;
        }

        if (
          toState.data.shopKind &&
          selectedShop &&
          !_.contains(toState.data.shopKind, $rootScope.selectedShop.kind)
        ) {
          return AuthExtranet.enterShop();
        }

        if (
          toState.data.resortKind &&
          selectedShop &&
          selectedShop.kind === 20 &&
          !_.contains(toState.data.resortKind, selectedShop.resortKind)
        ) {
          return AuthExtranet.enterShop();
        }

        // staffOnly array contains shop kinds where Staff validation needed
        if (
          !$rootScope.user.isStaff &&
          toState.data.staffOnly &&
          selectedShop &&
          _.contains(toState.data.staffOnly, selectedShop.kind)
        ) {
          if (
            $rootScope.user.isAffiliateManager &&
            toState.data.allowAffManager
          ) {
            return;
          }
          $rootScope.showNotify(
            gettextCatalog.getString('Only PT staff can enter this section'),
            4000
          );
          return AuthExtranet.enterShop();
        }

        // forbid non-staff users to access separate DR's and LA's
        // in future we might need a solution to allow separate pages of shops to be accessed but for now this will do.
        // if (
        //   ($rootScope.selectedShop.resortKind === 20 && !$rootScope.user.isStaff) ||
        //   ($rootScope.selectedShop.kind === 10 && !$rootScope.user.isStaff)
        // ) {
        //   $rootScope.showNotify('Only PT staff can enter this shop', 4000);
        //   return $location.path('/');
        // }
      } else {
        $rootScope.notFound = true;
      }

      if (typeof oldState !== 'undefined') {
        $templateCache.remove(oldState.templateUrl);
      }
    });

    $scope.$on('$viewContentLoaded', function () {
      var shop = $rootScope.choosenShop,
        isManager = $rootScope.user && !$rootScope.user.isStaff,
        isAdminRoute = ['vacations_agreement', 'admin'].includes(
          $route.current.locals.routeName
        );

      if (shop && shop.showAgreementPopup && isManager && !isAdminRoute) {
        utils
          .mandatoryPopup('../templates/_agreement-popup.html')
          .catch(function () {
            return false;
          });
      }
    });

    $rootScope.$on('$viewContentLoaded', function () {
      $timeout(function () {
        var url = $location.$$absUrl
          .replace('https://', '')
          .replace('http://', '');

        GTM.variables({
          page_data_title: $rootScope.title,
          page_data_url: url,
        });
        GTM.event(['page_new_load_finish', 'page_datalayer_load_finish']);
      }, 300);
      $rootScope.user = AuthExtranet.getUser();

      $timeout(function () {
        var selectedElement = $('.left-navigation li.active');

        if (selectedElement.length && !utils.isInViewport(selectedElement)) {
          $('.left-navigation').scrollTop(selectedElement.offset().top - 300);
        }
      }, 150);
    });

    $rootScope.user = AuthExtranet.getUser();

    // if ($rootScope.user) {
    //   AuthExtranet.setIntercom($rootScope.user);
    // }

    vm.auth = AuthExtranet;
    vm.goTo = goTo;
    vm.info = info;
    vm.utils = utils;
    vm.changeState = changeState;
    vm.recalcPrices = recalcPrices;
    vm.changeGTStatus = changeGTStatus;
    vm.gtmTrack = GTM.clickTrack;
    vm.groupTripStatusList = info.marketplace.GROUP_TRIP_STATUS_CHOICES;
    vm.autoSuggest = function (value) {
      if (value.length < 2) {
        return false;
      }
      vm.loadingTypeahead = true;
      return landing.search(value).finally(() => {vm.loadingTypeahead = false;});
    };

    if (
      diviacLocalStorage.get('choosenShop') &&
      !localStorageService.get('choosenShop')
    ) {
      localStorageService.set(
        'choosenShop',
        diviacLocalStorage.get('choosenShop')
      );
    }

    $rootScope.selectedShop =
      localStorageService.get('choosenShop') ||
      diviacLocalStorage.get('choosenShop');

    $rootScope.$watchCollection('selectedShop + user', function (newVal) {
      if (newVal && AuthExtranet.getUser()) {
        getChecklistStatus();
      }
    });

    getStatusUpdates(); // retrieve status once
    $interval(getStatusUpdates, 40000); // then start polling for updates

    var user = AuthExtranet.getUser();
    if (user) {
      GTM.variables({
        user_id: user.id,
        user_email: user.email,
        user_first_name: user.firstName,
        user_last_name: user.lastName,
        user_registration_date: new Date(user.claim).getTime(),
        user_services: user.services,
        user_type: 'Registered',
      });
      AuthExtranet.checkCookie().then((data) => {
        if (data.user && data.user.id !== user.id) {
          window.location.reload();
        }
      });
      // if ($cookies.get('logout') && $cookies.get('logout') === 'True') {
      //   AuthExtranet.logout();
      // }
    } else {
      GTM.variables({ user_type: 'Guest' });
      $rootScope.loader = true;
      AuthExtranet.checkCookie().then(() => {
        // We need to refresh the page one more time because some pages have different rules for staff. And I don't want to add listeners on each page, much better will be just to reload the page.
        window.location.reload();
      });
    }

    const shopId = $location.search().shopId;

    if (shopId) {
      shopService.getShopData(shopId).then((shop) => {
        AuthExtranet.setChoosenShop(shop, true);
        $location.search({});
        if ($rootScope.selectedShop && $rootScope.selectedShop.id !== +shopId) {
          window.location.reload();
        }
      });
    }

    // Sandro analytics
    var sourceId = $location.search().source;

    if (sourceId) {
      sourceId = sourceId.split(',');
      var sourceString = '';

      _.each(sourceId, function (val) {
        sourceString += val + ',';
      });

      GTM.variables({ user_source: sourceString.slice(0, -1) });
    }

    const dcReadyToPublish = ['basics', 'photos', 'createDiveSites'],
      { isStaff, selectedShop } = $rootScope;

    $rootScope.$watch(
      'checkedList',
      function (val) {
        var allChecked = false;

        if (val) {
          allChecked = true;

          $rootScope.dcReadyToPublish = dcReadyToPublish.every(
            (item) => val[item]
          );
          if (
            !isStaff &&
            selectedShop &&
            selectedShop.kind === 0 &&
            !$rootScope.isOnAdventures &&
            $rootScope.shopStatusLoaded &&
            $rootScope.dcReadyToPublish
          ) {
            // diveCenterService.showStatusPopup();
          }

          _.each(val, function (v, k) {
            if (v === false) {
              allChecked = false;
            }

            var key =
                'business_listing_' +
                k.replace(/([A-Z])/g, '_$1').toLowerCase(),
              variable = {};

            if (v) {
              variable[key] = 'Complete';

              GTM.variables(variable);
            }
          });

          vm.allChecked = allChecked;

          $rootScope.showListing = !vm.allChecked;

          if (vm.allChecked) {
            $rootScope.showManagement = true;
          }

          $rootScope.fullyDone = allChecked;
        }

        GTM.variables({
          business_listing_overall: allChecked
            ? gettextCatalog.getString('Complete')
            : gettextCatalog.getString('Start'),
        });
      },
      true
    );

    vm.getUpgradeURL = getUpgradeURL;

    function goTo(url) {
      $location.url({});
      $location.path(url);
    }

    vm.showNavigation = false;

    function getChecklistStatus() {
      if ($rootScope.selectedShop) {
        shopService.getNewChecklistStatus();
        $q.all([shopService.getChecklistStatus(), shopService.getState()]).then(
          function ([checkedList, shopStatus]) {
            $rootScope.isAvailableShop = shopStatus.isAvailableShop;
            $rootScope.isAdventureAvailableShop =
              shopStatus.isAdventureAvailableShop;
            $rootScope.shopStatusLoaded = true;
            $rootScope.checkedList = checkedList;
            $rootScope.isOnline = shopStatus.isOnline;
            GTM.variables({
              business_listing_status: shopStatus.isAvailableShop
                ? gettextCatalog.getString('Published')
                : gettextCatalog.getString('Unpublished'),
            });
          }
        );

        shopService.getSeoInfo().then(function (res) {
          vm.shopInfo = res;
          if (vm.shopInfo.businessType !== 'Dive resort') {
            vm.showNavigation = true;
          }
        });

        var shop = $rootScope.selectedShop;

        GTM.variables({
          business_id: shop.id,
          business_name: shop.title,
        });
      } else {
        $rootScope.noShop = true;
      }
    }

    function changeState(type) {
      return shopService.setStateWithConfirm(type);
    }

    function getStatusUpdates() {
      if ($rootScope.user && $rootScope.selectedShop) {
        recalcService.getRecalcUpdates().then(function (data) {
          vm.recalcDate = data;
        });
      } else {
        return;
      }
    }

    function recalcPrices() {
      recalcService.recalcPrices().then(
        function () {
          recalcService.setStatus(2);
        },
        function (err) {
          console.error(err);
        }
      );
    }

    function getUpgradeURL() {
      var link =
          'http://info.trekksoft.com/booking-platform-for-scuba-diving-centers',
        shop = diviacLocalStorage.get('choosenShop');

      if (shop) {
        return link + utils.generateQueryParams(shop);
      }

      return false;
    }

    function changeGTStatus(oldVal, callback) {
      function onSuccess() {
        $rootScope.showNotify(
          gettextCatalog.getString('Trip status was successfully updated')
        );
        vm.GTStatusLoading = false;
        if (typeof callback === 'function') {
          callback();
        }
      }

      function onError() {
        $rootScope.groupTrip.status = oldVal;
        vm.GTStatusLoading = false;
      }
      if ($rootScope.groupTrip.id) {
        vm.GTStatusLoading = true;
        // check if there is pricing packages in this trip
        if (
          (+$rootScope.groupTrip.status === 20 ||
            +$rootScope.groupTrip.status === 30) &&
          +oldVal === 10
        ) {
          groupTrips
            .getTripPackages($rootScope.groupTrip.id)
            .then(function (result) {
              if (result.data.length > 0) {
                groupTrips
                  .updateTrip($rootScope.groupTrip)
                  .then(onSuccess)
                  .catch(onError);
              } else {
                onError();
                $rootScope.showNotify(
                  gettextCatalog.getString(
                    'Please check your pricing tab, at least one package should be created'
                  )
                );
              }
            })
            .catch(onError);
        } else {
          groupTrips
            .updateTrip($rootScope.groupTrip)
            .then(onSuccess)
            .catch(onError);
        }
      }
    }
  });
