'use strict';

angular.module('app').directive('bindCopy', function (GTM) {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        $(element).bind('copy', function(){ GTM.event('marketing_widgets_booking_create_code_copy')});
      }
    };
});
