'use strict';

angular
  .module('app')
  .controller('BasicCtrl', function (
    $scope,
    $timeout,
    $location,
    $rootScope,
    utils,
    shopService,
    info,
    uiGmapGoogleMapApi,
    diviacLocalStorage,
    localStorageService,
    diveCenterService,
    gettextCatalog,
    GTM
  ) {
    var vm = this;
    // formSubmitted = false;

    vm.form = {
      tags: [],
    };
    vm.countryList = info.countryList;
    vm.timeZone = info.common.AVAILABLE_TIMEZONES;
    vm.centerChanged = centerChanged;
    vm.setPin = setPin;
    vm.getCoords = getCoords;
    vm.saveShop = saveShop;
    vm.loading = false;
    vm.activate = activate;
    vm.dcParams = diveCenterService.getAmenities('Dive center');
    vm.membershipParams = [];
    vm.paramsForBasicTab = [];
    vm.selectedParams = {};
    vm.needTranslate = ['dive_site_description', 'about'];

    vm.disabledDiveCenterAddressName =
      $rootScope.selectedShop.kind === 0 && !$rootScope.user.isStaff;

    vm.isHideDiveCenterAddressName = $rootScope.selectedShop.resortKind === 20;

    activate();

    // $scope.$on('$locationChangeStart', function (event, toState, oldState) {
    //   if (!formSubmited) {
    //     if ($scope.extranetForm.$dirty && $scope.extranetForm.$invalid) {
    //       event.preventDefault();
    //       utils
    //         .warningPopup()
    //         .then(function () {
    //           $scope.extranetForm.$setPristine();
    //           $location.path($rootScope.currentUrl);
    //         })
    //         .catch(function () {
    //           $rootScope.currentUrl = '/basics/';
    //         });
    //     }

    //     if ($scope.extranetForm.$dirty && $scope.extranetForm.$valid) {
    //       shopService.setInfo(vm.form).then(function () {
    //         $rootScope.checkedList.basics = true;

    //         GTM.variables({ business_listing_dive_center: 'Complete' });
    //         GTM.event('business_listing_basics_complete');
    //       });
    //     }
    //   }
    // });

    function saveShop(form) {
      if (form.$valid) {
        vm.loading = true;
        shopService
          .setInfo(vm.form)
          .then(function (data) {
            GTM.event('business_listing_basics_complete');
            GTM.variables({ business_listing_basics: 'Complete' });
            // formSubmitted = true;
            if ($rootScope.choosenShop.slug !== data.slug) {
              $rootScope.choosenShop.slug = data.slug;
              diviacLocalStorage.set('choosenShop', $rootScope.choosenShop);
              localStorageService.set('choosenShop', $rootScope.choosenShop);
            }

            diveCenterService
              .setParameters(vm.allParams)
              .then(function () {
                $rootScope.checkedList.basics = true;

                vm.loading = false;
                if (
                  $rootScope.choosenShop &&
                  parseInt($rootScope.choosenShop.kind, 10) === 20
                ) {
                  $rootScope.choosenShop.resortTitle = data.resortTitleRead;
                }
                $location.path(utils.createURL('photos'));
                diviacLocalStorage.set('choosenShop', $rootScope.choosenShop);
                localStorageService.set('choosenShop', $rootScope.choosenShop);
              })
              .catch(function () {
                $rootScope.showNotify(
                  gettextCatalog.getString('error saving parameters')
                );
              });
          })
          .catch(function (error) {
            vm.loading = false;
            _.each(error.data, function (val, key) {
              if ($scope.extranetForm[key]) {
                $scope.extranetForm[key].$setValidity(key, false);
              }
            });
            if (error.data.resortTitleWrite) {
              $scope.extranetForm.resortTitle.$invalid = true;
              $scope.extranetForm.resortTitle.errorText =
                error.data.resortTitleWrite[0];
            }
          });
      }
    }

    function centerChanged(marker) {
      var center = marker.getPosition();

      $scope.center = {
        latitude: center.lat(),
        longitude: center.lng(),
      };

      vm.form.lat = $scope.center.latitude;
      vm.form.lng = $scope.center.longitude;

      vm.form.coords = vm.form.lat + ', ' + vm.form.lng;

      vm.form.latitude = $scope.center.latitude;
      vm.form.longitude = $scope.center.longitude;
    }

    function setPin() {
      if (vm.form.coords) {
        var coords = vm.form.coords.split(',');

        $scope.center = {
          latitude: parseFloat(coords[0]),
          longitude: parseFloat(coords[1]),
        };

        $scope.map.marker = $scope.center;

        vm.form.latitude = $scope.center.latitude;
        vm.form.longitude = $scope.center.longitude;
      }
    }

    function getCoords() {
      var address = '';
      if (vm.form.country) {
        var country = _.find(vm.countryList, { id: vm.form.country });
        address += country.title + ', ';
      }

      if (vm.form.city) {
        address += vm.form.city + ', ';
      }

      if (vm.form.address) {
        address += vm.form.address;
      }

      if (address) {
        shopService.googleGeoCode(address).then(function (data) {
          if (!_.isEmpty(data) && data[0].geometry) {
            $scope.center = {
              latitude: data[0].geometry.location.lat,
              longitude: data[0].geometry.location.lng,
            };

            vm.form.coords =
              $scope.center.latitude + ',' + $scope.center.longitude;

            vm.form.latitude = $scope.center.latitude;
            vm.form.longitude = $scope.center.longitude;

            var viewport = data[0].geometry.viewport;

            if (viewport) {
              $scope.bounds = {
                northeast: {
                  latitude: viewport.northeast.lat,
                  longitude: viewport.northeast.lng,
                },
                southwest: {
                  latitude: viewport.southwest.lat,
                  longitude: viewport.southwest.lng,
                },
              };
            }

            $scope.map.marker = _.clone($scope.center);
          }
        });
      }
    }

    vm.toggleParameters = function (id) {
      var element = _.filter(vm.allParams, function (elem) {
        return elem === id;
      });

      if (element.length) {
        vm.allParams = _.reject(vm.allParams, function (elem) {
          return elem === id;
        });
      } else {
        vm.allParams.push(id);
      }
    };

    function activate() {
      GTM.event('business_listing_basics_start');

      $scope.center = {
        latitude: 50,
        longitude: 45,
      };

      $scope.map = {
        marker: {
          latitude: $scope.center.latitude,
          longitude: $scope.center.longitude,
        },
        options: {
          icon: '/images/marker.png',
          draggable: true,
        },
        events: {
          dragend: centerChanged,
        },
      };

      shopService.getInfo().then(function (data) {
        vm.form = data;

        if (
          utils.isArrayContainElementsFromOtherArray(
            vm.form.autoTranslatedFields,
            vm.needTranslate
          )
        ) {
          $rootScope.showNotify(
            gettextCatalog.getString(
              'The text fields on this page have been auto-translated using Google Translate. You may edit them manually if you wish.'
            )
          );
        }

        if (vm.form.latitude && vm.form.longitude) {
          vm.form.coords = vm.form.latitude + ', ' + vm.form.longitude;

          $scope.center = {
            latitude: data.latitude,
            longitude: data.longitude,
          };

          $scope.map.marker.latitude = data.latitude;
          $scope.map.marker.longitude = data.longitude;
        }

        if (
          $rootScope.selectedShop &&
          parseInt($rootScope.selectedShop.kind, 10) === 20
        ) {
          vm.form.resortTitleWrite = data.resortTitleRead;

          vm.form.title =
            data.resortTitleRead === vm.form.title ? null : vm.form.title;
        }

        if (vm.form.mainAgency === 10) {
          vm.form.padi = true;
        }
        if (vm.form.mainAgency === 20) {
          vm.form.ssi = true;
        }
      });

      diveCenterService.getParameters().then(function (data) {
        vm.allParams = data.parameters;
        _.each(data.parameters, function (val) {
          vm.selectedParams['selected_' + val] = true;
        });
      });

      _.each(vm.dcParams, function (paramGroup) {
        if (paramGroup && parseInt(paramGroup.kind, 10) === 180) {
          vm.membershipParams = paramGroup.parameters;
          return;
        }
        if (paramGroup && paramGroup.parameters.length !== 0) {
          _.each(paramGroup.parameters, function (param) {
            if (_.includes(param.tags, 'show-on-basics-page')) {
              vm.paramsForBasicTab.push(param);
            }
          });
        }
      });
    }
  });
