'use strict';

angular.module('app')
  .controller('ItineraryPriceCtrl', function ($q, $location, Upload, utils, gettextCatalog, itineraryService, $scope, shopService, $rootScope, info) {
    var vm = this;

    vm.changeCurrency = changeCurrency;
    vm.saveTrip = _.debounce(saveTrip, 300);
    vm.saveItinerary = _.debounce(saveItinerary, 300);
    vm.generatePriceForOccupancy = generatePriceForOccupancy;
    vm.getPricing = getPricing;
    vm.saveAll = saveAll;

    var changedTrips = [],
      changedItineraries = [],
      formSubmited = false;

    // $scope.$on('$locationChangeStart', function (event, toState, oldState) {
    //     if (!formSubmited) {
    //         if ($scope.pricingForm.$dirty || $scope.extranetForm.$dirty) {
    //             event.preventDefault();
    //             utils.warningPopup().then(function () {
    //                 $scope.pricingForm.$setPristine();
    //                 $scope.extranetForm.$setPristine();
    //                 $scope.pricingForm.$setPristine();
    //                 $location.path($rootScope.currentUrl);
    //             }).catch(function () {
    //                 $rootScope.currentUrl = '/itinerary-price/';
    //             });
    //         }
    //     }
    // });

    activate();

    function activate() {
      vm.loading = true;
      vm.currencyList = info.shop.CURRENCY_CHOICES;

      vm.diverOrNo = [
        {
          title: 'Diver',
          isDiver: true,
        },
        {
          title: 'Non-diver',
          isDiver: false,
        }
      ];


      shopService.getShopCurrency().then(function (data) {
        vm.currency = data.currency;
      });

      itineraryService.getItineraryList().then(function (data) {
        vm.allItineraries = data.allList;
        vm.itineraries = data.paginatedList;
      });

      itineraryService.getCabins().then(function (data) {
        var max = _.max(data, 'maxOccupancy');

        if (max) {
          vm.maxOccupancy = [];

          for (var i = 1; i <= max.maxOccupancy; i++) {
            vm.maxOccupancy.push(i);
          }
        }
      });


      itineraryService.getOptions().then(function (data) {
        vm.params = data;

        vm.loading = false;

        // $scope.$watch(function () {
        //     return vm.params;
        // }, _.debounce(function (oldVal, newVal) {
        //     if ($scope.extranetForm.$invalid) {
        //         return 1;
        //     }
        //     if (oldVal !== newVal) {
        //         if(oldVal.isItineraryLevelPrice && !newVal.isItineraryLevelPrice) {
        //             $rootScope.showNotify('WARNING: if you had previously entered prices at the trip level, these will be over-written by any prices you enter at the Itinerary level');
        //         }
        //         if (oldVal.isPerNightPricing !== newVal.isPerNightPricing) {
        //             vm.loading = true;
        //             itineraryService.saveOptions(vm.params).then(function () {
        //                 getPricing();
        //             });
        //         } else {
        //             itineraryService.saveOptions(vm.params);
        //         }
        //     }
        // }, 300), true);
      });

      itineraryService.getTrips().then(function (data) {
        vm.trips = data.trips;
        vm.groupedTrips = data.groupedProducts;
        vm.allTrips = data.allTrips;
      });
    }


    function changeCurrency() {
      vm.loading = true;
      shopService.setShopCurrency({currency: vm.currency}).then(function () {
        $rootScope.showNotify(gettextCatalog.getString('Currency was successfully saved'));

        vm.loading = false;
      });
    }

    function saveTrip(trip, occupancy, diver) {
      if ($scope.pricingForm.$invalid) {
        if (!$scope.pricingForm.$error.required) {
          $rootScope.showNotify(gettextCatalog.getString('Allowed maximum two digits after decimal point'));
        }

        return true;
      }
      if (occupancy) {
        _.each(trip.packages, function (val) {
          setPrice(val, occupancy, diver);
        });
      }

      changedTrips = _.reject(changedTrips, {pk: trip.pk});

      changedTrips.push(trip);
    }

    function saveItinerary(itinerary, occupancy, diver) {
      if ($scope.pricingForm.$invalid) {
        if (!$scope.pricingForm.$error.required) {
          $rootScope.showNotify(gettextCatalog.getString('Allowed maximum two digits after decimal point'));
        }

        return true;
      }

      _.each(itinerary.pricesRead, function (val) {
        setPrice(val, occupancy, diver);
      });

      itinerary.pricesWrite = itinerary.pricesRead;

      changedItineraries = _.reject(changedItineraries, {id: itinerary.id});

      changedItineraries.push(itinerary);
    }

    function setPrice(val, occupancy, diver) {
      var currentPrice,
        maxOccupancyRoom;

      if (val.roomCategory) {
        maxOccupancyRoom = val.roomCategory.maxOccupancy >= 2 ? 2 : 1;
      } else {
        maxOccupancyRoom = val.maxOccupancy >= 2 ? 2 : 1;
      }

      $rootScope.checkedList.pricing = true;

      if (occupancy === 'single') {
        currentPrice = _.find(val.prices, {
          numberOfInstances: 1,
          isDiver: true,
          isShared: true,
        });

        _.each(val.prices, function (v) {
          if (currentPrice) {
            v[vm.params.isPerNightPricing ? 'pricePerNight' : 'priceNet'] = currentPrice[vm.params.isPerNightPricing ? 'pricePerNight' : 'priceNet'];
          }
        });
      } else if (occupancy === 'diver') {
        currentPrice = _.find(val.prices, {
          numberOfInstances: maxOccupancyRoom,
          isDiver: diver,
          isShared: false,
        });


        _.each(val.prices, function (v) {
          if (v.isDiver === diver && currentPrice) {
            v[vm.params.isPerNightPricing ? 'pricePerNight' : 'priceNet'] = currentPrice[vm.params.isPerNightPricing ? 'pricePerNight' : 'priceNet'];
          }
        });
      } else {
        _.each(vm.maxOccupancy, function (max) {
          currentPrice = _.find(val.prices, {numberOfInstances: max, isDiver: true, isShared: false});
          _.each(val.prices, function (v) {
            if (v.numberOfInstances === max && !v.isShared && !v.isDiver) {
              v[vm.params.isPerNightPricing ? 'pricePerNight' : 'priceNet'] = currentPrice[vm.params.isPerNightPricing ? 'pricePerNight' : 'priceNet'];
            } else if (v.isShared) {
              var price = _.find(val.prices, {
                numberOfInstances: maxOccupancyRoom,
                isDiver: true,
                isShared: false,
              });

              if (price) {
                v[vm.params.isPerNightPricing ? 'pricePerNight' : 'priceNet'] = price[vm.params.isPerNightPricing ? 'pricePerNight' : 'priceNet'];
              }
            }
          });
        });
      }
    }

    function generatePriceForOccupancy(occupancy) {
      if (occupancy === 1) {
        return vm.params.isPerNightPricing ? 'pricePerNightSingle' : 'singlePrice';
      }

      return vm.params.isPerNightPricing ? 'pricePerNight' : 'priceNet';
    }

    function saveAll() {
      if ($scope.pricingForm.$invalid || $scope.extranetForm.$invalid) {
        return false;
      }

      var pricing = [];

      vm.loading = true;

      if ($scope.extranetForm.$dirty) {
        itineraryService.saveOptions(vm.params).then(function () {
          savePricing();
        }).catch(function (error) {
          formSubmited = true;
          vm.loading = false;
        });
      } else {
        savePricing();
      }

      function savePricing() {
        if (vm.params.isItineraryLevelPrice && changedItineraries.length) {
          _.each(changedItineraries, function (itinerary) {
            pricing.push(itineraryService.saveItineraryPrice(itinerary));
          });
        } else if (!vm.params.isItineraryLevelPrice && changedTrips.length) {
          _.each(changedTrips, function (trip) {
            pricing.push(itineraryService.saveTrip(trip));
          });
        }

        if (pricing.length) {
          return $q.all(pricing).then(function () {
            $location.path(utils.createURL('promotions'));
          })
            .finally(function () {
              vm.loading = false;
              formSubmited = true;
              $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved!'));
            });
        }
        vm.loading = false;
        formSubmited = true;
        $location.path(utils.createURL('promotions'));
        $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved!'));
      }
    }

    function getPricing(page) {
      vm.loading = true;

      if (vm.params.isItineraryLevelPrice === false) {
        itineraryService.getTrips(page).then(function (data) {
          vm.trips = data.trips;
          vm.allTrips = data.allTrips;
          vm.groupedTrips = data.groupedProducts;
          vm.loading = false;
        });
      } else {
        itineraryService.getItineraryList(page).then(function (data) {
          vm.allItineraries = data.allList;
          vm.itineraries = data.paginatedList;

          vm.loading = false;
        });
      }
    }
  })
;
