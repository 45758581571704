
'use strict';

angular.module('app').directive('paginationScroll', function () {
	return {
        restrict: 'A',
        replace: false,
        scope: {
        	scrollTo: '@'
        },
        link: function (scope,elem) {
            $(document).on('click', '.custom-pagination li a', function(){
                $('body,html').animate({scrollTop: $(scope.scrollTo ? scope.scrollTo : '.promotion-wrapper').offset().top - 40 }, '500', 'swing');
            });
        }
    };
});

angular.module('app').directive('paginationScrollDouble', function () {
	return {
        restrict: 'A',
        replace: false,
        scope: {
        	scrollTo: '@'
        },
        link: function (scope,elem) {
            elem.on('click', function (e) {
                if (event.target.tagName.toLowerCase() === 'a') {
                    $('body html').animate({scrollTop: $(scope.scrollTo).offset().top }, '300', 'linear');
                }
            });
        }
    };
})
      