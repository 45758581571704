'use strict';

angular
  .module('app')
  .directive('itineraryPopup', function ($uibModal, $timeout) {
    return {
      restrict: 'A',
      scope: {
        itinerarySlug: '=',
        currency: '=',
        shopSlug: '=',
        countrySlug: '=',
        updateUrl: '=',
        dateFrom: '=',
        dateTo: '=',
        duration: '=',
        isCharter: '=',
        tripId: '=',
        hideFooter: '=',
        promotion: '=',
        price: '=',
        comparePrice: '=',
        insurance: '=',
        scrollTo: '@',
      },
      link: function (scope, elem) {
        elem.on('touchstart, click', function (e) {
          e.preventDefault();
          e.stopPropagation();

          var modalInstance = $uibModal.open({
            templateUrl: window.templatePath
              ? window.templatePath + 'templates/_itinerary_popup.new.html?v=1'
              : '../templates/_itinerary_popup.new.angular.html?v=1',
            controller: function (
              $scope,
              $window,
              $location,
              $uibModalInstance,
              Request,
              utils
            ) {
              var vm = this;

              vm.hideFooter = scope.hideFooter;
              vm.getTimeOptions = utils.getTimeOptions;
              vm.createURL = utils.createURL;
              vm.selectCertification = utils.selectCertification;
              vm.isLastMinuteBooking = false;
              vm.itinerary = {
                loading: true,
              };
              vm.promotion = scope.promotion;
              vm.price = scope.price;
              vm.compareAtPrice = scope.comparePrice;
              vm.toggleAll = utils.toggleAll;
              vm.info = {
                currency: scope.currency,
                countrySlug: scope.countrySlug,
                slug: scope.shopSlug,
              };
              vm.getMilestoneAmountType = getMilestoneAmountType;

              function getMilestoneAmountType(amountTypeKey) {
                return (
                  utils.getTextFromInfo(
                    amountTypeKey,
                    'shop',
                    'MILESTONE_AMOUNT_TYPE'
                  ) || scope.currency
                );
              }
              // vm.paymentAmountTypes = {};
              // _.each(info.shop.MILESTONE_AMOUNT_TYPE, function (type) {
              //   if (type[0] === 20) type[1] = scope.currency;
              //   vm.paymentAmountTypes[type[0]] = type[1];
              // });
              vm.insurance = scope.insurance;

              vm.close = function () {
                $uibModalInstance.close(1);
              };

              vm.isLastMinuteBooking =
                scope.dateFrom && utils.isLastMinuteBooking(scope.dateFrom);

              Request.get(
                'shop/' +
                  $scope.countrySlug +
                  '/' +
                  $scope.shopSlug +
                  '/itineraries/' +
                  $scope.itinerarySlug +
                  '/'
              ).then(function (res) {
                vm.itinerary = res.data;
                vm.itinerary.id = scope.tripId || null;
                vm.itinerary.isCharter = scope.isCharter || null;
                vm.itinerary.startDate = scope.dateFrom;
                vm.itinerary.endDate =
                  scope.dateTo ||
                  moment(scope.dateFrom)
                    .add(scope.duration, 'd')
                    .utc()
                    .format();

                vm.itinerary.days = utils.bindItinerary(vm.itinerary.days);
                vm.info.paymentInformation = res.data.paymentInformation;
                vm.info.cancellationMilestones =
                  res.data.cancellationMilestones;
                vm.info.milestones = res.data.milestones;

                if (scope.updateUrl && window.shopUrl) {
                  $timeout(function () {
                    $location.url($scope.itinerarySlug);
                    $location.replace();
                    $window.history.pushState(null, 'any', $location.absUrl());
                  });
                }

                utils.bindLogistic(vm.itinerary.descriptions);

                if (scope.scrollTo) {
                  if (scope.scrollTo === '#logistics') {
                    vm.logisticExpanded = true;
                  } else if (scope.scrollTo === '#included-section') {
                    vm.whatsIncludedExpanded = true;
                  }

                  $timeout(function () {
                    $('.itinerary-popup-content').animate({
                      scrollTop: $(scope.scrollTo).position().top,
                    });
                  }, 300);
                }
              });
            },
            controllerAs: 'ctrl',
            windowClass: 'uib-itinerary-popup',
            scope: scope,
          });

          return modalInstance.result.then(
            function () {
              if (scope.updateUrl && window.shopUrl) {
                $timeout(function () {
                  window.history.replaceState({}, null, window.shopUrl);
                });
              }
            },
            function () {
              if (scope.updateUrl && window.shopUrl) {
                $timeout(function () {
                  window.history.replaceState({}, null, window.shopUrl);
                });
              }
            }
          );
        });
      },
    };
  });
