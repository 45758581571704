'use strict';

angular.module('app')
  .service('packageService',
    function (info, Request, Upload, utils, diviacLocalStorage, GTM, $q) {
      return {
        getList: function () {
          return Request.get('booking/' + utils.getCurrentShopSlug() + '/sleep-and-dives/').then(function (res) {
            return res.data;
          });
        },
        getPackage: function (id, data) {
          return Request.get('booking/sleep-and-dives/' + id + '/', data).then(function (res) {
            return res.data;
          });
        },
        addPackage: function (data) {
          if (!data.shop) {
            data.shop = utils.getCurrentShopSlug();
          }

          if (data.specificDates && data.possibleStartDates === 30) {
            data.dates = _.map(data.specificDates, function (val) { return { dateStart: val, dateFinish: val}; } );
          }

          if (data.dateRange && data.possibleStartDates === 40) {
            data.dates = data.dateRange;
          }

          return Request.post('booking/sleep-and-dives/add/', data).then(function (res) {
            return res.data;
          });
        },
        editPackage: function (data) {
          if (!data.shop) {
            data.shop = utils.getCurrentShopSlug();
          }

          if (data.possibleStartDates === 10) {
            data.dates = [];
            data.startingWeekdays = [];
          }

          if (data.possibleStartDates === 20) {
            data.dates = [];
          }

          if (data.specificDates && data.possibleStartDates === 30) {
            data.startingWeekdays = [];
            data.dates = _.map(data.specificDates, function (val) { return { dateStart: val, dateFinish: val}; } );
          }

          if (data.dateRange && data.possibleStartDates === 40) {
            data.startingWeekdays = [];
            data.dates = data.dateRange;
          }

          if (!data.startingWeekdays) {
            data.startingWeekdays = [];
          }
          return Request.patch('booking/sleep-and-dives/' + data.id + '/', data).then(function (res) {
            return res.data;
          });
        },
        getInclusionsList: function (id) {
          return $q.all([Request.get('booking/package-inclusions/' + id + '/'),
            Request.get('booking/package-inclusions/' + id + '/rental/')
          ]).then(function (res) {
            var list = [],
              allInclusions = res[0].data,
              rentalEquipment = res[1].data;

            _.each(_.sortBy(info.inclusionGroups, 'order'), function (val) {
              var data = [];
              _.each(allInclusions, function (inclusion) {
                if (inclusion.belongsTo === val.id) {
                  data.push(inclusion);
                }
              });


              if (data.length) {
                var fullBoard = _.remove(data, {title: 'Full board, including'}),
                  firstOfFullBoardGroup = _.findIndex(data, {secondLevelKind: 10}),
                  arrayShift = 0;

                if (firstOfFullBoardGroup >= 0 && fullBoard.length) {
                  data.insert(firstOfFullBoardGroup, fullBoard[0]);
                }

                var clonedData = _.cloneDeep(data);

                _.each(data, function (val, index) {
                  if (val.isSecondLevel && val.secondLevelKind !== 10) {
                    if ((data[index - 1] && (!data[index - 1].secondLevelKind || data[index - 1].secondLevelKind !== val.secondLevelKind)) || !data[index - 1]) {
                      var title = utils.getOptions(val.secondLevelKind, 'LIVEABOARD_EXTRA_SECOND_LEVEL');

                      clonedData.insert(index + arrayShift, {
                        title: title,
                        status: general.rentalEquipmentExtras,
                        belongsTo: val.belongsTo,
                        subkind: 'general',
                      });

                      arrayShift++;
                    }
                  }
                });

                var scubaSetPosition = _.findIndex(clonedData, { title: 'Full scuba set' });

                if (scubaSetPosition !== -1) {
                  _.each(rentalEquipment, function (val) {
                    scubaSetPosition++;

                    val.isSecondLevel = true;
                    val.checkboxField = true;
                    clonedData.insert(scubaSetPosition, val);
                  });
                }

                clonedData.push({
                  title: null,
                  belongsTo: val.id,
                  kind: 'new',
                  addField: true,
                });

                list.push({
                  title: val.title,
                  data: clonedData,
                });
              }
            });

            return {
              rentalEquipment: rentalEquipment,
              list: list,
            };
          });
        },
        addNewInclusion: function (id, data) {
          return Request.post('booking/package-inclusions/' + id + '/', data);
        },
        updateInclusion: function (data) {
          return Request.patch('booking/package-inclusions/' + data.packageId + '/' + data.pk + '/', data);
        },
        updateRentalEquipment: function (data) {
          return Request.patch('booking/package-inclusions/' + data.packageId + '/rental/' + data.pk + '/', data).then(function (res) {
            console.log(res);
            return res.data;
          });
        },
      };
    });
