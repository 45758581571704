'use strict';

angular.module('app')
  .service('GTM', function ($rootScope) {
    var GTM = {};

    if (!angular.isArray(window.dataLayer)) {
      window.dataLayer = [];
    }

    GTM.event = function (event) {
      if (angular.isArray(event)) {
        _.each(event, function (val) {
          window.dataLayer.push({'event': val});
        });

        return true;
      }

      return window.dataLayer.push({'event': event});
    };

    GTM.variables = function (variable) {
      return window.dataLayer.push(variable);
    };

    GTM.clickTrack = function (ev) {
      console.log(ev);

      if (ev) {
        GTM.event(ev);
      }
    };

    GTM.products = function (event, ecommerce, currencyCode) {
      var obj = {'ecommerce': ecommerce};

      if (event) {
        obj.event = event;
      }

      if (currencyCode) {
        obj.currencyCode = currencyCode;
      }

      return window.dataLayer.push(obj);
    };

    GTM.parseInfo = function (info) {
      var floodlight = {
        event: 'pageLoad',
        business_id: info.businessId,
        business_name: info.businessName,
        page_price: '',
        page_price_sale: '',
        page_price_currency: '',
        business_trip_date_start: '',
        business_trip_date_end: '',
        business_trip_availability: '',
        destination_name: info.destinationName,
        page_price_percent: '',
        business_usp_15_chars: info.businessUsp15Chars,
        gmp_echo_sale_opacity: '',
        destination_usp_15_chars: info.destinationUsp15Chars,
        destination_id: info.destinationId,
        business_image_1_600: info.businessImage1_600,
        business_image_2_600: info.businessImage2_600,
        destination_image_1_600: info.destinationImage1_600,
      };
      return floodlight;
    };

    GTM.calculateFloodlight = function (floodlight, order, currency, totalGuests) {
      // prices
      if (order.rate) {
        floodlight.page_price_currency = $rootScope.currency;
        floodlight.page_price = Math.round($rootScope.setValue(order.rate.ratePrice, currency, true) / totalGuests);
        floodlight.gmp_echo_sale_opacity = 0;
        if (order.rate.compareRatePrice > order.rate.ratePrice) {
          floodlight.page_price = Math.round($rootScope.setValue(order.rate.compareRatePrice, currency, true) / totalGuests);
          floodlight.page_price_sale = Math.round($rootScope.setValue(order.rate.ratePrice, currency, true) / totalGuests);
          floodlight.page_price_percent = (1 - (order.rate.compareRatePrice - order.rate.ratePrice) / order.rate.compareRatePrice ) * 100;
          floodlight.gmp_echo_sale_opacity = 1;
        }
      }

      floodlight.business_trip_availability = order.itemsLeft;
      floodlight.business_trip_date_start = order.from;
      floodlight.business_trip_date_end = order.to;

      return floodlight;
    };

    GTM.calculateFloodlightLA = function (floodlight, trip, currency) {
      // prices
      if (trip.price) {
        floodlight.page_price_currency = $rootScope.currency;
        floodlight.page_price = Math.round($rootScope.setValue(trip.price, currency, true));
        floodlight.gmp_echo_sale_opacity = 0;

        if (trip.compareAtPrice > trip.price) {
          floodlight.page_price = Math.round($rootScope.setValue(trip.compareAtPrice, currency, true));
          floodlight.page_price_sale = Math.round($rootScope.setValue(trip.price, currency, true));
          floodlight.page_price_percent = (1 - (trip.compareAtPrice - trip.price) / trip.compareAtPrice ) * 100;
          floodlight.gmp_echo_sale_opacity = 1;
        }
      }

      floodlight.business_trip_availability = trip.availability;
      floodlight.business_trip_date_start = trip.startDate;
      floodlight.business_trip_date_end = trip.endDate;

      return floodlight;
    };

    return GTM;
  });
