'use strict';

angular.module('app').directive('ratingSelect',  function($compile, $timeout, $location) {

        var link = function(scope, element, attrs, ngModel) {

            var n = Math.round(parseFloat(scope.ratingValue)),
                template = '<ul>',
                rating = parseInt($location.search().rating);

            if (rating && scope.checkQuery) {
                ngModel.$setViewValue(rating);
                element.attr('value', rating);
            }

            for(var i = 1; i <= 5; i++) {
                template += '<li ng-click="setValue({value: {rating: '+ i +',model: modelName }});">' +
                    '<span class="rating-star ' +
                    (rating && rating >= i && scope.checkQuery ? 'star-active' : 'star') +
                    '"></span></li>';
            }
            template += '</ul>';

            var contentTr = $compile(template)(scope);
            element.append(contentTr);


            var _elem= $(element),
                star = _elem.find('.rating-star');
            
            $(document).on('mouseover', _elem,  function(event){
                _.each(star, function(val,key) {
                    if (event.target === star[key]) {
                        for (var i=0; i<5; i++) {
                            if(i <= key) {
                                $(star[i]).addClass('star-active').removeClass('star');
                            } else {
                                $(star[i]).addClass('star').removeClass('star-active');
                            }
                        }

                        $(star[key]).on('click', function() {
                            _elem.attr('value',key + 1);

                            ngModel.$setViewValue(key + 1);
                        });
                    }
                });
            });

            $(document).on('mouseout', _elem, function() {
                _.each(star, function(val, key) {
                    if (event.target === star[key]) {
                        $(star[key]).addClass('star').removeClass('star-active');

                        if(!_elem.attr('value')) {
                            for (var i=0; i<key; i++) {
                                $(star[i]).addClass('star').removeClass('star-active');
                            }
                        }
                    }
                });
            });

            _elem.on('mouseleave', function(event) {
                var value = parseInt(_elem.attr('value'));
                $timeout(function() {
                    if(value) {
                        for (var i=0; i < 5; i ++ ){
                            if(i < value) {
                                $(star[i]).addClass('star-active').removeClass('star');
                            } else {
                                $(star[i]).addClass('star').removeClass('star-active');
                            }
                        }
                    }
                }, 100);
            });

            
        };

        return {
            restrict: 'AE',
            replace: true,
            require: 'ngModel',
            template: '<div class="stars"></div>',
            scope: {
                ratingValue: '=',
                starClass: '@',
                setValue: '&',
                modelName: '@',
                checkQuery: '='
            },
            link: link
        };
    });
