'use strict';

angular.module('app').directive('errorScroll', function ($timeout, $rootScope) {
  return {
    restrict: 'A',
    scope: {
      offset: '=',
    },
    link: function (scope, element, attr) {
      $(element).on('click', scrollToError);

      $rootScope.scrollToError = scrollToError;

      function scrollToError(travel, offset) {
        var currentOffset = scope.offset || offset || 0;
        $timeout(function () {
          var firstError = $(document).find('.has-error');

          if (firstError.length) {
            if (attr.travel || travel === true) {
              $('html,body').animate({
                scrollTop: $(firstError[0]).offset().top - currentOffset,
              }, 500);
            } else {
              $('.main-wrapper').animate({
                scrollTop: $(firstError[0]).offset().top + -$('.content').offset().top - 10 - currentOffset,
              }, 500);
            }
          }
        }, 300);
      }
    },
  };
}).directive('scrollToElement', function ($timeout, $rootScope) {
  return {
    restrict: 'A',
    scope: {
      scrollToElement: '=',
    },
    link: function (scope, element, attr) {
      $(element).on('click', function () {
        $timeout(function () {
          var firstError = $(document).find('.has-error');

          if (firstError.length) {
            var container = $(scope.scrollToElement),
              firstError = $(firstError[0]);

            container.animate({
              scrollTop: firstError.offset().top - container.offset().top + container.scrollTop(),
            });
          }
        }, 300);
      });
    },
  };
});
