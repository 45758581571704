'use strict';

angular.module('app')
  .controller('PackageCtrl', function ($q, $location, gettextCatalog,  $routeParams, product, utils, info, shopService, roomService, packageService) {
    var vm = this,
      SLEEP_AND_DIVE_BINDING = {
        10: gettextCatalog.getString('Diver Package'),
        20: gettextCatalog.getString('Non-Diver Package'),
        30: gettextCatalog.getString('Snorkeler Package'),
        40: gettextCatalog.getString('Dive Student Package'),
        50: '',
      };

    vm.SLEEP_AND_DIVE_PACKAGE_KIND = info.booking.SLEEP_AND_DIVE_PACKAGE_KIND;
    vm.DIVES_IN_PACKAGE = info.booking.DIVES_IN_PACKAGE;
    vm.START_DATE_CHOICES = info.booking.START_DATE_CHOICES;
    vm.RESORT_MEAL_PLANS = info.shop.RESORT_MEAL_PLANS;
    vm.redirectNewExtranet = product.redirectNewExtranet;

    vm.nextPricing = roomService.nextPricing;
    vm.getNamesList = getNamesList;
    vm.savePackage = savePackage;
    vm.uploadPhotos = uploadPhotos;
    vm.updatePricing = updatePricing;
    vm.packageId = $routeParams.id;
    vm.package = {
      specificDates: [],
      dateRange: [{}],
    };

    vm.loading = true;

    product.getNames().then(function (res) {
      vm.fullStandardName = res;
    });

    shopService.getShopCurrency().then(function (data) {
      vm.currency = data.currency;
    });

    if ($routeParams && $routeParams.id) {
      packageService.getPackage($routeParams.id).then(function (data) {
        data.id = $routeParams.id;
        if (data.possibleStartDates === 30) {
          data.specificDates = _.map(data.dates, function (item) {
            return item.dateStart;
          });
        } else if (data.possibleStartDates === 40 && data.dates.length) {
          data.dateRange = data.dates;
        }

        angular.extend(vm.package, data);
        seasonHandler(data.seasons);
        pricingHandler(data.rooms, data.seasons);
        vm.loading = false;
      });
    } else {
      $q.all([roomService.getList(), roomService.getInfo()])
        .then(function (data) {
          vm.package.rooms = data[0];

          _.each(vm.package.rooms, function (val) {
            val.id = +val.roomId;
          });
          seasonHandler(data[1].seasons);
          vm.package.pricesData = [];

          pricingHandler(vm.package.rooms, vm.package.seasons);
          vm.loading = false;
        });
    }

    function pricingHandler(rooms, seasons) {
      _.each(rooms, function (room) {
        var numberOfInstances = new Array(room.maxOccupancy);
        if (seasons && seasons.length) {
          _.each(seasons, function (season) {
            _.each(numberOfInstances, function (key, occupancy) {
              if (!_.find(vm.package.pricesData, { roomCategory: room.id, season: season.pk, numberOfInstances: occupancy + 1 })) {
                vm.package.pricesData.push({
                  roomCategory: room.id,
                  season: season.pk,
                  priceNet: null,
                  mealPlanUpgradePrices: null,
                  divesPerExtraNight: null,
                  numberOfInstances: occupancy + 1,
                });
              }
            });
          });
        } else {
          _.each(numberOfInstances, function (key, occupancy) {
            if (!_.find(vm.package.pricesData, { roomCategory: room.id, numberOfInstances: occupancy + 1 })) {
              vm.package.pricesData.push({
                roomCategory: room.id,
                priceNet: null,
                mealPlanUpgradePrices: null,
                divesPerExtraNight: null,
                numberOfInstances: occupancy + 1,
              });
            }
          });
        }
      });
    }


    function seasonHandler(data) {
      if (data.length) {
        vm.package.seasons = _.reject(data, {'title': 'Closed'});
        vm.selectedPriceTab = vm.package.seasons[0].pk;

        if (vm.package.seasons.length > 4) {
          vm.from = 0;
          vm.limit = 3;
        }
      }
    }


    function savePackage(form) {
      form.$setSubmitted();

      if (form.$invalid) {
        return false;
      }

      vm.loading = true;

      _.each(vm.package.pricesData, function (val) {
        val.isPackageExtraNightAllowed = val.extraNightPrice ? true : false;
      });

      packageService[$routeParams && $routeParams.id ? 'editPackage' : 'addPackage'](vm.package).then(function (newPackage) {
        if ($routeParams && $routeParams.id) {
          $location.path(utils.createURL('rooms'));
        } else {
          $location.path(utils.createURL('package_edit_inclusions', {id: newPackage.pk }));
        }
      }).finally(function () {
        vm.loading = false;
      });
    }

    function uploadPhotos(file) {
      if (!file || (file && utils.isImg(file.type))) {
        return false;
      }

      vm.loading = true;

      return roomService.uploadPhotos(file).then(function (data) {
        vm.package.photos = [data.id];
        vm.package.imagesRead = [data];
        vm.loading = false;
      }).catch(function () {
        vm.loading = false;
      });
    }

    function updatePricing(data) {
      _.each(vm.package.pricesData, function (item) {
        if ((data.season && item.season === data.season || !data.season) && item.roomCategory === data.roomCategory) {
          item[data.model] = data.value;
        }
      });
    }


    function getNamesList(kind) {
      var title = SLEEP_AND_DIVE_BINDING[kind];
      if (!vm.package.title || vm.package.title &&
                _.find(SLEEP_AND_DIVE_BINDING, function (item) {return item === vm.package.title;})) {
        vm.package.title = title;
      }
    }
  });
