'use strict';

angular
  .module('app')
  .controller('ProductAddEdit', function (
    $scope,
    $rootScope,
    $location,
    $routeParams,
    product,
    gettextCatalog,
    info,
    utils,
    SeoService
  ) {
    var vm = this;

    $rootScope.showActivity = true;

    // activate();

    vm.loading = true;
    vm.getNamesList = getNamesList;
    vm.setProductInfo = setProductInfo;
    vm.createNewProduct = createNewProduct;
    vm.languagesSpoken = info.activities.LANGUAGES;
    vm.specialEvents = info.activities.SPECIAL_EVENTS;
    vm.durationTypes = info.activities.DURATION_UNIT;
    vm.activityType = info.booking.ACTIVITY_SUBTYPE;
    vm.currentUrl = window.location.pathname;
    vm.getDurationTitle = getDurationTitle;
    vm.getPeoplePerTitle = getPeoplePerTitle;
    vm.needTranslate = [
      'title',
      'other_requirements',
      'short_description',
      'what_to_expect',
      'highlights',
    ];
    vm.guidedProductTypes = [10, 20, 40];
    vm.coursesList = [];
    vm.isNonDiveSiteLocation = isNonDiveSiteLocation;
    // vm.setCoursesType = setCoursesType;
    vm.redirectNewExtranet = product.redirectNewExtranet;

    vm.isCopy = window.location.href.search('copy') !== -1;

    function activate() {
      vm.add = {
        isGuided: true,
        productType: {},
        diveSiteType: {
          value: '20',
        },
        standardName: '',
        title: '',
        groupSize: {},
        customerPaymentMethod: {
          // eslint-disable-next-line no-undefined
          value: utils.isProDiverShop() ? 20 : undefined,
        },
        requirements: {},
        duration: {
          durationType: {
            value: 10,
          },
        },
        // eslint-disable-next-line no-undefined
        specialEvent: utils.isProDiverShop() ? undefined : null,
        locationType: {
          value: 10,
        },
        hasWheelchair: false,
        hasRequirements: false,
        sites: [],
        link: '',
      };
      vm.productKindList = getProductKindsList();
      vm.minCertificationOptions = _.clone(info.activities.MIN_CERTIFICATION);
      vm.minLoggedDivesOptions = _.clone(info.activities.MIN_LOGGED_DIVES);

      var notAllowedKinds = info.bookingEngine.PRODUCT_KIND_CHOICES;

      // var kind = diviacLocalStorage.get('choosenShop') ? diviacLocalStorage.get('choosenShop').kind : false;

      vm.utils = utils;

      product.getCourses().then((data) => {
        vm.coursesList = _.sortBy(data, 'title');
      });

      vm.privileges = _.get($rootScope, 'selectedShop.privileges', {});

      // If it's product edit
      if ($routeParams.id) {
        vm.productId = $routeParams.id;
        product.getProduct($routeParams.id).then(function (data) {
          if (
            utils.isArrayContainElementsFromOtherArray(
              data.autoTranslatedFields,
              vm.needTranslate
            )
          ) {
            $rootScope.showNotify(
              gettextCatalog.getString(
                'The text fields on this page have been auto-translated using Google Translate. You may edit them manually if you wish.'
              )
            );
          }

          vm.finishedTabs = data.finishedTabs;
          vm.add = _.clone(data);
          if (!vm.add.activityId) {
            vm.isOld = true;
          }

          if (vm.add.sites && vm.add.sites.length > 0) {
            _.each(vm.add.sites, function (site) {
              var index = _.findIndex(vm.diveSitesList, { id: site.id });
              if (index !== -1) vm.diveSitesList[index].isChecked = true;
            });
          }

          if (vm.isCopy) {
            vm.add.title = '';
            vm.add.diveProductId = null;
            vm.add.activityId = null;
          }

          SeoService.generateMetaTags(
            vm.add.title +
              gettextCatalog.getString(' - Products - PADI Travel for Business')
          );

          // Check kind... If this variable is empty then we try to add another kind from another array;

          // Joel logic
          //   o
          // ~\/~
          //  /\
          var allowedKind = _.find(vm.productKindList, function (val) {
            return val[0] === data.productType.value;
          });

          if (data.productType.value && !allowedKind) {
            var newKind = _.find(notAllowedKinds, function (val) {
              return val[0] === data.productType.value;
            });

            if (!_.isEmpty(newKind)) {
              vm.productKindList.push(newKind);
            }
          }
          // _.each(data.imagesRead, function (val) {
          //   vm.imagesReadThumb.push(val.thumbnails.SmallMobile);
          //   vm.add.imagesRead.push(val);
          // });

          getNamesList(vm.add.productType.value);
        });
        // Get standard name list
      } else {
        getNamesList(null, true);
      }
    }

    // get Dive sites list
    product.getDiveSites().then(function (data) {
      vm.diveSitesList = data;
      activate();
    });

    // function setCoursesType(item) {
    //   vm.add.isElearning = item.isElearning;
    // }

    function getProductKindsList() {
      if (utils.isProDiver()) {
        // logic described: https://djangostars.atlassian.net/browse/DC-18203?focusedCommentId=77603
        return _.filter(
          info.booking.DIVE_PRODUCT_KIND,
          (kind) => kind[0] === 50
        );
      }
      return _.clone(info.booking.DIVE_PRODUCT_KIND);
    }

    function isNonDiveSiteLocation(locationType) {
      return [20, 30].includes(locationType);
    }

    function getNamesList(kind, isFirstLoading) {
      vm.categories = info.activities.ACTIVITY_CATEGORIES[kind] || [];
      if (vm.fullStandardName) {
        vm.standardNameList = _.filter(vm.fullStandardName, { kind: kind });

        if (vm.standardNameList.length === 1) {
          vm.add.standardName = vm.standardNameList[0].id;
          vm.setProductInfo(vm.standardNameList[0]);
        } else {
          vm.add.standardName = null;
        }
        vm.loading = false;
      } else {
        product.getNames().then(function (res) {
          vm.standardNameList = res;
          vm.fullStandardName = res;

          if ($routeParams.id) {
            vm.standardNameList = _.filter(vm.fullStandardName, { kind: kind });
          }

          if (isFirstLoading && utils.isProDiverShop()) {
            // set Conservation type by default
            vm.add.productType.value = 50;
            getNamesList(vm.add.productType.value);
          }

          vm.loading = false;
        });
      }
    }

    function setProductInfo(productInfo) {
      const pInfo = productInfo.standardName
        ? productInfo.standardName
        : productInfo;

      if (!$routeParams.id) {
        vm.add = product.prefill(vm.add, pInfo, $scope.extranetForm);
        vm.add.productType.value = pInfo.kind
          ? pInfo.kind
          : vm.add.productType.value;
      }
      if (+vm.add.productType.value === 10) {
        if (productInfo.id === 45) {
          // one tank dive
          vm.add.numberOfDives = 1;
        } else if (productInfo.id === 59) {
          // two tank dive
          vm.add.numberOfDives = 2;
        }
      }
      if (+vm.add.productType.value === 50) {
        vm.add.standardNameId = productInfo.id;
        vm.add.padiPrice = 0;
      }
      if (+vm.add.productType.value === 30) {
        vm.add.isElearning = productInfo.isElearning;
      }
    }

    function createNewProduct(form, newProduct) {
      if (form.$valid) {
        newProduct.sites = [];
        _.each(vm.diveSitesList, function (site) {
          if (site.isChecked) {
            newProduct.sites.push(site);
          }
        });

        if (newProduct.productType.value === 50) {
          const locationTypeValue = _.get(newProduct, 'locationType.value');

          if (newProduct.sites.length < 1 && locationTypeValue === 10) {
            form.$invalid = true;
            $rootScope.scrollToError();
            vm.diveSiteError = gettextCatalog.getString(
              'Select at least 1 dive site'
            );
            return;
          }

          if (locationTypeValue !== 30) {
            newProduct.link = null;
          }
        }

        if (vm.add.productType.value !== 50) {
          delete vm.add.activitySubtype;
        }

        if (newProduct.productType.value === 30) {
          // delete newProduct.standardNameId;
        } else {
          delete newProduct.isElearning;
          delete newProduct.courseInfoId;
        }

        vm.diveSiteError = gettextCatalog.getString('This field is required');

        vm.loading = true;
        product.addNew(newProduct, $routeParams.id).then(
          function (result) {
            // $location.path('/products/');

            vm.loading = false;

            if ($routeParams.id) {
              $rootScope.showNotify(
                gettextCatalog.getString('The product was successfully changed')
              );
            } else {
              $rootScope.showNotify(
                gettextCatalog.getString('The product was successfully added')
              );
            }
            // go to next page
            if (result.data.diveProductId) {
              $location.path(
                utils.createURL('product_description', {
                  id: result.data.diveProductId,
                })
              );
            }
          },
          function (error) {
            vm.loading = false;
            if (error.data.groupSize) {
              $scope.extranetForm.maxSize.$invalid = true;
            } else if (error.data.standardNameId) {
              $scope.extranetForm.standardName.$invalid = true;
            }
          }
        );

        if ($rootScope.checkedList) $rootScope.checkedList.products = true;
      }
    }

    var durationTitles = {
        10: gettextCatalog.getString('Dive trip duration'),
        20: gettextCatalog.getString('Snorkelling trip duration'),
        30: gettextCatalog.getString('Dive course duration'),
        40: gettextCatalog.getString('Event duration'),
        50: gettextCatalog.getString('Activity duration'),
      },
      maxMinTitles = {
        10: gettextCatalog.getString('divers per trip'),
        20: gettextCatalog.getString('snorkelers per trip'),
        30: gettextCatalog.getString('students per course'),
        40: gettextCatalog.getString('people per event'),
        50: gettextCatalog.getString('people per event'),
      };

    function getDurationTitle() {
      var value = _.get(vm.add, 'productType.value');
      if (!value) {
        return 'Duration';
      }

      return durationTitles[value];
    }

    function getPeoplePerTitle() {
      var value = _.get(vm.add, 'productType.value');
      if (!value) {
        return maxMinTitles[50];
      }

      return maxMinTitles[value];
    }
  })
  .directive('uiSelectRequired', function () {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$validators.required = function (modelValue, viewValue) {
          return !(
            ((viewValue && viewValue.length === 0) || false) &&
            attrs.uiSelectRequired === 'true'
          );
        };

        attrs.$observe('uiSelectRequired', function () {
          ctrl.$setValidity(
            'required',
            !(
              attrs.uiSelectRequired === 'true' &&
              ctrl.$viewValue &&
              ctrl.$viewValue.length === 0
            )
          );
        });
      },
    };
  });
