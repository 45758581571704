'use strict';

angular.module('app').directive('membership', function () {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, element, attr, ctrl) {
        function inputValue(val) {
          if (val) {
            var newValue = val.replace(/\W+[!\-]?\D+/, '');

            if (newValue !== val) {
              console.log(newValue, val);
              ctrl.$setViewValue(newValue);
              ctrl.$render();
            }

            return newValue;
          }
          return undefined;
        }            
        ctrl.$parsers.push(inputValue);
      }
    };
});
