'use strict';

angular.module('app')
    .controller('ItineraryPreviewCtrl', function ($q, $timeout, info, utils, $uibModalInstance, itineraryService, itineraries) {

        var vm = this;

        vm.itineraries = itineraries;
        vm.toggleAll = toggleAll;
        vm.utils = utils;
        vm.selectedItinerary = vm.itineraries[0];

        _.each(vm.itineraries, function(val) {
          val.days = bindItinerary(val.days);
          utils.bindLogistic(val.descriptions);
        });

        function toggleAll(array, type) {
            _.each(array, function (v,k) {
                array[k] = type;
            });
        }

        function bindItinerary(days) {
            if (!days.length) {
                return [];
            }

            var notEmpty = _.filter(days, function(val) {
                return !_.isEmpty(val.description);
            });

            if (!notEmpty.length) {
                return [];
            }

            if (notEmpty.length > 1) {
                for (var i = 0; i < notEmpty.length; i++) {
                    if (i === 0) {
                        notEmpty[i].dayStart = 1;

                        if (notEmpty[i + 1]) {
                            notEmpty[i].dayFinish = notEmpty[i + 1].dayStart - 1;
                        }
                    } else if (i !== 0 && notEmpty[i + 1]) {
                        notEmpty[i].dayFinish = notEmpty[i + 1].dayStart - 1;
                    } else if (i !== 0 && !notEmpty[i + 1]) {
                        notEmpty[i].dayFinish = days.length;
                    }
                }

                return notEmpty;
            } else {
                return [{
                    dayStart: 1,
                    dayFinish: days.length,
                    description: _.clone(notEmpty[0]).description
                }];
            }
        };

    });
