(function () {
  window.createUrl = function (route, lang) {
    if (!lang) {
      lang = window.lang;
    }

    if (lang === 'ach') {
      return '/ach' + window.routes.en[route];
    }

    if (!window.routes[lang]) {
      return '';
    }

    return window.routes[lang][route];
  };

  const routesList = {
    homepage: '/',
    homepage_home: '/home/',
    basics: '/basics/',
    liveaboard_basic: '/liveaboard-basics/',
    resort: '/resort/',
    dive_center: '/dive-center/',
    dive_sites: '/dive-sites/',
    dive_sites_list: '/dive-sites/list/',
    photos: '/photos/',
    resort_photos: '/resort-photos/',
    products: '/products/',
    product: '/product/',
    product_add: '/product/add',
    product_copy: '/product/copy/:id',
    product_basics: '/product/:id/basics/',
    product_description: '/product/:id/description/',
    product_pricing: '/product/:id/pricing/',
    product_inclusions: '/product/:id/inclusions/',
    product_photos: '/product/:id/photos/',
    product_logistics: '/product/:id/logistics/',
    product_complete: '/product/:id/complete/',
    product_edit_id: 'product/edit/:id/',
    rooms: '/rooms/',
    rooms_add: '/room/add',
    rooms_edit: '/room/edit/:id',
    rooms_copy: '/room/copy/:id',
    sleep_and_dive_add: '/sleep-and-dive/add',
    sleep_and_dive_edit: '/sleep-and-dive/edit/:id',
    admin: '/admin/',
    agreement: '/agreement/',
    vacations_agreement: '/vacations-agreement/',
    login: '/login/',
    account_password_reset: '/account/password/reset/:uid/:token/',
    account_activation: '/account/activation/:key/',
    reviews: '/reviews/',
    cabins: '/cabins/',
    room_add: '/room/add',
    cabins_add: '/cabins/add/',
    cabins_edit: '/cabins/edit/:id',
    itinerary: '/itinerary/',
    itinerary_edit: '/itinerary/edit/:id',
    itinerary_add: '/itinerary/add',
    itinerary_price: '/itinerary-price/',
    availability: '/availability/',
    schedule: '/schedule/',
    promotions: '/promotions/',
    inclusions: '/inclusions/',
    resort_inclusions: '/resort-inclusions/',
    dc_inclusions: '/dc-inclusions/',
    promotions_edit: '/promotions/edit/:id/',
    promotions_add: '/promotions/add/',
    manage_rooms: '/manage-rooms/',
    availability_and_pricing: '/availability-and-pricing/',
    rates: '/rates/',
    rates_add: '/rates/add/',
    rates_edit: '/rates/edit/:id/',
    rates_copy: '/rates/copy/:id/',
    groups: '/groups/',
    group_trips: '/group-trips/',
    group_trips_add: '/group-trips/add',
    group_trips_listing_type: '/group-trips/listing-type',
    group_trips_listing_type_id: '/group-trips/:id/listing-type',
    group_trips_basics: '/group-trips/:id/basics',
    group_trips_description: '/group-trips/:id/description',
    group_trips_photos: '/group-trips/:id/photos',
    group_trips_pricing: '/group-trips/:id/pricing',
    group_trips_pricing_add: '/group-trips/:id/pricing/add',
    group_trips_pricing_edit: '/group-trips/:id/pricing/:package/edit',
    group_trips_inclusions: '/group-trips/:id/inclusions',
    group_trips_complete: '/group-trips/:id/complete',
    group_trips_custom_operator: '/group-trips/:tripId/custom-operator/',
    group_trips_custom_operator_id:
      '/group-trips/:tripId/custom-operator/:operatorId/',
    packages: '/packages/',
    package_edit: '/package/edit/:id/',
    package_edit_inclusions: '/package/edit/:id/inclusions',
    package: '/package/',
    staff_only: '/staff-only/',
    bookings: '/bookings/',
    bookings_uuid: '/bookings/:uuid/',
    payment_details: '/payment-details/',
    payment_details_adventures: '/payment-details-adventures/',
    dive_guides: '/dive-guides/',
    dive_guide_terms: '/dive-guides/terms-01-2021/',
    forms: '/forms/',
    gis_management: '/gis-management/',
    contacts: '/contacts/',
    trip_documents: '/trip-documents/',
  };

  window.routes = {
    en: routesList,
  };
  const availableLanguages = [
    'de',
    'fr',
    'it',
    'es',
    'nl',
    'ja',
    'ko',
    'zh-hans',
    'zh-hant',
    'other',
  ];

  _.each(availableLanguages, (lang) => {
    let routesWithPrifix = {};
    _.each(routesList, (route, key) => {
      routesWithPrifix[key] = `/${lang}${route}`;
    });
    window.routes[lang] = routesWithPrifix;
  });
})();
