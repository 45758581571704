'use strict';

angular.module('app')
  .controller('LanguagePopupController', function ( config, shopService, utils, AuthExtranet) {
    var vm = this;
    vm.langList = config.langList;
    vm.save = save;

    function save() {
      vm.loading = true;
      shopService.setShopLanguage({defaultLanguage: vm.selectedLanguage}).then((res)=>{
        let chosenShop = AuthExtranet.getChoosenShop();
        chosenShop.defaultLanguage = res.defaultLanguage;
        AuthExtranet.setChoosenShop(chosenShop, true);

        utils.setLanguage(res.defaultLanguage);
        vm.loading = false;
        window.location.href = utils.generateLangUrl(res.defaultLanguage);
      }).catch((err)=>{
        console.log(err);
        vm.loading = false;
      });
    }
  });
