'use strict';

angular.module('app')
  .controller('SleepDiveCtrl', function ($scope, $routeParams, $location, $timeout, $rootScope, product, gettextCatalog, shopService, Upload, roomService, utils) {
    var vm = this,
      formSubmited = false;

    vm.nextPricing = roomService.nextPricing;
    vm.fileUpload = fileUpload;
    vm.savePackage = savePackage;
    vm.deletePhoto = deletePhoto;
    vm.dragEnd = dragEnd;

    init();

    // $scope.$on('$locationChangeStart', function (event, toState, oldState) {
    //     if (!formSubmited) {
    //         if ($scope.extranetForm.$dirty && $scope.extranetForm.$invalid) {
    //             event.preventDefault();
    //             utils.warningPopup().then(function () {
    //                 $scope.extranetForm.$setPristine();
    //                 $location.path($rootScope.currentUrl);
    //             }).catch(function () {
    //                 $rootScope.currentUrl = '/products/';
    //             });
    //         }

    //         if ($scope.extranetForm.$dirty && $scope.extranetForm.$valid) {

    //             vm.add.pricesWrite = _.clone(vm.add.pricesRead);
    //             vm.add.rooms = vm.rooms;
    //             roomService.saveSleep(vm.add, $routeParams.id).then(function () {
    //                 if ($routeParams.id) {
    //                     $rootScope.showNotify('The product was successfully changed');
    //                 } else {
    //                     $rootScope.showNotify('The product was successfully added');
    //                 }
    //             });
    //         }
    //     }
    // });

    function init() {
      vm.imagesThumb = [];
      vm.errorPhoto = [];
      vm.errorFormat = [];
      vm.disableLoading = [];
      vm.rooms = [];
      vm.seasons = [];
      vm.add = {
        title: '',
        numberOfNights: '',
        description: '',
        imagesRead: [],
        photos: [],
        packages: [],
        whatsIncluded: null,
        whatsNotIncluded: null,
        requirements: null,
      };

      shopService.getShopCurrency().then(function (data) {
        vm.currency = data.currency;
      });

      roomService.getInfo().then(function (data) {
        vm.params = data;

        if (data.seasons.length) {
          vm.seasons = _.reject(data.seasons, {'title': 'Closed'});
          vm.selectedPriceTab = vm.seasons[0].pk;


          if (vm.seasons.length > 4) {
            vm.from = 0;
            vm.limit = 3;
          }
        }

        if (vm.seasons.length > 4) {
          vm.from = 0;
          vm.limit = 3;
        }

        if (!$routeParams.id) {
          generatePricingArray();
        }
      });

      if ($routeParams.id) {
        roomService.getSleep($routeParams.id).then(function (data) {
          vm.add = data;

          vm.seasons = data.seasons;

          if (!vm.add.packages.length) {
            generatePricingArray();
          }
        });
      }
    }

    function deletePhoto(id) {
      return product.deletePhoto(id).then(function () {
        vm.add.imagesRead = _.reject(vm.add.imagesRead, {id: id});
        vm.add.photos = _.without(vm.add.photos,  id);
      });
    }

    function savePackage(form) {
      if (form.$invalid) {
        var seasons = vm.seasons.length,
          error = false;

        // _.each(vm.add.packages, function (val, i) {
        //     _.each(val.prices, function (price, j) {
        //         _.each(price.prices, function (v, k) {
        //             console.log()
        //             if (form['perGuestPrice-' + i + '-' + j + '-' + k] && form['perGuestPrice-' + i + '-' + j + '-' + k].$invalid && !error) {
        //                 vm.selectedPriceTab = vm.add.pricesRead[i].id;
        //
        //                 if (vm.add.pricesRead.length > 4) {
        //                     vm.from = i >= 3 ? i - 1 : 0;
        //                     vm.limit = i >= 3 ? 2 : 3;
        //                 }
        //
        //                 error = true;
        //             }
        //         });
        //     });
        // });

        _.each(vm.seasons, function (season, i) {
          _.each(vm.add.packages, function (val, j) {
            _.each(val.prices, function (v, k) {
              // console.log(val.prices);
              // console.log('perGuestPrice-' + i + '-' + j + '-' + k);
              if (form['perGuestPrice-' + i + '-' + j + '-' + k] && form['perGuestPrice-' + i + '-' + j + '-' + k].$invalid && !error) {
                console.log('perGuestPrice-' + i + '-' + j + '-' + k, v.season);
                // vm.selectedPriceTab = vm.add.pricesRead[i].id;
                //
                // if (vm.add.pricesRead.length > 4) {
                //     vm.from = i >= 3 ? i - 1 : 0;
                //     vm.limit = i >= 3 ? 2 : 3;
                // }
                //
                // error = true;
                vm.selectedPriceTab = _.find(val.prices, function (val) {
                  return !val.priceNet && val.priceNet !== 0;
                }).season;

                if (seasons > 4) {
                  vm.from = i >= 3 ? i - 1 : 0;
                  vm.limit = i >= 3 ? 2 : 3;
                }
                error = true;
              }
            });
          });
        });
      }

      if (form.$valid) {
        vm.loading = true;
        vm.add.pricesWrite = _.clone(vm.add.pricesRead);
        vm.add.rooms = vm.rooms;

        if ($routeParams.id) {
          vm.add.isActive = true;
        }

        roomService.saveSleep(vm.add, $routeParams.id).then(function () {
          formSubmited = true;

          $location.path(utils.createURL('rooms'));

          vm.loading = false;

          $rootScope.checkedList.rooms = true;

          if ($routeParams.id) {
            $rootScope.showNotify(gettextCatalog.getString('The package was successfully changed'));
          } else {
            $rootScope.showNotify(gettextCatalog.getString('The package was successfully added'));
          }
        }, function (error) {
          vm.loading = false;
        });
      }
    }

    function fileUpload(files, maxLength) {
      if (files && (vm.add.imagesRead.length <= maxLength)) {
        vm.errorPhoto = [];
        vm.errorFormat = [];

        _.each(files, function (file) {
          var acceptedFormat = _.find(['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'], function (v) {
            return v == file.type;
          });

          if (!acceptedFormat) {
            return vm.errorFormat.push(file.name);
          }

          var randomId = parseInt(Math.random() * 1000000);

          Upload.imageDimensions(file).then(function (d) {
            if (d.width < 600 || d.height < 400) {
              vm.errorPhoto.push(file.name);
            } else if (vm.add.imagesRead.length + 1 <= 6) {
              vm.disableLoading.push(randomId);

              vm.add.imagesRead.push({
                id: randomId,
                absoluteImage: '/images/loading-photo.jpg',
                type: 'loader',
              });

              product.uploadPhotos(file).then(function (data) {
                vm.add.imagesRead = _.reject(vm.add.imagesRead, function (val) {
                  return val.id == randomId;
                });

                vm.add.imagesRead.push(data);

                if (!_.isArray(vm.add.photos)) {
                  vm.add.photos = [];
                }
                vm.add.photos.push(data.id);

                vm.disableLoading = _.without(vm.disableLoading, randomId);
              }, function (error) {
                vm.add.imagesRead = _.reject(vm.add.imagesRead, function (val) {
                  return val.id == randomId;
                });

                vm.disableLoading = _.without(vm.disableLoading, randomId);
              }, function (progress) {
                _.each(vm.add.imagesRead, function (val) {
                  if (val.id == randomId) {
                    val.progress = progress;

                    if (progress == 100) {
                      val.processing = true;
                    }
                  }
                });
              });
            }
          }, function (err) {
            console.log(err);
          });
        });
      }
    }

    function dragEnd() {
      _.each(vm.add.imagesRead, function (val, index) {
        val.position = index;
      });

      product.setPhoto(vm.add.imagesRead);
    }

    function generatePricingArray() {
      roomService.getList().then(function (data) {
        vm.roomsList = data;

        _.each(vm.roomsList, function (val) {
          vm.rooms.push(val.roomId);
          var price = {
            roomCategory: {
              title: val.title,
              images: val.imagesRead,
            },
            roomCategoryWrite: val.roomId,
            prices: [],
          };

          if (vm.seasons.length) {
            _.each(vm.seasons, function (season) {
              for (var i = 1; i <= val.maxOccupancy; i++) {
                price.prices.push({
                  priceNet: null,
                  seasonDateRange: season.dates[0].pk,
                  season: season.pk,
                  numberOfInstances: i,
                });
              }
            });
          } else {
            for (var i = 1; i <= val.maxOccupancy; i++) {
              price.prices.push({
                priceNet: null,
                seasonDateRange: null,
                season: null,
                numberOfInstances: i,
              });
            }
          }

          vm.add.packages.push(price);
        });
      });
    }
  })
;
