'use strict';

angular
  .module('app')
  .controller('TripDescriptionCtrl', function (
    $scope,
    $rootScope,
    $location,
    groupTrips,
    $routeParams,
    SeoService,
    gettextCatalog,
    utils
  ) {
    var vm = this;
    vm.productDescriptionPage = true;
    vm.tripId = $routeParams.id;

    vm.saveDescription = saveDescription;

    SeoService.generateMetaTags(gettextCatalog.getString('Trip Description - PADI Travel for Business'));

    vm.loading = true;

    activate();

    function activate() {
      vm.loading = true;
      groupTrips.getTrip(vm.tripId).then(function (data) {
        vm.tripData = _.clone(data);
        vm.loading = false;
      });
    }

    function saveDescription() {
      $scope.extranetForm.$setSubmitted();

      if ($scope.extranetForm.$valid) {
        vm.loading = true;
        groupTrips.updateTrip(vm.tripData, vm.tripId).then(function () {
          vm.loading = false;
          $rootScope.showNotify(gettextCatalog.getString('The product was successfully changed'));
          $location.path(utils.createURL('group_trips_photos', {id: vm.tripId }));
        }).catch(function () {
          vm.loading = false;
        });
      }

      return false;
    }
  });
