'use strict';

angular.module('app').directive('generateIncludeString', function ($compile, gettextCatalog, Request) {
  return {
    restrict: 'A',
    scope: {
      array: '=',
      included: '=',
      itinerary: '=',
      currency: '=',
      dateFrom: '=',
      insurance: '=',
    },
    link: function (scope, element) {
      var string = '<ul>';

      if (scope.array && scope.array.length) {
        var firstLevelItems = _.filter(scope.array, {'includedIn': null}),
          secondLevelItems = _.filter(scope.array, function (incl) {
            // filter out meal plan and scuba items;
            if ((incl.belongsTo === 1 && incl.includedIn) || (incl.belongsTo === 6 && incl.includedIn) ) {
              return false;
            }
            return true;
          }),
          groupedSecondLevel = _.groupBy(secondLevelItems, 'includedIn'),
          insurancePrice = '';

        if (scope.insurance) {
          insurancePrice = scope.insurance.limitPriceCurrency  + ' ' + Math.round(scope.insurance.limitPrice);
          string += '<li>' + gettextCatalog.getString('Free DAN dive accident insurance (if trip value > {{price}} per person)', { price: insurancePrice }) + '</li>';
        }


        _.each(firstLevelItems, function (item) {
          if (item.validFrom && item.validTo && scope.dateFrom) {
            const utcStartDate = scope.dateFrom.indexOf('T') !== -1 ? moment(scope.dateFrom).utc().format('YYYY-MM-DD') : scope.dateFrom;
            if (!moment(utcStartDate).isBetween(item.validFrom, item.validTo, undefined, '[]')) {
              return;
            }
          }

          string += '<li>' + item.title;

          var isFullBoard = (item.fullBoardDescription && item.fullBoardDescription != '') ? true : false,
            isScubaSet = (item.fullSetDescription && item.fullSetDescription != '') ? true : false;

          // display included items in tooltip
          if (groupedSecondLevel[item.extraId] || isFullBoard || isScubaSet) {
            string += ' <a class="link-color itinerary-tooltip" tooltip-title="';

            _.each(groupedSecondLevel[item.extraId], function (inclusion) {
              string += '<p>' + inclusion.title + '</p>';
            });

            if (isFullBoard) {
              string += '<p><strong>' + gettextCatalog.getString('Included') + ': </strong>' + item.fullBoardDescription.included + '</p>';
              if (item.fullBoardDescription.notIncluded) {
                string += '<p><strong>' + gettextCatalog.getString('Not included') + ': </strong>' + item.fullBoardDescription.notIncluded + '</p>';
              }
            }

            if (isScubaSet) {
              string += '<p><strong>' + gettextCatalog.getString('Included') + ': </strong>' + item.fullSetDescription + '</p>';
            }

            string += '">(' + gettextCatalog.getString('see more') + ')</a>';
          }

          string += '</li>';
        });
      }


      var contentTr = $compile(string)(scope);
      element.append(contentTr);
    },
  };
});


angular.module('app').directive('generateIncludeStringResort', function ($compile, utils, gettextCatalog) {
  return {
    restrict: 'A',
    scope: {
      includedDiver: '=',
      includedNonDiver: '=',
      insurance: '=',
      numberOfDives: '=',
      mealPlan: '=',
      duration: '=',
      mealPlanDescription: '=',
      fixedPackage: '=',
      room: '=',
      currency: '=',
    },
    // template: '<ul>'+
    //     '<li class="title">For each diver</li>' +
    //     '<li>{{ numberOfDives }} dives per diver<p class="inclusion-explanation">Except if listed below, surcharges for national park fees, boat dives, night dives or other special trips are not included in the price</li>' +
    //     '<li>Free DAN dive accident insurance (if trip value > {{ insurance.limitPriceCurrency }} {{ insurance.limitPrice }} per person)</li>' +
    //     '<li class="title" ng-if="included.length">For all guests</li>' +
    //     '<li ng-repeat="incl in included">{{ incl.title }}</li></ul>',
    link: function (scope, element) {
      scope.$watchCollection('includedDiver + includedNonDiver + mealPlan + numberOfDives + duration + room + mealPlanDescription + fixedPackage', function () {
        var string = '<ul>',
          insurancePrice = '';

        if (scope.room) {
          string += '<li>' + scope.room.title + ' for ' + scope.duration  + ' night(s)</li>';

          // if (scope.mealPlanDescription) {
          //     string += '<li><a class="pointer" ng-click="showMealPlan = !showMealPlan">' + utils.getTextFromInfo(scope.mealPlan,'shop','RESORT_MEAL_PLANS') + '</a>';
          //     string += '<div class="inclusion-explanation" ng-show="showMealPlan">' + scope.mealPlanDescription + '</div>'
          // } else {
          //     console.log(scope.mealPlan)
          //     string += '<li>' + utils.getTextFromInfo(scope.mealPlan,'shop','RESORT_MEAL_PLANS')
          // }

          // string += '</li>';

          if (scope.room.divesAmount) {
            if (scope.fixedPackage) {
              string += '<li>' + scope.room.divesAmount + ' ' + gettextCatalog.getString('dives per diver') + '</li>';
            } else {
              string += '<li>' + scope.room.divesAmount + ' ' + utils.getTextFromInfo(scope.room.diveType, 'shop', 'DIVE_TYPE').toLowerCase() + ' ' + gettextCatalog.getString('dives per diver') + '<p class="inclusion-explanation">' + gettextCatalog.getString('Your pre-booked dives are clearly marked as either boat or shore dives and take place at standard dive sites. Unless otherwise indicated, they don&#39;t cover premium options like night dives or special dive sites and don&#39;t include national park fees or equipment beyond weights, weight belt and tanks.') + '</p></li>';
            }
          }
        }

        if (scope.insurance) {
          insurancePrice = scope.insurance.limitPriceCurrency  + ' ' + Math.round(scope.insurance.limitPrice);
          string += '<li>' + gettextCatalog.getString('Free DAN dive accident insurance (if trip value > {{price}} per person)', { price: insurancePrice }) + '</li>';
        }

        _.forEach(scope.includedDiver, function (val) {
          string += '<li>' + val.title + '</li>';
        });

        if (scope.includedNonDiver && scope.includedNonDiver.length) {
          // string += '<li class="title">For all guests</li>';

          _.forEach(scope.includedNonDiver, function (val) {
            string += '<li>' + val.title + '</li>';
          });
        }

        string += '</ul>';

        var contentTr = $compile(string)(scope);
        element.empty().append(contentTr);
      });
    },
  };
});

angular.module('app').directive('optionalSurcharges', function (gettextCatalog, $compile, inclusionsHelper) {
  return {
    restrict: 'A',
    scope: {
      array: '=',
      onBoard: '=',
      itinerary: '=',
      currency: '=',
      notIncludedInfo: '=',
      payableOnSite: '=',
      dateFrom: '=',
    },
    link: function (scope, element) {
      var string = '<ul>',
        payedInAdvance = !scope.onBoard,
        firstLevelItems = _.filter(scope.array, {'includedIn': null}),
        secondLevelItems = _.filter(scope.array, function (incl) {
          // filter out meal plan items;
          if ((incl.belongsTo === 1 && incl.includedIn) || (incl.belongsTo === 6 && incl.includedIn) ) {
            return false;
          }
          return true;
        }),
        groupedSecondLevel = _.groupBy(secondLevelItems, 'includedIn');

      if (scope.payableOnSite) {
        string += '<li class="title">' + gettextCatalog.getString('Bookable in advance but payable on board')  + '</li>';
      } else {
        string += '<li class="title">' + (payedInAdvance ? gettextCatalog.getString('Bookable & payable in advance') : gettextCatalog.getString('Bookable on board')) + '</li>';
      }

      if (firstLevelItems && firstLevelItems.length) {
        _.each(firstLevelItems, function (item) {
          if (item.validFrom && item.validTo && scope.dateFrom) {
            const utcStartDate = scope.dateFrom.indexOf('T') !== -1 ? moment(scope.dateFrom).utc().format('YYYY-MM-DD') : scope.dateFrom;
            if (!moment(utcStartDate).isBetween(item.validFrom, item.validTo, undefined, '[]')) {
              return;
            }
          }
          string += '<li>' + item.title;

          var isFullBoard = (item.fullBoardDescription && item.fullBoardDescription !== '') ? true : false,
            isScubaSet = (item.fullSetDescription && item.fullSetDescription !== '') ? true : false;

          if (item.title === 'Tips for the crew') {
            string += ' (' + gettextCatalog.getString('recommended') + ')';
          }

          if (item.priceGross) {
            string += ': ';
            string += scope.currency + ' ' + item.priceGross;
            string += ' (' + gettextCatalog.getString('per') + ' ' + inclusionsHelper.selectType('LIVEABOARD_EXTRA_PAYED_PER', item.payedPer, true) + ')';
          } else if (!payedInAdvance && item.extraValue) {
            string += ': ' + item.extraValue;
            string += ' (' + gettextCatalog.getString('per') + ' ' + inclusionsHelper.selectType('LIVEABOARD_EXTRA_PAYED_PER', item.payedPer, true) + ')';
          }

          // display included items in tooltip
          if (groupedSecondLevel[item.extraId] || isFullBoard || isScubaSet) {
            string += ' <a class="link-color itinerary-tooltip" tooltip-title="';

            _.each(groupedSecondLevel[item.extraId], function (inclusion) {
              string += '<p>' + inclusion.title + '</p>';
            });

            if (isFullBoard) {
              string += '<p><strong>' + gettextCatalog.getString('Included') + ': </strong>' + item.fullBoardDescription.included + '</p>';
              if (item.fullBoardDescription.notIncluded) {
                string += '<p><strong>' + gettextCatalog.getString('Not included') + ': </strong>' + item.fullBoardDescription.notIncluded + '</p>';
              }
            }

            if (isScubaSet) {
              string += '<p><strong>' + gettextCatalog.getString('Included') + ': </strong>' + item.fullSetDescription + '</p>';
            }

            string += '">(' + gettextCatalog.getString('see more') + ')</a>';
          }

          string += '</li>';
        });
      } else {
        string += '<li>' + gettextCatalog.getString('None') + '</li>';
      }

      var contentTr = $compile(string)(scope);
      element.append(contentTr);
    },
  };
});

angular.module('app').directive('optionalSurchargesResort', function (gettextCatalog, $compile, inclusionsHelper) {
  return {
    restrict: 'A',
    scope: {
      array: '=',
      onBoard: '=',
      currency: '=',
      notIncludedInfo: '=',
      payableOnSite: '=',
    },
    link: function (scope, element) {
      scope.$watchCollection('array', function (newVal, oldVal) {
        var string = '<ul>',
          payedInAdvance = !scope.onBoard;

        string += '<li class="title">' +
                        (payedInAdvance ?
                          (scope.payableOnSite ?
                            gettextCatalog.getString('Bookable in advance, but payable at the resort or dive center') :
                            gettextCatalog.getString('Bookable in advance')) :
                          gettextCatalog.getString('Bookable at the resort or dive center')) + '</li>';


        if (scope.array && scope.array.length) {
          _.each(scope.array, function (val) {
            string += '<li>' + val.title;

            if (val.priceGross) {
              string += ':';
              // PER STAY
              if (+val.payedPer === 60 || +val.payedPer === 70) {
                string += ' ' +  scope.currency + ' ' + Math.round(val.priceGross);
              } else {
                string += ' ' + scope.currency + ' ' + Math.round(val.priceGross) + ' ';

                if (val.payedPer == 51 || val.payedPer == 53 || val.payedPer == 56) {
                  // transfers per person
                  string += ' (' + gettextCatalog.getString('per person') + ')';
                } else if (val.payedPer == 52 || val.payedPer == 54 || val.payedPer == 57) {
                  // transfers per vehicle
                  string += ' (' + gettextCatalog.getString('per vehicle') + ')';
                } else {
                  string += ' (per ' + inclusionsHelper.selectType('RESORT_EXTRA_PAYED_PER', val.payedPer, true) + ')';
                }
              }
            } else if (!payedInAdvance && val.extraValue) {
              string += ': ' + val.extraValue;
              string += ' (per ' + inclusionsHelper.selectType('RESORT_EXTRA_PAYED_PER', val.payedPer, true) + ')';
            }

            string += '</li>';
          });
        } else {
          string += '<li>' + gettextCatalog.getString('None') + '</li>';
        }

        var contentTr = $compile(string)(scope);
        element.empty().append(contentTr);
      });
    },
  };
});

angular.module('app').directive('mandatorySurcharges', function (gettextCatalog, $compile, inclusionsHelper) {
  return {
    restrict: 'A',
    scope: {
      array: '=',
      onBoard: '=',
      itinerary: '=',
      currency: '=',
      notIncludedInfo: '=',
      dateFrom: '=',
    },
    link: function (scope, element) {
      var string = '<ul>',
        payedInAdvance = !scope.onBoard,
        firstLevelItems = _.filter(scope.array, {'includedIn': null}),
        secondLevelItems = _.filter(scope.array, function (incl) {
        // filter out meal plan items;
          if ((incl.belongsTo === 1 && incl.includedIn) || (incl.belongsTo === 6 && incl.includedIn) ) {
            return false;
          }
          return true;
        }),
        groupedSecondLevel = _.groupBy(secondLevelItems, 'includedIn');

      string += '<li class="title">' + (payedInAdvance ? gettextCatalog.getString('To be paid with your booking') : gettextCatalog.getString('Payable on board')) + '</li>';
      

      if (scope.array && scope.array.length) {
        _.each(firstLevelItems, function (item) {
          if (item.validFrom && item.validTo && scope.dateFrom) {
            const utcStartDate = scope.dateFrom.indexOf('T') !== -1 ? moment(scope.dateFrom).utc().format('YYYY-MM-DD') : scope.dateFrom;
            if (!moment(utcStartDate).isBetween(item.validFrom, item.validTo, undefined, '[]')) {
              return;
            }
          }
          
          string += '<li>' + item.title;

          var isFullBoard = (item.fullBoardDescription && item.fullBoardDescription != '') ? true : false,
            isScubaSet = (item.fullSetDescription && item.fullSetDescription != '') ? true : false;

          if (item.priceGross) {
            string += ': ' + scope.currency + ' ' + Math.round(item.priceGross);
            string += ' (' + gettextCatalog.getString('per') + ' ' + inclusionsHelper.selectType('LIVEABOARD_EXTRA_PAYED_PER', item.payedPer, true) + ')';
          } else if (!payedInAdvance && item.extraValue) {
            string += ': ' + item.extraValue;
            string += ' (' + gettextCatalog.getString('per') + ' ' + inclusionsHelper.selectType('LIVEABOARD_EXTRA_PAYED_PER', item.payedPer, true) + ')';
          }

          // display included items in tooltip
          if (groupedSecondLevel[item.extraId] || isFullBoard || isScubaSet) {
            string += ' <a class="link-color itinerary-tooltip" tooltip-title="';

            _.each(groupedSecondLevel[item.extraId], function (inclusion) {
              string += '<p>' + inclusion.title + '</p>';
            });

            if (isFullBoard) {
              string += '<p><strong>' + gettextCatalog.getString('Included') + ': </strong>' + item.fullBoardDescription.included + '</p>';
              if (item.fullBoardDescription.notIncluded) {
                string += '<p><strong>' + gettextCatalog.getString('Not included') + ': </strong>' + item.fullBoardDescription.notIncluded + '</p>';
              }
            }

            if (isScubaSet) {
              string += '<p><strong>' + gettextCatalog.getString('Included') + ': </strong>' + item.fullSetDescription + '</p>';
            }

            string += '">(' + gettextCatalog.getString('see more') + ')</a>';
          }

          string += '</li>';
        });
      }

      if (scope.notIncludedInfo) {
        _.each(scope.notIncludedInfo, function (val) {
          string += '<li>' + val.title + '</li>';
        });
      } else if (!scope.array || !scope.array.length) {
        string += '<li>' + gettextCatalog.getString('None') + '</li>';
      }


      var contentTr = $compile(string)(scope);
      element.append(contentTr);
    },
  };
});

angular.module('app').directive('mandatorySurchargesResort', function (gettextCatalog, $compile, inclusionsHelper) {
  return {
    restrict: 'A',
    scope: {
      array: '=',
      onBoard: '=',
      currency: '=',
    },
    link: function (scope, element) {
      scope.$watchCollection('array', function (newVal, oldVal) {
        var string = '<ul>',
          payedInAdvance = !scope.onBoard;

        string += '<li class="title">' + (payedInAdvance ? gettextCatalog.getString('To be paid with your booking') : gettextCatalog.getString('Payable at the resort or dive center')) + '</li>';

        if (scope.array && scope.array.length) {
          _.each(scope.array, function (val) {
            string += '<li>' + val.title;

            if (val.priceGross) {
              string += ':';
              // PER STAY
              if (+val.payedPer === 60 || +val.payedPer === 70) {
                string += ' ' +  scope.currency + ' ' + Math.round(val.priceGross);
              } else {
                string += ' ' + scope.currency + ' ' + Math.round(val.priceGross) + ' ';
                if (val.payedPer == 51 || val.payedPer == 53 || val.payedPer == 56) {
                  // transfers per person
                  string += ' (' + gettextCatalog.getString('per person') + ')';
                } else if (val.payedPer == 52 || val.payedPer == 54 || val.payedPer == 57) {
                  // transfers per vehicle
                  string += ' (' + gettextCatalog.getString('per vehicle') + ')';
                } else {
                  string += ' (' + gettextCatalog.getString('per') + ' ' + inclusionsHelper.selectType('RESORT_EXTRA_PAYED_PER', val.payedPer, true) + ')';
                }
              }
            } else if (!payedInAdvance && val.extraValue) {
              string += ': ' + val.extraValue;
              string += ' (' + gettextCatalog.getString('per') + ' ' + inclusionsHelper.selectType('RESORT_EXTRA_PAYED_PER', val.payedPer, true) + ')';
            }

            string += '</li>';
          });
        } else {
          string += '<li>' + gettextCatalog.getString('None') + '</li>';
        }

        var contentTr = $compile(string)(scope);
        element.empty().append(contentTr);
      });
    },
  };
});

angular.module('app').factory('inclusionsHelper', function (info) {
  return {
    isBelongsTo: function (belongsTo, groupIdentifier) {
      var item = _.find(info.inclusionGroups, {id: +belongsTo});

      return item && item.unique_identifier === groupIdentifier ? item : false;
    },
    selectType: function (type, id, lowercase) {
      if (!info) {
        return '';
      }

      var index = _.findIndex(info.shop[type], function (val) {
        return val[0] === +id;
      });

      if (index !== -1) {
        var elem = info.shop[type][index][1];
        if (lowercase) {
          return elem.toLowerCase();
        }

        return elem;
      }
    },
  };
});
