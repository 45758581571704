/* eslint-disable no-undefined */
'use strict';

angular
  .module('app')
  .service('diveGuideService', function (
    Request,
    Upload,
    guideConstants,
    utils,
    $q,
    $uibModal
  ) {
    let diveGuides = {};

    function setVerboseVersion(data) {
      if (!data.version) return `${data.inputLanguage}-draft`;

      const versionType =
        data.version.split('.').length === 3 ? 'minor' : 'major';

      return `v${data.version}-${data.inputLanguage}-${versionType}`;
    }

    function normalizeFields(data) {
      if (_.isObject(data.readHyperbaricChamber)) {
        data.hyperbaricChamber = data.readHyperbaricChamber.title;
      }
      if (data.trainings && data.trainings.length) {
        let formattedTrainings = {};
        data.trainings.forEach((val) => (formattedTrainings[val] = true));

        data.trainings = formattedTrainings;
      }
      data.versionVerbose = setVerboseVersion(data);

      if (!data.area) {
        data.area = guideConstants.EDIT_COUNTRY_KEY;
      } else if (!data.location) {
        data.location = guideConstants.EDIT_AREA_KEY;
      }

      return diveGuides.prepareMultipleChoices(data);
    }

    const multipleChoicesFields = [
      'utcOffset',
      'plug',
      'languages',
      'domesticAirports',
      'internationalAirports',
    ];

    diveGuides.setVerboseVersion = setVerboseVersion;

    diveGuides.save = (guide) => {
      const guideInfo = { ...guide };
      // Transform field before submitting. Because we have [{title: "text", id:1}] but we need to send [1, 2, 3]
      _.each(guideInfo, (val, key) => {
        if (multipleChoicesFields.includes(key)) {
          if (guideInfo[key]) {
            guideInfo[key] = val.map((item) => item.id);
          }
        }
      });

      if (guideInfo.area === guideConstants.EDIT_COUNTRY_KEY) {
        guideInfo.area = null;
        guideInfo.location = null;
      }

      if (guideInfo.location === guideConstants.EDIT_AREA_KEY) {
        guideInfo.location = null;
      }

      if (_.isObject(guideInfo.hyperbaricChamber)) {
        const { placeId } = guideInfo.hyperbaricChamber;
        guideInfo.hyperbaricChamber = placeId;
      } else if (_.isObject(guideInfo.readHyperbaricChamber)) {
        guideInfo.hyperbaricChamber = guideInfo.readHyperbaricChamber.placeId;
      } else {
        guideInfo.hyperbaricChamber = undefined;
      }

      if (!_.isEmpty(guideInfo.trainings)) {
        let transformedTrainings = [];

        _.each(guideInfo.trainings, (selected, key) => {
          if (selected) {
            transformedTrainings.push(+key);
          }
        });

        guideInfo.trainings = transformedTrainings;
      }

      return Request.post('dive-guide/', {
        ...guideInfo,
        ...utils.getContributedBy(),
      }).then(({ data }) => data);
    };

    diveGuides.getById = (id, lang) => {
      return Request.get(`dive-guide/${id}/`, { lang }).then(({ data }) => {
        data.imageCopyright = false;
        data.videoCopyright = false;

        return normalizeFields(data);
      });
    };

    diveGuides.get = (locationId, type, lang, preventLoadingUserVersion) => {
      const q = $q.defer();

      q.resolve(false);
      return $q
        .all([
          !preventLoadingUserVersion
            ? Request.get(`dive-guide/${type}/${locationId}/user/`, {
              lang,
            }).catch(() => null)
            : q.promise,
          Request.get(`dive-guide/${type}/${locationId}/stable/`, {
            lang,
          }).catch(() => null),
        ])
        .then(([userVersion, stableVersion]) => {
          return {
            userVersion: userVersion
              ? normalizeFields(userVersion.data)
              : false,
            stableVersion: stableVersion
              ? normalizeFields(stableVersion.data)
              : false,
          };
        });
    };

    diveGuides.getAreas = (location) => {
      return Request.get(`places/${location}/areas/`).then(({ data }) => data);
    };

    diveGuides.getLocations = (location) => {
      return Request.get(`places/${location}/locations/`).then(
        ({ data }) => data
      );
    };

    diveGuides.getCountryInfo = (location) => {
      return Request.get(`places/country/${location}/`).then(({ data }) => {
        return diveGuides.prepareMultipleChoices(data);
      });
    };

    diveGuides.prepareMultipleChoices = (guide) => {
      const data = { ...guide };

      if (data.utcOffset) {
        data.utcOffset = data.utcOffset.map((item) => ({
          id: +item,
          title: utils.placesOptions(item, 'LOCAL_UTC_OFFSET'),
        }));
      }

      if (data.languages) {
        data.languages = data.languages.map((item) => ({
          id: +item,
          title: utils.placesOptions(item, 'LOCAL_LANGUAGES'),
        }));
      }

      if (data.plug) {
        data.plug = data.plug.map((item) => ({
          id: +item,
          title: utils.placesOptions(item, 'LOCAL_PLUG_TYPE'),
        }));
      }

      if (data.readInternationalAirports) {
        data.internationalAirports = data.readInternationalAirports.map(
          (item) => ({
            id: item.id,
            title: item.complexTitle,
          })
        );
      }

      if (data.readDomesticAirports) {
        data.domesticAirports = data.readDomesticAirports.map((item) => ({
          id: item.id,
          title: item.complexTitle,
        }));
      }

      return data;
    };

    diveGuides.getMarineLife = (slug, type) => {
      return Request.get(`marine-life/${type}/${slug}/`).then((res) => {
        return res.data;
        // return _.groupBy(res.data, 'group.id')
      });
    };

    diveGuides.searchMarineLife = (search) => {
      return Request.get('marine-life/autosuggest', { search }).then(
        ({ data }) => data
      );
    };

    diveGuides.uploadPhotos = function (photo) {
      var header = {
          'Content-Type': 'application/json',
        },
        token = localStorage.getItem('token');

      if (token) {
        header.Authorization = 'JWT ' + token;
      }

      return Upload.upload({
        url: utils.apiURL + 'dive-guide/photo/',
        headers: header,
        arrayKey: '',
        method: 'POST',
        data: {
          image: photo,
        },
      }).then(
        function (res) {
          // SUCCESS EVENT
          return res.data;
        },
        function (err) {
          // ERROR EVENT
          throw err;
        },
        function (evt) {
          // PROGRESS EVENT
          var progressPercentage = parseInt(
            (100.0 * evt.loaded) / evt.total,
            10
          );
          return progressPercentage;
        }
      );
    };

    diveGuides.createNewLocation = function (countryId) {
      const modal = $uibModal.open({
        animation: true,
        templateUrl: '../templates/_add-location.html',
        openedClass: 'itinerary-modal modal-open',
        resolve: {
          countryId,
        },
        controller: function ($uibModalInstance) {
          var vm = this;

          vm.close = function () {
            $uibModalInstance.dismiss('close');
          };
          vm.isLocationSelected = () => _.isObject(vm.newLocation);

          vm.searchLocation = (search) =>
            diveGuides.getGoogleLocations(countryId, search);

          vm.save = function () {
            $uibModalInstance.close(vm.newLocation);
          };
        },
        controllerAs: '$modal',
      });

      return modal.result.then(function (location) {
        return location;
      });
    };

    diveGuides.topDiveSites = (locationId, type) =>
      Request.get(`shop/${type}/${locationId}/dive-sites/top-3/`).then(
        ({ data }) => data
      );

    diveGuides.getGoogleLocations = function (countryId, search) {
      if (search.length < 3) {
        return false;
      }
      return Request.get('places/gmaps/autocomplete/', {
        country_id: countryId,
        search,
      }).then(({ data }) => data);
    };

    diveGuides.addNewLocation = (area, placeId) =>
      Request.post('places/location/add_by_place_id/', { area, placeId }).then(
        ({ data }) => data
      );

    diveGuides.rejectGuide = (id, values) =>
      Request.put(`dive-guide/${id}/reject/`, values).then(({ data }) => data);

    return diveGuides;
  });
