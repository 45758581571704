'use strict';

angular
  .module('app')
  .controller('ProductPricing', function (
    $location,
    $rootScope,
    product,
    $routeParams,
    SeoService,
    shopService,
    info,
    $uibModal,
    gettextCatalog,
    utils
  ) {
    var vm = this;

    $rootScope.showActivity = true;
    vm.loading = true;
    vm.productId = $routeParams.id;

    vm.productPricingPage = true;
    vm.updatePricing = updatePricing;

    vm.scheduleTypes = info.activities.SCHEDULE_TYPE;
    vm.cancellationPolicyTypes = info.activities.CANCELLATION_POLICY;
    vm.timeZones = info.common.AVAILABLE_TIMEZONES;
    vm.paymentMethods = info.activities.CUSTOMER_PAYMENT_METHOD;
    vm.leadTimeTypes = info.activities.LEAD_TIME_TYPE;
    vm.deleteOtherTypes = deleteOtherTypes;
    vm.durationTypes = info.activities.DURATION_UNIT;
    vm.weekdaysSelected = [];
    vm.weekdayStartTimes = [];
    vm.daysOfWeek = [
      gettextCatalog.getString('Monday'),
      gettextCatalog.getString('Tuesday'),
      gettextCatalog.getString('Wednesday'),
      gettextCatalog.getString('Thursday'),
      gettextCatalog.getString('Friday'),
      gettextCatalog.getString('Saturday'),
      gettextCatalog.getString('Sunday'),
    ];
    vm.datesSelected = [];
    vm.datesRange = {};
    vm.dateStartTimes = {};
    vm.dateToNum = dateToNum;
    vm.getPriceTitle = getPriceTitle;
    vm.applyToAllDates = applyToAllDates;
    vm.onRangeChange = onRangeChange;
    vm.redirectNewExtranet = product.redirectNewExtranet;

    product.getProduct($routeParams.id).then(function (data) {
      vm.finishedTabs = data.finishedTabs;
      vm.productData = _.clone(data);
      // parse weekdays from BE;
      if (vm.productData.scheduleType.value === 20 && vm.productData.weekdays) {
        _.each(vm.productData.weekdays, function (day) {
          vm.weekdaysSelected.push('' + day.weekday); // needs to be string;
          vm.weekdayStartTimes[day.weekday] = day.startTime;
        });
      }

      // parse dates from BE;
      if (
        vm.productData.scheduleType.value === 30 &&
        vm.productData.dateRanges
      ) {
        _.each(vm.productData.dateRanges, function (dateRange) {
          vm.datesSelected.push(dateRange.dateFrom);
          vm.dateStartTimes[dateToNum(dateRange.dateFrom)] = dateRange.startTime;
        });
      } else if (vm.productData.scheduleType.value === 40 && vm.productData.dateRanges) {
        const dateFrom = _.get(vm.productData, 'dateRanges[0].dateFrom'),
          dateTo = _.last(vm.productData.dateRanges).dateTo;
        vm.datesRange = {
          dateFrom,
          dateTo,
        };
        _.each(vm.productData.dateRanges, function (dateRange) {
          vm.datesSelected.push(dateRange.dateFrom);
          vm.dateStartTimes[dateToNum(dateRange.dateFrom)] = dateRange.startTime;
        });
      }

      SeoService.generateMetaTags(
        vm.productData.title +
          gettextCatalog.getString(' -Products - PADI Travel for Business')
      );
      vm.loading = false;
    });

    shopService.getShopCurrency().then(function (data) {
      if (data.currency === 'USD') {
        data.currency = '$';
      } else if (data.currency === 'EUR') {
        data.currency = '€';
      } else if (data.currency === 'GBP') {
        data.currency = '£';
      } else if (data.currency === 'UAH') {
        data.currency = '₴';
      }

      vm.currency = data.currency;
    });

    shopService.getInfo().then(function (data) {
      if (data.timezoneId) {
        vm.productData.timezoneId = data.timezoneId;
      }
    });

    function deleteOtherTypes(type) {
      switch (type) {
      case 10:
        vm.productData.dateRanges = [];
        vm.productData.weekdays = [];
        break;
      case 20:
        vm.productData.dateRanges = [];
        break;
      case 30:
        vm.productData.weekdays = [];
        break;
      default:
        break;
      }
    }
    function updatePricing(form) {
      if (form.$valid) {
        vm.loading = true;

        product
          .checkAvailabilityStatus($routeParams.id)
          .then(function (activities) {
            if (activities && activities.length) {
              var modal = $uibModal.open({
                animation: false,
                controller: function ($uibModalInstance) {
                  this.activities = activities;
                  this.close = function () {
                    $uibModalInstance.dismiss('close');
                  };

                  this.save = function () {
                    $uibModalInstance.close('saved');
                  };
                },
                controllerAs: 'ctrl',
                templateUrl: '../templates/_changed-availabilities.html',
              });

              modal.result
                .then(function () {
                  vm.productData.forceUpdateAvailabilities = true;
                  saveInfo();
                })
                .catch(function () {
                  vm.productData.forceUpdateAvailabilities = false;
                  saveInfo();
                });
            } else {
              saveInfo();
            }
          });
      }
    }

    function saveInfo() {
      // to avoid errors with staff;
      if (
        vm.productData.durationBefore &&
        (!vm.productData.durationBefore.durationType.value ||
          !vm.productData.durationBefore.duration)
      ) {
        vm.productData.durationBefore = null;
      }

      // create weekdays object for BE;
      if (vm.productData.scheduleType.value === 20) {
        vm.productData.weekdays = [];
        _.each(vm.weekdaysSelected, function (day) {
          if (day) {
            vm.productData.weekdays.push({
              weekday: +day,
              startTime: vm.weekdayStartTimes[day],
            });
          }
        });
      }

      // create dates with start time object for BE;
      if ([30, 40].includes(vm.productData.scheduleType.value)) {
        vm.productData.dateRanges = [];
        _.each(vm.datesSelected, function (date) {
          if (date) {
            vm.productData.dateRanges.push({
              dateFrom: date,
              dateTo: date,
              startTime: vm.dateStartTimes[dateToNum(date)],
            });
          }
        });
      }
      // actually update
      product.addNew(vm.productData, $routeParams.id).then(
        function () {
          vm.loading = false;
          $rootScope.showNotify(
            gettextCatalog.getString('The product was successfully changed')
          );
          const isConservation =
            _.get(vm.productData, 'productType.value') === 50;
          if (isConservation) {
            $location.path(
              utils.createURL('product_complete', { id: $routeParams.id })
            );
          } else {
            $location.path(
              utils.createURL('product_inclusions', { id: $routeParams.id })
            );
          }
        },
        function (error) {
          vm.loading = false;
          console.error(error);
        }
      );
    }

    function dateToNum(date) {
      if (date && date.length) {
        return 't' + date.replace(/-/g, '');
      }
      return false;
    }

    function applyToAllDates(firstTime) {
      // var thatTime = _.clone(firstTime);
      if (firstTime) {
        _.each(vm.dateStartTimes, function (val, key) {
          vm.dateStartTimes[key] = firstTime;
        });
      }
    }

    function getPriceTitle(productType) {
      if (productType === 50) {
        return gettextCatalog.getString('Activity price');
      }

      return gettextCatalog.getString('PADI Travel price');
    }

    function onRangeChange() {
      const { dateFrom, dateTo } = vm.datesRange;
      vm.showAllDates = true;
      if (dateFrom && dateTo) {
        vm.datesSelected = [];
        vm.dateStartTimes = {};
        const range = utils.enumerateDaysBetweenDates(dateFrom, dateTo);

        _.each(range, function (date) {
          const isAlreadySelected = _.find(vm.productData.dateRanges, { dateFrom: date });
          if (isAlreadySelected && isAlreadySelected.startTime) {
            vm.dateStartTimes[dateToNum(date)] = isAlreadySelected.startTime;
          } else {
            vm.dateStartTimes[dateToNum(date)] = null;
          }
          vm.datesSelected.push(date);
        });
      }
    }
  });
