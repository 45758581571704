'use strict';

angular.module('app').directive('expandableItem', function () {
  return {
    restrict: 'A',
    link: function (_, elem) {
      elem.on('click', function () {
        this.classList.toggle('active');
      });
    },
  };
});
