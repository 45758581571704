'use strict';

angular
  .module('app')
  .controller('DiveCenterCtrl', function (
    $scope,
    $location,
    $rootScope,
    $timeout,
    diveCenterService,
    utils,
    info,
    GTM
  ) {
    var vm = this,
      formSubmited = false;

    vm.selectedParams = {};
    vm.loading = false;
    vm.getGroupsParams = diveCenterService.getAmenities;
    vm.divePolicyTypes = info.shop.DIVE_POLICY_TYPE;

    // GTM.variables({'business_listing_dive_center': "Start"});

    $scope.$on('$locationChangeStart', function (event, toState, oldState) {
      if (!formSubmited) {
        if ($scope.extranetForm.$dirty && $scope.extranetForm.$invalid) {
          event.preventDefault();
          utils
            .warningPopup()
            .then(function () {
              $scope.extranetForm.$setPristine();
              $location.path($rootScope.currentUrl);
            })
            .catch(function () {
              $rootScope.currentUrl = utils.createURL('dive_center');
            });
        }

        if ($scope.extranetForm.$dirty && $scope.extranetForm.$valid) {
          diveCenterService.setInfo(vm.form).then(function () {
            $rootScope.checkedList.diveCenter = true;

            GTM.event('business_listing_dive_center_complete');
            GTM.variables({ business_listing_dive_center: 'Complete' });
          });
        }
      }
    });

    GTM.event('business_listing_dive_center_start');

    diveCenterService.getInfo().then(function (data) {
      vm.form = data;

      diveCenterService.getParameters().then(function (data) {
        vm.form.parameters = data.parameters;
        _.each(data.parameters, function (val) {
          vm.selectedParams['selected_' + val] = true;
        });
      });
    });

    vm.saveInfo = function (form) {
      form.$setSubmitted();
      if (form.$valid) {
        vm.loading = true;
        diveCenterService
          .setInfo(vm.form)
          .then(function () {
            GTM.event('business_listing_dive_center_complete');
            GTM.variables({ business_listing_dive_center: 'Complete' });

            formSubmited = true;

            $location.path(utils.createURL('resort_photos'));
            $rootScope.checkedList.diveCenter = true;
            vm.loading = false;
          })
          .catch(function (error) {
            vm.loading = false;
            _.each(error.data, function (val, key) {
              $scope.extranetForm[key].$setValidity('required', false);
            });
          });
      }
    };

    vm.toggleParameters = function (id) {
      var element = _.filter(vm.form.parameters, function (elem) {
        return elem === id;
      });

      if (element.length) {
        vm.form.parameters = _.reject(vm.form.parameters, function (elem) {
          return elem === id;
        });
      } else {
        vm.form.parameters.push(id);
      }
    };
  });
