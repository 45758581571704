'use strict';

angular.module('app').directive('footerHide', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, elem, attr) {
            scope.$on("$routeChangeSuccess", function () {
                $timeout(function () {
                    var searchPage = angular.element(document.getElementById('search-page')),
                        promoReg = angular.element(document.getElementById('promo-reg')),
                        footerHide = angular.element(document.getElementById('footer-hide'));

                    if (searchPage.length || promoReg.length || footerHide.length) {
                        $('#main-footer').hide();
                    } else {
                        $('#main-footer').show();
                    }
                }, 150);
            });
        }
    };
});
