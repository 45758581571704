'use strict';

angular
  .module('app')
  .controller('InclusionsCtrl', function (
    $q,
    $rootScope,
    $uibModal,
    $scope,
    info,
    shopService,
    inclusionsService,
    config,
    gettextCatalog
  ) {
    var vm = this;

    vm.updateRow = _.debounce(updateRow, 300);
    vm.updateGeneral = _.debounce(updateGeneral, 300);
    vm.setGoodToKnow = _.debounce(inclusionsService.setGoodToKnow, 500);
    vm.updateRentalEquipment = _.debounce(updateRentalEquipment, 300);
    vm.showItineraries = showItineraries;
    vm.saveItineraries = saveItineraries;
    vm.addNewInclusion = addNewInclusion;
    vm.updateTitle = updateTitle;
    vm.deleteItem = deleteItem;
    vm.preview = preview;
    vm.setValidFromTo = setValidFromTo;
    vm.itinerariesForm = {};
    vm.isInfoSection = isInfoSection;
    vm.featuredOrderChoices = config.featuredOrderChoices;

    init();

    function init() {
      vm.loading = true;
      vm.pricePerOptions = info.shop.LIVEABOARD_EXTRA_PAYED_PER;
      vm.validForOptions = inclusionsService.validForOptions;
      vm.paybleOptions = inclusionsService.paybleOptions;
      vm.gtkReady = false;

      inclusionsService.getGoodToKnow().then(function (data) {
        vm.goodToKnowInfo = data;
        vm.gtkReady = true;
      });

      shopService.getShopCurrency().then(function (data) {
        vm.currency = data.currency;
      });

      getList();
    }

    function getList() {
      return inclusionsService.getList().then(function (val) {
        vm.inclusions = val.list;
        vm.isNewModelUsed = val.general.isNewModelUsed;
        vm.loading = false;
      });
    }

    function updateRow(form, data) {
      if (data.addField) {
        return true;
      }

      if (data.payedPer === 0 || data.payedPer === 20 || data.payedPer === 40) {
        data.payedInAdvance = false;
      }

      if(data.payedPer === 110){
        data.payedInAdvance = true;
      }

      if (form.$valid) {
        // if (data.subkind) {
        //     var general = _.filter(vm.inclusions[1].data, {subkind: 'general'}),
        //         obj = {};

        //     _.each(general, function (val) {
        //         obj[val.kind] = val.status;
        //     });
        //     console.log(general, vm.inclusions)

        //     data = obj;
        //     data.kind = 'general';
        // }

        if (
          data.payedInAdvance === false &&
          data.extraValue &&
          data.extraValue.trim()
        ) {
          data.price = !isNaN(+data.extraValue) ? data.extraValue : null;
        }

        inclusionsService.update(data);
      }
    }

    function updateTitle(data) {
      if (data.title) {
        vm.loading = true;
        inclusionsService.update(data).then(function () {
          data.edit = false;
          vm.loading = false;
        });
      }
    }

    function showTableBody(title) {
      _.each(vm.inclusions, function (item) {
        if (item.title === title) {
          item.isActive = true;
        }
      });
    }

    function deleteItem(data, title) {
      vm.loading = true;
      inclusionsService.del(data).then(getList).then(function () {
        showTableBody(title);
      });
    }

    function showItineraries(incl) {
      if (incl.appliedToTrips) {
        return 1;
      }

      vm.disableScreen = true;
      vm.showTable = true;
      vm.loadingItineraries = true;
      vm.selectedInclusion = incl;

      inclusionsService.itinerariesList(incl).then(function (val) {
        vm.loadingItineraries = false;
        vm.itineraries = val;
      });
    }

    function saveItineraries(cancel) {
      if (cancel) {
        vm.disableScreen = false;
        vm.showTable = false;
        return (vm.loadingItineraries = false);
      }


      var preventClose = false,
        touchedObjects = [];
      _.each(vm.itinerariesForm, function (val, key) {
        if (val.$invalid) { preventClose = true; }
        if (val.$dirty && val.$valid) {
          var itinerary = _.find(vm.itineraries, { id: +key });

          itinerary.pk = vm.selectedInclusion.pk;

          touchedObjects.push(inclusionsService.updateItinerary(itinerary));
        }
      });

      if (preventClose) return false;

      if (touchedObjects.length) {
        vm.loadingItineraries = true;

        $q.all(touchedObjects)
          .then(function () {
            vm.disableScreen = false;
            vm.showTable = false;
            vm.loadingItineraries = false;
          })
          .catch(function () {
            vm.loadingItineraries = false;
          });
      } else {
        vm.disableScreen = false;
        vm.showTable = false;
      }
    }

    function addNewInclusion(incl, title) {
      incl.submitted = true;

      if (incl.title) {
        vm.loading = true;
        incl.kind = 600;
        inclusionsService.addNew(incl).then(getList).then(function () {
          showTableBody(title);
        });
      }
    }

    function updateGeneral() {
      return inclusionsService.updateGeneral({
        isNewModelUsed: vm.isNewModelUsed,
      });
    }

    function preview() {
      vm.loading = true;
      $uibModal.open({
        animation: true,
        templateUrl: '../templates/_inclusions-preview.modal.html',
        controller: 'PreviewModalCtrl',
        controllerAs: '$ctrl',
        windowClass: 'inclusions-modal',
        size: 'lg',
        resolve: {
          items: function () {
            return inclusionsService.preview().then(function (data) {
              vm.loading = false;
              return data;
            });
          },
        },
      });
    }

    function isInfoSection(belongsTo) {
      var item = _.find(info.inclusionGroups, { id: belongsTo });

      return item.unique_identifier === gettextCatalog.getString('Information');
    }

    function setValidFromTo(item) {
      inclusionsService.setValidFromTo(item, inclusionsService.update, getList);
    }

    function updateRentalEquipment(incl) {
      inclusionsService.updateRentalEquipment(incl);
    }
  });
