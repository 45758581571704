'use strict';

angular.module('app').directive('roomsAndGuests', function (utils, diviacLocalStorage, $timeout) {
  return {
    restrict: 'E',
    templateUrl: window.templatePath ? window.templatePath + 'templates/_rooms-and-guest.popup.html?v=4' : '../templates/_rooms-and-guest.popup.html?v=4',
    scope: {
      updateRoom: '&',
      selectedRooms: '=',
      showLabel: '=',
      showIcon: '=',
    },
    link: function (scope, elem) {
      var selectedGuests = diviacLocalStorage.get('roomParams');

      scope.totalText = '';
      scope.addRoom = addRoom;
      scope.delRoom = delRoom;
      scope.save = save;
      scope.onOpen = onOpen;
      scope.showError = false;
      scope.emptyRooms = false;
      scope.roomsAndGuests = _.get(selectedGuests, 'details', [{
        divers: 0,
        students: 0,
        nonDivers: 0,
      }]);

      function save(data) {
        scope.emptyRooms = false;
        var diversNumber = data.reduce(function (acc, val) {
            return acc + val.divers + val.students;
          }, 0),
          nonDiversNumber = data.reduce(function (acc, val) {
            return acc + val.nonDivers;
          }, 0);

        if ((nonDiversNumber > 0 && diversNumber < 1) && !utils.isStaff()) {
          scope.showError = true;
          $('html, body').animate({
            scrollTop: $(elem).offset().top,
          }, 300);
        } else {
          scope.showError = false;
          if (diversNumber === 0 && nonDiversNumber === 0) {
            scope.emptyRooms = true;
            data = null;
          }

          scope.updateRoom({
            arg1: data,
          });
          scope.isActive = false;

          $('body').css('overflow', '');
        }
      }

      function addRoom() {
        var newRoom = {
          divers: 1,
          nonDivers: 0,
          students: 0,
        };
        scope.roomsAndGuests.push(newRoom);
      }

      function delRoom(index) {
        scope.roomsAndGuests.splice(index, 1);
      }

      function onOpen() {
        if (window.innerWidth <= 1024) {
          $('body').css('overflow', 'hidden');
        }
        $('html, body').animate({
          scrollTop: $(elem).offset().top,
        }, 300);
      }

      scope.$watch('roomsAndGuests', function (newValue) {
        var option = {
          rooms: 0,
          divers: 0,
          students: 0,
          nonDivers: 0,
        };
        _.each(scope.roomsAndGuests, function (val) {
          option.divers += val.divers;
          option.students += val.students;
          option.nonDivers += val.nonDivers;
        });

        option.rooms = newValue.length;

        scope.totalText = utils.formattedRoomsText(option);
      }, true);


      $(document).on(utils.isMobile() ? 'touchend' : 'click', function (e) {
        if ($(e.target).parents('.rooms-and-guest-selector, rooms-and-guests, .rooms-popup, .p-row').length === 0 && scope.isActive) {
          $timeout(function () {
            save(scope.roomsAndGuests);
          });
        }
      });
    },
  };
});
