'use strict';

angular
  .module('app')
  .controller('ProductsCtrl', function (
    $scope,
    $uibModal,
    Request,
    product,
    info,
    utils,
    shopService,
    $rootScope,
    $location,
    $anchorScroll,
    $timeout,
    GTM,
    gettextCatalog,
  ) {
    var vm = this,
      activeProducts = [],
      singleDiveProduct = [],
      twoTankDiveProduct = [];

    activate();
    vm.deleteProduct = deleteProduct;
    vm.saveCurrency = saveCurrency;
    vm.savePrices = savePrices;
    vm.changeCurrency = _.debounce(changeCurrency, 300);
    vm.unselectBaseProduct = unselectBaseProduct;
    vm.checkBtn = checkBtn;
    vm.calcPrices = calcPrices;
    vm.singleDivePrices = [];
    vm.twoTankDivePrices = [];
    vm.loading = false;
    vm.changeSeasonsNumber = changeSeasonsNumber;
    vm.saveSeasons = saveSeasons;
    vm.getFromInfo = utils.getFromInfo;
    vm.redirectNewExtranet = product.redirectNewExtranet;

    for (let i = 1; i <= 21; i++) {
      vm.singleDivePrices.push({
        priceNet: null,
        numberOfInstances: i,
        _perDiveSetPrice: null,
      });
    }

    for (let i = 1; i <= 11; i++) {
      vm.twoTankDivePrices.push({
        priceNet: null,
        numberOfInstances: i * 2,
        _perDiveSetPrice: null,
      });
    }

    singleDiveProduct = vm.singleDivePrices;
    twoTankDiveProduct = vm.twoTankDivePrices;

    function activate() {
      // GTM.variables({'business_listing_products': "Start"});
      GTM.event('business_listing_product_start');

      vm.currencyList = info.shop.CURRENCY_CHOICES;
      vm.utils = utils;
      vm.info = info;
      vm.base = {};

      shopService.getShopCurrency().then(function (data) {
        vm.shopConfig = data;
        // MOCK
        vm.divingSeasons = [];
      });

      getProducts();

      product.getBaseProducts().then(function (data) {
        vm.base = data;

        if (vm.base.prices.length) {
          if (vm.base.isTwoTank) {
            vm.twoTankDivePrices = _.sortBy(
              vm.base.prices,
              'numberOfInstances',
            );
          } else {
            vm.singleDivePrices = _.sortBy(vm.base.prices, 'numberOfInstances');
          }
        }
      });

      getSeasons();
      if ($location.hash()) {
        $timeout(function () {
          $anchorScroll();
        }, 500);
      }
    }

    function getProducts() {
      // Get list of products
      vm.loader = true;
      product.getProducts().then(function (data) {
        vm.products = data;
        vm.loader = false;
        activeProducts = _.find(vm.products, { isActive: true });
      });
    }

    function saveCurrency() {
      product.checkStatus(vm.products, activeProducts);
    }

    function updateProductVisibility(prod) {
      product
        .updateProduct(prod.id, { shownOn: prod.shownOn })
        .catch(function () {
          getProducts();
        });
    }

    function deleteProduct(prod) {
      product.delProduct(prod.id).then(
        function () {
          product.getBaseProducts().then(function (data) {
            vm.base = {};
            vm.base[data.standardName] = data;

            if (data) {
              $rootScope.checkedList.products = true;
            }
            findBaseProduct(data);
          });

          vm.products = _.reject(vm.products, function (val) {
            return val.id == prod.id;
          });
          $rootScope.showNotify(
            gettextCatalog.getString('The selected product was successfully deleted'),
          );
        },
        function (err) {
          if (err.data.nonFieldErrors) {
            $rootScope.showNotify(err.data.nonFieldErrors);
          }
        },
      );
    }

    function changeCurrency() {
      shopService
        .setShopCurrency(vm.shopConfig)
        .then(function () {
          $rootScope.showNotify(gettextCatalog.getString('Successfully saved'));

          product.checkStatus(vm.products, activeProducts);
        });
    }

    function savePrices() {
      if ($scope.baseProduct.$valid) {
        vm.loading = true;

        vm.base.prices = vm.base.isTwoTank
          ? vm.twoTankDivePrices
          : vm.singleDivePrices;

        product
          .savePrices(vm.base)
          .then(function (res) {
            vm.loading = false;

            vm.base = res;

            if (res.isTwoTank) {
              vm.twoTankDivePrices = _.sortBy(res.prices, 'numberOfInstances');
              vm.singleDivePrices = singleDiveProduct;
            } else {
              vm.singleDivePrices = _.sortBy(res.prices, 'numberOfInstances');
              vm.twoTankDivePrices = twoTankDiveProduct;
            }

            $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved'));
          })
          .catch(function () {
            vm.loading = false;
          });
      }
    }

    function checkBtn() {
      return _.find(_.map(vm.base), function (val) {
        return val.standardName && val.price;
      });
    }

    function updateBasePrice() {
      return product
        .getProducts()
        .then(function (data) {
          vm.products = data;
        })
        .then(
          product
            .getBaseProducts()
            .then(function (data) {
              vm.base = {};
              vm.base[data.standardName] = data;
              vm.loading = false;
              findBaseProduct(data);

              if (data) {
                $rootScope.checkedList.products = true;
              }
            })
            .catch(function () {
              vm.loading = false;
            }),
        )
        .catch(function () {
          vm.loading = false;
        });
    }

    function unselectBaseProduct(id) {
      _.each(vm.base, function (val, key) {
        if (parseInt(key) !== parseInt(id)) {
          vm.base[key].standardName = false;
        }
      });
    }

    function findBaseProduct(data) {
      _.each(data.otherBaseProducts, function (v, k) {
        if (info.baseProducts[0].kind == k) {
          vm.base[info.baseProducts[0].id] = { price: v };
        } else if (info.baseProducts[1].kind == k) {
          vm.base[info.baseProducts[1].id] = { price: v };
        }
      });
    }

    function calcPrices(index, prices, fieldName) {
      const fieldsToConvert = ['priceNet', '_pricePerAddDive', '_perDiveSetPrice'];

      var prevPrice = prices[index - 1],
        currentPrice = prices[index];

      _.each(currentPrice, (val, key) => {
        if (fieldsToConvert.includes(key)) {
          currentPrice[key] = Number(val);
        }
      });

      _.each(prevPrice, (val, key) => {
        if (fieldsToConvert.includes(key)) {
          prevPrice[key] = Number(val);
        }
      });

      if (!prevPrice) {
        prevPrice = {
          priceNet: 0,
          _pricePerAddDive: 0,
          _perDiveSetPrice: 0,
        };
      }

      switch (fieldName) {
      default:
        break;
      case '_pricePerAddDive':
        currentPrice._perDiveSetPrice = utils.twoDecimalRound(
          prevPrice._perDiveSetPrice + currentPrice._pricePerAddDive
        );
        currentPrice.priceNet = utils.twoDecimalRound(
          currentPrice._perDiveSetPrice / (index + 1),
        );
        break;
      case 'priceNet':
        currentPrice._perDiveSetPrice = utils.twoDecimalRound(
          (index + 1) * currentPrice.priceNet,
        );
        currentPrice._pricePerAddDive =
          utils.twoDecimalRound( currentPrice._perDiveSetPrice - prevPrice._perDiveSetPrice );

        break;
      case '_perDiveSetPrice':
        currentPrice.priceNet = utils.twoDecimalRound(
          currentPrice._perDiveSetPrice / (index + 1),
        );
        currentPrice._pricePerAddDive =
          utils.twoDecimalRound(currentPrice._perDiveSetPrice - prevPrice._perDiveSetPrice);
        break;
      }

      if (currentPrice.priceNet < 0) {
        currentPrice.priceNet = 0;
      }

      if (currentPrice._perDiveSetPrice < 0) {
        currentPrice._perDiveSetPrice = 0;
      }

      if (currentPrice._pricePerAddDive < 0) {
        currentPrice._pricePerAddDive = 0;
      }
    }

    function changeSeasonsNumber() {
      if (!vm.numberOfSeasons) return;
      if (vm.divingSeasons.length > vm.numberOfSeasons) {
        vm.divingSeasons.splice(vm.numberOfSeasons, vm.divingSeasons.length - vm.numberOfSeasons);
      }
      for (var i = 0; i < vm.numberOfSeasons; i++) {
        if (!vm.divingSeasons[i]) {
          vm.divingSeasons[i] = {
            qty: [0],
            dates: [],
          };
        }
      }
    }

    function saveSeasons(form) {
      vm.loading = true;
      form.$submitted = true;
      if (form.$valid || !vm.haveSeason) {
        if (!vm.haveSeason) vm.divingSeasons.length = 0;
        product.saveSeasons(vm.divingSeasons).then(function () {
          $rootScope.showNotify(gettextCatalog.getString('Seasons was successfully saved'));
          vm.loading = false;
        }).catch(function () {
          vm.loading = false;
        });
      } else {
        vm.loading = false;
      }
    }

    function getSeasons() {
      vm.loading = true;
      product.getSeasons().then(function (res) {
        if (res.diveSeasons.length) {
          vm.divingSeasons = res.diveSeasons;
          vm.haveSeason = true;
          vm.numberOfSeasons = res.diveSeasons.length;
          _.each(vm.divingSeasons, function (val) {
            val.qty = val.dates;
          });
        } else {
          vm.divingSeasons = [
            {
              qty: [0],
              dates: [],
            },
            {
              qty: [0],
              dates: [],
            }];
          vm.haveSeason = false;
          vm.numberOfSeasons = 2;
        }
        vm.loading = false;
      });
    }

    vm.toggleShownOn = function (param, prod) {
      var paramIndex = prod.shownOn.indexOf(param);

      if (paramIndex !== -1) {
        prod.shownOn.splice(paramIndex, 1);
      } else {
        prod.shownOn.push(param);
      }

      updateProductVisibility(prod);
    };
  });
