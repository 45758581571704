'use strict';

angular.module('app').directive('resize', function ($window, $location, $timeout) {
    return {
        restrict: 'AE',
        scope: {
            resizeFull: '@',
            paddingSize: '=',
            extranet: '=',
            noOffset: '=',
            watchSize: '='
        },
        link: function (scope, element) {

            var w = angular.element($window),
                offset = scope.resizeFull ? $('.default-header').outerHeight() : 150;
            if (scope.paddingSize) {
                offset = scope.paddingSize;
            }

            if (scope.extranet) {
                offset += $('.information-block').outerHeight();

                if ($location.path() === '/dive-sites/list/') {
                    offset = 60;
                }
            }

            if (scope.noOffset) {
                offset = 0;
            }

            scope.getWindowDimensions = function () {
                return {height: w[0].innerHeight, width: w[0].innerWidth};
            };

            scope.$watch(scope.getWindowDimensions, function (newValue, oldValue) {
                var newOffset = newValue.height - offset > 300 ? newValue.height - offset : 300;

                element.css('height',  newOffset + 'px');

                if (scope.watchSize) {
                    $timeout(resizeExtranet);
                }

            },true);

            if (scope.watchSize) {
                $timeout(resizeExtranet);

                $('.main-wrapper').on('classChange', function() {
                    $timeout(resizeExtranet,310);
                });
            }

            w.bind('resize', function () {
                scope.$apply();
            });


            function resizeExtranet () {
                var sidebarCollapse = $('.left-navigation').css('left');
                
                sidebarCollapse = sidebarCollapse.slice(0,-2);

                $(element).find('.content').css('width', (window.innerWidth > scope.watchSize ?  window.innerWidth : scope.watchSize ) - $('.left-navigation').innerWidth() - sidebarCollapse - 40 + 'px');
            }
        }
    };
})