/* eslint-disable no-undefined */
/* eslint-disable guard-for-in */
/* eslint-disable consistent-return */
'use strict';

angular
  .module('app')
  .service('utils', function (
    $http,
    $window,
    $injector,
    $sce,
    $rootScope,
    info,
    diviacLocalStorage,
    localStorageService,
    $uibModal,
    selectedLanguage
  ) {
    var utils = {},
      isLocalDev = _.find(['diviaclocal', '192.168', 'localhost'], function (
        str
      ) {
        return _.contains(window.location.host, str);
      }),
      isStage = _.find(['comingsoon.rocks'], function (str) {
        return _.includes(window.location.host, str);
      }),
      isStageCN = _.find(['travel-qa.padi.com.cn'], function (str) {
        return _.includes(window.location.host, str);
      }),
      isDev = _.find(['padi.nearlydone.rocks'], function (str) {
        return _.contains(window.location.host, str);
      }),
      isInProgress = _.find(['inprogress.rocks'], function (str) {
        return _.contains(window.location.host, str);
      }),
      shopifyURL = 'http://shop.diviac.travel/cart/',
      server = '//travel.padi.com/api/v2/extranet/',
      redirectUri = 'https://travel.padi.com',
      cognitoURL =
        'https://account.padi.com/login/sign-in?client_id=503u87mvc25i53veank5m32e6i',
      cookieDomain = '.padi.com',
      desktop = 1400,
      tabletDesktop = 1024,
      tabletPort = 767,
      mobileLand = 500,
      currentWidth = angular.element($window)[0].innerWidth;

    if (isLocalDev) {
      shopifyURL = 'https://diviac-sandbox.myshopify.com/cart/';
      redirectUri = 'https://travel.padi.localhost';
      cognitoURL =
        'https://account.global-np.padi.com/login/sign-in/en-US?client_id=7u0q6o7jbqck26k2adbdu6qbjk';
      server = 'https://travel.padi.localhost/api/v2/extranet/';
      cookieDomain =
        window.location.host === 'localhost:9216'
          ? 'localhost'
          : '.travel.padi.localhost';
    } else if (isStage) {
      redirectUri = 'https://travel.padi.comingsoon.rocks';
      cognitoURL =
        'https://account.global-np.padi.com/login/sign-in/en-US?client_id=7u0q6o7jbqck26k2adbdu6qbjk';
      server = '//travel.padi.comingsoon.rocks/api/v2/extranet/';
      cookieDomain = '.padi.comingsoon.rocks';
    } else if (isStageCN) {
      redirectUri = 'https://travel.travel-qa.padi.com.cn';
      cognitoURL =
        'https://account.global-np.padi.com/login/sign-in/en-US?client_id=7u0q6o7jbqck26k2adbdu6qbjk';
      server = '//travel.travel-qa.padi.com.cn/api/v2/extranet/';
      cookieDomain = '.padi.com.cn';
    } else if (isDev) {
      shopifyURL = 'https://diviac-sandbox.myshopify.com/cart/';
      server = 'https://travel.padi.nearlydone.rocks/api/v2/extranet/';
      redirectUri = 'https://travel.padi.nearlydone.rocks';
      cognitoURL =
        'https://account.global-np.padi.com/login/sign-in/en-US?client_id=7u0q6o7jbqck26k2adbdu6qbjk';
      cookieDomain = '.padi.nearlydone.rocks';
    } else if (isInProgress) {
      redirectUri = 'https://travel.padi.inprogress.rocks';
      cognitoURL =
        'https://account.global-np.padi.com/login/sign-in/en-US?client_id=7u0q6o7jbqck26k2adbdu6qbjk';
      server = '//travel.padi.inprogress.rocks/api/v2/extranet/';
      cookieDomain = '.padi.inprogress.rocks';
    }

    var localStorageIsSupported = true;
    if (typeof localStorage === 'object') {
      try {
        localStorage.setItem('localStorage', 1);
        localStorage.removeItem('localStorage');
        localStorageIsSupported = true;
      } catch (e) {
        localStorageIsSupported = false;
      }
    }

    if (
      localStorageIsSupported &&
      localStorage.getItem('testMode') &&
      localStorage.getItem('testMode') === 'test'
    ) {
      server = 'https://travel.padi.comingsoon.rocks/api/v2/extranet/';
    } else {
      var storage = angular.injector(['ngCookies']).get('$cookies');

      if (storage.get('testMode') && storage.get('testMode') === 'test') {
        server = 'https://travel.padi.comingsoon.rocks/api/v2/extranet/';
      }
    }

    utils.apiURL = server;
    utils.shopifyURL = shopifyURL;

    utils.makeURL = function (link) {
      if (link.includes('http')) {
        return link;
      }
      if (typeof link !== 'string') {
        return false;
      }
      if (link[link.length - 1] !== '/') {
        link += '/';
      }

      return utils.apiURL + link;
    };

    utils.isTest = function () {
      return (
        isDev ||
        isLocalDev ||
        (localStorage.getItem('testMode') &&
          (localStorage.getItem('testMode') === 'test' ||
            localStorage.getItem('testMode') === 'prod'))
      );
    };

    utils.getCurrentShopSlug = function () {
      if (localStorageService.get('choosenShop')) {
        return localStorageService.get('choosenShop').slug;
      }

      return diviacLocalStorage.get('choosenShop')
        ? diviacLocalStorage.get('choosenShop').slug
        : null;
    };

    utils.getCurrentShopId = function () {
      if (localStorageService.get('choosenShop')) {
        return localStorageService.get('choosenShop').id;
      }

      if (diviacLocalStorage.get('choosenShop')) {
        return diviacLocalStorage.get('choosenShop').id;
      }

      return null;
    };

    utils.isProDiverShop = () => {
      const shop =
        localStorageService.get('choosenShop') ||
        diviacLocalStorage.get('choosenShop');

      return shop && shop.kind === 110;
    };

    utils.generateListingUrl = function () {
      const shop = diviacLocalStorage.get('choosenShop'),
        travelLink = window.location.origin.replace('business', 'travel');

      if (shop) {
        return `${travelLink}/${utils.getShopKind(shop.kind)}/preview/${
          shop.slug
        }/`;
      }
    };

    utils.getShopKind = (id) => {
      if (id === 0) {
        return 'dive-center';
      } else if (id === 10) {
        return 'liveaboard';
      }
      return 'dive-resort';
    };

    utils.encodeURL = function (string) {
      return string.replace(/\s/g, '_').replace(/\.|,|;/g, '-');
    };

    utils.decodeURL = function (string) {
      return string.replace(/--/g, '.,').replace(/-/g, ',').replace(/_/g, ' ');
    };

    utils.getCorrectImage = function (object, type) {
      if (!object) return false;

      if (currentWidth >= desktop) {
        return $sce.trustAsResourceUrl(object[type + 'Desktop']);
      } else if (currentWidth >= tabletDesktop && currentWidth < desktop) {
        return $sce.trustAsResourceUrl(object[type + 'TabletLand']);
      } else if (currentWidth >= tabletPort && currentWidth < tabletDesktop) {
        return $sce.trustAsResourceUrl(object[type + 'TabletPort']);
      } else if (currentWidth >= mobileLand && currentWidth < tabletPort) {
        return $sce.trustAsResourceUrl(object[type + 'MobileLandscape']);
      }
      return $sce.trustAsResourceUrl(object[type + 'MobilePort']);
    };

    utils.getHomepageBg = function () {
      if (currentWidth >= desktop) {
        return '/images/desktop_bg-main.jpg';
      } else if (currentWidth >= tabletDesktop && currentWidth < desktop) {
        return '/images/tablet_desktop_bg-main.jpg';
      } else if (currentWidth >= tabletPort && currentWidth < tabletDesktop) {
        return '/images/tablet_port_bg-main.jpg';
      } else if (currentWidth < tabletPort) {
        return '/images/mobile_big_bg-main.jpg';
      }
    };

    utils.shopKind = function (type) {
      var index = _.findIndex(info.shop.SHOP_KIND, function (val) {
        return val[1] === type;
      });

      if (index !== -1) {
        return info.shop.SHOP_KIND[index][0];
      }
    };

    utils.diveSiteKind = function (type) {
      var index = _.findIndex(info.places.ADVENTURE_DIVE_SITE_TYPE, function (
        val
      ) {
        return val[0] === type;
      });

      if (index !== -1) {
        return info.places.ADVENTURE_DIVE_SITE_TYPE[index][1];
      }
    };

    utils.operatorSlug = function (type) {
      if (type === 'Liveaboard') {
        return 'liveaboard';
      } else if (type === 'Dive resort') {
        return 'dive-resort';
      } else if (type === 'Dive center') {
        return 'dive-center';
      }
    };

    utils.revertSlug = function (slug) {
      if (slug === 'liveaboards') {
        return 'Liveaboard';
      } else if (slug === 'dive-resorts') {
        return 'Dive resort';
      } else if (slug === 'dive-centers') {
        return 'Dive center';
      } else if (slug === 'dive-sites') {
        return 'Dive sites';
      }
    };

    utils.checkObjectLength = function (obj) {
      return _.isEmpty(obj);
    };

    utils.placesOptions = function (id, type) {
      var intId = parseInt(id, 10),
        index = _.findIndex(info.places[type], function (val) {
          return val[0] === intId;
        });

      if (index !== -1) {
        return info.places[type][index][1];
      }
    };

    utils.visibility = function (id) {
      var index = _.findIndex(info.marineLife.VISIBILITY, function (val) {
        return val[0] === id;
      });

      if (index !== -1) {
        return info.marineLife.VISIBILITY[index][1];
      }
    };

    utils.dayDiff = function (from, to) {
      return moment(to, 'YYYY-MM-DD').diff(
        moment(from, 'YYYY-MM-DD'),
        'days',
        true
      );
    };

    utils.toLowerCase = function (text) {
      return text.toLocaleLowerCase();
    };

    utils.twoDecimalRound = function (val) {
      return Math.round(val * 100) / 100;
    };

    utils.toFahrenheit = function (data, round) {
      if (!data) {
        return null;
      }

      if (round) {
        return Math.round((data * 9) / 5 + 32);
      }

      return Math.round(((data * 9) / 5 + 32) * 100) / 100;
    };

    utils.toInch = function (data, round) {
      if (!data) {
        return null;
      }

      if (round) {
        return Math.round(data / 25.4);
      }

      return Math.round((data / 25.4) * 100) / 100;
    };

    utils.capitalizeFirstLetter = function (str) {
      var firstLetter = str.substr(0, 1);

      return firstLetter.toUpperCase() + str.substr(1);
    };

    utils.bookingChoices = function (type) {
      var index = _.findIndex(info.booking.DIVE_PRODUCT_KIND, function (val) {
        return val[1].toLowerCase() === type.toLowerCase();
      });

      if (index !== -1) {
        return info.booking.DIVE_PRODUCT_KIND[index][0];
      }
    };

    utils.bookingChoicesTransform = function (type) {
      var index = _.findIndex(info.booking.DIVE_PRODUCT_KIND, function (val) {
        return val[0] === type;
      });

      if (index !== -1) {
        return info.booking.DIVE_PRODUCT_KIND[index][1];
      }
    };

    utils.getFromInfo = function (id, type, item) {
      var data = info[type][item],
        index = _.findIndex(data, function (val) {
          return val[0] === id;
        });

      return index !== -1 ? data[index][1] : '';
    };

    utils.getShopKindTitle = function (id) {
      var text = utils.getFromInfo(id, 'shop', 'SHOP_KIND');

      return text;
    };

    utils.getTextForPages = function (key) {
      if (key === 'basics') {
        return {
          sort: 0,
          text: 'Complete the basics section',
        };
      }
      if (key === 'liveaboardBasics') {
        return {
          sort: 0,
          text: 'Complete the liveaboard section',
        };
      } else if (key === 'diveCenter') {
        return {
          sort: 1,
          text: 'Complete the dive center section',
        };
      } else if (key === 'diveSites') {
        return {
          sort: 2,
          text: 'Complete the dive sites section',
        };
      } else if (key === 'photos') {
        return {
          sort: 3,
          text: 'Complete the photos section',
        };
      } else if (key === 'products') {
        return {
          sort: 4,
          text: 'Setup your product and prices',
        };
      } else if (key === 'admin') {
        return {
          sort: 5,
          text: 'Complete the admin section',
        };
      } else if (key === 'agreement') {
        return {
          sort: 6,
          text: 'Sign the agreement',
        };
      } else if (key === 'rooms') {
        return {
          sort: 3.8,
          text: 'Complete your rooms and prices',
        };
      } else if (key === 'resortPhotos') {
        return {
          sort: 3.5,
          text: 'Complete the dive resort photos section',
        };
      } else if (key === 'resort') {
        return {
          sort: 0.9,
          text: 'Complete the dive resort section',
        };
      } else if (key === 'itinerary') {
        return {
          sort: 3.9,
          text: 'Complete the itineraries section',
        };
      } else if (key === 'liveaboardPhotos') {
        return {
          sort: 3,
          text: 'Complete the photos section',
        };
      } else if (key === 'cabins') {
        return {
          sort: 3.8,
          text: 'Complete your cabins section',
        };
      } else if (key === 'schedule') {
        return {
          sort: 3.92,
          text: 'Complete schedule section',
        };
      } else if (key === 'pricing' || key === 'itineraryPrice') {
        return {
          sort: 3.91,
          text: 'Complete your pricing section',
        };
      } else if (key === 'availability') {
        return {
          sort: 3.92,
          text: 'Complete your availability section',
        };
      }
    };

    utils.warningPopup = function (template, scope) {
      var modalInstance = $uibModal.open({
        animation: false,
        templateUrl: template ? template : '../templates/_warning-popup.html',
        controller: 'ModalClaimCtrl',
        controllerAs: 'claimModal',
        windowClass: 'contact-us',
        scope: scope,
      });

      return modalInstance.result.then(function (res) {
        return res;
      });
    };

    utils.confirmationPopup = function () {
      var modalInstance = $uibModal.open({
        animation: false,
        templateUrl: '../templates/_form-change-status.html',
        controller: 'ModalClaimCtrl',
        controllerAs: 'claimModal',
        windowClass: 'contact-us',
      });

      return modalInstance.result.then(function (res) {
        return res;
      });
    };

    utils.mandatoryPopup = function (template) {
      var modalInstance = $uibModal.open({
        animation: false,
        templateUrl: template ? template : '../templates/_agreement-popup.html',
        controller: 'ModalClaimCtrl',
        controllerAs: 'claimModal',
        windowClass: 'contact-us',
        backdrop: 'static',
        keyboard: false,
      });

      return modalInstance.result.then(function (res) {
        return res;
      });
    };

    utils.generateQueryParams = function (data) {
      return (
        '?company=' +
        data.title +
        '&firstname=' +
        $rootScope.user.firstName +
        '&lastname=' +
        $rootScope.user.lastName +
        '&email=' +
        data.email +
        '&phone=' +
        data.phone +
        '&website=' +
        data.webSite
      );
    };

    utils.getType = function (id) {
      if (id === 0) {
        return 'Dive center';
      } else if (id === 10) {
        return 'Liveaboard';
      } else if (id === 20) {
        return 'Dive resort';
      }
    };

    utils.acceptedFileFormat = function (fileFormat) {
      return _.find(
        ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
        function (v) {
          return v === fileFormat;
        }
      );
    };

    utils.isDateInRange = function (dateFrom, dateTo) {
      var today = moment(moment(new Date()).format('YYYY-MM-DD')),
        from = moment(dateFrom).format('YYYY-MM-DD'),
        to = moment(dateTo).format('YYYY-MM-DD');

      return today.isBetween(from, to, null, '[]');
    };

    utils.camelToUnderscore = function (key) {
      return key.replace(/([A-Z0-9])/g, '_$1').toLowerCase();
    };

    utils.mergeCollections = function (collection1, collection2) {
      return _.map(collection1, function (data) {
        var list = _.find(collection2, function (d) {
          return d.id === data.id;
        });
        return _.extend(data, list);
      });
    };

    utils.formatDateTime = function (date) {
      if (!date) {
        return null;
      }

      return moment(date).format('HH:mm');
    };

    utils.selectExperience = function (id) {
      var index = _.findIndex(info.shop.EXPERIENCE_REQUIRED_DIVES, function (
        val
      ) {
        return val[0] === id;
      });

      if (index !== -1) {
        return info.shop.EXPERIENCE_REQUIRED_DIVES[index][1];
      }
    };

    utils.selectCertification = function (id) {
      var index = _.findIndex(
        info.shop.ITINERARY_CERTIFICATION_CHOICES,
        function (val) {
          return val[0] === id;
        }
      );

      if (index !== -1) {
        return info.shop.ITINERARY_CERTIFICATION_CHOICES[index][1];
      }
    };

    utils.bindLogistic = function (descriptions) {
      _.each(descriptions, function (v) {
        if (v.section === 10) {
          if (!descriptions.beforeTrip) {
            descriptions.beforeTrip = [];
          }

          descriptions.beforeTrip.push(v);
        } else if (v.section === 20) {
          if (!descriptions.onBoard) {
            descriptions.onBoard = [];
          }

          descriptions.onBoard.push(v);
        } else if (v.section === 30) {
          if (!descriptions.afterTrip) {
            descriptions.afterTrip = [];
          }

          descriptions.afterTrip.push(v);
        }
      });

      return descriptions;
    };

    utils.getTimeOptions = function (type) {
      var index = _.findIndex(info.shop.ITINERARY_TIME_APPROXIMATION, function (
        val
      ) {
        return val[0] === type;
      });

      if (index !== -1) {
        return info.shop.ITINERARY_TIME_APPROXIMATION[index][1];
      }
    };

    utils.getOptions = function (value, type) {
      var index = _.findIndex(info.shop[type], function (val) {
        return val[0] === +value;
      });

      if (index !== -1) {
        return info.shop[type][index][1];
      }
    };

    utils.getOptionsByText = function (text, type) {
      var index = _.findIndex(info.shop[type], function (val) {
        return val[1] === text;
      });

      if (index !== -1) {
        return info.shop[type][index][0];
      }
    };

    utils.isInViewport = function (element) {
      var elementTop = element.offset().top,
        elementBottom = elementTop + element.outerHeight(),
        viewportTop = $(window).scrollTop(),
        viewportBottom = viewportTop + $(window).height();
      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    utils.isMobile = function () {
      return false;
    };

    utils.catchError = function (error) {
      return error;
    };

    utils.isImg = function (file) {
      return _.find(
        ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
        function (v) {
          return v === file;
        }
      );
    };

    utils.tagTransform = function (item) {
      return {
        title: item,
      };
    };

    utils.searchTag = function (s, callback) {
      var Request = $injector.get('Request');

      if (s && s.length >= 2) {
        let tag = [];

        return Request.get('shop/tags/', { search: s }).then(function (res) {
          if (
            !_.find(res.data, function (item) {
              return item.title.toLowerCase() === s.toLowerCase();
            })
          ) {
            tag = [{ title: s, isNew: true }];
          }

          _.each(res.data, function (item) {
            tag.push({
              id: item.id,
              title: item.title,
            });
          });

          if (callback) {
            callback(tag);
          }

          return tag;
        });
      }
    };

    utils.generateGroupTripLink = function (slug) {
      var link;
      if (isDev || isLocalDev) {
        link = 'https://travel.padi.nearlydone.rocks/';
      } else {
        link = 'https://travel.padi.com/';
      }

      return link + 'group-trip/preview/' + slug + '/';
    };

    utils.setCookie = function (name, value, options = {}) {
      var expires = options.expires;

      if (typeof expires === 'number' && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
      }

      if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
      }

      value = encodeURIComponent(value);

      var updatedCookie = `${name}=${value}`;

      for (var propName in options) {
        updatedCookie += `;${propName}`;
        var propValue = options[propName];
        if (propValue !== true) {
          updatedCookie += `=${propValue}`;
        }
      }

      document.cookie = updatedCookie;
    };

    utils.deleteCookie = (name) => {
      document.cookie =
        name +
        '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' +
        '.' +
        window.location.hostname.split('.').splice(1).join('.');
    };

    utils.getCookie = function (name) {
      var matches = document.cookie.match(
        new RegExp(
          '(?:^|; )' +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
            '=([^;]*)'
        )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    };

    utils.setLanguage = function (lang) {
      utils.setCookie('travel_language', lang, {
        path: '/',
        domain: cookieDomain,
        expires: 3600 * 24 * 7 /* 7 days */,
      });
    };

    utils.generateLangUrl = function (lang) {
      if (!$rootScope.routeName) {
        return window.createUrl('homepage', lang);
      }

      var url = window.createUrl($rootScope.routeName, lang);
      if (url.search(':') !== -1) {
        var splittedUrl = url.split('/'),
          isTranslatedUrl = window.location.pathname.match(
            /^\/[a-z]{2,3}(?!\-)\/|zh-han[t|s]\/|other\//
          ),
          splittedCurrentRoute = window.location.pathname.split('/'),
          generatedUrl = '';

        _.each(splittedUrl, function (val, index) {
          if (val) {
            if (val[0] === ':') {
              // GAVNOCODE
              if ($rootScope.allLanguageSlugs && val.search('slug') > 0) {
                generatedUrl += '/' + $rootScope.allLanguageSlugs[lang];
              } else if (
                $rootScope.itineraryLocationTranslations &&
                val.search('location') > 0
              ) {
                generatedUrl +=
                  '/' + $rootScope.itineraryLocationTranslations[lang];
              } else if (isTranslatedUrl && lang === 'en') {
                generatedUrl += '/' + splittedCurrentRoute[index + 1];
              } else if (!isTranslatedUrl && lang !== 'en') {
                generatedUrl += '/' + splittedCurrentRoute[index - 1];
              } else {
                generatedUrl += '/' + splittedCurrentRoute[index];
              }
            } else {
              generatedUrl += '/' + val;
            }
          }
        });

        url = generatedUrl;
      }

      return url;
    };

    utils.isArrayContainElementsFromOtherArray = function (array1, array2) {
      if (array1 && array1.length) {
        var result = false;
        _.each(array2, function (item) {
          if (!result) {
            _.each(array1, function (item2) {
              if (item2.indexOf(item) > -1) {
                result = true;
              }
            });
          }
        });
        return result;
      }
      return false;
    };

    utils.createURL = function (path, params, query) {
      if (!path) {
        return '';
      }

      if (
        path.search('https://') !== -1 ||
        path.search('http://') !== -1 ||
        path[0] === '/'
      ) {
        return path;
      }

      var route = window.createUrl(path, selectedLanguage);
      if (params) {
        _.each(params, function (val, key) {
          route = route.replace(':' + key, val);
        });
      }

      if (query) {
        if (route[route.length - 1] === '/') {
          route = route.slice(0, -1) + query;
        } else {
          route += query;
        }
      }

      return route;
    };

    utils.generateNewExtranetUrl = function (path, query = '') {
      let affiliatePortal = 'https://extranet.padi.com/';
      const language = selectedLanguage !== 'en' ? `${selectedLanguage}/` : '';

      if (isLocalDev) {
        affiliatePortal = 'http://extranet.padi.localhost/';
      } else if (isStage) {
        affiliatePortal = 'http://extranet.padi.comingsoon.rocks/';
      } else if (isStageCN) {
        affiliatePortal = 'http://extranet.travel-qa.padi.com.cn/';
      } else if (isDev) {
        affiliatePortal = 'http://extranet.padi.nearlydone.rocks/';
      } else if (isInProgress) {
        affiliatePortal = 'http://extranet.padi.inprogress.rocks/';
      }
      return `${affiliatePortal}${language}${path}?shopId=${utils.getCurrentShopId()}${query}`;
    };

    utils.generateAffiliateUrl = function (path) {
      var affiliatePortal = 'https://affiliates.padi.com/';

      if (isLocalDev) {
        affiliatePortal = 'http://affiliates.padi.localhost/';
      } else if (isStage) {
        affiliatePortal = 'http://affiliates.padi.comingsoon.rocks/';
      } else if (isStageCN) {
        affiliatePortal = 'http://affiliates.travel-qa.padi.com.cn/';
      } else if (isDev) {
        affiliatePortal = 'http://affiliates.padi.nearlydone.rocks/';
      } else if (isInProgress) {
        affiliatePortal = 'http://affiliates.padi.inprogress.rocks/';
      }
      return `${affiliatePortal}${path}`;
    };

    utils.login = function () {
      const options = {
        domain: '.' + window.location.hostname.split('.').splice(1).join('.'),
        path: '/',
        expires: 3600, // 1 hour
      };
      utils.setCookie('login_next_page', window.location.href, options);
      window.location.href = `${cognitoURL}&redirect_uri=${redirectUri}/cognito_auth`;
    };

    utils.isProDiver = function () {
      return utils.isProDiverShop();
    };

    utils.getContributedBy = function () {
      const isProDiver = utils.isProDiver(),
        shopId = utils.getCurrentShopId();

      if ($rootScope.user.isStaff) {
        return {
          contributedByType: 'account',
          contributedBy: $rootScope.user.id,
        };
      }

      if (shopId && !utils.isProDiverShop()) {
        return {
          contributedByType: 'shop',
          contributedBy: shopId,
        };
      }

      if (isProDiver) {
        return {
          contributedByType: 'account',
          contributedBy: $rootScope.user.id,
        };
      }

      return {};
    };

    utils.enumerateDaysBetweenDates = function (startDate, endDate) {
      startDate = moment(startDate);
      endDate = moment(endDate);

      var now = startDate,
        dates = [];

      while (now.isBefore(endDate) || now.isSame(endDate)) {
        dates.push(now.format('YYYY-MM-DD'));
        now.add(1, 'days');
      }
      return dates;
    };

    return utils;
  });
