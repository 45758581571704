'use strict';

angular
  .module('app')
  .controller('HomeCtrl', function (
    $rootScope,
    error404,
    utils,
    gettextCatalog,
    info,
    homeService,
    diviacLocalStorage,
    localStorageService,
    $uibModal,
    config
  ) {
    var vm = this;

    vm.linksToPages = [];
    vm.changeSelectedItems = changeSelectedItems;
    vm.saveDiveResort = saveDiveResort;
    vm.createDC = createDC;
    vm.updateResortKind = updateResortKind;
    vm.loading = true;
    vm.shopKind = $rootScope.selectedShop && $rootScope.selectedShop.kind;
    vm.placesOptions = utils.placesOptions;
    vm.resortKind =
      $rootScope.selectedShop && $rootScope.selectedShop.resortKind;

    if (error404) {
      $rootScope.error404 = true;
    }

    vm.shopKindList = _(info.shop.SHOP_KIND)
      .map((el) => {
        if (el[0] === 0 || el[0] === 20) {
          return el;
        }
        return false;
      })
      .compact()
      .value();

    if ($rootScope.choosenShop && $rootScope.choosenShop.defaultLanguage) {
      let lang = _.find(config.langList, {
        id: $rootScope.choosenShop.defaultLanguage,
      });
      if (lang) vm.inputLang = lang.title;
    }

    function changeSelectedItems() {
      if (vm.shopKind === 0) {
        vm.aloneDC = true;
        vm.oneOwner = false;
      } else {
        vm.aloneDC = false;
        vm.oneOwner = true;
      }

      saveDiveResort();
    }

    function updateResortKind() {
      if (utils.isProDiver()) {
        vm.loading = false;
        return;
      }
      if (vm.resortKind === 20) {
        createDC();
        return;
      }

      vm.loading = true;
      homeService
        .linkShop({
          linkedDiveCenter: +vm.resortKind === 10 ? '' : null,
          resortKind: vm.resortKind,
        })
        .then(function (data) {
          if (data.resortKind === 20) {
            $rootScope.showNotify(
              gettextCatalog.getString(
                'Don`t forget to link this Dive Resort to a Dive Center or many errors will take place!'
              ),
              10000
            );
          }
          setLinkedShop(data);
        })
        .catch(() => {
          vm.loading = false;
        });
    }

    function setLinkedShop(data) {
      vm.loading = false;

      var linkedDiveShop;

      if (+data.resortKind === 10 || !data.linkedDiveCenter) {
        linkedDiveShop = null;
      } else {
        linkedDiveShop = {
          resortKind: data.resortKind,
          linkedDiveShop: data.linkedDiveCenter,
          title: data.shopTitle,
          slug: data.shopSlug,
          kind: 0,
          id: data.id,
        };
      }

      var shop = _.clone($rootScope.selectedShop);
      shop.linkedDiveShop = linkedDiveShop;
      shop.resortKind = data.resortKind;
      diviacLocalStorage.set('choosenShop', shop);
      localStorageService.set('choosenShop', shop);

      $rootScope.selectedShop = shop;
    }

    function saveDiveResort() {
      var data = {
        isRealDiveResort: !vm.aloneDC,
        kind: vm.shopKind,
      };

      vm.loading = true;

      homeService
        .setShopKind(data)
        .catch(() => {
          vm.shopKind = $rootScope.choosenShop.kind;
        })
        .finally(() => {
          vm.loading = false;
        });
    }

    function createDC() {
      var modalInstance = $uibModal.open({
        animation: false,
        templateUrl: '../templates/_add-dc.html',
        backdrop: 'static',
        keyboard: false,
        controller: function ($uibModalInstance, landing) {
          var _this = this;

          _this.autoSuggest = homeService.dcAutosuggest;
          _this.save = save;
          _this.close = function () {
            $uibModalInstance.dismiss('close');
          };

          function save() {
            _this.loading = true;
            if (_.isObject(_this.linkedDiveCenter)) {
              homeService
                .linkShop({
                  linkedDiveCenter: _this.linkedDiveCenter.id,
                  resortKind: 20,
                })
                .then(function () {
                  homeService.getLinkedShop().then(function (d) {
                    d.id = _this.linkedDiveCenter.id;
                    setLinkedShop(d);
                    $uibModalInstance.close(d);
                  });
                });
            } else {
              landing
                .createNewShop({
                  title: _this.linkedDiveCenter,
                  kind: 0,
                })
                .then(function (data) {
                  homeService
                    .linkShop({
                      linkedDiveCenter: data.id,
                      resortKind: 20,
                    })
                    .then(function () {
                      homeService.getLinkedShop().then(function (d) {
                        d.id = data.id;
                        setLinkedShop(d);
                        $uibModalInstance.close(d);
                      });
                    });
                });
            }
          }
        },
        controllerAs: 'ctrl',
        windowClass: 'contact-us',
      });

      return modalInstance.result.then(function (res) {
        return res;
      });
    }

    $rootScope.$watch('checkedList', function (newVal) {
      if (newVal) {
        vm.linksToPages = [];
        vm.notFinished = [];
        const kind = +$rootScope.selectedShop.kind;
        _.each(newVal, function (val, key) {
          if (val === false) {
            vm.notFinished.push(key);
            if (key === 'diveResort') {
              key = 'resort';
            }
            if (key === 'resortPhotos') {
              key = 'resort_photos';
            }
            if (key === 'rooms' && kind === 10) {
              key = 'cabins';
            }
            if (key === 'basics' && kind === 10) {
              key = 'liveaboard_basic';
            }
            if (key === 'pricing' && kind === 10) {
              key = 'itinerary_price';
            }
            if (key === 'itineraries' && kind === 10) {
              key = 'itinerary';
            }
            if (key === 'liveaboardPhotos' && kind === 10) {
              key = 'photos';
            }
            vm.linksToPages.push({
              url: utils.createURL(key),
              text: utils.getTextForPages(key),
            });
          }
        });

        vm.loading = false;
      }
    });
  });
