'use strict';

angular.module('app').directive('availabilityCalendar', function () {
  return {
    restrict: 'A',
    templateUrl: '../templates/_availability-calendar.html',
    scope: {
      dateRange: '=',
      rooms: '=',
      monthes: '=',
      getList: '&',
      allowDrag: '=',
    },
    controller: function (availabilityService, $uibModal) {
      var vm = this;
      vm.changeAvailability = changeAvailability;
      vm.getDateData = getDateData;
      vm.editRoom = editRoom;
      vm.openRoomTypePopup = openRoomTypePopup;

      function getDateData(date, changeDate, allDates) {
        var index = moment(date)[changeDate](1, 'days').format('YYYY-MM-DD'),
          dateObj = allDates[index];

        return dateObj ? dateObj.isBookable : null;
      }

      function changeAvailability(date, roomId, key, roomChange) {
        var prevState = date.isBookable,
          request = 'patch';

        if (!date.id) {
          request = 'post';
          date.dateFrom = moment(key).format('YYYY-MM-DDThh:mm');
        }

        if (!roomChange) {
          if (date.loading) {
            return 1;
          }

          date.loading = true;

          if (!date.id) {
            date.isBookable = true;
          } else {
            date.isBookable = !date.isBookable;
          }
        } else if (!date.itemsLeft) {
          date.itemsLeft = 0;
        }

        return availabilityService
          .changeAvailability(date, roomId, request)
          .then(function (res) {
            date.loading = false;
            date.id = res.id;
            date.itemsLeft = res.itemsLeft;
            date.isBookable = res.isBookable;
          })
          .catch(function (err) {
            if (err.data) {
              if (err.data.dateFrom) {
                date.loading = false;
                date.isBookable = prevState;
              }
            }
          });
      }

      function openRoomTypePopup(room) {
        var modal = $uibModal.open({
          animation: true,
          templateUrl: '../templates/_room-type.html',
          openedClass: 'itinerary-modal modal-open',
          resolve: {
            currentRoom: room,
          },
          controller: function (currentRoom, $uibModalInstance) {
            var _this = this;
            _this.room = {...currentRoom};

            _this.close = function () {
              $uibModalInstance.dismiss('close');
            };

            _this.save = function () {
              _this.loading = true;

              availabilityService
                .updateNumberOfRooms(currentRoom.id, _this.room.numberOfRooms)
                .then(function () {
                  _this.loading = false;

                  $uibModalInstance.close('saved');
                });
            };
          },
          controllerAs: '$modal',
        });

        modal.result.then(function () {
          vm.getList();
        });
      }

      function editRoom(room) {
        var modal = $uibModal.open({
          animation: true,
          templateUrl: '../templates/_edit-room.html',
          openedClass: 'itinerary-modal modal-open',
          resolve: {
            currentRoom: room,
          },
          controller: function (currentRoom, $uibModalInstance) {
            var _this = this;
            _this.filter = {};
            _this.items = [];
            _this.today = new Date();
            _this.room = currentRoom;

            _this.close = function () {
              $uibModalInstance.dismiss('close');
            };

            _this.save = function () {
              _this.loading = true;

              availabilityService
                .updateRoom(currentRoom.id, _this.items, _this.filter)
                .then(function () {
                  _this.loading = false;

                  $uibModalInstance.close('saved');
                });
            };
          },
          controllerAs: '$modal',
        });

        modal.result.then(function () {
          vm.getList();
        });
      }
    },
    controllerAs: 'vm',
    bindToController: true,
  };
});
