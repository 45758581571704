'use strict';

angular
  .module('app')
  .service('homeService', function (
    Request,
    utils,
    diviacLocalStorage,
    localStorageService,
    $rootScope,
    gettextCatalog
  ) {
    return {
      setShopKind: function (data) {
        const changingMessage = gettextCatalog.getString(
          'Your listing has successfully been changed to the category Dive center (i.e. only offer diving products, no rooms)'
        );
        return Request.put(
          'shop/' + utils.getCurrentShopSlug() + '/change-kind/',
          data
        ).then(function (res) {
          var shop = diviacLocalStorage.get('choosenShop');

          shop.kind = res.data.kind;
          shop.isRealDiveResort = true;

          diviacLocalStorage.set('choosenShop', shop);
          localStorageService.set('choosenShop', shop);

          _.forEach($rootScope.user.managedShops, function (val) {
            if (val.shop.id === shop.id) {
              val.shop = shop;
            }
          });

          diviacLocalStorage.set('user', $rootScope.user);
          $rootScope.selectedShop = shop;

          if (shop.kind === 0) {
            $rootScope.showNotify(changingMessage, 10000);
          }

          return res.data;
        });
      },
      dcAutosuggest: function (value) {
        return Request.get('shop/autosuggest/dive-center/', {
          search: value,
        }).then(function (res) {
          return res.data;
        });
      },
      linkShop: function (data) {
        return Request.put(
          'shop/' + utils.getCurrentShopSlug() + '/resort-kind/',
          data
        ).then(function (res) {
          return res.data;
        });
      },
      getLinkedShop: function () {
        return Request.get(
          'shop/' + utils.getCurrentShopSlug() + '/resort-kind/'
        ).then(function (res) {
          return res.data;
        });
      },
    };
  });
