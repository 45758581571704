'use strict';

angular.module('app').directive('previewBooking', function ($rootScope, utils, $uibModal) {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
         var modal = '<div class="close-popup-btn" ng-click="close()"></div>';

         if (utils.isTest()) {
            modal += '<iframe src="https://travel.padi.nearlydone.rocks//widget/'+ ($rootScope.selectedShop.kind == 10 ? "la/" : "") + $rootScope.selectedShop.slug +'" height="100%" width="100%"></iframe>';
         } else {
            modal += '<iframe src="https://travel.padi.com/widget/'+ ($rootScope.selectedShop.kind == 10 ? "la/" : "") + $rootScope.selectedShop.slug +'" height="100%" width="100%"></iframe>';
         }

         $(element).on('click', function() {
             $uibModal.open({
                animation: false,
                template: modal,
                openedClass: 'preview-modal',
                controller: function($scope, $uibModalInstance) {
                    $scope.close = function() {
                        $uibModalInstance.close('close');
                    };
                }
              });
         });
      }
    };
});
