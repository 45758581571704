'use strict';

angular.module('app').directive('circleProgress', function($window, $timeout) {
    return {
        restrict: '',
        scope: {
            total: '=',
            currentNumber: '=',
            size: '=',
            rotate: '=',
            prefix: '@'
        },
        link: function(scope, element, attrs, ngModel) {
            scope.$watch('currentNumber', function(newVal) {
                if (_.isNumber(newVal)) {
                    var el = angular.element(element),
                        options = {
                            percent: scope.currentNumber / scope.total ,
                            size:  scope.size || 220,
                            lineWidth:  50,
                            rotate: scope.rotate || 0,
                            prefix: scope.prefix || ''
                        };

                    var canvas = document.createElement('canvas'),
                        span = document.createElement('span');
                        span.textContent = Math.round(scope.currentNumber * 100)/100 + '' + options.prefix;

                    if (typeof(G_vmlCanvasManager) !== 'undefined') {
                        G_vmlCanvasManager.initElement(canvas);
                    }

                    var ctx = canvas.getContext('2d');
                    canvas.width = canvas.height = options.size;

                    el.append(span);
                    el.append(canvas);

                    ctx.translate(options.size / 2, options.size / 2); // change center
                    ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI); // rotate -90 deg

                    //imd = ctx.getImageData(0, 0, 240, 240);
                    var radius = (options.size - options.lineWidth) / 2;

                    drawCircle('#efefef', options.lineWidth, radius);
                    drawPieceCircle('#1e80bf', options.lineWidth, 360 * options.percent);
                    drawCircle('#fff', 0, radius - 15);



                    function toRadians (deg) {
                        return deg * Math.PI / 180;
                    }

                    function drawPieceCircle(color, lineWidth, percent) {
                            ctx.moveTo(0,0);
                            ctx.arc(0,0,radius + (lineWidth / 2),0,toRadians(percent), false);
                            ctx.fillStyle = color;
                            ctx.lineTo(0,0);
                            ctx.closePath();
                            ctx.fill();
                    }

                    function drawCircle(color, lineWidth, radius) {
                            //percent = Math.min(Math.max(0, percent || 1), 1);
                            ctx.beginPath();
                            ctx.arc(0, 0, radius, 0, 360 , false);
                            ctx.strokeStyle = color;
                            ctx.fillStyle = '#fff';
                            ctx.lineCap = 'round'; // butt, round or square
                            ctx.lineWidth = lineWidth;

                            ctx.fill();
                            ctx.stroke();
                            ctx.closePath();
                    }
                }
            });

        }
    };
});