'use strict';

angular
  .module('app')
  .controller('BookingList', function (
    bookings,
    $timeout,
    server,
    utils,
  ) {
    var vm = this;
    vm.displayBookingStatus = displayBookingStatus;
    vm.nextPageDates = nextPageDates;
    vm.nextPageTrips = nextPageTrips;
    vm.setView = setView;
    vm.getCSVLink = getCSVLink;
    vm.viewMode = 'dates';
    vm.getFromInfo = utils.getFromInfo;
    vm.formats = [
      {
        format: 'csv',
      },
      {
        format: 'xlsx',
      }
    ];
    vm.filters = {
      search: '',
      dateStart: '',
      dateEnd: '',
      tripFilter: null,
      ordering: '',
    };
    vm.datesChanged = datesChanged;
    vm.handleSearch = handleSearch;
    vm.clearFilter = clearFilter;
    vm.setOrdering = setOrdering;

    activate();

    function activate() {
      setView('dates');
    }

    function displayBookingStatus(status) {
      switch (status) {
      case 10:
        return 'icon-question orange';
      case 20:
        return 'ok-icon-new orange';
      case 30:
        return 'ok-icon-new green';
      case 40:
      case 50:
        return 'fail-icon red';
      case 60:
        return 'ok-icon-new gray';
      case 70:
        return 'fail-icon-new red';
      case 80:
        return 'icon-question green';
      default:
        return '';
      }
    }

    function nextPageDates(page) {
      vm.loading = true;
      bookings.getBookingList(page, vm.filters).then(function (response) {
        $timeout(function () {
          vm.bookings = response.bookings;
          vm.totalBookings = response.allBookings;

          vm.loading = false;
        });
      });
    }

    function nextPageTrips(page) {
      vm.loading = true;
      // for trip filter
      bookings.getTripListForFilter().then(function (res) {
        _.each(res, function (itinerary) {
          itinerary.formattedTitle =
            moment(itinerary.startDate).format('MMM Do') +
            ' - ' +
            moment(itinerary.endDate).format('MMM Do, YYYY');
        });
        vm.tripsListForFilter = res;
      });

      bookings
        .getTripList(page, _.get(vm.filters.tripFilter, 'id'))
        .then(function (response) {
          vm.trips = response.trips;
          vm.totalTrips = response.allTrips;

          if (vm.trips.length) {
            _.each(vm.trips, function (trip, index) {
              bookings
                .getTripBookings(trip.id)
                .then(function (tripBookings) {
                  trip.bookings = tripBookings;
                  if (index === vm.trips.length - 1) {
                    vm.loading = false;
                  }
                })
                .catch(function (err) {
                  console.log(err);
                  vm.loading = false;
                });
            });
          } else {
            vm.loading = false;
          }
        });
    }

    function setView(mode) {
      vm.viewMode = mode;
      if (vm.viewMode === 'dates') {
        nextPageDates(false);
      } else if (vm.viewMode === 'trips') {
        nextPageTrips(false);
      }
    }

    function getCSVLink(uuid) {
      return server + 'checkout/bookings/' + uuid + '/guests/?format=';
    }

    function datesChanged() {
      if (vm.filters.dateStart && vm.filters.dateEnd) {
        setView(vm.viewMode);
      }
    }

    function handleSearch(event) {
      if (event.which === 13) {
        setView(vm.viewMode);
      }
    }

    function clearFilter(type) {
      switch (type) {
      case 'search':
        vm.filters.search = '';
        break;
      case 'dates':
        vm.filters.dateStart = '';
        vm.filters.dateEnd = '';
        break;
      case 'trip':
        vm.filters.tripFilter = null;
        break;
      default:
        return;
      }

      setView(vm.viewMode);
    }

    function setOrdering(column) {
      if (vm.filters.ordering === column) {
        vm.filters.ordering = '-' + column;
      } else {
        vm.filters.ordering = column;
      }
      setView('dates');
      console.log(vm.filters);
    }
  });
