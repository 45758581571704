'use strict';

angular.module('app').config([
  '$routeProvider',
  function ($routeProvider) {
    var __timestamp__ = '?.1730887631795',
      routes = [
        {
          route: window.createUrl('homepage'),
          routeName: 'homepage',
          params: {
            controller: 'RegistrationCtrl as reg',
            // templateUrl: 'templates/_registration.html' + __timestamp__,
            data: {
              authorization: false,
            },
          },
        },
        {
          route: window.createUrl('homepage_home'),
          routeName: 'homepage_home',
          params: {
            controller: 'HomeCtrl as home',
            templateUrl: 'templates/_welcome.html' + __timestamp__,
            data: {
              authorization: true,
              staffOnly: [60],
            },
            resolve: {
              error404: function () {
                return false;
              },
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Dashboard - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('basics'),
          routeName: 'basics',
          params: {
            controller: 'BasicCtrl as basic',
            templateUrl: 'templates/_basic.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [0, 20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Basics - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('liveaboard_basic'),
          routeName: 'liveaboard_basic',
          params: {
            controller: 'BasicLaCtrl as basic',
            templateUrl: 'templates/_la-basic.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Basics - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('resort'),
          routeName: 'resort',
          params: {
            controller: 'ResortCtrl as resort',
            templateUrl: 'templates/_resort.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [20],
              staffOnly: [20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Resort - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('dive_center'),
          routeName: 'dive_center',
          params: {
            controller: 'DiveCenterCtrl as dc',
            templateUrl: 'templates/_dive-center.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Dive Center - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('dive_sites'),
          routeName: 'dive_sites',
          params: {
            controller: 'DiveSitesCtrl as ds',
            templateUrl: 'templates/_dive-sites.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Diving - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('dive_sites_list'),
          routeName: 'dive_sites_list',
          params: {
            controller: 'DiveSitesManagementCtrl as ds',
            templateUrl: 'templates/_dive_sites_list.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Dive Center - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
            reloadOnSearch: false,
          },
        },
        {
          route: window.createUrl('photos'),
          routeName: 'photos',
          params: {
            controller: 'PhotosCtrl as photo',
            templateUrl: 'templates/_photo.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Photos - PADI Travel For Business'
                    )
                  );
                },
              ],
              type: function () {
                return 'DC';
              },
            },
          },
        },
        {
          route: window.createUrl('resort_photos'),
          routeName: 'resort_photos',
          params: {
            controller: 'PhotosCtrl as photo',
            templateUrl: 'templates/_resort-photos.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Photos - PADI Travel For Business'
                    )
                  );
                },
              ],
              type: function () {
                return 'DR';
              },
            },
          },
        },
        {
          route: window.createUrl('products'),
          routeName: 'products',
          params: {
            controller: 'ProductsCtrl as product',
            templateUrl: 'templates/_products.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [20, 0, 110],
              resortKind: [10],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Products - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('product_add'),
          routeName: 'product_add',
          params: {
            controller: 'ProductAddEdit as product',
            templateUrl: 'templates/_new-product.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Products - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('product_copy'),
          routeName: 'product_copy',
          params: {
            controller: 'ProductAddEdit as product',
            templateUrl: 'templates/_new-product.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Products - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('product_basics'),
          routeName: 'product_basics',
          params: {
            controller: 'ProductAddEdit as product',
            templateUrl: 'templates/_new-product.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('product_description'),
          routeName: 'product_description',
          params: {
            controller: 'ProductDescription as product',
            templateUrl: 'templates/_product-description.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('product_pricing'),
          routeName: 'product_pricing',
          params: {
            controller: 'ProductPricing as product',
            templateUrl: 'templates/_product-pricing.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('product_inclusions'),
          routeName: 'product_inclusions',
          params: {
            controller: 'ProductInclusions as product',
            templateUrl: 'templates/_product-inclusions.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('product_photos'),
          routeName: 'product_photos',
          params: {
            controller: 'AcitivyPhotos as product',
            templateUrl: 'templates/_activity-photos.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [0, 20, 110],
            },
          },
        },
        {
          route: window.createUrl('product_logistics'),
          routeName: 'product_logistics',
          params: {
            controller: 'LogistcsCtrl as product',
            templateUrl: 'templates/_product-logistcs.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [0, 20, 110],
            },
          },
        },
        {
          route: window.createUrl('product_complete'),
          routeName: 'product_complete',
          params: {
            controller: 'ActivityCompleteCtrl as product',
            templateUrl: 'templates/_activity-complete.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [0, 20, 110],
            },
          },
        },
        {
          route: window.createUrl('rooms'),
          routeName: 'rooms',
          params: {
            controller: 'RoomsCtrl as room',
            templateUrl: 'templates/_rooms.html',
            data: {
              authorization: true,
              shopKind: [20],
              staffOnly: [20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString('Rooms - PADI Travel For Business')
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('rooms_add'),
          routeName: 'rooms_add',
          params: {
            controller: 'RoomAddEdit as room',
            templateUrl: 'templates/_new-room.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString('Rooms - PADI Travel For Business')
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('rooms_edit'),
          routeName: 'rooms_edit',
          params: {
            controller: 'RoomAddEdit as room',
            templateUrl: 'templates/_new-room.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [20],
            },
          },
        },
        {
          route: window.createUrl('rooms_copy'),
          routeName: 'rooms_copy',
          params: {
            controller: 'RoomAddEdit as room',
            templateUrl: 'templates/_new-room.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [20],
            },
          },
        },
        {
          route: window.createUrl('sleep_and_dive_add'),
          routeName: 'sleep_and_dive_add',
          params: {
            controller: 'SleepDiveCtrl as ctrl',
            templateUrl: 'templates/_sleep_and_dive.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [20],
            },
          },
        },
        {
          route: window.createUrl('sleep_and_dive_edit'),
          routeName: 'sleep_and_dive_edit',
          params: {
            controller: 'SleepDiveCtrl as ctrl',
            templateUrl: 'templates/_sleep_and_dive.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [20],
            },
          },
        },
        {
          route: window.createUrl('admin'),
          routeName: 'admin',
          params: {
            controller: 'AdminCtrl as admin',
            templateUrl: 'templates/_admin-section.html' + __timestamp__,
            data: {
              authorization: true,
              // staffOnly: [10, 20],
              // allowAffManager: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString('Admin - PADI Travel For Business')
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('agreement'),
          routeName: 'agreement',
          params: {
            controller: 'AgreementCtrl as agr',
            templateUrl: 'templates/_agreement.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [0, 20],
              resortKind: [10],
              allowAffManager: true,
            },
            resolve: {
              isVacation: () => false,
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Agreement - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('vacations_agreement'),
          routeName: 'vacations_agreement',
          params: {
            controller: 'AgreementCtrl as agr',
            templateUrl: 'templates/_agreement-vacations.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [0, 10, 20],
              allowAffManager: true,
            },
            resolve: {
              isVacation: () => true,
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Agreement - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        // {
        //   route: window.createUrl('login'),
        //   routeName: 'login',
        //   params: {
        //     controller: 'RegistrationCtrl as reg',
        //     templateUrl: 'templates/_registration.html' + __timestamp__,
        //     data: {
        //       authorization: false,
        //     },
        //   },
        // },
        {
          route: window.createUrl('account_password_reset'),
          routeName: 'account_password_reset',
          params: {
            templateUrl: 'templates/_reset-password.html' + __timestamp__,
            controller: 'RegistrationCtrl as reg',
            data: {
              authorization: false,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Recover your password - PADI Travel for Business'
                    ),
                    gettextCatalog.getString(
                      'Recover your password to access the business section of PADI Travel.'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('account_activation'),
          routeName: 'account_activation',
          params: {
            resolve: {
              activate: [
                'AuthExtranet',
                '$route',
                '$location',
                '$rootScope',
                function (AuthExtranet, $route, $location, $rootScope) {
                  AuthExtranet.activateAccount($route.current.params.key).then(
                    function () {
                      $location.path('/');

                      $rootScope.showNotify(
                        'Your account was activated successfully'
                      );
                    },
                    function () {
                      $location.path('/');
                    }
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('reviews'),
          routeName: 'reviews',
          params: {
            templateUrl: 'templates/_reviews.html' + __timestamp__,
            controller: 'ReviewsCtrl as ctrl',
            data: {
              authorization: true,
              staffOnly: [0, 10, 20],
            },
          },
        },
        {
          route: window.createUrl('cabins'),
          routeName: 'cabins',
          params: {
            templateUrl: 'templates/_cabins-list.html' + __timestamp__,
            controller: 'CabinsListCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('room_add'),
          routeName: 'room_add',
          params: {
            controller: 'RoomAddEdit as room',
            templateUrl: 'templates/_new-room.html' + __timestamp__,
            data: {
              authorization: true,
              shopKind: [20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString('Rooms - PADI Travel For Business')
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('cabins_add'),
          routeName: 'cabins_add',
          params: {
            templateUrl: 'templates/_cabins.html' + __timestamp__,
            controller: 'CabinsCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('cabins_edit'),
          routeName: 'cabins_edit',
          params: {
            templateUrl: 'templates/_cabins.html' + __timestamp__,
            controller: 'CabinsCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('itinerary'),
          routeName: 'itinerary',
          params: {
            templateUrl: 'templates/_itinerary-new.html' + __timestamp__,
            controller: 'ItineraryListCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [10],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Itineraries - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('itinerary_edit'),
          routeName: 'itinerary_edit',
          params: {
            templateUrl: 'templates/_itinerary.html' + __timestamp__,
            controller: 'ItineraryCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('itinerary_add'),
          routeName: 'itinerary_add',
          params: {
            templateUrl: 'templates/_itinerary.html' + __timestamp__,
            controller: 'ItineraryCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('itinerary_price'),
          routeName: 'itinerary_price',
          params: {
            templateUrl: 'templates/_itinerary-price.html' + __timestamp__,
            controller: 'ItineraryPriceCtrl as ctrl',
            reloadOnSearch: false,
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('availability'),
          routeName: 'availability',
          params: {
            templateUrl: 'templates/_availability.html' + __timestamp__,
            controller: 'AvailabilityCtrl as ctrl',
            reloadOnSearch: false,
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('schedule'),
          routeName: 'schedule',
          params: {
            templateUrl: 'templates/_schedule.html' + __timestamp__,
            controller: 'ScheduleCtrl as ctrl',
            reloadOnSearch: false,
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('promotions'),
          routeName: 'promotions',
          params: {
            templateUrl: 'templates/_promotions.html' + __timestamp__,
            controller: 'PromotionsListCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('inclusions'),
          routeName: 'inclusions',
          params: {
            templateUrl: 'templates/_inclusions.html' + __timestamp__,
            controller: 'InclusionsCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('resort_inclusions'),
          routeName: 'resort_inclusions',
          params: {
            templateUrl: 'templates/_inclusions-dr.html' + __timestamp__,
            controller: 'InclusionsDrCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [20],
              staffOnly: [20],
            },
          },
        },
        {
          route: window.createUrl('dc_inclusions'),
          routeName: 'dc_inclusions',
          params: {
            templateUrl: 'templates/_inclusions-dc.html' + __timestamp__,
            controller: 'InclusionsDcCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [0, 20],
              staffOnly: [0, 20],
            },
          },
        },
        {
          route: window.createUrl('promotions_edit'),
          routeName: 'promotions_edit',
          params: {
            templateUrl: 'templates/_new-promotions.html' + __timestamp__,
            controller: 'PromotionsCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('promotions_add'),
          routeName: 'promotions_add',
          params: {
            templateUrl: 'templates/_new-promotions.html' + __timestamp__,
            controller: 'PromotionsCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [10],
            },
          },
        },
        {
          route: window.createUrl('dive_guides'),
          routeName: 'dive_guides',
          params: {
            templateUrl: 'templates/_dive-guides.html' + __timestamp__,
            controller: 'DiveGuidesCtrl as ctrl',
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('dive_guide_terms'),
          routeName: 'dive_guide_terms',
          params: {
            templateUrl: 'templates/_dive-guides_terms.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('manage_rooms'),
          routeName: 'manage_rooms',
          params: {
            templateUrl: 'templates/_manage-rooms.html' + __timestamp__,
            controller: 'ManageRoomsCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [20],
              staffOnly: [20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Open/Close Rooms - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('availability_and_pricing'),
          routeName: 'availability_and_pricing',
          params: {
            templateUrl:
              'templates/_availability-and-pricing.html' + __timestamp__,
            controller: 'AvailabilityPricingCtrl as ctrl',
            reloadOnSearch: false,
            data: {
              authorization: true,
              shopKind: [0, 20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Availability and Pricing - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('rates'),
          routeName: 'rates',
          params: {
            templateUrl: 'templates/_rate-categories.html' + __timestamp__,
            controller: 'RateCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [20],
              staffOnly: [20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Rate Categories - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('rates_add'),
          routeName: 'rates_add',
          params: {
            templateUrl: 'templates/_rate-add-update.html' + __timestamp__,
            controller: 'RateAddEditCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'New Rate - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('rates_edit'),
          routeName: 'rates_edit',
          params: {
            templateUrl: 'templates/_rate-add-update.html' + __timestamp__,
            controller: 'RateAddEditCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Edit Rate - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('rates_copy'),
          routeName: 'rates_copy',
          params: {
            templateUrl: 'templates/_rate-add-update.html' + __timestamp__,
            controller: 'RateAddEditCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Add Rate - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('groups'),
          routeName: 'groups',
          params: {
            templateUrl: 'templates/_groups.html' + __timestamp__,
            controller: 'GroupCtrl as ctrl',
            data: {
              authorization: true,
              staffOnly: [10, 20, 0],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Groups - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('group_trips'),
          routeName: 'group_trips',
          params: {
            templateUrl: 'templates/_group-trips-list.html' + __timestamp__,
            controller: 'GroupTripsListCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [0, 20, 30, 40, 50, 60, 70, 80],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Group Trips - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('group_trips_add'),
          routeName: 'group_trips_add',
          params: {
            controller: 'TripAddEditCtrl as trip',
            templateUrl: 'templates/_trip-add-edit.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Group Trips - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('group_trips_listing_type'),
          routeName: 'group_trips_listing_type',
          params: {
            controller: 'TripListingCtrl as trip',
            templateUrl: 'templates/_trip-listing-type.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('group_trips_listing_type_id'),
          routeName: 'group_trips_listing_type_id',
          params: {
            controller: 'TripListingCtrl as trip',
            templateUrl: 'templates/_trip-listing-type.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('group_trips_basics'),
          routeName: 'group_trips_basics',
          params: {
            controller: 'TripAddEditCtrl as trip',
            templateUrl: 'templates/_trip-add-edit.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('group_trips_description'),
          routeName: 'group_trips_description',
          params: {
            controller: 'TripDescriptionCtrl as trip',
            templateUrl: 'templates/_trip-description.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('group_trips_photos'),
          routeName: 'group_trips_photos',
          params: {
            controller: 'TripPhotosCtrl as trip',
            templateUrl: 'templates/_trip-photos.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('group_trips_pricing'),
          routeName: 'group_trips_pricing',
          params: {
            controller: 'TripPricingCtrl as trip',
            templateUrl: 'templates/_trip-pricing.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('group_trips_pricing_add'),
          routeName: 'group_trips_pricing_add',
          params: {
            controller: 'TripPackageCtrl as package',
            templateUrl: 'templates/_trip-pricing-package.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('group_trips_pricing_edit'),
          routeName: 'group_trips_pricing_edit',
          params: {
            controller: 'TripPackageCtrl as package',
            templateUrl: 'templates/_trip-pricing-package.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('group_trips_inclusions'),
          routeName: 'group_trips_inclusions',
          params: {
            controller: 'TripInclusionsCtrl as trip',
            templateUrl: 'templates/_trip-inclusions.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Group Trips - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('group_trips_complete'),
          routeName: 'group_trips_complete',
          params: {
            controller: 'TripCompleteCtrl as trip',
            templateUrl: 'templates/_trip-complete.html' + __timestamp__,
            data: {
              authorization: true,
            },
          },
        },
        {
          route: window.createUrl('group_trips_custom_operator'),
          routeName: 'group_trips_listing_type',
          params: {
            controller: 'CustomOperator as trip',
            templateUrl: 'templates/_add-custom-operator.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Group Trips - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('group_trips_custom_operator_id'),
          routeName: 'group_trips_listing_type',
          params: {
            controller: 'CustomOperator as trip',
            templateUrl: 'templates/_add-custom-operator.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Group Trips - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('packages'),
          routeName: 'packages',
          params: {
            templateUrl:
              'templates/_new-dive-package-list.html' + __timestamp__,
            controller: 'PackageListCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [0, 20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Sleep & Dive package - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('package_edit'),
          routeName: 'package_edit',
          params: {
            templateUrl: 'templates/_new-dive-package.html' + __timestamp__,
            controller: 'PackageCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [0, 20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Sleep & Dive package - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('package_edit_inclusions'),
          routeName: 'package_edit_inclusions',
          params: {
            templateUrl:
              'templates/_new-dive-package-step2.html' + __timestamp__,
            controller: 'PackageInclusionsCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [0, 20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Sleep & Dive package - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('package'),
          routeName: 'package',
          params: {
            templateUrl: 'templates/_new-dive-package.html' + __timestamp__,
            controller: 'PackageCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [0, 20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Sleep & Dive package - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('staff_only'),
          routeName: 'staff_only',
          params: {
            templateUrl: 'templates/_staff.html' + __timestamp__,
            controller: 'StaffCtrl as ctrl',
            data: {
              authorization: true,
              shopKind: [0, 10, 20],
              staffOnly: [0, 10, 20],
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Staff settings - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('bookings'),
          routeName: 'bookings',
          params: {
            templateUrl: 'templates/_booking-list.html' + __timestamp__,
            controller: 'BookingList as ctrl',
            reloadOnSearch: false,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Bookings - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('bookings_uuid'),
          routeName: 'bookings_uuid',
          params: {
            templateUrl: 'templates/_booking-details.html' + __timestamp__,
            controller: 'BookingDetails as ctrl',
            reloadOnSearch: false,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Booking details - PADI Travel For Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('payment_details'),
          routeName: 'payment_details',
          params: {
            templateUrl: 'templates/_pdt.html' + __timestamp__,
            controller: 'PaymentDetails as ctrl',
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Payment details - PADI Travel for Business'
                    )
                  );
                },
              ],
              hyperwalletInstance: () => 20,
            },
          },
        },
        {
          route: window.createUrl('payment_details_adventures'),
          routeName: 'payment_details_adventures',
          params: {
            templateUrl: 'templates/_pdt.html' + __timestamp__,
            controller: 'PaymentDetails as ctrl',
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Payment details - PADI Travel for Business'
                    )
                  );
                },
              ],
              hyperwalletInstance: () => 10,
            },
          },
        },
        {
          route: window.createUrl('forms'),
          routeName: 'forms',
          params: {
            templateUrl: 'templates/_forms.html' + __timestamp__,
            controller: 'FormsCtrl as ctrl',
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Required Forms - PADI Travel for Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('gis_management'),
          routeName: 'gis_management',
          params: {
            templateUrl: 'templates/_gis-management.html' + __timestamp__,
            controller: 'GisManagement as ctrl',
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Required Forms - PADI Travel for Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('trip_documents'),
          routeName: 'trip_documents',
          params: {
            templateUrl: 'templates/_trip-documents.html' + __timestamp__,
            controller: 'TripDocuments as ctrl',
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Required Forms - PADI Travel for Business'
                    )
                  );
                },
              ],
            },
          },
        },
        {
          route: window.createUrl('contacts'),
          routeName: 'contacts',
          params: {
            templateUrl: 'templates/_contacts.html' + __timestamp__,
            data: {
              authorization: true,
            },
            resolve: {
              seo: [
                'SeoService',
                'gettextCatalog',
                function (SeoService, gettextCatalog) {
                  SeoService.generateMetaTags(
                    gettextCatalog.getString(
                      'Contacts - PADI Travel for Business'
                    )
                  );
                },
              ],
            },
          },
        },
      ];

    _.each(routes, function (v) {
      if (!v.params.resolve) {
        v.params.resolve = {};
      }
      v.params.resolve.routeName = [
        '$rootScope',
        function ($rootScope) {
          $rootScope.routeName = v.routeName;
          return v.routeName;
        },
      ];
      v.params.resolve.lang = function () {
        return window.lang;
      };

      $routeProvider.when(v.route, v.params);
    });

    $routeProvider
      // .when('/', {
      //   controller: 'RegistrationCtrl as reg',
      //   templateUrl: 'templates/_registration.html' + __timestamp__,
      //   data: {
      //     authorization: false,
      //   },
      // })
      // .when('/home/', {
      //   controller: 'HomeCtrl as home',
      //   templateUrl: 'templates/_welcome.html' + __timestamp__,
      //   data: {
      //     authorization: true,
      //     staffOnly: [60],
      //   },
      //   resolve: {
      //     error404: function () {
      //       return false;
      //     },
      //     seo: function (SeoService) {
      //       SeoService.generateMetaTags('Dashboard - PADI Travel for Business');
      //     },
      //     updateShop: function ($rootScope, homeService) {
      //       // if ($rootScope.selectedShop && $rootScope.selectedShop.kind == 20 && !$rootScope.selectedShop.isRealDiveResort) {
      //       //     var data = {
      //       //         isRealDiveResort: true,
      //       //         kind: 20
      //       //     };
      //       //
      //       //     return homeService.setShopKind(data);
      //       // }
      //     },
      //   },
      // })
      .otherwise({
        controller: 'HomeCtrl as home',
        templateUrl: 'templates/404.html' + __timestamp__,
        resolve: {
          error404: function ($rootScope) {
            $rootScope.notFound = true;
            return true;
          },
          seo: function (SeoService) {
            'ngInject';
            SeoService.generateMetaTags(
              'Page not found - PADI Travel for Business'
            );
          },
        },
      });
  },
]);
