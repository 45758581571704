'use strict';

angular.module('app').directive('scroll', function ($timeout, $rootScope) {
  return function (scope, elem) {
    $(elem).on('click', function (e) {
      //e.preventDefault();

      scrollTo($(this).attr('scroll'));
    });

    $rootScope.scrollTo = scrollTo;

    function scrollTo(target) {
      $timeout(function () {
        var el = $(target);

        if (el.offset()) {
          $('html,body').animate({
            scrollTop: el.offset().top,
          }, 500);
        }
      }, 100);
    }
  };
});
