'use strict';

angular.module('app')
  .directive('diviacTooltip', function ($timeout, $compile) {
    return {
      restrict: 'AE',
      scope: {
        tooltipTitle: '@',
        updatePosition: '=',
      },
      link: function (scope, elem, attr) {
        if (!scope.tooltipTitle) {
          return false;
        }
        var template = '<i class="tooltip fade-onload">' + scope.tooltipTitle + '</i>';

        elem.append($compile(template)(scope));

        elem.on('mouseover', function () {
          if (scope.updatePosition) {
            setPosition();
          }

          $(elem).find('.tooltip').addClass('active');
        });

        elem.on('mouseout', function () {
          $(elem).find('.tooltip').removeClass('active');
        });

        $timeout(setPosition, 250);

        function setPosition() {
          $(elem).find('.tooltip').css({
            marginLeft: -$(elem).find('.tooltip').outerWidth() / 2,
          });
        }
      },
    };
  })
  .directive('addTooltip', function ($timeout, $compile) {
    return {
      restrict: 'A',
      scope: {
        tooltipTitle: '@',
        addTooltip: '@',
      },
      link: function (scope) {
        var template = '<div class="tooltip fade-onload fixed-tooltip">' + scope.tooltipTitle + '</div>',
          el = $(scope.addTooltip);

        $('.search-page-la').append($compile(template)(scope));

        $(document).on('mouseenter', scope.addTooltip, function () {
          $('.fixed-tooltip').addClass('active').css({
            position: 'absolute',
            left: $(this).offset().left - 220,
            top: $(this).offset().top - 100,
          });
        });
        $(document).on('mouseleave', scope.addTooltip, function () {
          $('.fixed-tooltip').removeClass('active');
        });
      },
    };
  })
  .directive('fixedPositionTooltip', function ($timeout, $compile) {
    return {
      restrict: 'A',
      scope: {
        addTooltip: '@',
        child: '@',
        parent: '@',
        style: '@',
      },
      link: function (scope) {
        var template = '<div class="fixed-tooltip tooltip fade-onload" style="max-width:300px;bottom:auto"></div>';
        $(scope.parent || 'body').append($compile(template)(scope));
        var tooltip = $('.fixed-tooltip');

        if (scope.style) {
          tooltip.css(JSON.parse(scope.style));
        }

        $(document).on('mouseover', scope.child, function () {
          var _this = $(this),
            tooltipTitle = _this.attr('tooltip-title');

          if (!tooltipTitle) {
            return false;
          }

          var tooltipHeight = tooltip.innerHeight(),
            elementOffsetTop = _this.offset().top,
            leftOffset = _this.offset().left + _this.innerWidth() / 2;
          topOffset = elementOffsetTop - tooltipHeight - 10,
          additionalClass = '';

          if (topOffset < 0) {
            topOffset = elementOffsetTop + 30;
            additionalClass = ' tooltip-bottom';
          }

          tooltip.html(tooltipTitle).css({
            position: 'absolute',
            left: leftOffset,
            top: topOffset,
            marginTop: 0,
            zIndex: 8050, // Because fancybox has 8040 :-)
          });

          var tooltipWidth = tooltip.outerWidth(),
            // tooltipParent = tooltip.parent(),
            margin = -tooltipWidth / 2,
            parentPosition = _this.offset().left,
            maxWidth = window.innerWidth;

          tooltip.removeClass('tooltip-left-corner tooltip-right-corner tooltip-bottom');

          if (parentPosition + _this.outerWidth() / 2 + tooltipWidth / 2 > maxWidth) {
            margin = -tooltipWidth + 15;

            tooltip.addClass('tooltip-right-corner');
          } else if (parentPosition + _this.outerWidth() / 2 - tooltipWidth / 2 < 0) {
            margin = '-15px';

            tooltip.addClass('tooltip-left-corner');
          }

          tooltip.css({
            marginLeft: margin,
          });

          tooltip.addClass('active' + additionalClass);
        });

        $(document).on('mouseleave', scope.child, function () {
          tooltip.removeClass('active');
        });
      },
    };
  })

  .directive('promotionTooltip', function ($timeout, $compile) {
    return {
      restrict: 'A',
      scope: {
        tooltipTitle: '@',
        addTooltip: '@',
      },
      link: function (scope) {
        var template = '<div class="promotion-tooltip"></div>';
        $('body').append($compile(template)(scope));
        var tooltip = $('.promotion-tooltip');

        $(document).on('mouseenter', '.promotion-deal', function () {
          var _this = $(this);

          tooltip.html(_this.attr('tooltip-title')).addClass('active').css({
            position: 'absolute',
            left: _this.offset().left + 20,
            top: _this.offset().top - tooltip.innerHeight() + 20,
          });
        });

        $(document).on('mouseleave', '.promotion-deal', function () {
          tooltip.removeClass('active');
        });
      },
    };
  })


  .directive('smartTooltip', function ($timeout, $compile) {
    return {
      restrict: 'AE',
      scope: {
        tooltipTitle: '@',
        updatePosition: '=',
        revert: '=',
        borderElement: '@',
        hideTooltip: '=',
      },
      link: function (scope, elem, attr) {
        if (!scope.tooltipTitle || scope.hideTooltip) {
          return 1;
        }

        var template = '<div class="tooltip fade-onload">' + scope.tooltipTitle + '</div>';

        elem.append($compile(template)(scope));

        var tooltip = $(elem).find('.tooltip'),
          tooltipParent = tooltip.parent();

        elem.on('mouseover', function () {
          if (scope.updatePosition) {
            setPosition();
          }

          // if (this.offsetWidth < this.scrollWidth) {
          tooltip.addClass('active');
          // }
        });

        elem.on('mouseout', function () {
          tooltip.removeClass('active');
        });

        $timeout(setPosition, 250);

        function setPosition() {
          var tooltipWidth = tooltip.outerWidth(),
            tooltipHeight = tooltip.innerHeight(),
            margin = -tooltipWidth / 2,
            parentPosition = tooltipParent.offset().left,
            parentPositionTop = tooltipParent.offset().top,
            maxWidth = scope.borderElement ? $(scope.borderElement).innerWidth() : window.innerWidth;

          tooltip.removeClass('tooltip-left-corner tooltip-right-corner tooltip-bottom');

          if (parentPosition + tooltipParent.outerWidth() / 2 + tooltipWidth / 2 > maxWidth) {
            margin = -tooltipWidth + 15;

            tooltip.addClass('tooltip-right-corner');
          } else if (parentPosition + tooltipParent.outerWidth() / 2 - tooltipWidth / 2 < 0) {
            margin = '-15px';

            tooltip.addClass('tooltip-left-corner');
          }

          if (parentPositionTop < tooltipHeight + 10) {
            tooltip.addClass('tooltip-bottom');
          }

          tooltip.css({
            marginLeft: margin,
          });
        }
      },
    };
  })
  .directive('cabinsTooltip', function ($timeout, $compile) {
    return {
      restrict: 'AE',
      scope: {
        type: '@',
      },
      link: function (scope, elem, attr) {
        var template = '<div class="tooltip fade-onload">';

        scope.type = scope.type.toLowerCase();

        if (scope.type.match('shared')) {
          template += 'Shared room: you are booking a bed only. You accept to share the cabin with an unknown guest of the same sex.';
        } else if (scope.type.match('single')) {
          template += 'Single: you are booking the cabin for your exclusive use (you will have the cabin by yourself)';
        } else if (scope.type.match('double')) {
          template += 'Double: you are booking the cabin for your exclusive use (you must book for 2 people)';
        } else if (scope.type.match('tripple')) {
          template += 'Tripple: you are booking the cabin for your exclusive use (you must book for 3 people)';
        } else {
          template += 'You are booking the cabin for your exclusive use (you must book for all guests)';
        }

        template += '</div>';

        elem.append($compile(template)(scope));

        elem.on('mouseover', function () {
          $(elem).find('.tooltip').addClass('active');
        });

        elem.on('mouseout', function () {
          $(elem).find('.tooltip').removeClass('active');
        });

        $timeout(function () {
          $(elem).find('.tooltip').css({
            marginLeft: -$(elem).find('.tooltip').outerWidth() / 2,
          });
        }, 300);
      },
    };
  });
