'use strict'

angular.module('app').directive('validateEmailList', function() {
  var EMAIL_REGEXP = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return {
    require: 'ngModel',
    restrict: '',
    link: function(scope, elm, attrs, ctrl) {
        ctrl.$parsers.unshift(function(viewValue) {

        var emails = viewValue.split(',');

        // angular.foreach(emails, function() {
          var validityArr = emails.map(function(str){
              return EMAIL_REGEXP.test(str.trim());
          });

          var atLeastOneInvalid = false;

          angular.forEach(validityArr, function(value) {
            if(value === false) {
              atLeastOneInvalid = true;
            }
          });

          if(!atLeastOneInvalid) {
            // ^ all I need is to call the angular email checker here, I think.
            ctrl.$setValidity('multipleEmails', true);
            return viewValue;
          } else {
            ctrl.$setValidity('multipleEmails', false);
            return undefined;
          }
      });
    }
  };
});