'use strict';

angular.module('app')
    .service('videoService', function ($q, $rootScope, fileReader, Upload, utils, diviacLocalStorage, Request) {
        return {
            postVideo: function (data, type) {
                var slug = utils.getCurrentShopSlug(),
                    videoKind = '/videos/';

                data.shop = slug;

                if (type === 'DR') {
                    videoKind = '/resort-videos/';
                }

                return Request.post('shop/' + slug + videoKind, data).then(function (res) {
                    return res.data;
                });
            },
            getVideos: function () {
                return Request.get('shop/' + utils.getCurrentShopSlug() + '/videos/').then(function (res) {
                    return res.data;
                });
            },
            removeVideo: function (id) {
                return Request.del('shop/image/' + id + '/').then(function (res) {
                    return res.data;
                });
            },
            getResortVideos: function () {
                return Request.get('shop/' + utils.getCurrentShopSlug() + '/resort-videos/').then(function (res) {
                    return res.data;
                });
            },
        }
    })
