'use strict';

angular.module('app').constant('config', {
  mapIcons: {
    withPrice: {
      iconUrl: '/images/marker.png',
      iconSize: [23, 30],
    },
    withPriceSelected: {
      iconUrl: '/images/marker-selected.png',
      iconSize: [23, 30],
    },
    withoutPrice: {
      iconUrl: '/images/circle-pin.png',
      iconSize: [15, 15],
    },
    diveSites: {
      iconUrl: '/images/ds-pin.png',
      iconSize: [18, 18],
    },
    markerViolet: {
      iconUrl: '/images/marker-violet.png',
      iconSize: [23, 30],
    },
  },
  featuredOrderChoices: _.range(6).map(function (x, i) {
    if (i === 0) x = 'No';
    return [i, x];
  }),
  googleAPIKey: 'AIzaSyDW3twWYL1HNAHAnTmDcsQS_RdThngie9Y',
  langList: [
    {
      id: 'en',
      title: 'English',
    },
    {
      id: 'de',
      title: 'Deutsch',
    },
    {
      id: 'fr',
      title: 'Français',
    },
    {
      id: 'it',
      title: 'Italiano',
    },
    {
      id: 'es',
      title: 'Español',
    },
    {
      id: 'nl',
      title: 'Nederlands',
    },
    {
      id: 'ja',
      title: '日本語',
    },
    {
      id: 'ko',
      title: '한국어',
    },
    {
      id: 'zh-hans',
      title: '简体中文',
    },
    {
      id: 'zh-hant',
      title: '繁體中文',
    },
    {
      id: 'other',
      title: 'Other',
    },
    {
      id: 'ach',
      title: 'Acholi',
      staff: true,
    },
  ],
  SHOP_LISTING: 'canManageExtranetShopData',
  CREATE_WIDGET: 'canCreateExtranetEmbedWidget',
  REFERRAL: 'canCreateExtranetReferralLinks',
  PAYOUT_REPORT: 'canViewExtranetPayoutReport',
  CUSTOMER_REPORT: 'canViewExtranetCustomerReport',
  TRANSACTION_REPORT: 'canViewExtranetTransactionReport',
  ADJUSTMENT_REPORT: 'canViewExtranetAdjustmentReport',
  LEGAL_ENTITY: 'canManageExtranetLegalEntity',
  AGREEMENT: 'canViewExtranetAgreement',
  PAYOUT_METHOD: 'canManageExtranetPayoutSetup',
  PERMISSION_PAGE: 'canManageUserPermissions',
});
