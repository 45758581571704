'use strict';

angular.module('app')
    .service('cabinService', function ($q, Request, diviacLocalStorage, $rootScope, info, utils, Upload) {
        var cabin = {};

        cabin.getInfo = function () {
            return Request.get('shop/'+  utils.getCurrentShopSlug() + '/rooms-basic/').then(function (res) {
                return res.data;
            });
        };

        cabin.setInfo = function (data) {
			if (!data.haveSeason) {
				data.seasons = [];
			}

            return Request.patch('shop/'+  utils.getCurrentShopSlug() + '/rooms-basic/',data).then(function (res) {
                return res.data;
            });
        };

        cabin.getList = function () {
            return Request.get('booking/'+  utils.getCurrentShopSlug() + '/rooms/').then(function (res) {
                return res.data;
            });
        };

		cabin.getSleepList = function() {
            return Request.get('booking/'+  utils.getCurrentShopSlug() + '/sleep-and-dives/').then(function (res) {
                return res.data;
            });
		};

		cabin.getSleep = function (id) {
			return Request.get('booking/sleep-and-dives/' + id + '/').then(function(res){
				return res.data;
			});
		};

		cabin.saveSleep = function (data, productId) {
			data.shop = utils.getCurrentShopSlug();
			if (productId) {
				return Request.patch('booking/sleep-and-dives/' + productId + '/', data);
			}

			return Request.post('booking/sleep-and-dives/add/', data);
		};

		cabin.getCabin = function (id) {
			return Request.get('booking/cabins/' + id + '/').then(function(res){
				return res.data;
			});
		};

		cabin.deleteCabin = function (id) {
			return Request.del('booking/cabins/' + id + '/').then(function(res){
				return res.data;
			});
		};

		cabin.deletePackage = function (id) {
			return Request.del('booking/sleep-and-dives/' + id + '/').then(function(res){
				return res.data;
			});
		};

		cabin.saveCabin = function (data, productId) {
			data.shop = utils.getCurrentShopSlug();
			if (productId) {
				return Request.patch('booking/cabins/' + productId + '/', data);
			}

			return Request.post('booking/cabins/add/', data);
		};

		cabin.deletePhoto = function (id) {
			return Request.del('booking/products/photo/' + id + '/').then(function(data){
				return data;
			});
		};

		cabin.setPhoto = function (data) {
			return Request.put('booking/products/photos/ordering/', data).then(function(data){
				return data;
			});
		};

		cabin.nextPricing = function (plus, vm) {
            if (plus) {
                vm.from += vm.from === 0 ? 2 : 1;
            } else {
                vm.from -= vm.from === 2 ? 2 : 1;
            }

            vm.limit = vm.from > 0 ? 2 : 3;
        };


        cabin.uploadPhotos = function (photo) {
			var header = {
						"Content-Type": "application/json"
					},
					token = localStorage.getItem('token');

			if (token) {
				header["Authorization"] = "Bearer " + token;
			}

			return Upload.upload({
				url: utils.apiURL + 'booking/products/photo/',
				headers: header,
				arrayKey: '',
				method: 'POST',
				data: {
					picture: photo
				}
			}).then(function(res) {
			    //SUCCESS EVENT
		        return res.data;
		    }, function(err) {
		        //ERROR EVENT
		        console.warn('ERROR: ',err);
		        throw err;
		    }, function (evt) {
		          // PROGRESS EVENT
		          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
		          return progressPercentage;
		    });
		};

        return cabin;
    });
