'use strict';

angular.module('app')
  .controller('InclusionsDrCtrl', function ($q, $rootScope, diveResortService, info, shopService, gettextCatalog, inclusionsService, roomService, config) {
    var vm = this;

    vm.updateRow = _.debounce(updateRow, 300);
    vm.updateGeneral = _.debounce(updateGeneral, 300);
    vm.updateRentalEquipment = _.debounce(updateRentalEquipment, 300);
    vm.showRates = showRates;
    vm.saveRates = saveRates;
    vm.addNewInclusion = addNewInclusion;
    vm.updateTitle = updateTitle;
    vm.updateGoodToKnow = _.debounce(updateGoodToKnow, 500);
    vm.deleteItem = deleteItem;
    vm.getPayableOptions = getPayableOptions;
    vm.ratesForm = {};
    vm.setValidFromTo = setValidFromTo;
    vm.featuredOrderChoices = config.featuredOrderChoices;
    vm.isDynamicPackages = true;

    init();

    function init() {
      vm.loading = true;
      vm.pricePerOptions = info.shop.RESORT_EXTRA_PAYED_PER;
      vm.pricePerTransfers = _.filter(info.shop.RESORT_EXTRA_PAYED_PER, function (val) {
        return val[0] > 50 && val[0] < 60;
      });
      vm.validForOptions = inclusionsService.validForOptionsResort;
      vm.paybleOptions = inclusionsService.paybleOptionsResort;
      vm.gtkReady = false;

      diveResortService.getInfo().then(function (data) {
        vm.goodToKnow = data.goodToKnow;
        vm.goodToKnowStandard = data.goodToKnowStandard;
        vm.gtkReady = true;
      });

      shopService.getShopCurrency().then(function (data) {
        vm.currency = data.currency;
      });

      inclusionsService.getIsPackageDynamic().then(function (data) {
        vm.isDynamicPackages = data;
      });

      getResortItems();
    }

    function updateRow(form, data) {
      if (data.addField) {
        return true;
      }
      if (data.status === 0 || data.isMandatory || data.payedInAdvance) {
        data.isBookableInAdvance = true;
      }

      if (data.payedPer === 110) {
        data.payedInAdvance = true;
      }

      if (form.$valid) {
        if (data.subkind) {
          var general = _.filter(vm.inclusions[1].data, {subkind: 'general'}),
            obj = {};

          _.each(general, function (val) {
            obj[val.kind] = val.status;
          });

          data = obj;
          data.kind = 'general';
        }

        if (data.payedInAdvance === false && data.extraValue && data.extraValue.trim()) {
          data.price = !isNaN(+data.extraValue) ? data.extraValue : null;
        }

        if (data.payedInAdvance) {
          // remove extra value (refers only to payable on premise)
          data.extraValue = null;
        }

        inclusionsService.updateResort(data);
      }
    }

    function updateTitle(data) {
      if (data.title) {
        vm.loading = true;
        inclusionsService.updateResort(data).then(function () {
          data.edit = false;
          vm.loading = false;
        });
      }
    }

    function deleteItem(data, title) {
      vm.loading = true;
      inclusionsService.delResort(data).then(getResortItems).then(function () {
        showTableBody(title);
      });
    }

    function showRates(incl) {
      if (incl.appliedToTrips) {
        return 1;
      }

      vm.disableScreen = true;
      vm.showTable = true;
      vm.loadingRates = true;
      vm.selectedInclusion = incl;

      inclusionsService.getRatesList(incl).then(function (val) {
        vm.loadingRates = false;
        vm.rates = val;
      });
    }

    function saveRates(cancel) {
      if (cancel) {
        vm.disableScreen = false;
        vm.showTable = false;
        vm.loadingRates = false;
        return;
      }

      var touchedObjects = [];
      _.each(vm.ratesForm, function (val, key) {
        if (val.$dirty && val.$valid) {
          var rate = _.find(vm.rates, {id: +key});

          rate.pk = vm.selectedInclusion.pk;

          touchedObjects.push(
            inclusionsService.updateRate(rate)
          );
        }
      });

      if (touchedObjects.length) {
        vm.loadingRates = true;

        $q.all(touchedObjects).then(function () {
          vm.disableScreen = false;
          vm.showTable = false;
          vm.loadingRates = false;
        }).catch(function () {
          $rootScope.showNotify(gettextCatalog.getString('Something went wrong!'));
          vm.loadingRates = false;
        });
      } else {
        vm.disableScreen = false;
        vm.showTable = false;
      }
    }

    function showTableBody(title) {
      _.each(vm.inclusions, function (item) {
        if (item.title === title) {
          item.isActive = true;
        }
      });
    }

    function addNewInclusion(incl, title) {
      incl.submitted = true;

      if (incl.title) {
        vm.loading = true;
        incl.kind = 360;


        inclusionsService.addNewResortItem(incl).then(getResortItems).then(function () {
          showTableBody(title);
        });
      }
    }

    function updateGeneral() {
      return inclusionsService.updateGeneral({isNewModelUsed: vm.isNewModelUsed});
    }

    function updateRentalEquipment(incl) {
      inclusionsService.updateRentalEquipment(incl);
    }

    function updateGoodToKnow() {
      diveResortService.setInfo({ goodToKnow: vm.goodToKnow }, true);
    }

    function getPayableOptions(belongsTo) {
      // if transfer
      if (inclusionsService.isBelongsTo(belongsTo, 'Transfers')) {
        return vm.pricePerTransfers;
      }

      return vm.pricePerOptions;
    }

    function setValidFromTo(item) {
      inclusionsService.setValidFromTo(item, inclusionsService.updateResort, getResortItems);
    }

    function getResortItems() {
      vm.loading = true;
      return inclusionsService.getListResort().then(function (val) {
        vm.inclusions = val.list;
        vm.loading = false;
      });
    }
  });
