'use strict';

angular.module('app').directive('youtubeVideo', function () {
  return function (scope, elem, attrs) {
    $(elem).on('click', function () {
      $.fancybox({
        'padding': 0,
        'autoScale': false,
        'transitionIn': 'none',
        'transitionOut': 'none',
        'title': attrs.title,
        'width': 680,
        'href': attrs.url.replace(new RegExp('watch\\?v=', 'i'), 'v/'),
        'type': 'swf',
        'swf': {
          'wmode': 'transparent',
          'allowfullscreen': 'true',
        },
      });
    });
  };
});
