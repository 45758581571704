'use strict';

angular.module('app')
    .controller('PromotionsListCtrl', function ($q, $rootScope, promotionsService, utils) {
        var vm = this;

        vm.loader = true;
        vm.deletePromo = deletePromo;

        promotionsService.getList().then(function (data) {
            vm.promotions = data;
            vm.loader = false;
        });


        function deletePromo (promo) {
        	utils.confirmationPopup().then(function () {
                promotionsService.deletePromo(promo.id).then(function () {
                    vm.promotions = _.reject(vm.promotions, function (val) {
                        return val.id === promo.id;
                    });
                });
            });
        }



        vm.updateActive = function (promo, type) {
            vm.loading = true;

            console.log(promo,type);
            promotionsService.patchPromo(promo, type).catch(function (err) {
                promo[type] = !promo[type];
                vm.loading = false;
            }).then(function (res) {
                promo[type] = res[type];
                vm.loading = false;
            });
        }

    });
