'use strict';

angular.module('app')
  .service('SeoService', function ($rootScope, gettextCatalog) {
    var Seo = {};

    Seo.generateMetaTags = function (title, description, image, type) {
      setTags();

      $rootScope.$watch('translationsUploaded', function (newVal) {
        if (newVal) {
          setTags();
        }
      });

      function setTags() {
        $rootScope.title = gettextCatalog.getString(title);
        $rootScope.description = gettextCatalog.getString(description);
        $rootScope.GplusTitle = gettextCatalog.getString(title).replace('| PADI Travel', '').trim();

        if (!image) {
          $rootScope.seoImg = 'https://travel.padi.com/images/padi_travel_social_default.jpg';
        } else {
          $rootScope.seoImg = image;
        }

        if (type) {
          $rootScope.pageType = type;
        }
      }
    };

    return Seo;
  });
