'use strict';

angular.module('app')
    .controller('PreviewModalCtrl', function ($scope, items, inclusionsService) {
        var vm = this;

        vm.list = items.results;
        vm.items = items;
        vm.showMore = showMore;

        function showMore () {
            if (!vm.items.next) {
                return true;
            }
            vm.loader = true;

            inclusionsService.preview(vm.items.next).then(function (data) {
                vm.items = data;
                vm.list = vm.list.concat(data.results);
                vm.loader = false;
            });
        }
    });
