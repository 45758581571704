'use strict';

angular.module('app').directive('showMenu', function($window, $timeout) {
    return {
        restrict: 'A',
        scope: {
            showMenu: '@'
        },
        link: function(scope, element, attrs) {
            var isActive = false,
                ev = 'ontouchend' in document ? 'touchend' : 'click';

            if (ev === 'touchend') {
                var clickingCallback = function(el,p) {
                    $(scope.showMenu).removeClass('hidden');
                    isActive = true;
                };
                
                element.on(ev, clickingCallback);

                $(document).on(ev, function(e){
                    if ( $(e.target).closest(element).length === 0 && isActive) {
                        $(scope.showMenu).addClass('hidden');
                        isActive = false;
                    }
                })
            } else {
                $(element).on({
                    mouseenter: function () {
                        $(scope.showMenu).removeClass('hidden');
                    },
                    mouseleave: function () {
                        $(scope.showMenu).addClass('hidden');
                    }
                })
            }
        }
    };
});