'use strict';

angular.module('app').directive('previewActivity', function ($uibModal) {
  return {
    restrict: 'A',
    scope: {
      activityId: '=',
      isSinglePhoto: '=',
      isGroupTrip: '=',
      operator: '=',
      maxNumberOfPictures: '=',
      templateUrl: '=',
    },
    link: function (scope, elem) {
      elem.on('click', function () {
        $uibModal.open({
          animation: false,
          windowClass: 'activity-modal-open',
          controller: function (product, utils) {
            var vm = this;
            vm.loading = true;
            vm.isProDiverShop = utils.isProDiverShop();
            product
              .preview(scope.activityId)
              .then(function (activity) {
                vm.activity = activity;
                vm.hideDescriptionSection =
                  !activity.hasRequirements &&
                  !activity.packageInclusiveOf.length &&
                  !activity.packageNotInclusiveOf.length;

                vm.activity.isAdvanced =
                  _.isObject(activity.requirements) &&
                  !!activity.requirements.minDivesCount &&
                  !!activity.requirements.minCertification &&
                  (activity.requirements.minDivesCount.value > 20 ||
                    activity.requirements.minCertification.value > 20);
              })
              .finally(function () {
                vm.loading = false;
              });
          },
          controllerAs: 'ctrl',
          templateUrl: function () {
            return (
              scope.templateUrl || '../templates/_activity-preview.html?v=2'
            );
          },
        });
      });
    },
  };
});
