'use strict';

angular.module('app')
  .controller('ItineraryCtrl', function ($q, $scope, $timeout, Upload, itineraryService, gettextCatalog, $routeParams, $rootScope, utils, $location) {
    var vm = this,
      formSubmited = false;

    vm.saveItinerary = saveItinerary;
    vm.fileUpload = fileUpload;
    vm.deletePhoto = deletePhoto;
    vm.changeDays = changeDays;
    vm.toggleParameters = toggleParameters;
    vm.setId = setId;
    vm.getAirport = itineraryService.getAirport;
    vm.getHighlights = getHighlights;
    vm.photosThumb = [];
    vm.errorPhoto = [];
    vm.errorFormat = [];
    vm.disableLoading = [];

    activate();

    function activate() {
      vm.add = {
        photosRead: [],
        days: [],
        highlights: [],
        referenceLocations: [],
      };
      vm.marineLife = [];
      vm.themes = [];
      vm.sponsors = [];
      vm.routes = [];

      itineraryService.getMarineLife().then(function (data) {
        vm.marineLife  = data;
      });

      itineraryService.getThemes().then(function (data) {
        vm.themes  = data;
      });

      itineraryService.getSponsors().then(function (data) {
        vm.sponsors  = data;
      });

      itineraryService.getRoutes().then(function (data) {
        vm.routes  = data;
      });

      if ($routeParams.id) {
        vm.loading = true;
        itineraryService.getItinerary($routeParams.id).then(function (data) {
          if (data.timeDeparture) {
            data.timeDeparture = new Date('Thu Jan 01 1970 ' + data.timeDeparture);
          }
          if (data.timeArrival) {
            data.timeArrival = new Date('Thu Jan 01 1970 ' + data.timeArrival);
          }
          vm.selectedParams = {};

          vm.add = data;

          _.each(vm.add.highlights, function (id) {
            vm.selectedParams['selected_' + id] = true;
          });
          changeDays();
          getHarbours(true);

          $timeout(function () {
            vm.loading = false;
          }, 400);
        });
      } else {
        getHarbours();
      }
    }

    function getHarbours(notRemove) {
      itineraryService.getHarbours().then(function (data) {
        vm.harbours = data;

        getHighlights(notRemove);
      });
    }

    function toggleParameters(params) {
      var index = vm.add.highlights.indexOf(params.id);

      if (index !== -1) {
        vm.add.highlights.splice(index, 1);
      } else {
        vm.add.highlights.push(params.id);
      }
    }


    function saveItinerary(form) {
      if (form.$valid) {
        vm.loading = true;

        var data = _.clone(vm.add);

        data.timeArrival = formatDateTime(vm.add.timeArrival);
        data.timeDeparture = formatDateTime(vm.add.timeDeparture);

        if (data.days.length > data.nights + 1) {
          data.days.splice(data.nights + 1);
        }

        _.each(data.days, function (val) {
          // Hack for Andrew, because he can't do it on his side :D
          if (!val.description) {
            val.description = '';
          }
        });

        itineraryService.saveItinerary(data, $routeParams.id).then(function (itinerary) {
          formSubmited = true;

          vm.loading = false;

          if ($routeParams.id) {
            $rootScope.showNotify(gettextCatalog.getString('The product was successfully changed'));
          } else {
            $rootScope.showNotify(gettextCatalog.getString('The product was successfully added'));
          }

          $location.path(utils.createURL('itinerary'));
        }, function (error) {
          vm.loading = false;
        });

        $rootScope.checkedList.itineraries = true;
      }
    }

    function fileUpload(files, maxLength) {
      if (files && (vm.add.photosRead.length <= maxLength)) {
        vm.errorPhoto = [];
        vm.errorFormat = [];

        _.each(files, function (file) {
          var acceptedFormat = _.find(['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp'], function (v) {
            return v == file.type;
          });

          if (!acceptedFormat) {
            return vm.errorFormat.push(file.name);
          }

          var randomId = parseInt(Math.random() * 1000000);

          Upload.imageDimensions(file).then(function (d) {
            if (d.width < 600 || d.height < 400) {
              vm.errorPhoto.push(file.name);
            } else if (vm.add.photosRead.length + 1 <= 6) {
              vm.disableLoading.push(randomId);

              vm.add.photosRead.push({
                id: randomId,
                absoluteImage: '/images/loading-photo.jpg',
                type: 'loader',
              });

              itineraryService.uploadPhotos(file).then(function (data) {
                vm.add.photosRead = _.reject(vm.add.photosRead, function (val) {
                  return val.id == randomId;
                });

                vm.add.photosRead.push(data);

                if (!_.isArray(vm.add.photos)) {
                  vm.add.photos = [];
                }
                vm.add.photos.push(data.id);

                vm.disableLoading = _.without(vm.disableLoading, randomId);
              }, function (error) {
                vm.add.photosRead = _.reject(vm.add.photosRead, function (val) {
                  return val.id == randomId;
                });

                vm.disableLoading = _.without(vm.disableLoading, randomId);
              }, function (progress) {
                _.each(vm.add.photosRead, function (val) {
                  if (val.id == randomId) {
                    val.progress = progress;

                    if (progress == 100) {
                      val.processing = true;
                    }
                  }
                });
              });
            }
          }, function (err) {
            console.log(err);
          });
        });
      }
    }


    function deletePhoto(id) {
      itineraryService.deletePhoto(id).then(function () {
        vm.add.photosRead = _.reject(vm.add.photosRead, {id: id});
        vm.add.photos = _.without(vm.add.photos, id);
      });
    }

    function setId($item, field) {
      vm.add[field] = $item.id;
    }

    function formatDateTime(date) {
      if (!date) {
        return null;
      }

      return moment(date).format('HH:mm');
    }

    function changeDays() {
      if (vm.add.nights) {
        vm.daysNumber = new Array(+vm.add.nights + 1);
      }
    }

    function getHighlights(notRemove) {
      var data = [],
        harbourArrival,
        harbourDeparture;

      if (!notRemove) {
        vm.add.highlights = [];
        vm.selectedParams = {};
      }

      if (vm.add.harbourDeparture) {
        harbourDeparture = _.filter(vm.harbours, {id: vm.add.harbourDeparture});
        data.push(harbourDeparture[0].country);
      }
      if (vm.add.harbourArrival) {
        harbourArrival = _.filter(vm.harbours, {id: vm.add.harbourArrival});

        data.push(harbourArrival[0].country);
      }

      itineraryService.getHighligts(data).then(function (res) {
        vm.closeHighlights = res;
      });

      itineraryService.getLocations(data).then(function (res) {
        vm.referenceLocations = res;

        if (!notRemove) {
          if (harbourDeparture && harbourArrival) {
            if (harbourDeparture[0].referenceLocation.id !== harbourArrival[0].referenceLocation.id) {
              vm.add.referenceLocations = [
                harbourDeparture[0].referenceLocation.id,
                harbourArrival[0].referenceLocation.id
              ];
            } else {
              vm.add.referenceLocations = [
                harbourDeparture[0].referenceLocation.id
              ];
            }
          } else if (harbourDeparture) {
            vm.add.referenceLocations = [
              harbourDeparture[0].referenceLocation.id
            ];
          } else if (harbourArrival) {
            vm.add.referenceLocations = [
              harbourArrival[0].referenceLocation.id
            ];
          }
        }
      });
    }
  });
