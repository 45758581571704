'use strict';

angular.module('app')
  .controller('StaffCtrl', function ($rootScope, shopService, gettextCatalog) {
    var vm = this;
    vm.loading = true;
    vm.saveSettings = saveSettings;
    vm.settings = {};

    shopService.getStaffSettings().then( function (data) {
      vm.settings = data;
      vm.loading = false;
    });

    function saveSettings() {
      vm.loading = true;
      shopService.setStaffSettings(vm.settings).then( function (result) {
        vm.settings = result;
        vm.loading = false;
        $rootScope.showNotify(gettextCatalog.getString('Successfully saved'));
      }).catch(function () {
        vm.loading = false;
      });
    }
  });
