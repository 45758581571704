'use strict';

angular.module('app').directive('helpBlock', function() {
  return function(scope, elem, attr) {
    $(document).on('mouseenter','.has-info', function(e){
    	var _this = $(this),
    		offset = _this.offset(),
    		parentOffset = $('.content').offset();

        $(elem).animate({'top' : offset.top - parentOffset.top - 18},500);

    	scope.$apply(function(){
    		scope.infoText = _this.data('info-text');
    		scope.infoTitle = _this.data('info-title');
    	});
    });
  };
})
