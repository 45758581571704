'use strict';

angular.module('app')
  .controller('RoomsCtrl', function ($scope, $rootScope, $uibModal, shopService, $location, roomService, gettextCatalog, info, utils, recalcService, GTM) {
    var vm = this,
      formSubmited = false;

    vm.changeCurrency = changeCurrency;
    vm.saveRoom = saveRoom;
    vm.deleteRoom = deleteRoom;
    vm.deletePackage = deletePackage;
    vm.updateActive = updateActive;
    vm.changeSeasonsNumber = changeSeasonsNumber;
    vm.move = move;
    vm.copyPackage = copyPackage;
    vm.showWarning = true;
    vm.mealOptions = info.shop.RESORT_MEAL_PLANS;
    vm.numberOfNights = 7;
    vm.calculateDives = calculateDives;
    vm.dragEnd = dragEnd;
    vm.maxNumberOfNights = Array.apply(null, { length: 30 }).map(function (value, index) {return index + 1;}); // create array from 1 to 30
    vm.priceTypes = info.shop.PRICE_TYPE_CHOICES;
    vm.showPriceWarn = showPriceWarn;
    vm.sleepAndDivePackageKinds = {};
    info.booking.SLEEP_AND_DIVE_PACKAGE_KIND.forEach(function (item) {
      vm.sleepAndDivePackageKinds[item[0]] = item[1];
    });
    vm.togglePackageDraft = togglePackageDraft;

    init();

    // $scope.$on('$locationChangeStart', function (event) {
    //     if ($scope.seasons.$dirty && !formSubmited) {
    //         event.preventDefault();
    //         if ($rootScope.currentUrl === '/room/edit/:id/') {
    //             $rootScope.currentUrl = vm.url;
    //         }

    //         utils.warningPopup().then(function(){
    //             $location.path($rootScope.currentUrl);
    //             formSubmited = true;
    //         }).catch(function() {
    //             $rootScope.currentUrl = '/rooms/';
    //         });
    //     } else if (!$scope.seasons.$dirty && !formSubmited) {
    //         // roomService.setInfo(vm.params).then(function () {
    //         //     $rootScope.showNotify('Information was successfully saved', 5000);
    //         // });
    //         // if (checkTotalNumbers()) {
    //         //        event.preventDefault();
    //         //        if ($rootScope.currentUrl === '/room/edit/:id/') {
    //         //              $rootScope.currentUrl = vm.url;
    //         //        }
    //         //
    //         //        utils.warningPopup('../templates/_number-of-rooms-popup.html', $scope).then(function () {
    //         //            vm.showWarning = false;
    //         //            saveOnLeave();
    //         //
    //         //            $location.path($rootScope.currentUrl);
    //         //
    //         //        }).catch(function () {
    //         //            vm.loading = false;
    //         //            $rootScope.currentUrl = '/rooms/';
    //         //        });
    //         //
    //         //        return true;
    //         //} else {
    //         //    saveOnLeave();
    //         //}
    //         //function saveOnLeave() {

    //         //}
    //     }

    //     var product = _.find(vm.roomsList,{isActive: true});

    //     $rootScope.checkedList.rooms = product && vm.params.resortInfo.totalNumberOfRooms ? true: false;
    // });

    function init() {
      vm.currencyList = info.shop.CURRENCY_CHOICES;
      vm.loader = true;
      vm.loaderDP = true;
      vm.roomsNumber = 0;

      shopService.getShopCurrency().then(function (data) {
        vm.currency = data.currency;
      });

      roomService.getInfo().then(function (data) {
        getRoomData(data);
      });

      getPackagesList();

      roomService.getList().then(function (data) {
        vm.roomsList =  data;

        _.each(data, function (val) {
          vm.roomsNumber += val.roomNumber;
        });
        vm.loader = false;
      });

      recalcService.getRecalcUpdates();
    }

    function deleteRoom(prod) {
      utils.confirmationPopup().then(function () {
        vm.loader = true;
        roomService.deleteRoom(prod.roomId).then(function () {
          vm.roomsList = _.reject(vm.roomsList, function (val) {
            return val.roomId === prod.roomId;
          });
          vm.roomsNumber = 0;

          _.each(vm.roomsList, function (val) {
            vm.roomsNumber += val.roomNumber;
          });

          $rootScope.checkedList.rooms = vm.roomsList.length && vm.params.resortInfo.totalNumberOfRooms ? true : false;

          vm.loader = false;
          $rootScope.showNotify(gettextCatalog.getString('The selected product was successfully deleted'));
        }, function (err) {
          if (err.data.nonFieldErrors) {
            $rootScope.showNotify(err.data.nonFieldErrors);
          }
          vm.loader = false;
        });
      });
    }

    function saveRoom(form, noRedirect) {
      // check if user changed seasons date fields
      var seasonsChanged = false,
        pricesChanged = false,
        priceRelatedFields = [form.isTaxesInPrice, form.taxPercent, form.priceType, form.commissionRate];

      angular.forEach(form.seasons, function (value, key) {
        if (typeof value === 'object' && (key.indexOf('Title') < 0)  && value.$dirty ) {
          seasonsChanged = true;
        }
      });

      angular.forEach(priceRelatedFields, function (value) {
        if (value.$dirty) pricesChanged = true;
      });

      form.$submitted = true;
      $scope.seasons.$submitted = true;

      if (form.$valid) {
        vm.loading = true;


        // get latest recalc status
        recalcService.getRecalcUpdates().then(function () {
          if (checkTotalNumbers() && !noRedirect) {
            utils.warningPopup('../templates/_number-of-rooms-popup.html', $scope).then(function () {
              vm.showWarning = false;
              saveRoom(form, noRedirect);
            }).catch(function () {
              vm.loading = false;
            });

            return true;
          }

          handleSave();
        });

        function handleSave() {
          // forbid seasons saving if recalculating
          if ($rootScope.recalcStatus === 2 && (seasonsChanged || pricesChanged) ) {
            $rootScope.showNotify(gettextCatalog.getString('Editing seasons and price related fields is disabled during price recalculations'), 6000);
            vm.loading = false;
            return false;
          }

          roomService.setInfo(vm.params).then(function (data) {
            formSubmited = true;


            vm.totalRooms = _.clone(vm.params.resortInfo.totalNumberOfRooms);

            if (pricesChanged) {
              recalcService.setStatus(1);
            }

            if (vm.params.haveSeason && seasonsChanged) {
              $rootScope.showNotify(gettextCatalog.getString('Please review the base prices for each room now that you`ve changed the seasons. Missing base prices will cause some errors!'), 8000);
            } else {
              $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved'), 5000);
            }
            $rootScope.checkedList.rooms = vm.roomsList.length && vm.params.resortInfo.totalNumberOfRooms ? true : false;

            if (!vm.params.haveSeason) {
              data.seasons = [];
            }
            getRoomData(data);

            if (!noRedirect) {
              $location.path($rootScope.choosenShop.resortKind == 20 ? utils.createURL('resort_inclusions') : utils.createURL('products'));
            } else {
              getPackagesList();
              roomService.getList().then(function (data) {
                vm.roomsNumber = 0;
                vm.roomsList = data;
                vm.loading = false;

                _.each(data, function (val) {
                  vm.roomsNumber += val.roomNumber;
                });
              });
            }
          }).catch(function (error) {
            if (error.data.totalNumberOfRooms) {
              form.totalNumberOfRooms.$invalid = true;
            }

            if (error.data.resortInfo) {
              if (error.data.resortInfo.divesPerDay) {
                form.divesPerDay.$invalid = true;
              }

              if (error.data.resortInfo.isAutomaticallyGenerated) {
                form.isAutomaticallyGenerated.$invalid = true;
              }

              if (error.data.resortInfo.divesPerNightsObjects) {
                _.each(error.data.resortInfo.divesPerNightsObjects, function (value, key) {
                  form['manualDives' + key].$invalid = true;
                  form['manualDives' + key].errorText = value.dives;
                });
                $rootScope.scrollToError();
              }
            }

            vm.loading = false;
          });
        }
      }
    }

    function changeCurrency() {
      vm.loading = true;
      shopService.setShopCurrency({currency: vm.currency}).then(function () {
        $rootScope.showNotify(gettextCatalog.getString('Currency was successfully saved'));

        vm.loading = false;
      });
    }

    function updateActive(prod, type) {
      if (!prod.price && vm.params.resortInfo.isDynamicPackages) {
        prod.isActive = false;
        $uibModal.open({
          animation: false,
          controller: ['$scope', function ($scope) {
            $scope.notFinished = ['Product price'];
          }],
          templateUrl: '../templates/_complete_product_popup.html',
        });
      } else {
        vm.loading = true;

        if (!type) {
          type = 'saveRoom';
        }

        roomService[type]({isActive: prod.isActive}, prod.roomId || prod.id).catch(function (err) {
          prod.isActive = !prod.isActive;
          vm.loading = false;
        }).then(function (res) {
          if (type === 'saveRoom') {
            prod.isActive = res.data.isActive;
            getPackagesList().then(function () { return vm.loading = false; });
          } else {
            prod.isActive = res.data.isActive;
            vm.loading = false;
          }
        });
      }
    }

    function changeSeasonsNumber() {
      if (vm.params.seasons.length > vm.params.numberOfSeasons) {
        vm.params.seasons.splice(vm.params.numberOfSeasons, vm.params.seasons.length - vm.params.numberOfSeasons);
      }
      for (var i = 0; i < vm.params.numberOfSeasons; i++) {
        if (!vm.params.seasons[i]) {
          vm.params.seasons[i] = {qty: [0], dates: []};
        }
      }
    }

    function move(type, options) {
      const url = utils.createURL(type, options);
      vm.url = url;

      $location.path(url);
    }


    function checkTotalNumbers() {
      $scope.rooms = vm.roomsNumber;
      $scope.total = vm.params ? +vm.params.resortInfo.totalNumberOfRooms : 0;

      return $scope.rooms != $scope.total && vm.showWarning === true;
    }

    function getRoomData(data) {
      vm.params = data;

      if (!data.seasons.length) {
        vm.params.seasons = [{qty: [0], dates: []}, {qty: [0], dates: []}];
        vm.params.haveSeason = false;
        vm.params.numberOfSeasons = 2;
      } else {
        vm.params.numberOfSeasons = vm.params.seasons.length;
        vm.params.haveSeason = true;
        _.each(vm.params.seasons, function (val) {
          val.qty = val.dates;
        });
      }

      vm.haveSeasonOriginal = _.clone(vm.params.haveSeason);

      vm.totalRooms = _.clone(vm.params.resortInfo.totalNumberOfRooms);
    }

    function calculateDives() {
      var result = ' ';
      if (vm.params) {
        if (vm.params.resortInfo.nightsRatio > vm.numberOfNights) {
          vm.params.resortInfo.nightsRatio = vm.numberOfNights - 1;
        }
        result = (vm.numberOfNights - vm.params.resortInfo.nightsRatio) * vm.params.resortInfo.divesPerDay;
      }
      return isNaN(result) ? ' ' : result;
    }

    function dragEnd(list) {
      console.log(list);
    }

    function showPriceWarn() {
      if (!$rootScope.isDraft && (vm.haveSeasonOriginal !== vm.params.haveSeason) ) {
        utils.warningPopup('../templates/_seasons-warning-popup.html', $scope).then(function () {
          shopService.setState({ isDraft: true }).then(function () {
            $rootScope.isDraft = true;
            GTM.variables({business_listing_status: 'Unpublished'});
            GTM.event('business_listing_unpublish');
          });
        }).catch(function () {
          vm.params.haveSeason = !vm.params.haveSeason;
        });

        return true;
      }
    }

    function togglePackageDraft(pack) {
      vm.loading = true;

      roomService.updatePackage({isActive: pack.isActive}, pack.id).catch(function (err) {
        pack.isActive = !pack.isActive;
        vm.loading = false;
      }).then(function (res) {
        pack.isActive = res.data.isActive;
        vm.loading = false;
      });
    }

    function deletePackage(pack) {
      utils.confirmationPopup().then(function () {
        vm.loading = true;
        roomService.deletePackage(pack.id).then(function () {
          vm.sleepList = _.reject(vm.sleepList, function (val) {
            return val.id === pack.id;
          });
          vm.loading = false;

          $rootScope.showNotify(gettextCatalog.getString('The selected sleep & dive package was successfully deleted'));
        }).catch(function (err) {
          vm.loading = false;
        });
      });
    }

    function copyPackage(pack) {
      vm.loading = true;
      roomService.copyPackage(pack).then(function () {
        $rootScope.showNotify(gettextCatalog.getString('The selected sleep & dive package was successfully copied'));
        getPackagesList().then(function () { vm.loading = false; });
      });
    }

    function getPackagesList() {
      return roomService.getSleepList().then(function (data) {
        vm.sleepList = data;
        vm.loaderDP = false;
      });
    }
  });
