'use strict';

angular
  .module('app')
  .controller('GisManagement', function (
    info,
    config,
    $rootScope,
    $routeParams,
    bookings
  ) {
    var vm = this;

    $rootScope.showActivity = true;
    vm.productInclusionsPage = true;
    vm.productId = $routeParams.id;

    vm.updateRow = _.debounce(updateRow, 300);
    vm.updateSection = _.debounce(updateSection, 300);
    vm.itinerariesForm = {};
    vm.featuredOrderChoices = config.featuredOrderChoices;
    vm.sectionsToManage = [];
    vm.setGuestLink = setGuestLink;
    vm.resetUrlIfHidden = urlState;

    init();

    function urlState() {
      if (!vm.isShown) {
        bookings.setGuestFormLink(null);
      }

      bookings.getGuestFormLink().then((res) => {
        vm.guestFormLink = res.guestFormLink;
      });
    }

    function init() {
      vm.loading = true;
      vm.deadLineType = info.gis.GIS_DEADLINE;
      vm.deadLineType.push([null, 'None']);
      bookings.getGuestFormLink().then((res) => {
        vm.guestFormLink = res.guestFormLink;
        vm.isShown = Boolean(res.guestFormLink);
      });

      getFields();
    }

    function getFields() {
      bookings
        .getFieldsToManage()
        .then(function (section) {
          vm.sectionsToManage = section;
        })
        .finally(function () {
          vm.loading = false;
        });
    }

    function updateRow(data) {
      vm.loading = true;

      bookings.updateGisField(data).finally(function () {
        vm.loading = false;
      });
    }

    function updateSection(data) {
      vm.loading = true;

      bookings.updateGisSection(data).finally(function () {
        vm.loading = false;
      });
    }

    function setGuestLink(form) {
      vm.loading = true;
      if (form.$invalid) {
        return;
      }

      bookings.setGuestFormLink(vm.guestFormLink).finally(function () {
        vm.loading = false;
      });
    }
  });
