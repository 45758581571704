'use strict';

angular
  .module('app')
  .service('groupTrips', function (
    $rootScope,
    Request,
    Upload,
    utils
  ) {
    var groupTrips = {};

    groupTrips.getTripLanguage = function () {
      return Request.get('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/shop-info/').then(function (req) {
        return req.data;
      });
    };

    groupTrips.setTripLanguage = function (data) {
      return Request.patch('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/shop-info/', data);
    };

    groupTrips.deleteTrip = function (id) {
      return Request.del('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + id + '/').then(function (data) {
        return data;
      });
    };

    groupTrips.getTrips = function () {
      return Request.get('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' ).then(function (req) {
        return req.data;
      }, function (err) {
        return err;
      });
    };

    groupTrips.getTrip = function (tripId) {
      return Request.get('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/').then(function (req) {
        // save in upper scope for side menu;
        $rootScope.groupTrip = _.clone(req.data);
        req.data.status = undefined;
        return req.data;
      }, function (err) {
        return err;
      });
    };

    groupTrips.addTrip = function (trip) {
      return Request.post('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/', trip);
    };

    groupTrips.updateTrip = function (trip) {
      return Request.patch('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + trip.id + '/', trip);
    };

    groupTrips.getOperators = function (text) {
      return Request.get('marketplace/group-trips/operators/', {search: text} ).then(function (req) {
        return req.data;
      }, function (err) {
        return err;
      });
    };

    groupTrips.getOperatorItineraries = function (operator) {
      return Request.get('marketplace/group-trips/operators/' + operator + '/trips/' ).then(function (req) {
        return req.data;
      }, function (err) {
        return err;
      });
    };

    groupTrips.getAreas = function (countryId) {
      return Request.get('marketplace/group-trips/countries/' + countryId + '/areas/' ).then(function (req) {
        return req.data;
      }, function (err) {
        return err;
      });
    };

    groupTrips.getTags = function () {
      return Request.get('marketplace/group-trips/tags/').then(function (res) {
        return res.data;
      });
    };


    groupTrips.uploadPhotos = function (uploadData, tripId) {
      var header = {
          'Content-Type': 'application/json',
        },
        token = localStorage.getItem('token');

      if (token) {
        header.Authorization = 'Bearer ' + token;
      }

      return Upload.upload({
        url: utils.apiURL + 'marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/images/',
        headers: header,
        arrayKey: '',
        method: 'POST',
        data: uploadData,
      }).then(
        function (res) {
          // SUCCESS EVENT
          return res.data;
        },
        function (err) {
          // ERROR EVENT
          console.warn('ERROR: ', err);
          throw err;
        },
        function (evt) {
          // PROGRESS EVENT
          var progressPercentage = parseInt((100.0 * evt.loaded) / evt.total);
          return progressPercentage;
        }
      );
    };

    groupTrips.deletePhoto = function (photoId, tripId) {
      return Request.del('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/images/' + photoId).then(function (data) {
        return data;
      });
    };

    groupTrips.getTripPhotos = function (tripId) {
      return Request.get('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/images/').then(function (res) {
        return res.data;
      });
    };


    groupTrips.saveActivityPhotos = function (tripId, photos) {
      photos.groupTrip = 5;
      return Request.post('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/images/', photos).then(function (res) {
        return res.data;
      });
    };

    groupTrips.copyPhotos = function (tripId, image, isMain) {
      return Request.put('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/images/copy/' + (image.category || 'backgrounds') + '/' + image.id + '/', { id: image.id, is_main: isMain }).then(function (res) {
        return res.data;
      });
    };

    groupTrips.addTripPackage = function (tripId, packageData) {
      return Request.post('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/packages/', packageData);
    };

    groupTrips.updateTripPackage = function (tripId, packageData) {
      return Request.patch('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/packages/'  + packageData.id + '/', packageData);
    };

    groupTrips.getTripPackage = function (tripId, packageId) {
      return Request.get('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/packages/' + packageId + '/').then(function (req) {
        return req.data;
      }, function (err) {
        return err;
      });
    };

    groupTrips.getOperatorRooms = function (tripId) {
      return Request.get('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/rooms/').then(function (req) {
        return req.data;
      }, function (err) {
        return err;
      });
    };

    groupTrips.deleteTripPackage = function (tripId, packageId) {
      return Request.del('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/packages/' + packageId + '/');
    };

    groupTrips.getTripPackages = function (tripId) {
      return Request.get('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/packages/');
    };

    groupTrips.getTripTaxes = function () {
      return Request.get('marketplace/fees/');
    };

    groupTrips.getInclusions = function (tripId) {
      return Request.get('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/inclusions/').then(function (req) {
        return req.data;
      }, function (err) {
        return err;
      });
    };

    groupTrips.addInclusion = function (tripId, inclusion) {
      return Request.post('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/inclusions/', inclusion);
    };

    groupTrips.updateInclusion = function (tripId, inclusion) {
      return Request.patch('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/inclusions/' + inclusion.id + '/', inclusion);
    };

    groupTrips.deleteInclusion = function (tripId, inclusionId) {
      return Request.del('marketplace/' + utils.getCurrentShopSlug() + '/group-trips/' + tripId + '/inclusions/' + inclusionId + '/');
    };

    groupTrips.getSpecies = function (operator) {
      return Request.get('marketplace/group-trips/operators/' + (operator || utils.getCurrentShopSlug()) + '/species/').then(function (res) {
        return res.data;
      });
    };

    groupTrips.getBackground = function (operator) {
      return Request.get('marketplace/group-trips/operators/' + (operator || utils.getCurrentShopSlug()) + '/background/').then(function (res) {
        return res.data;
      });
    };

    groupTrips.getPhotos = function (operator) {
      return Request.get('marketplace/group-trips/operators/' + (operator || utils.getCurrentShopSlug()) + '/photos/').then(function (res) {
        return res.data;
      });
    };

    groupTrips.getCustomOperatorList = function () {
      return Request.get('marketplace/' + utils.getCurrentShopSlug() + '/custom-operators/').then(function (res) {
        return res.data;
      });
    };

    groupTrips.getCustomOperator = function (tripId, operatorId) {
      return Request.get('marketplace/' + utils.getCurrentShopSlug() + '/custom-operators/' + operatorId + '/').then(function (res) {
        return res.data;
      });
    };

    groupTrips.deleteOperator = function (tripId, operatorId) {
      return Request.del('marketplace/' + utils.getCurrentShopSlug() + '/custom-operators/' + operatorId + '/').then(function (res) {
        return res.data;
      });
    };

    groupTrips.createCustomOperator = function (operator) {
      var header = {
          'Content-Type': 'application/json',
        },
        token = localStorage.getItem('token');

      if (token) {
        header.Authorization = 'Bearer ' + token;
      }
      operator.point = JSON.stringify(operator.point);

      return Upload.upload({
        url: utils.apiURL + 'marketplace/' + utils.getCurrentShopSlug() + '/custom-operators' + (operator.id ? '/' + operator.id : '') + '/',
        headers: header,
        arrayKey: '',
        method: operator.id ? 'PATCH' : 'POST',
        data: operator,
      }).then(
        function (res) {
          // SUCCESS EVENT
          return res.data;
        },
        function (err) {
          // ERROR EVENT
          console.warn('ERROR: ', err);
          throw err;
        },
        function (evt) {
          // PROGRESS EVENT
          var progressPercentage = parseInt((100.0 * evt.loaded) / evt.total);
          return progressPercentage;
        }
      );
    };

    groupTrips.getGroupMilestones = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/group-trip-milestones/').then(function (res) {
        return res.data;
      });
    };

    groupTrips.saveGroupMilestones = function (milestones) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/group-trip-milestones/', milestones);
    };

    groupTrips.getGroupPageInfo = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/group/').then(function (res) {
        return res.data;
      });
    };

    groupTrips.saveGroupPageInfo = function (data) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/group/', data);
    };

    return groupTrips;
  });
