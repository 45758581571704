'use strict';

angular.module('app').directive('headerFixed', function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attr) {
            var itineraryTable = 0;
            $('.main-wrapper').on('scroll', function () {
                var _this = $(this),
                    header = $(attr.headerClass || '.header'),
                    tableBody = $('.itineraries-table-body' || '.table-itinerary .tbody');

                if (itineraryTable == 0) {
                    // set only once
                    itineraryTable = $(attr.selectedClass || '.table-itinerary').offset().top - header.outerHeight();
                }

                if (_this.scrollTop() > itineraryTable) {
                    header.addClass('fixed');
                    tableBody.addClass('scrolled');
                } else {
                    header.removeClass('fixed');
                    tableBody.removeClass('scrolled');
                }
            });
        }
    };
}).directive('horizontalScroll', function () {
    return {
        restrict: 'A',
        link: function (scope, elem) {
            var leftNav = $('.left-navigation').width();

            $('.table-itinerary').on('scroll', function () {
                var _this = $(this);

                $(elem).find('.header').css({'left': -_this.scrollLeft() + leftNav + 20});

            });
        }
    };
});
