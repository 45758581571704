'use strict'

angular.module('app').directive('sticky', function () {
    return {
        link: function (scope, elem, attrs, ctrl) {
            var $elem = $(elem),
                position = $elem.offset().top,
                $wrapper = $('.main-wrapper');

            $wrapper.on('scroll', function () {
                if ($wrapper.scrollTop() > position) {
                    $elem.addClass('scrolled');
                } else {
                    $elem.removeClass('scrolled');
                }
            });
        }
    };
});