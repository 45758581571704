'use strict';

angular
  .module('app')
  .controller('CustomOperator', function (
    $rootScope,
    utils,
    Upload,
    $location,
    groupTrips,
    $routeParams,
    shopService,
    localStorageService,
    gettextCatalog,
  ) {
    var vm = this;
    vm.tripId = $routeParams.tripId;
    vm.getAddress = getAddress;
    vm.getCoords = getCoords;
    vm.fileUpload = fileUpload;
    vm.save = save;
    vm.needTranslate = ['title'];

    activate();

    function activate() {
      vm.operator = {
        point: {
        },
      };
      vm.mapParams = {
        marker: {
          latitude: 48,
          longitude: 8,
        },
        options: {
          icon: '/images/marker.png',
          draggable: true,
        },
        events: {
          dragend: centerChanged,
        },
        center: {
          latitude: 48,
          longitude: 8,
        },
        mapStyle: {'mapTypeControl': false, 'streetViewControl': false, 'fullscreenControl': false},
        bounds: {},
      };

      groupTrips.getTrip($routeParams.tripId).then(function (data) {
        if (utils.isArrayContainElementsFromOtherArray(data.autoTranslatedFields, vm.needTranslate)) {
          $rootScope.showNotify(gettextCatalog.getString('The text fields on this page have been auto-translated using Google Translate. You may edit them manually if you wish.'));
        }
      });

      if ($routeParams.operatorId) {
        vm.loading = true;
        groupTrips.getCustomOperator($routeParams.tripId, $routeParams.operatorId).then(function (data) {
          data.image = data.picture;
          data.picture = undefined;
          vm.operator = data;
          vm.mapParams.marker = vm.mapParams.center = data.point;
          vm.loading = false;
        });
      }
    }

    function centerChanged(marker) {
      var center = marker.getPosition();

      vm.mapParams.center = {
        latitude: center.lat(),
        longitude: center.lng(),
      };

      vm.operator.point.latitude = vm.mapParams.center.latitude;
      vm.operator.point.longitude = vm.mapParams.center.longitude;
      getAddress(vm.mapParams.center.latitude + ',' + vm.mapParams.center.longitude).then(function (result) {
        if (result[0].geometry.viewport) {
          setZoom(result[0].geometry.viewport, vm.mapParams);
        }
        vm.operator.address = result[0].formatted_address;
      });
    }

    function getAddress(latlng) {
      return shopService.googleReverseGeoCode(latlng).then(function (data) {
        if (data[0] && data[0].formatted_address) {
          return data;
        }
        return 'no address';
      });
    }

    function getCoords(targetPoint, currentMap) {
      if (targetPoint) {
        shopService.googleGeoCode(targetPoint.address).then(function (data) {
          if (!_.isEmpty(data) && data[0].geometry) {
            currentMap.center = {
              latitude: data[0].geometry.location.lat,
              longitude: data[0].geometry.location.lng,
            };
            targetPoint.point.latitude = vm.mapParams.center.latitude;
            targetPoint.point.longitude = vm.mapParams.center.longitude;

            if (data[0].geometry.viewport) {
              setZoom(data[0].geometry.viewport, currentMap);
            }

            currentMap.marker = _.clone(currentMap.center);
          }
        });
      }
    }

    function fileUpload(files) {
      if (!files) {
        return false;
      }
      var file = files[0];

      if (!utils.acceptedFileFormat(file.type)) {
        vm.loading = false;
        return $rootScope.showNotify('unaccepted file format:' + file.type);
      }

      return Upload.imageDimensions(file).then(function (d) {
        if (d.width < 600 || d.height < 400) {
          vm.loading = false;
          return $rootScope.showNotify(file.name + gettextCatalog.getString(':image must me at least 600px wide and 400px tall'));
        }

        vm.operator.image = file.$ngfBlobUrl;
        vm.operator.picture = file;

        return d;
      });
    }

    function setZoom(viewport, mapModel) {
      if (viewport) {
        mapModel.bounds = {
          northeast: {
            latitude: viewport.northeast.lat,
            longitude: viewport.northeast.lng,
          },
          southwest: {
            latitude: viewport.southwest.lat,
            longitude: viewport.southwest.lng,
          },
        };
      }
    }

    function save(form) {
      if (form.$valid) {
        vm.loading = true;

        groupTrips.createCustomOperator(vm.operator).then(function (operator) {
          var customOperatorList = localStorageService.get('customOperator') || [],
            index = _.findIndex(customOperatorList, { id: operator.id }); // if it's on edit
          operator.tripId = vm.tripId;
          if (vm.operator.id && index !== -1) {
            customOperatorList.splice(index, 1, operator);
            localStorageService.set('customOperator', customOperatorList);
          } else if (!vm.operator.id) {
            customOperatorList.push(operator);
            localStorageService.set('customOperator', customOperatorList);
          }
          if (vm.tripId === 'new') {
            $location.path(utils.createURL('group_trips_add'));
          } else {
            $location.path(utils.createURL('group_trips_basics', {id: vm.tripId}));
          }
        }).finally(function () {
          vm.loading = false;
        });
      }
    }
  });
