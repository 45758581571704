
'use strict';

angular.module('app').directive('scrollAvailabilityTable', function () {
    return {
        restrict: 'A',
        link: function($scope, element) {
            var elem = $(element);
            
            elem.find('#left-scroll').on('click', function() {
                $('.availability-calendar').animate({'scrollLeft':'-=280px'});
            });

            elem.find('#right-scroll').on('click', function() {
                $('.availability-calendar').animate({'scrollLeft':'+=280px'});
            })
        }
    };
});