'use strict';

angular.module('app')
  .controller('ReviewsCtrl', function ($rootScope, $q, $uibModal, $location, Reviews, shopService, gettextCatalog, utils) {
    var vm = this;

    vm.loading = true;

    $rootScope.$watch('fullyDone', function (val) {
      if (val === true) {
        init();
      } else if (val === false) {
        // $location.path('/home/');
        $location.path(utils.createURL('home_home'));
      }
    });

    function init() {
      vm.hideWidget = sessionStorage.getItem('gotIt');

      $q.all([shopService.getInfo(), Reviews.getReview()]).then(function (data) {
        vm.shopInfo = data[0];

        vm.reviews = data[1];
        vm.allReviews = data[1].results;

        vm.loading = false;
      }).catch(function () {
        vm.loading = false;
      });
    }

    vm.getMoreReviews = function () {
      Reviews.getReview(vm.reviews.next).then(function (rating) {
        vm.reviews = rating;

        _.map(rating.results, function (val) {
          vm.allReviews.push(val);
        });

        vm.reviews.results = vm.allReviews;
      });
    };

    vm.flagReview = function (review) {
      if (review.isInappropriate) {
        return 1;
      }

      var modalInstance = $uibModal.open({
        backdrop: 'static',
        animation: false,
        templateUrl: '../templates/_flag-popup.html',
        controller: 'ModalClaimCtrl',
        controllerAs: 'claimModal',
      });

      modalInstance.result.then(function () {
        vm.loading = true;
        Reviews.updateReview(review.id, {is_inappropriate: true}).then(function () {
          review.isInappropriate = true;
          vm.loading = false;
        }).catch(function () {
          vm.loading = false;
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.addReply = function (review) {
      vm.loading = true;
      Reviews.updateReview(review.id, {reply: review.reply}).then(function () {
        vm.loading = false;
        review.replyAdded = true;
        $rootScope.showNotify(gettextCatalog.getString('Your reply was successfully saved'));
      }).catch(function () {
        vm.loading = false;
      });
    };

    vm.sendInvitation = function () {
      vm.loading = true;
      Reviews.sendInvitation({emails: vm.emails}).then(function () {
        vm.loading = false;
        $rootScope.showNotify(gettextCatalog.getString('Invitation emails was successfully sent'));
      }).catch(function (error) {
        vm.loading = false;
      });
    };

    vm.gotIt = function () {
      sessionStorage.setItem('gotIt', true);
      vm.hideWidget = true;
    };
  });
