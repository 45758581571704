'use strict';

angular.module('app').directive('wysiwygLength', function () {
  return {
    require: 'ngModel',
    restrict: '',
    link: function (scope, elm, attrs, ctrl) {
      if (ctrl) {
        ctrl.$validators.maxLength = function () {
          if (scope.disabled) {
            return true;
          }

          var text = $(elm).parent().find('.note-editor .note-editable').text();
          return !text || (text && text.length <= attrs.wysiwygLength);
        };
      }
    },
  };
});
