'use strict';

angular.module('app')
    .service('Reviews', function ($q, Request, diviacLocalStorage, $rootScope, info, utils) {
        var reviews = {};

        reviews.getReview = function(newPageLink){
            var link = 'shop/' + utils.getCurrentShopSlug() + '/reviews/',
                query = { page_size: 3 };

            if (newPageLink) {
              link = newPageLink;
              query = {};
            }

            return Request.get(link, query, newPageLink).then(function (res){
                _.each(res.data.results, function(val) {
                    if (val.reply) {
                        val.replyAdded = true;
                    }
                });
                return res.data;
            });
        };

        reviews.updateReview = function (id, data) {
            return Request.patch( 'shop/' + utils.getCurrentShopSlug() + '/reviews/' + id + '/', data).then(function (res){
                return res.data;
            });
        };

        reviews.sendInvitation = function(data) {
            return Request.post( 'shop/' + utils.getCurrentShopSlug() + '/invitation/', data).then(function (res){
                return res.data;
            });
        };

        return reviews;
    });