/* eslint-disable consistent-return */
'use strict';

angular
  .module('app')
  .service('AuthExtranet', function (
    $location,
    $window,
    $rootScope,
    Request,
    GTM,
    diviacLocalStorage,
    localStorageService,
    landing,
    $uibModal,
    shopService,
    gettextCatalog,
    utils,
    selectedLanguage,
    config
  ) {
    const AuthExtranet = {},
      aud =
        window.location.origin === 'https://business.padi.com'
          ? '503u87mvc25i53veank5m32e6i'
          : '7u0q6o7jbqck26k2adbdu6qbjk',
      sub = utils.getCookie(
        `CognitoIdentityServiceProvider.${aud}.LastAuthUser`
      ),
      cognitoCookieName = `CognitoIdentityServiceProvider.${aud}.${sub}`,
      refreshTokenCookieName = `${cognitoCookieName}.refreshToken`,
      idTokenCookieName = `${cognitoCookieName}.idToken`;

    $rootScope.permissions = {};

    AuthExtranet.registration = function (credentials) {
      GTM.event('user_registration_email_submitted');

      var _this = this;
      return Request.post('account/register/', credentials).then(
        function (res) {
          _this.handler(res.data);

          GTM.event('user_registration_email_completed');

          return $rootScope.showNotify(
            'Your account was successfully created.'
          );
        },
        function (error) {
          GTM.event('user_registration_email_error');

          throw _this.handleError(error);
        }
      );
    };

    AuthExtranet.registrationClaim = function (credentials) {
      credentials.shop = diviacLocalStorage.get('choosenShop').id;
      // console.log(credentials, diviacLocalStorage.get('choosenShop'));

      GTM.event('user_registration_email_submitted');

      var _this = this;
      return Request.post('shop/claiming/', credentials).then(
        function (res) {
          _this.handler(res.data);
          GTM.event('user_registration_email_completed');

          return $rootScope.showNotify('Shop was successfully claimed.');
        },
        function (error) {
          GTM.event('user_registration_email_error');

          throw _this.handleError(error);
        }
      );
    };

    AuthExtranet.login = function (credentials) {
      GTM.event('user_login_email_submitted');

      var _this = this;
      return Request.post('account/login/', credentials).then(
        function (res) {
          _this.handler(res.data);
          return res.data;
        },
        function (error) {
          GTM.event('user_login_email_error');

          throw _this.handleError(error);
        }
      );
    };

    AuthExtranet.handleError = function (error) {
      if (error.status === 403) {
        return [error.statusText];
      }

      if (error.data) {
        var messages = [];
        _.each(error.data, function (val) {
          messages.push(val[0]);
        });

        return messages;
      }
      return false;
    };

    AuthExtranet.handler = function (data, preventRedirect) {
      var _this = this;

      GTM.event('business_listing_overall_start');

      if (data.token) {
        _this.saveToken(data.token).saveUser(data.user);

        diviacLocalStorage.set('userSettings', '');

        AuthExtranet.checkUserShop(data.user);
        AuthExtranet.checkShopStatus(false, preventRedirect);
      } else {
        // $location.path('/');
        utils.login();
        // $location.path(utils.createURL('homepage'));
      }
    };

    AuthExtranet.redirectBack = function (reload, path) {
      if (reload) {
        // $window.location.href = path || '/';
        $window.location.href = path || utils.login();
      } else {
        // $location.path(path || '/');
        path ? $location.path(path) : utils.login();
        AuthExtranet.checkShopLanguage();
      }
    };

    AuthExtranet.setIntercom = function (user) {
      try {
        // eslint-disable-next-line new-cap
        window.Intercom('boot', {
          email: user.email,
          created_at: user.accountRegistrationDate,
          name: user.firstName + ' ' + user.lastName,
          user_id: user.id,
        });
      } catch (e) {
        console.error(e);
      }

      return this;
    };

    AuthExtranet.saveToken = function (token) {
      localStorage.setItem('login_date', moment().format('YYYY-MM-DD'));
      localStorage.setItem('token', token);

      return this;
    };

    AuthExtranet.getToken = function () {
      return localStorage.getItem('token');
    };

    AuthExtranet.checkUserShop = function (data) {
      // manager with no shops
      if (data.isProDiver) {
        if (
          !diviacLocalStorage.get('choosenShop') &&
          data.managedShops.length
        ) {
          diviacLocalStorage.set('choosenShop', data.managedShops[0].shop);
          localStorageService.set('choosenShop', data.managedShops[0].shop);
          $rootScope.selectedShop = data.managedShops[0].shop;
        }
        return this;
      } else if (
        !data.isStaff &&
        (!data.managedShops || data.managedShops.length < 1)
      ) {
        // AuthExtranet.logout();
        return $rootScope.showNotify(
          gettextCatalog.getString('You have no shops to manage')
        );
      } else if (
        !diviacLocalStorage.get('choosenShop') &&
        _.isArray(data.managedShops) &&
        data.managedShops.length
      ) {
        diviacLocalStorage.set('choosenShop', data.managedShops[0].shop);
        localStorageService.set('choosenShop', data.managedShops[0].shop);
        $rootScope.selectedShop = data.managedShops[0].shop;

        GTM.event('user_login_email_completed');
        $rootScope.showNotify(
          gettextCatalog.getString('You are successfully logged in')
        );
      } else if (
        data.isStaff &&
        (!data.managedShops || data.managedShops.length < 1)
      ) {
        return $rootScope.showNotify(
          gettextCatalog.getString('Select a shop to manage')
        );
      }
      return this;
    };

    AuthExtranet.checkShopStatus = function (reload, preventRedirect) {
      // check existing shop
      var user = AuthExtranet.getUser();
      if (user) {
        if (user.isProDiver && !localStorage.choosenShop) {
          if (window.location.pathname === utils.createURL('homepage')) {
            $location.path(utils.createURL('homepage_home'));
          }
          return true;
        }
        if (localStorage.choosenShop) {
          $rootScope.selectedShop =
            localStorageService.get('choosenShop') ||
            diviacLocalStorage.get('choosenShop');
          return landing
            .checkUserStatus($rootScope.selectedShop.slug)
            .then(function (res) {
              if (res.isClaimed && !res.isManager && !$rootScope.user.isStaff) {
                $rootScope.managers = res.managers[0];
                $rootScope.selectedShop = false;

                AuthExtranet.alreadyClaimedModal(reload);
              } else {
                shopService.updateShopList(reload);
              }

              AuthExtranet.enterShop(false, preventRedirect);
            });
        } else if (user.isStaff) {
          // $location.path('/home/');
          if (window.location.pathname === utils.createURL('homepage')) {
            $location.path(utils.createURL('homepage_home'));
          }
        }
      } else {
        utils.login();
        // if (reload) {
        //   // window.location.href = '/login/';
        //   window.location.href = utils.createURL('login');
        // }
        // // $location.path('/login/');
        // $location.path(utils.createURL('login'));
      }

      return this;
    };

    AuthExtranet.enterShop = function (reload, preventRedirect) {
      var user = this.getUser();
      if (!user) return;
      if (
        !preventRedirect ||
        window.location.pathname === utils.createURL('homepage')
      ) {
        AuthExtranet.redirectBack(reload, utils.createURL('homepage_home'));
      }
      $rootScope.hideHeader = false;
    };

    AuthExtranet.checkShopLanguage = function () {
      var user = this.getUser(),
        currentShop = AuthExtranet.getChoosenShop(),
        currentLang = selectedLanguage || utils.getCookie('travel_language');
      // console.log('checkShopLanguage', user, currentShop);
      if (!user || user.isStaff || !currentShop || utils.isProDiverShop()) {
        return;
      }

      shopService.getShopLanguage(currentShop.slug).then((data) => {
        // console.log('getShopLang', data);
        if (!data.defaultLanguage) {
          $uibModal.open({
            backdrop: 'static',
            windowClass: 'lang-select-popup',
            animation: false,
            templateUrl: '../templates/_lang-select-popup.html',
            controller: 'LanguagePopupController',
            controllerAs: 'langPopup',
            keyboard: false,
          });
        } else if (data.defaultLanguage !== currentLang) {
          utils.setLanguage(data.defaultLanguage);
          window.location.href =
            utils.generateLangUrl(data.defaultLanguage) +
            window.location.search;
        }
      });
    };

    AuthExtranet.saveUser = function (data) {
      GTM.variables({
        user_id: data.id,
        user_email: data.email,
        user_first_name: data.firstName,
        user_last_name: data.lastName,
        user_services: data.services,
        user_type: 'Registered',
        user_registration_date: data.accountRegistrationDate,
        user_hash: data.accountIntercomHash,
      });
      _.each(data.managedShops, function (managedShop) {
        managedShop.shop.affiliateProfileKind =
          managedShop.affiliateProfileKind;
      });

      return localStorage.setItem('user', JSON.stringify(data));
    };

    AuthExtranet.tokenRefresh = function () {
      const refreshToken = utils.getCookie(refreshTokenCookieName);
      if (!refreshToken) {
        return false;
      }
      Request.post(
        window.location.origin === 'https://business.padi.com'
          ? 'https://api.global-prod.padi.com/auth/api/oauth/refresh'
          : 'https://api-stage.global-np.padi.com/auth/api/oauth/refresh/',
        {
          clientId: utils.getCookie('padi_aud') || aud,
          refreshToken,
        }
      ).then(function ({ data }) {
        const params = {
          expires: data.expiresIn,
          domain: '.' + window.location.hostname.split('.').splice(1).join('.'),
          path: '/',
        };
        utils.setCookie(idTokenCookieName, data.idToken, params);
      });
    };

    AuthExtranet.alreadyClaimedModal = function (reload) {
      if ($rootScope.user && $rootScope.user.isStaff) {
        return;
      }
      var modal = $uibModal.open({
          animation: false,
          templateUrl: '../templates/_already_claimed.html',
          controller: 'ModalClaimCtrl',
          controllerAs: 'claimModal',
          keyboard: false,
        }),
        _this = this;

      modal.result.then(
        function () {
          _this.handleClaimedShop();

          if (reload) {
            window.location.reload();
          }
        },
        function () {
          _this.handleClaimedShop();

          if (reload) {
            window.location.reload();
          }
        }
      );
    };

    AuthExtranet.handleClaimedShop = function () {
      if ($rootScope.user.isStaff) {
        return false;
      }
      if ($rootScope.user.managedShops) {
        $rootScope.choosenShop = $rootScope.user.managedShops[0].shop;
        $rootScope.selectedShop = $rootScope.user.managedShops[0].shop;
        diviacLocalStorage.set(
          'choosenShop',
          $rootScope.user.managedShops[0].shop
        );
      } else {
        $rootScope.choosenShop = false;
        diviacLocalStorage.set('choosenShop', '');
      }
    };

    AuthExtranet.openClaimModal = function (reload) {
      if ($rootScope.user && $rootScope.user.isStaff) {
        return;
      }

      var modalInstance = $uibModal.open({
        backdrop: 'static',
        animation: false,
        templateUrl: '../templates/_claim-modal.html',
        controller: 'ModalClaimCtrl',
        controllerAs: 'claimModal',
        keyboard: false,
      });

      modalInstance.result.then(
        function () {
          shopService.updateShopList();
          shopService.getChecklistStatus().then(function (data) {
            $rootScope.checkedList = data;
            if (reload) {
              window.location.reload();
            }
          });
        },
        function () {
          if (reload) {
            window.location.reload();
          }
        }
      );
    };

    AuthExtranet.contactUs = function (sendEvent) {
      if (sendEvent) {
        GTM.event('contact_form_open');
      }

      var modalInstance = $uibModal.open({
        animation: false,
        templateUrl: '../templates/_contact_us.html',
        controller: 'ModalClaimCtrl',
        controllerAs: 'claimModal',
        windowClass: 'contact-us',
      });

      modalInstance.result.then(
        function () {},
        function (err) {
          console.error(err);
        }
      );
    };

    AuthExtranet.getUser = function () {
      return localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : false;
    };

    AuthExtranet.logout = function () {
      localStorage.removeItem('token');
      localStorage.removeItem('choosenShop');
      localStorage.removeItem('newShop');
      localStorage.removeItem('user');
      localStorageService.remove('choosenShop');
      utils.deleteCookie(refreshTokenCookieName);
      utils.deleteCookie(idTokenCookieName);

      Request.post('account/logout/');

      return utils.login();
    };

    AuthExtranet.setShopFromAutosuggest = (selectedShop) => {
      shopService.getShopData(selectedShop.id).then((shop) => {
        AuthExtranet.setChoosenShop(shop, true);
        window.location.reload();
      });
    };

    AuthExtranet.getChoosenShop = function () {
      return (
        localStorageService.get('choosenShop') ||
        diviacLocalStorage.get('choosenShop')
      );
    };

    AuthExtranet.setChoosenShop = function (shop, preventRedirect) {
      diviacLocalStorage.set('choosenShop', shop);
      localStorageService.set('choosenShop', shop);
      if (!preventRedirect) {
        AuthExtranet.enterShop(true);
      }
    };

    AuthExtranet.activateAccount = function (key) {
      return Request.post('account/confirm/', { activation_key: key });
    };

    AuthExtranet.recoverPassword = function (email) {
      var _this = this;
      return Request.post('account/password/reset/', { email: email }).then(
        function (data) {
          $rootScope.showNotify(
            gettextCatalog.getString('Password reset e-mail has been sent')
          );

          return data;
        },
        function (error) {
          throw _this.handleError(error);
        }
      );
    };

    AuthExtranet.checkCookie = function () {
      var _this = this;

      return Request.get('account/get-userdata/').then(function (res) {
        res.data.token = utils.getCookie(idTokenCookieName);
        if (res.data) {
          _this.handler(res.data, true);
          $rootScope.user = _this.getUser();
          $rootScope.choosenShop = AuthExtranet.getChoosenShop();
        }
        _this.getPermissions();

        return res.data;
      });
    };

    AuthExtranet.resetPassword = function (data) {
      var _this = this;

      return Request.post('account/password/reset/confirm/', data).then(
        function (res) {
          $rootScope.showNotify(
            gettextCatalog.getString('Your password successfully changed')
          );
          // $location.path('/login/');

          $location.path(utils.createURL('login'));
          return res;
        },
        function (error) {
          throw _this.handleError(error);
        }
      );
    };

    AuthExtranet.getPermissions = function () {
      Request.get(`shops/${utils.getCurrentShopSlug()}/permissions/`).then(
        ({ data }) => {
          data.permissions[config.PERMISSION_PAGE] = data.isAdmin;

          $rootScope.permissions = data.permissions;
        }
      );
    };

    AuthExtranet.checkPermission = function (page) {
      if (Array.isArray(page)) {
        let isPermitted = false;

        page.forEach((p) => {
          if ($rootScope.permissions[p]) {
            isPermitted = true;
            return false;
          }
        });

        return isPermitted;
      }
      if ($rootScope.permissions[page]) {
        return true;
      }

      return false;
    };

    return AuthExtranet;
  });
