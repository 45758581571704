'use strict';

angular.module('app').directive('fitText', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, elem, attr) {
           $timeout(function() {
             var $text = $(elem),
                 $container = $text.parent(),
                 containerHeight = $container.outerHeight(),
                 originalText = $text.text().trim(),
                 temp = originalText;

             if (containerHeight < $text.outerHeight()) {
               while($container.outerHeight() < $text.outerHeight()) {
                   $text.text( temp = temp.substr(0, temp.length-1) );
               }
               $text.text( temp = temp.substr(0, temp.length-4) + '...');
             }
           });
        }
    };
})
