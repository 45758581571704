'use strict';

angular.module('app')
  .controller('ItineraryListCtrl', function ($q, $uibModal, $timeout, Upload, utils, gettextCatalog, info, itineraryService, $routeParams, $rootScope, $location) {
    var vm = this;

    vm.deleteItinerary = deleteItinerary;
    vm.save = save;
    vm.updateActive = updateActive;
    vm.showPopup = showPopup;
    vm.saveBase = saveBase;
    vm.getTimeOptions = utils.getTimeOptions;
    vm.setTab = setTab;
    vm.selectAll = selectAll;
    vm.massAction = massAction;
    vm.triggerLogisticSwitch = triggerLogisticSwitch;
    vm.massActions = {};

    vm.tab = 'Base';
    vm.massActionsChocies = [
      {title: gettextCatalog.getString('Copy'), id: 'copy'},
      {title: gettextCatalog.getString('Delete'), id: 'delete'},
      {title: gettextCatalog.getString('Preview logistics'), id: 'logistics'},
      {title: gettextCatalog.getString('Preview itineraries'), id: 'itineraries'},
      {title: gettextCatalog.getString('Apply start/end/logistics to selection'), id: 'mass-fill'}
    ];

    vm.add = {
      photosRead: [],
      days: [],
      highlights: [],
      referenceLocations: [],
    };

    var types = {
      core: {
        templateUrl: '../templates/_core-itinerary.html',
        controller: 'CoreItineraryCtrl',
        controllerAs: '$core',
      },
      base: {
        templateUrl: '../templates/_base-itinerary.html',
        controller: 'BaseItineraryCtrl',
        controllerAs: '$base',
      },
      route: {
        templateUrl: '../templates/_route-itinerary.html',
        controller: 'RouteItineraryCtrl',
        controllerAs: '$route',
      },
      tripStart: {
        templateUrl: '../templates/_trip-start-itinerary.html',
        controller: 'TripStartCtrl',
        controllerAs: '$trip',
      },
      tripEnd: {
        templateUrl: '../templates/_trip-end-itinerary.html',
        controller: 'TripEndCtrl',
        controllerAs: '$trip',
      },
      logistics: {
        templateUrl: '../templates/_logistics-itinerary.html',
        controller: 'LogisticsItineraryCtrl',
        controllerAs: '$logistic',
      },
    };

    init(true);

    function init(generateCheckboxes) {
      vm.loading = true;

      itineraryService.getCore().then(function (data) {
        if (generateCheckboxes) {
          _.each(data, function (val) {
            vm.massActions[val.id] = false;
          });
        }

        itineraryService['get' + vm.tab]().then(function (base) {
          vm.itineraries = utils.mergeCollections(data, base);
          if (!vm.itineraries.length) $rootScope.checkedList.itineraries = false;

          $timeout(function () {
            vm.loading = false;
          }, 300);
        });
      });
    }


    function deleteItinerary(itinerary) {
      utils.confirmationPopup().then(function () {
        itineraryService.deleteItinerary(itinerary.id).then(function () {
          vm.itineraries = _.reject(vm.itineraries, function (val) {
            return val.id === itinerary.id;
          });
        });
      });
    }

    function save() {
      if (vm.itineraries.length) {
        $rootScope.checkedList.itineraries = true;
      }

      $location.path(utils.createURL('itinerary_price'));
    }


    function updateActive(itinerary) {
      vm.loading = true;

      itineraryService.update(itinerary.id, {isActive: !itinerary.isActive}).catch(function (err) {
        vm.loading = false;
      }).then(function (res) {
        itinerary.isActive = res.isActive;
        $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved'));
        vm.loading = false;
      });
    }

    function showPopup(type, itinerary) {
      var modal = $uibModal.open({
        animation: true,
        windowClass: 'modal modal-slide-in-right',
        templateUrl: types[type].templateUrl,
        controller: types[type].controller,
        openedClass: 'itinerary-right-modal modal-open',
        resolve: {
          itinerary: angular.copy(itinerary),
        },
        controllerAs: types[type].controllerAs,
      });
      modal.rendered.then(function () {
        if ($('.header-scroll').hasClass('fixed')) {
          $('.modal-dialog').css('top', '217px');
        } else {
          $('.modal-dialog').css('top', '');
        }
      });
      modal.result.then(init);
    }

    function setTab(tab) {
      if (vm.tab !== tab) {
        init();

        vm.tab = tab;
      }
    }

    function saveBase(itinerary) {
      if (itinerary.length) {
        itineraryService.saveBase(itinerary);
      }
    }

    function triggerLogisticSwitch(itinerary) {
      itineraryService.setLogisticsSwitch(itinerary);
    }

    function selectAll() {
      $timeout(function () {
        _.each(vm.massActions, function (val, key) {
          vm.massActions[key] = vm.selectedAll;
        });
      });
    }

    function massAction() {
      var massActionIds = [];
      _.each(vm.massActions, function (val, key) {
        if (val === true) massActionIds.push(+key);
      });

      if (!massActionIds.length) {
        return $rootScope.showNotify(gettextCatalog.getString('Please select itineraries'));
      }

      vm.loading = true;

      if (vm.actionSelected === 'delete') {
        var modal = $uibModal.open({
          animation: true,
          controller: 'ModalClaimCtrl',
          controllerAs: 'claimModal',
          windowClass: 'contact-us',
          templateUrl: '../templates/_form-change-status.html',
        });

        modal.result.then(function () {
          itineraryService.massDelete({ itinerary: massActionIds }).then(function () {
            init(true);
          }).catch(function () {
            vm.loading = false;
          });
        }, function () {
          vm.loading = false;
        });
      } else if (vm.actionSelected === 'copy') {
        var modal = $uibModal.open({
          animation: true,
          controller: 'ModalClaimCtrl',
          controllerAs: 'claimModal',
          windowClass: 'contact-us',
          templateUrl: '../templates/_form-change-status.html',
        });

        modal.result.then(function () {
          itineraryService.massCopy({ itinerary: massActionIds }).then(function () {
            init(true);
          }).catch(function () {
            vm.loading = false;
          });
        }, function () {
          vm.loading = false;
        });
      } else if (vm.actionSelected === 'itineraries') {
        itineraryService.previewFull({ itinerary: massActionIds }).then(function (data) {
          vm.loading = false;
          var modal = $uibModal.open({
            animation: true,
            openedClass: 'itinerary-popup modal-open',
            templateUrl: '../templates/_itinerary-popup.html',
            controller: 'ItineraryPreviewCtrl',
            resolve: {
              itineraries: function () {
                return data;
              },
            },
            controllerAs: '$ctrl',
          });
        }).catch(function () {
          vm.loading = false;
        });
      } else if (vm.actionSelected === 'logistics') {
        itineraryService.previewLogistics({ itinerary: massActionIds }).then(function (data) {
          vm.loading = false;
          var modal = $uibModal.open({
            animation: true,
            openedClass: 'itinerary-popup modal-open',
            templateUrl: '../templates/_logistics-preview.html',
            controller: 'LogisticsPreviewCtrl',
            resolve: {
              itineraries: function () {
                return data;
              },
            },
            controllerAs: '$ctrl',
          });
        });
      } else if (vm.actionSelected === 'mass-fill') {
        vm.loading = false;

        if (massActionIds.length < 2) {
          return $rootScope.showNotify(gettextCatalog.getString('You should select atleast two itineraries'));
        }

        var modal = $uibModal.open({
          animation: true,
          openedClass: 'itinerary-popup modal-open',
          templateUrl: '../templates/_apply-start-end.html',
          controller: 'ApplyStartEndCtrl',
          resolve: {
            itineraries: function () {
              return vm.itineraries;
            },
            selectedItems: function () {
              return massActionIds;
            },
          },
          controllerAs: '$ctrl',
        });

        modal.result.then(function () {
          init(true);
        });
      } else if (vm.actionSelected === 'logistics-copy') {
        vm.loading = false;

        if (massActionIds.length < 2) {
          return $rootScope.showNotify(gettextCatalog.getString('You should select atleast two itineraries'));
        }

        var modal = $uibModal.open({
          animation: true,
          openedClass: 'itinerary-popup modal-open',
          templateUrl: '../templates/_logistics-copy.html',
          controller: 'CopyLogisticsCtrl',
          resolve: {
            itineraries: function () {
              return vm.itineraries;
            },
            selectedItems: function () {
              return massActionIds;
            },
          },
          controllerAs: '$ctrl',
        });

        modal.result.then(function () {
          init(true);
        });
      }
    }
  });
