'use strict';

angular.module('app')
  .service('photoService', function ($q, $rootScope, fileReader, Upload, utils, diviacLocalStorage, Request) {
    var photo = {};


    photo.fileUpload = function (file, type, isDiveCenter) {
      var header = {
          'Content-Type': 'application/json',
        },
        token = localStorage.getItem('token'),
        slug = utils.getCurrentShopSlug();

      if (token) {
        header.Authorization = 'Bearer ' + token;
      }

      var data = {},
        tail = '',
        method = 'PUT';

      // if (type === 'background') {
      //  data.picture_background = file;
      // } else if (type === 'logo') {
      //  data.picture = file;
      // } else if (type === 'photos') {
      //  data.image = file;
      //  data.shop = slug;
      //  method = 'POST';
      // } else if (type === 'species') {
      //  data.image = file;
      //  data.shop = slug;
      //  method = 'POST';
      // } else if (type === 'spa') {
      //  data.image = file;
      //  data.shop = slug;
      //  method = 'POST';
      // }

      switch (type) {
      case 'background':
        data.picture_background = file;
        if (isDiveCenter) {
          tail = 'dive-center/';
        }
        break;
      case 'logo':
        data.picture = file;
        break;
      case 'boat-photos':
        data.image = file;
        data.shop = slug;
        method = 'POST';
        break;
      case 'photos':
        data.image = file;
        data.shop = slug;
        method = 'POST';
        break;
      case 'species':
        data.image = file;
        data.shop = slug;
        method = 'POST';
        break;
      case 'restaurant':
        data.image = file;
        data.shop = slug;
        method = 'POST';
        type = 'restaurant-photos';
        break;
      case 'resort':
        data.image = file;
        data.shop = slug;
        type = 'resort-photos';
        method = 'POST';
        break;
      case 'spa':
        data.image = file;
        data.shop = slug;
        method = 'POST';
        type = 'spa-photos';
        break;
      case 'layout':
        data.image = file;
        data.shop = slug;
        method = 'POST';
        type = 'layout';
        break;
      }

      return Upload.upload({
        method: method,
        url: utils.apiURL + 'shop/' + slug + '/' + type + '/' + tail,
        headers: header,
        arrayKey: '',
        data: data,
      }).then(function (res) {
        // SUCCESS EVENT
        console.warn('SUCCESS: ', res);
        return res.data;
      }, function (err) {
        // ERROR EVENT
        console.warn('ERROR: ', err);
        throw err;
      }, function (evt) {
        // PROGRESS EVENT
        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        return progressPercentage;
      });
    };

    photo.deletePhoto = function (id, type, tail) {
      var url;

      if (type === 'photos' || type === 'boat-photos') {
        url = 'shop/image/' + id + '/';
      } else if (type === 'species') {
        url = 'shop/species-image/' + id + '/';
      }  else if (type === 'spa') {
        url = 'shop/image/' + id + '/';
      }  else if (type === 'resort') {
        url = 'shop/image/' + id + '/';
      }  else if (type === 'restaurant') {
        url = 'shop/image/' + id + '/';
      } else if (type === 'layout') {
        url = 'shop/layout/' +  id + '/';
      } else {
        url = 'shop/' + utils.getCurrentShopSlug() + '/' + type + '/';
        if (type === 'background' && tail) {
          url += tail;
        }
      }

      return Request.del(url).then(function (res) {
        return res.data;
      });
    };

    photo.getPhotos = function (operator) {
      return Request.get('shop/' + (operator || utils.getCurrentShopSlug()) + '/photos/').then(function (res) {
        return res.data;
      });
    };

    photo.setPhotos = function (data) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/photos/ordering/', data).then(function (res) {
        return res.data;
      });
    };

    photo.getLogo = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/logo/').then(function (res) {
        return res.data;
      });
    };

    photo.getSpecies = function (operator) {
      return Request.get('shop/' + (operator || utils.getCurrentShopSlug()) + '/species/').then(function (res) {
        return res.data;
      });
    };

    photo.setSpecies = function (data) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/species/ordering/', data).then(function (res) {
        return res.data;
      });
    };

    photo.getBackground = function (operator, tail) {
      if (!tail) tail = '';
      return Request.get('shop/' + (operator || utils.getCurrentShopSlug()) + '/background/' + tail).then(function (res) {
        return res.data;
      });
    };

    photo.setVirtualTour = function (data, tail) {
      return Request.patch('shop/' + utils.getCurrentShopSlug() + '/background/' + tail, data).then(function (res) {
        return res.data;
      });
    };

    photo.getRestaurant = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/restaurant-photos/').then(function (res) {
        return res.data;
      });
    };

    photo.setRestaurant = function (data) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/restaurant-photos/ordering/', data).then(function (res) {
        return res.data;
      });
    };

    photo.getSpa = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/spa-photos/').then(function (res) {
        return res.data;
      });
    };

    photo.setSpa = function (data) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/spa-photos/ordering/', data).then(function (res) {
        return res.data;
      });
    };

    photo.getResort = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/resort-photos/').then(function (res) {
        return res.data;
      });
    };

    photo.setResort = function (data) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/resort-photos/ordering/', data).then(function (res) {
        return res.data;
      });
    };

    photo.getBoatPhotos = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/boat-photos/').then(function (res) {
        return res.data;
      });
    };


    photo.setBoatPhotos = function (data) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/boat-photos/ordering/', data).then(function (res) {
        return res.data;
      });
    };


    photo.getBoatLayout = function () {
      return Request.get('shop/' + utils.getCurrentShopSlug() + '/layout/').then(function (res) {
        return res.data;
      });
    };


    photo.setBoatLayout = function (data) {
      return Request.put('shop/' + utils.getCurrentShopSlug() + '/layout/ordering/', data).then(function (res) {
        return res.data;
      });
    };


    return photo;
  });
