'use strict';

angular.module('app')
  .controller('LandingCtrl', function ($scope, $rootScope, landing, AuthExtranet, $uibModal, $location, diviacLocalStorage, localStorageService, info, GTM, gettextCatalog, utils) {
    var vm = this;

    vm.search = {};
    vm.kind = [];
    console.log('landing.controllers');

    getMainInfo();

    vm.autoSuggest = function (value) {
      if (value.length < 3) {
        return false;
      }

      getMainInfo();
      return landing.search(value);
    };

    if ($location.search().title) {
      vm.autoSuggest($location.search().title).then(function (data) {
        if (data.length) {
          vm.name = data[0].title;
          filterShopKind(data[0]);
        }
      });
    }

    vm.openElement = function () {
      $('.selectize-input').click();
    };

    vm.filterShopKind = filterShopKind;

    vm.getMainInfo = getMainInfo;

    function getMainInfo() {
      vm.kind = [];
      vm.shopKindList = _(info.shop.SHOP_KIND).map(function (el) {
        if (el[0] == 0 || el[0] == 10 || el[0] == 20) {return el;}
      }).compact().value();
    }

    function filterShopKind($item) {
      vm.shop = $item;
      vm.selectedShop = true;

      vm.shopKindList = _(vm.shopKindList).map(function (el) {
        if (el[0] == +$item.kind) {return el;}
      }).compact().value();
      vm.kind = vm.shopKindList[0];
    }

    vm.setInfo = function () {
      if (!vm.name || _.isEmpty(vm.kind)) {
        GTM.event('business_listing_create_error');
        return $rootScope.showNotify(gettextCatalog.getString('Please fill in all fields'));
      }

      if (vm.shop) {
        diviacLocalStorage.set('choosenShop', vm.shop);
        localStorageService.set('choosenShop', vm.shop);
        GTM.event('business_listing_create_existing');
      } else {
        vm.shop = {
          title: vm.name,
          kind: vm.kind[0],
        };
        diviacLocalStorage.set('newShop', vm.shop);
        GTM.event('business_listing_create_new');
      }

      if (vm.shop.kind == 0) {
        GTM.event('business_listing_create_dive_center');
      } else if (vm.shop.kind == 10) {
        GTM.event('business_listing_create_liveaboard');
      } else if (vm.shop.kind == 20) {
        GTM.event('business_listing_create_dive_resort');
      }

      if ($rootScope.user) {
        if (diviacLocalStorage.get('newShop')) {
          landing.createNewShop(diviacLocalStorage.get('newShop')).then(function (res) {
            diviacLocalStorage.set('newShop', null);
            diviacLocalStorage.set('choosenShop', res);
            localStorageService.set('choosenShop', res);
            $rootScope.selectedShop = res;

            AuthExtranet.checkShopStatus();
          });
        } else {
          AuthExtranet.checkUserShop($rootScope.user).checkShopStatus();
        }
      } else {
        utils.login()
        // $location.path(utils.createURL('login'));
      }
    };

    vm.openClaimModal = function () {
      var modalInstance = $uibModal.open({
        animation: false,
        templateUrl: '../templates/_claim-modal.html',
        controller: 'ModalClaimCtrl',
        controllerAs: 'claimModal',
      });

      modalInstance.result.then(function (res) {
        console.log('success');
      }, function (err) {
        console.log(err);
      });
    };
  })

  .controller('ModalClaimCtrl', function ($scope, $timeout, Request, info, $uibModalInstance, AuthExtranet, GTM, utils, diviacLocalStorage, localStorageService, $location, $rootScope) {
    var vm = this;

    vm.auth = AuthExtranet;

    vm.registration = {
      credentials: {},
    };

    vm.registration.credentials.email = diviacLocalStorage.get('user').email;
    vm.registration.credentials.first_name = diviacLocalStorage.get('user').firstName;
    vm.registration.credentials.last_name = diviacLocalStorage.get('user').lastName;

    vm.createClaimAccount = function (credentials) {
      if ($scope.claimRegistrationForm.$invalid) {
        return vm.formSubmited = true;
      }

      vm.regErrors = false;

      vm.auth.registrationClaim(credentials).catch(function (data) {
        vm.regErrors = data;
      }).then(function (res) {
        $uibModalInstance.close('close');
        $location.path(utils.createURL('home_home'));
      });
    };

    // vm.contactTypes = info.common.CONTACT_US_KIND;
    vm.contact = {
      name: $rootScope.user ? $rootScope.user.fullName : null,
      email: $rootScope.user ? $rootScope.user.email : null,
    };

    $scope.center = {
      latitude: 50,
      longitude: 45,
    };
    $scope.control = {};


    $scope.dragInit = function (pin) {
      var dragFlag = false;

      google.maps.event.addListener($scope.control.getGMap(), 'mousemove', function (event) {
        if (dragFlag) {
          $scope.dragPoint = [{
            id: 'draggable1',
            latitude: event.latLng.lat(),
            longitude: event.latLng.lng(),
            draggable: true,
          }];

          $scope.$broadcast('gmMarkersUpdate');

          $scope.newPoint.latitude = event.latLng.lat();
          $scope.newPoint.longitude = event.latLng.lng();

          $scope.$digest();

          dragFlag = false;
        }
      });
      $('.place-pin').dragger({
        dragItem: '<img src="' + app.DEFAULTS.mapIcons.green + '" id="drag-mark">',
        dropCallback: function (e) {
          return dragFlag = true;
        },
      });
    };

    $scope.setValue = function (params) {
      console.log(params);
    };

    vm.moveTo = function (url) {
      $uibModalInstance.close('close');

      $location.path(url);
    };

    vm.redirectToTerms = function () {
      $uibModalInstance.close('close');

      if($rootScope.choosenShop) {
        $rootScope.choosenShop.showAgreementPopup = false;
      }
      diviacLocalStorage.set('choosenShop', $rootScope.choosenShop);
      localStorageService.set('choosenShop', $rootScope.choosenShop);

      if ($rootScope.checkedList && !$rootScope.checkedList.admin) {
        $location.path(utils.createURL('admin'));
      } else {
        $location.path(utils.createURL('vacations_agreement'));
      }
    };

    vm.showContactUs = function () {
      $uibModalInstance.close('close');

      vm.auth.contactUs();
    };

    vm.contactUs = function (form) {
      if (form.$invalid) {
        GTM.event('contact_form_error');

        return vm.contactUsSubmited = true;
      }

      GTM.event('contact_form_complete');

      vm.contactUsSubmited = false;

      vm.contact.kind = 60;

      Request.post('contact-us/', vm.contact).then(function (data) {
        $rootScope.showNotify('Your message has been sent. Thanks!');
        $uibModalInstance.close('close');
      });
    };

    vm.answer = function (ans) {
      console.log(ans);
      if (ans == 'yes') {
        $uibModalInstance.close('close');
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };

    vm.productAnswer = function (ans) {
      if (ans == 'yes') {
        $uibModalInstance.close('close');
      } else {
        $uibModalInstance.dismiss('cancel');
      }
    };
  });
