'use strict';

angular
  .module('app')
  .service('walletService', function (
    Request,
    $rootScope,
    utils,
    $timeout,
    $location,
    gettextCatalog,
    selectedLanguage
  ) {
    var isTestingEnv = _.find(
      ['localhost:9216', 'localhost', 'inprogress.rocks', 'comingsoon.rocks'],
      function (str) {
        return _.includes(window.location.host, str);
      }
    );
    function Wallet() {
      return {
        setToken: function (token) {
          this.config.userToken = token;
        },
        getToken: function () {
          return this.config.userToken;
        },
        config: {
          userToken: '',
          hwSite: isTestingEnv
            ? 'pay.uat.hyperwallet.com'
            : 'pay.hyperwallet.com',
        },

        loadHyperWallet: function (userToken, loadingCallbacks, elementId) {
          if (!userToken) return;
          this.setToken(userToken);
          if (!this.wallet) {
            var script = document.createElement('script'),
              lang = selectedLanguage || 'en';

            if (lang === 'zh-hant' || lang === 'zh-hans') lang = 'zh';

            script.type = 'text/javascript';
            script.src = `https://${this.config.hwSite}/rest/widgets/users/${userToken}/${lang}.v2_4_4.min.js`;
            script.onload = () => {
              this.wallet = window.HWWidgets;
              this.initPayeeVerification(elementId);
              if (loadingCallbacks) {
                this.config.loadingCallbacks = loadingCallbacks;
              }
            };
            document.body.appendChild(script);
          } else {
            this.initPayeeVerification(elementId);
            if (loadingCallbacks) {
              this.config.loadingCallbacks = loadingCallbacks;
            }
          }
        },
        initPayeeVerification: function (elementId = 'VerificationUI') {
          this.wallet.initialize((onSuccess) => {
            this.getAuthToken((response) => {
              onSuccess(response);
            });
          });

          this.wallet.users
            .configure({
              template: 'bootstrap3',
              el: document.getElementById(elementId),
            })
            .display(() => {});

          this.wallet.events.on(
            'widget:users:completed',
            (verificationObject, completionResult) => {
              // redirect payee to another page with the result, status and token
              console.info(verificationObject, completionResult);
            }
          );

          this.wallet.events.on('widget:loading:shown', () => {
            // code to add spinner or loading icon
            this.config.loadingCallbacks.start();
          });

          this.wallet.events.on('widget:loading:hidden', () => {
            // code to hide spinner or loading icon
            this.config.loadingCallbacks.finish();
          });

          this.wallet.events.on('widget:error:shown', (message) => {
            this.setError(message);
          });
        },
        initTransferMethods: function () {
          this.transferMethodsLib.initialize((onSuccess) => {
            this.getAuthToken((response) => {
              onSuccess(response);
            });
          });

          const hw = this.transferMethodsLib.transferMethods.configure({
            template: 'bootstrap3',
            el: document.getElementById('TransferMethodUI'),
            skipProfileFields: true,
          });
          if (this.config.transferToken) {
            hw.update(this.transferToken);
          } else {
            hw.create({
              profileType: this.config.profileType,
            });
          }

          this.transferMethodsLib.events.on(
            'widget:transfermethods:completed',
            () => {
              $location.path(utils.createURL('homepage_home'));
              $rootScope.showNotify(
                gettextCatalog.getString('Payment details saved')
              );
              $rootScope.checkedList.paymentDetails = true;
            }
          );

          this.transferMethodsLib.events.on('widget:loading:shown', () => {
            this.config.transferMethodLoadingCallback.show();
          });

          this.transferMethodsLib.events.on('widget:loading:hidden', () => {
            this.config.transferMethodLoadingCallback.hide();
          });

          this.transferMethodsLib.events.on('widget:error:shown', (message) => {
            this.setError(message);
          });
        },
        getAuthToken: function (callback) {
          return Request.get(
            `shop/hw-user/${this.config.userToken}/authentication-token/`
          ).then(function (res) {
            // return res.data;
            callback(res.data.authenticationToken);
          });
        },
        loadTransferMethods: function (
          userToken,
          loadingCallback,
          profileType,
          transferToken
        ) {
          if (!userToken) {
            loadingCallback();
            return;
          }
          this.config.userToken = userToken;
          this.config.transferMethodLoadingCallback = loadingCallback;
          this.config.profileType = profileType;
          this.config.transferToken = transferToken;

          if (!this.transferMethodsLib) {
            var script = document.createElement('script'),
              lang = selectedLanguage || 'en';

            if (lang === 'zh-hant' || lang === 'zh-hans') lang = 'zh';

            script.type = 'text/javascript';
            script.src = `https://${this.config.hwSite}/rest/widgets/transfer-methods/${userToken}/${lang}.v2_4_4.min.js`;
            script.onload = () => {
              this.transferMethodsLib = window.HWWidgets;
              this.initTransferMethods();
            };
            script.onerror = () => {
              this.setError(
                gettextCatalog.getString(
                  'Something went wrong with HyperWallet form loading!'
                )
              );
              this.config.transferMethodLoadingCallback.hide();
            };
            document.body.appendChild(script);
          } else {
            this.initTransferMethods();
          }
        },

        getHWInfo: function () {
          return Request.get(
            'shop/' + utils.getCurrentShopSlug() + '/payment-details/'
          ).then(function (res) {
            return res.data;
          });
        },

        setAccountIdentifier: function (id, data) {
          return Request.put(
            `shop/${utils.getCurrentShopSlug()}/transfer-methods/${id}/`,
            data
          );
        },
        setError: function (msg) {
          const errorField = document.getElementById('hw-errors');
          if (errorField) {
            errorField.innerHTML = msg;
          } else {
            $rootScope.showNotify(msg);
          }
        },
      };
    }

    return {
      Wallet,
    };
  });
