'use strict';

angular.module('app').directive('travelDatepicker', function ($timeout, $rootScope, Request, selectedLanguage, gettextCatalog) {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      range: '=',
      datesInOneField: '=',
      dateTo: '=',
      dateToInputId: '@',
      callback: '&callback',
      updateDuration: '=',
      durationModel: '=',
      totalGuests: '=',
      nights: '=',
      shopId: '=',
      roomsAndGuests: '=',
      roomSelected: '=',
      showLegend: '=',
      showDuration: '=',
      minDuration: '=',
      maxDuration: '=',
      pricesData: '=',
      isPackage: '=',
      validateStudentsPackage: '=',
      minDurationForStudents: '=',
      students: '=',
      monthNumber: '=',
      dateFromLabel: '@',
    },
    link: function (scope, elem, attr, ngModelCtrl) {
      elem[0].setAttribute('readonly', 'true');

      if (selectedLanguage !== 'en') {
        // Waiting for translation file loading
        var translationListener = $rootScope.$watch('translationsUploaded', function (newVal) {
          if (newVal) {
            initCalendar();
            translationListener();
          }
        });
      } else {
        initCalendar();
      }

      function initCalendar() {
        $timeout(function () {
          var calendar = new TravelCalendar({
            element: elem[0],
            dateFrom: ngModelCtrl.$modelValue,
            dateTo: scope.dateTo,
            monthNumber: scope.monthNumber || 36,
            language: selectedLanguage,
            dateFromLabel: scope.dateFromLabel || gettextCatalog.getString('From'),
            dateToLabel: gettextCatalog.getString('To'),
            showLegend: scope.showLegend,
            minDuration: scope.minDuration,
            maxDuration: scope.maxDuration,
            showDuration: scope.showDuration,
            roomAvailableLabel: gettextCatalog.getString('Available'),
            roomSoldOutLabel: gettextCatalog.getString('Sold-out'),
            roomNoInfoLabel: gettextCatalog.getString('On request'),
            selectBtnLabel: gettextCatalog.getString('Select'),
            closeBtnLabel: gettextCatalog.getString('Close'),
            clearBtnLabel: gettextCatalog.getString('Clear'),
            possibleStartDateLabel: gettextCatalog.getString('Possible start date'),
            possibleEndDateLabel: gettextCatalog.getString('Possible end date'),
            calendarErrorLabel: window.isSSR ? gettextCatalog.getString('Minimal duration {$ minNights $} nights. Maximum duration {$ maxNights $} nights.', {
              minNights: scope.minDuration,
              maxNights: scope.maxDuration,
            }) : gettextCatalog.getString('Minimal duration {{ minNights }} nights. Maximum duration {{ maxNights }} nights.', {
              minNights: scope.minDuration,
              maxNights: scope.maxDuration,
            }),
            dateErrorLabel: gettextCatalog.getString('Package must start on a date highlighted with a circle. Please choose new dates'),
            highlghtedDatesMsg: gettextCatalog.getString('Please choose an end date highlighted in blue'),
            isFixedPackage: scope.isPackage,
            students: scope.students,
            validateStudentsPackage: scope.validateStudentsPackage,
            minDurationForStudents: scope.minDurationForStudents || 5,
            calendarStudentsErrorLabel:  window.isSSR ? gettextCatalog.getString('Student packages must be {$ minNights $} nights or longer', {
              minNights: scope.minDurationForStudents || 5,
            }) : gettextCatalog.getString('Student packages must be {{ minNights }} nights or longer', {
              minNights: scope.minDurationForStudents || 5,
            }),
            nightsLabel: gettextCatalog.getString('nights'),
            onSelectDates: function (dates) {
              $timeout(function () {
                ngModelCtrl.$setViewValue(dates.dateFrom);
                elem[0].value = dates.dateFromFormatted;

                if (scope.dateToInputId) {
                  var elem2 = document.getElementById(scope.dateToInputId),
                    ctrl = angular.element(elem2).controller('ngModel');

                  ctrl.$setViewValue(dates.dateTo);
                  elem2.value = dates.dateToFormatted;

                  if (scope.updateDuration) {
                    scope.durationModel = dates.dateTo ? moment(dates.dateTo).diff(dates.dateFrom, 'd') : undefined;
                  }

                  if (scope.datesInOneField) {
                    formatInputValue(dates.dateFrom, dates.dateTo);
                  }
                }

                if (scope.callback) {
                  $timeout(function () {
                    scope.$eval(scope.callback);
                  });
                }
              });
            },
            rangePicker: scope.range || false,
            dateToInputId: scope.dateToInputId || null,
          });

          if (scope.datesInOneField && scope.dateTo && ngModelCtrl.$modelValue) {
            formatInputValue(ngModelCtrl.$modelValue, scope.dateTo);
          }

          function formatInputValue(from, to) {
            var momentDateFrom = moment(from),
              momentDateTo = moment(to),
              formatDateFrom = 'DD MMM YYYY';

            if (momentDateTo.format('YYYY') === momentDateFrom.format('YYYY')) {
              formatDateFrom = 'DD MMM';
            }

            if (from && to) {
              elem[0].value = momentDateFrom.format(formatDateFrom) + ' - ' + momentDateTo.locale(selectedLanguage).format('DD MMM YYYY');

              if (window.isSSR) {
                elem[0].value += ', ' + gettextCatalog.getPlural(scope.durationModel, '{$ count $} night', '{$ count $} nights', {count: scope.durationModel});
              } else {
                elem[0].value += ', ' + gettextCatalog.getPlural(scope.durationModel, '{{ count }} night', '{{ count }} nights', {count: scope.durationModel});
              }
            }
          }

          scope.$watch(function () {
            return ngModelCtrl.$modelValue;
          },
          function (newVal, oldVal) {
            if (newVal !== oldVal) {
              try {
                var date = moment(newVal).locale(selectedLanguage).format('L');

                if (!newVal) {
                  date = null;
                }

                elem[0].value = date;
                calendar.updateDates(newVal, scope.dateTo);
              } catch (err) {
                console.log(err);
              }
            }
          });

          if (scope.range) {
            scope.$watch(function () {
              return scope.dateTo;
            },
            function (newVal, oldVal) {
              if (newVal !== oldVal) {
                try {
                  var date = moment(newVal).locale(selectedLanguage).format('L');

                  document.getElementById(scope.dateToInputId).value = date;

                  calendar.updateDates(ngModelCtrl.$modelValue, newVal);
                } catch (err) {
                  console.log(err);
                }
              }
            });
          }

          scope.$watchCollection('roomsAndGuests', updateAvailableDates);

          scope.$watch('nights', updateAvailableDates);

          function updateAvailableDates() {
            if (scope.validateStudentsPackage && scope.roomsAndGuests) {
              calendar.updateNumbeOfStudents(scope.roomsAndGuests.students);
            }
            
            if (scope.shopId) {
              var guests;

              if (scope.roomsAndGuests.details) {
                guests = [];
                _.each(scope.roomsAndGuests.details, function (val) {
                  guests.push(val.nonDivers + val.divers + val.students);
                });
              } else {
                guests = scope.roomsAndGuests.divers + scope.roomsAndGuests.nonDivers + scope.roomsAndGuests.students;
              }

              if (!scope.isPackage) {
                Request.get('booking/resort/' + scope.shopId + '/availability/', {
                  date_after: moment().format('YYYY-MM-DD'),
                  date_before: moment().add(24, 'M').format('YYYY-MM-DD'),
                  guests: guests || 1,
                  nights: scope.nights || 7,
                  guests_split: scope.roomsAndGuests.details || undefined,
                }).then(function (res) {
                  calendar.updateAvailableDates(res.data);
                });
              }
            }
          }

          if (scope.updateDuration) {
            scope.$watch('durationModel', function (newVal) {
              if (ngModelCtrl.$modelValue) {
                $timeout(function () {
                  scope.dateTo = moment(ngModelCtrl.$modelValue).add(newVal, 'days').format('YYYY-MM-DD');

                  calendar.updateDates(ngModelCtrl.$modelValue, scope.dateTo);
                  calendar.updateDurationText(newVal + ' ' +  gettextCatalog.getString('nights'));
                });
              }
            });
          }

          scope.$watch('pricesData', function (val) {
            if (val && val.length) {
              calendar.updatePricesData(val);
            }
          });
        });
      }
    },
  };
});
