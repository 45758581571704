'use strict';

angular.module('app')
  .controller('ScheduleCtrl', function ($q, $rootScope, $timeout, $location, itineraryService, scheduleService, utils, gettextCatalog) {
    var vm = this;
    vm.saveTrip = _.debounce(scheduleService.saveTrip, 350);
    vm.changeItinerary = changeItinerary;
    vm.deleteTrip = deleteTrip;
    vm.changedDate = changedDate;
    vm.saveNewTrip = saveNewTrip;
    vm.addNewTrip = addNewTrip;
    vm.saveTrips = saveTrips;
    vm.nextPage = nextPage;

    activate();

    function activate() {
      vm.today = new Date();
      vm.loading = true;

      $q.all([
        itineraryService.getList(),
        scheduleService.getList()
      ]).then(function (val) {
        vm.itineraryList = val[0];
        vm.tripsList = val[1].data;
        vm.allList = val[1].allList;

        if (!vm.tripsList.length) {
          addNewTrip();
        } else {
          $rootScope.checkedList.schedule = true;
        }

        vm.loading = false;
      });
    }

    function nextPage(page) {
      vm.loading = true;
      scheduleService.getList(page).then(function (val) {
        $timeout(function () {
          vm.tripsList = val.data;
          vm.allList = val.allList;
          vm.loading = false;
        });
      });
    }

    function addNewTrip(trip) {
      if (trip) {
        return vm.tripsList.push({
          duration: trip.duration,
          startDate: trip.endDate ? moment(trip.endDate).format('YYYY-MM-DD') : '',
          itinerary: trip.itinerary,
          isActive: true,
        });
      }

      return vm.tripsList.push({
        duration: null,
        startDate: null,
        itinerary: null,
        isActive: true,
      });
    }

    function saveNewTrip(trip) {
      if (trip.startDate && trip.itinerary) {
        vm.loading = true;

        scheduleService.addTrip(trip).then(function (data) {
          $rootScope.showNotify(gettextCatalog.getString('New trip successfully added'));

          vm.loading = false;

          $rootScope.checkedList.schedule = true;
          trip.id = data.id;
        }).catch(function () {
          vm.loading = false;
        });
      } else {
        $rootScope.showNotify(gettextCatalog.getString('Fill in all information'));
      }
    }

    function saveTrips() {
      var error = false,
        newTrips = [];

      _.each(vm.tripsList, function (trip) {
        if (!trip.id) {
          if (trip.startDate && trip.itinerary) {
            newTrips.push(scheduleService.addTrip(trip));
          } else {
            error = true;
          }
        }
      });

      if (error) {
        $rootScope.showNotify(gettextCatalog.getString('Fill in all information'));
      } else {
        if (!newTrips.length) {
          $location.path(utils.createURL('itinerary_price'));
          return $rootScope.showNotify(gettextCatalog.getString('Information was successfully saved'));
        }

        vm.loading = true;
        $q.all(newTrips).then(function () {
          $rootScope.checkedList.schedule = true;
        }).catch(function () {
          vm.loading = false;
        }).then(function () {
          scheduleService.getList().then(function (res) {
            vm.loading = false;

            $rootScope.showNotify(gettextCatalog.getString('New trip successfully added'));

            vm.tripsList = res.data;
            vm.allList = res.allList;
          });
        });
      }
    }

    function changeItinerary(trip) {
      if (trip.itinerary) {
        var duration = _.find(vm.itineraryList, {id: trip.itinerary});

        trip.duration = duration.length;
      }

      changedDate(trip);
    }

    function changedDate(trip) {
      if (trip.duration && trip.startDate) {
        trip.endDate = moment(trip.startDate).add(trip.duration, 'days').toDate();
      }

      vm.saveTrip(trip);
    }

    function deleteTrip(trip) {
      utils.confirmationPopup().then(function () {
        if (trip.id) {
          scheduleService.deleteTrip(trip).then(function () {
            removeFromList();
          });
        } else {
          removeFromList();
        }
      });

      function removeFromList() {
        vm.tripsList = _.reject(vm.tripsList, function (val) {
          return val.id === trip.id;
        });

        if (!vm.tripsList.length) {
          addNewTrip();
          $rootScope.checkedList.schedule = false;
        }
      }
    }
  });
