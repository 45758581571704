'use strict';

angular.module('app')
    .service('rateService', function ($q, Request, diviacLocalStorage, $rootScope, info, utils) {
        var rateService = {};

        rateService.getList = function () {
            return Request.get('booking/' + utils.getCurrentShopSlug() + '/rates/').then(function (res) {
                _.each(res.data, function(val) {
                    val.diverItems = _.filter(val.items,{ guestType: 20 });
                    val.allGuestsItems = _.filter(val.items,{ guestType: 10 });
                });
                return res.data;
            });
        }

        rateService.getRate = function (id) {
            return Request.get('booking/' + utils.getCurrentShopSlug() + '/rates/' + id + '/').then(function (res) {
                return res.data;
            });
        }
        
        rateService.deleteRate = function (id) {
            return Request.del('booking/' + utils.getCurrentShopSlug() + '/rates/' + id + '/').then(function (res) {
                return res.data;
            });
        }

        rateService.createRate = function (data) {
            return Request.post('booking/' + utils.getCurrentShopSlug() + '/rates/', data).then(function (res) {
                return res.data;
            });
        }

        rateService.updateRate = function (data) {
            return Request.put('booking/' + utils.getCurrentShopSlug() + '/rates/' + data.id + '/',data).then(function (res) {
                return res.data;
            });
        }

        return rateService;
    });