'use strict';

angular
  .module('app')
  .controller('GroupTripsListCtrl', function (
    groupTrips
  ) {
    var vm = this;
    vm.loading = true;
    vm.deleteTrip = deleteTrip;
    vm.updateLanguage = updateLanguage;
    
    groupTrips.getTripLanguage().then(function (data) {
      vm.language = data.groupTripEditLanguage;
    });

    getTrips();

    function getTrips() {
      groupTrips.getTrips().then(function (trips) {
        vm.tripsList = trips;
        vm.loading = false;
      });
    }

    function updateLanguage() {
      groupTrips.setTripLanguage({
        groupTripEditLanguage: vm.language,
      });
    }

    function deleteTrip(tripId) {
      vm.loading = true;
      groupTrips.deleteTrip(tripId).then(getTrips);
    }
  });
