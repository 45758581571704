'use strict';

angular.module('app').directive('coordinatesValidate', function () {
    var COORDS_REGEXP = /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/;

    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, element, attr, ctrl) {
        
        ctrl.$validators.coords = function(modelValue) {
          return ctrl.$isEmpty(modelValue) || COORDS_REGEXP.test(modelValue);
        };

        function inputValue(val) {
          if (val) {
            var coords = val.replace(/[^\s\-,.0-9]/g, '');

            ctrl.$setViewValue(coords);
            ctrl.$render();
            return coords;
          }
          return undefined;
        }            

        ctrl.$parsers.push(inputValue);
      }
    };
});